import { VehicleBatch } from '../../models/vehicle';
import { apiSlice } from '../../api/baseApi';

interface ListAndMergeVehicleBatchesParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeVehicleBatchesResponse {
  count: number;
  data: VehicleBatch[];
}

export const vehicleBatchesList = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeVehicleBatchesList: builder.query<ListAndMergeVehicleBatchesResponse, ListAndMergeVehicleBatchesParams>(
      {
        query: ({ query }) => `batch${query && `?${query}`}`,
        serializeQueryArgs: ({ endpointName }) => {
          return endpointName;
        },
        merge: (currentCache, newItems, { arg: { refetch } }) => {
          if (refetch) {
            return {
              data: newItems.data,
              count: newItems.count,
            };
          }

          return {
            data: [...currentCache.data, ...newItems.data],
            count: newItems.count,
          };
        },
        forceRefetch({ currentArg, previousArg }) {
          return currentArg !== previousArg;
        },
      }
    ),
  }),
});

export const { useListAndMergeVehicleBatchesListQuery } = vehicleBatchesList;
