import { Assessment } from '../../models/assessment';
import { apiSlice } from '../../api/baseApi';

interface ListAndMergeAssessmentsListParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeAssessmentsListResponse {
  count: number;
  data: Assessment[];
}

export const assessmentsList = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeAssessmentsList: builder.query<ListAndMergeAssessmentsListResponse, ListAndMergeAssessmentsListParams>({
      query: ({ query }) => `assessment${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useListAndMergeAssessmentsListQuery } = assessmentsList;
