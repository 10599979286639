import React from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { DamageForm } from './damageResolutionForm.styles';
import { GridLayout } from '../../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../../../../../uiComponents/inputs/textField/textField';
import { TextArea } from '../../../../../../uiComponents/inputs/textArea/textArea';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { MultiUploaderInput } from '../../../../../../uiComponents/inputs/multiUploaderInput/multiUploaderInput';
import { DamageResolutionItem } from '../../../../../../consts/conditionReport';

interface DamageResolutionFormInfo {
  values?: DamageResolutionItem | undefined;
  submittingImages: boolean;
  onClose: () => void;
  onSubmit: (submitValues: DamageResolutionItem) => Promise<void>;
}

export const DamageResolutionForm = ({ values, submittingImages, onClose, onSubmit }: DamageResolutionFormInfo) => {
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<DamageResolutionItem>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: values,
  });

  const onFormSubmit = async (submitValues: DamageResolutionItem) => {
    await onSubmit(submitValues);
  };

  return (
    <DamageForm>
      <GridLayout template={2} gap={27}>
        <TextField
          {...register('date', {
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                if (date && new Date(date) > new Date()) {
                  return 'Date of resolution should be in the past';
                }
              },
            },
          })}
          label="Date of resolution"
          type="date"
          error={errors?.date}
          required
        />
        <TextField
          label="Supplier"
          placeholder="Enter supplier"
          type="text"
          name="supplier"
          width="100%"
          defaultValue={values?.supplier}
          error={errors.supplier}
        />
        <div>
          <TextArea
            {...register('resolution', {
              required: 'Describe damage resolution is a required field',
            })}
            styled={{ height: 108 }}
            label="Describe damage resolution"
            name="resolution"
            required
            placeholder="Type here"
            defaultValue={values?.resolution}
            error={errors.resolution}
          />
        </div>
        <MultiUploaderInput
          control={control as unknown as Control<FieldValues>}
          label="Add photos"
          name="photos"
          maxFiles={10}
          defaultValue={values?.photos}
        />
      </GridLayout>
      <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: 16 }}>
        <SecondaryButton
          disabled={submittingImages}
          styled={{ marginRight: '16px', padding: '0px 32px' }}
          onClick={onClose}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          isProcessing={submittingImages}
          styled={{ padding: '0px 38px' }}
          onClick={handleSubmit(onFormSubmit)}
        >
          Submit
        </PrimaryButton>
      </FlexLayout>
    </DamageForm>
  );
};
