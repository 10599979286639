import { EvaluationType } from '../../models/assessment';
import api from '../../utils/api';

/**
 * Create Order Request
 * @category API/POST
 * @param {object} data - Data for re-assessment in order flow
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  assessment_id: '123',
 *  evaluation_type: 'ONLINE',
 * }
 * */
export const createReassessmentType = (data: {
  assessment_id: string;
  evaluation_type: EvaluationType;
  notes: string;
}): Promise<{ data: { id: string } }> => api.post('assessment/newAssessmentType', data);
