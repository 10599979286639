import { styled } from 'styled-components';
import { BsX } from 'react-icons/bs';
import { Text } from '../text/text';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';

export const ToastTitle = styled(Text)`
  margin-right: 8px;
  word-break: keep-all;
`;

export const StyledCloseButton = styled(BsX)<{ $isAlert: boolean }>`
  align-self: ${(props) => (props.$isAlert ? 'center' : 'flex-start')};
  margin: 5px;
  color: ${PRIMARY_PURPLE};
`;
