import { GenericAbortSignal } from 'axios';
import api from '../../utils/api';
import { ModifiedVehicleType } from '../../models/vehicle';

/**
 * Log In Request
 * @category API/POST
 * @param {object} loginData - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * LogIn(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getVehicleTypesForSource = (vehicleSource: string, query = '', signal: GenericAbortSignal) =>
  api.get(`vehicle-type/source/${vehicleSource}${query && `?${query}`}`, { signal });

export const getVehicleTypesWithPackagesForSource = (vehicleSource: string, query = '', signal: GenericAbortSignal) =>
  api.get(`vehicle-type/source/${vehicleSource}/package${query && `?${query}`}`, { signal });

export const getVehicleTypesWithUsedForSource = (vehicleSource: string, query = '', signal: GenericAbortSignal) =>
  api.get(`vehicle-type/source/${vehicleSource}/used${query && `?${query}`}`, { signal });

export const getAllVehicleTypes = (
  query = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: ModifiedVehicleType[] }> =>
  api.get(`vehicle-type${query && `?${query}`}`, { signal });

export const getVehicleTypesSelect = (signal: GenericAbortSignal) => api.get('vehicle-type/select', { signal });

export const getAllVehicleTypesWithPackages = (query = '', signal: GenericAbortSignal) =>
  api.get(`vehicle-type/package${query && `?${query}`}`, { signal });

export default {
  getAllVehicleTypes,
  getAllVehicleTypesWithPackages,
  getVehicleTypesForSource,
  getVehicleTypesWithPackagesForSource,
  getVehicleTypesWithUsedForSource,
};
