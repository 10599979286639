import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { EditVehicleTypeForm } from './editVehicleTypeForm';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { Card } from './vehicleType.styles';

export const CreateVehicleType = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const prevRoute = location.state;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <FlexLayout styled={{ marginBottom: '10px', marginRight: '18px' }} itemsX="end" itemsY="end">
        <SecondaryButton styled={{ padding: '0px 40px' }} onClick={() => navigate(prevRoute ?? -1)}>
          Back
        </SecondaryButton>
      </FlexLayout>
      <Card>
        <EditVehicleTypeForm />
      </Card>
    </>
  );
};
