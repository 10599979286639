// DEPENDENCIES
import React, { useContext, useCallback, useEffect, useMemo, useState } from 'react';
import { BsArrowLeftRight, BsCalendar2Check, BsKey } from 'react-icons/bs';
import { FiAlertCircle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
// COMPONENTS & STYLES
import { AddFinishServicing } from '../servicingForms/addFinishServicing';
import { CreateSingleServicing } from '../servicingForms/createSingleServicing';
import { InterimVehicleOptions } from '../servicingForms/interimVehicleOptions';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { servicingListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { servicingTypes } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTagCell,
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../uiComponents/text/text';
// API CALLS & MODELS
import { getAllBranches } from '../../../api/get/branch.get';
import { getAllServicings } from '../../../api/get/servicing.get';
import { Branch } from '../../../models/branch';
import { Servicing } from '../../../models/servicing';
// HOOKS & UTILS & COMMONS
import { VEHICLES } from '../../../consts/routes';
import { COMPLETE_STATUS } from '../../../consts/status';
import { AGREEMENT_FROZEN } from '../../../consts/agreement';
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_GRAY_80,
  STATUS_RED,
  STATUS_YELLOW,
} from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import { interimVehicleOptions } from '../../../consts/servicing';
import { FilterInput } from '../../../uiComponents/table/tableFilters/tableFilters.styles';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { InternalTransfer } from '../../fleet/transfers/internalTransfer/internalTransfer';

export const ServicingList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [daysSinceTransferFilter, setDaysSinceTransferFilter] = useState<string>('');
  const [interimVehicleData, setInterimVehicleData] = useState<Servicing>();
  const [isAddFinishModal, setIsAddFinishModal] = useState<boolean>(false);
  const [isInitiateServicing, setIsInitiateServicing] = useState<boolean>(false);
  const [isInterimModal, setIsInterimModal] = useState<boolean>(false);
  const [isInternalTransferModal, setIsInternalTransferModal] = useState<boolean>(false);
  const [selectedBranch, setSelectedBranch] = useState<OptionList[]>([]);
  const [selectedInterimVehicle, setSelectedInterimVehicle] = useState<OptionList[]>([]);
  const [selectedServiceType, setSelectedServiceType] = useState<OptionList[]>([]);
  const [selectedServicing, setSelectedServicing] = useState<Servicing | null>(null);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    setSortAscending,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC&filter=parent_id$eq=null&filter=vehicle.vehicle_status$eq=IN-SERVICING`;

  const servicingTypeOptions = useMemo(
    () => [
      {
        label: 'Repair',
        value: 'REPAIR',
      },
      ...servicingTypes,
    ],
    []
  );

  const filters: FilterItem[] = [
    {
      name: 'servicing_type',
      element: (
        <DropDownFilter
          name="servicing_type"
          placeholder="Select servicing type"
          multiValues={selectedServiceType}
          options={servicingTypeOptions}
          title="Service types:"
          onChange={(items) => setSelectedServiceType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'interim_vehicle',
      element: (
        <DropDownFilter
          name="interim_vehicle"
          placeholder="Interim vehicle"
          multiValues={selectedInterimVehicle}
          options={interimVehicleOptions}
          title="Interim vehicle"
          onChange={(items) => setSelectedInterimVehicle(items as OptionList[])}
        />
      ),
    },
    {
      name: 'branch',
      element: (
        <DropDownFilter
          name="branch"
          placeholder="Location"
          options={branchOptions}
          title="Location"
          onChange={(items) => setSelectedBranch(items as OptionList[])}
          multiValues={selectedBranch}
        />
      ),
    },
    {
      name: 'days_since_transfer',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Days since transfer
          </Text>
          <FilterInput
            type="number"
            value={daysSinceTransferFilter}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDaysSinceTransferFilter(e.target.value)}
          />
        </div>
      ),
    },
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Check-in date"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const daysSinceTransfer = (service: number, subService: number): number => service + (subService || 0);

  const handleGetServicingsResponse = useCallback(
    (count: number, servicings: Servicing[]) => {
      const servicingRows = servicings?.map((servicing: Servicing) => {
        return {
          rowData: { data: servicing },
          cells: [
            <FlexLayout
              itemsY="center"
              styled={{ marginLeft: servicing?.agreement_status === AGREEMENT_FROZEN ? -40 : 0 }}
            >
              {servicing?.agreement_status === AGREEMENT_FROZEN && (
                <ActionIcon icon={<FiAlertCircle color={STATUS_RED} size={20} />} tooltip="Account frozen" />
              )}
              <TableTextCell value={servicing?.driver_name || '-'} />
            </FlexLayout>,
            <TableTextCell value={servicing?.vrm} />,
            <TableTextCell value={`${servicing?.manufacturer ?? ''} ${servicing?.model ?? ''}`} />,
            <TableTextCell value={servicing?.vehicle_color ?? '-'} />,
            <TableTextCell
              value={daysSinceTransfer(servicing?.days_off_road, servicing?.days_off_road_sub_service).toString()}
              color={
                daysSinceTransfer(servicing?.days_off_road, servicing?.days_off_road) >= 60
                  ? STATUS_RED
                  : daysSinceTransfer(servicing?.days_off_road, servicing?.days_off_road_sub_service) >= 30 &&
                      daysSinceTransfer(servicing?.days_off_road, servicing?.days_off_road_sub_service) < 60
                    ? STATUS_YELLOW
                    : SECONDARY_GRAY_80
              }
            />,
            <TableTextCell value={servicing?.branch_name} />,
            <div>
              {servicing?.interim_vehicle_vrm !== null &&
              (servicing?.courtesy_condition_report_out || servicing?.hasLiveInServiceRental) ? (
                <FlexLayout
                  styled={{ cursor: 'pointer' }}
                  vertical
                  onClick={() => navigate(`${VEHICLES}/${servicing?.interim_vehicle_id}`)}
                >
                  <Text variant="body8" weight={500} color={STATUS_RED} block>
                    {servicing?.interim_vehicle_vrm}
                  </Text>
                  <Text variant="body9" color={STATUS_RED} weight={300} block>
                    {servicing?.hasLiveInServiceRental ? 'In-service rental' : 'Replacement'}
                  </Text>
                </FlexLayout>
              ) : (
                <TableTextCell value="-" />
              )}
            </div>,
            <FlexLayout wrap>
              {servicing?.servicing_type.length > 0 && servicing?.servicing_type[0] !== null && (
                <TableTagCell wrap tags={servicing?.servicing_type} />
              )}
            </FlexLayout>,
            <div>
              {servicing?.servicing_status === COMPLETE_STATUS ? (
                <TableTagCell tags={[COMPLETE_STATUS]} />
              ) : (
                <FlexLayout gap={4} itemsY="center">
                  {/* Add/Finish servicing icon */}
                  <ActionIcon
                    onClick={() => onAddFinishClick(servicing)}
                    icon={<BsCalendar2Check size={24} color={PRIMARY_PURPLE} />}
                    tooltip="Add/finish servicing"
                  />
                  {/* Interim vehicle options icon */}
                  <ActionIcon
                    onClick={() => onInterimVehicleClick(servicing)}
                    icon={<BsKey style={{ transform: 'rotate(90deg)' }} size={24} color={PRIMARY_PURPLE} />}
                    tooltip="Interim vehicle options"
                  />
                  <ActionIcon
                    onClick={() => onClickInternalTransfer(servicing)}
                    icon={<BsArrowLeftRight size={24} color={PRIMARY_PURPLE} />}
                    tooltip="Internal transfer"
                  />
                </FlexLayout>
              )}
            </div>,
          ],
        };
      });
      setTableData(servicingRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, navigate]
  );

  const fetchServicingList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllServicings(queryString, controller.signal).then((response: { count: number; data: Servicing[] }) => {
        handleGetServicingsResponse(response.count, response.data);
      });
    },
    [handleGetServicingsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchServicingList(`${queryString}&filter=parent_id$eq=null&filter=vehicle.vehicle_status$eq=IN-SERVICING`);
    },
    [setTableData, goToPageNumber, tableFilters, fetchServicingList]
  );

  useEffect(() => {
    setActiveSideNav('servicingListPage');
    setPageTitle('Servicings');
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
    setSortingColumn('created_date');
    setSortAscending(false);
    fetchServicingList(defaultString);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending, fetchServicingList, defaultString]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'interim_vehicle', options: selectedInterimVehicle },
      { columnName: 'branch.id', options: selectedBranch },
      {
        columnName: 'days_off_road',
        options: { value: daysSinceTransferFilter, label: daysSinceTransferFilter },
      },
      { columnName: 'servicing_type', options: selectedServiceType, clause: '$in' },
      {
        columnName: 'created_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [
    setTableFilters,
    dateRangeFilter,
    selectedBranch,
    selectedServiceType,
    daysSinceTransferFilter,
    selectedInterimVehicle,
  ]);

  const onClearClick = useCallback(() => {
    setSelectedBranch([]);
    setSelectedServiceType([]);
    setSelectedInterimVehicle([]);
    setDaysSinceTransferFilter('');
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const onAddFinishClick = (servicing: Servicing) => {
    setIsAddFinishModal(true);
    setSelectedServicing(servicing);
  };

  const onClickInternalTransfer = (servicing: Servicing) => {
    setIsInternalTransferModal(true);
    setSelectedServicing(servicing);
  };

  const onInterimVehicleClick = (servicing: Servicing) => {
    setIsInterimModal(true);
    setInterimVehicleData(servicing);
  };

  const closeModal = () => {
    setIsInternalTransferModal(false);
    setIsInterimModal(false);
    applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
  };

  return (
    <>
      <Table
        header="Servicing list"
        actionButtonText="Initiate servicing"
        onActionButtonClick={() => setIsInitiateServicing(true)}
        disableApply={invalidDates}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={servicingListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllServicings}
        dataDownloadMethod="download"
        downloadName="Servicings"
        filterQuery={`${filterQuery}&filter=vehicle.vehicle_status$eq=IN-SERVICING`}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />
      <Modal
        styled={{ width: '80vw', minWidth: 800 }}
        title={isAddFinishModal ? 'Add/finish servicing' : 'Initiate servicing'}
        open={isAddFinishModal || isInitiateServicing}
        showClose
        onClose={() => {
          setIsAddFinishModal(false);
          setIsInitiateServicing(false);
          setSelectedServicing(null);
        }}
      >
        <>
          {isAddFinishModal && selectedServicing && <AddFinishServicing rowData={selectedServicing} />}
          {isInitiateServicing && (
            <CreateSingleServicing
              isMainServicing
              onFormSubmit={() => {
                setIsInitiateServicing(false);
                applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
              }}
              onClose={() => setIsInitiateServicing(false)}
            />
          )}
        </>
      </Modal>
      <Modal
        styled={{ width: isInterimModal ? '80%' : '70%', minWidth: 600 }}
        title={isInterimModal ? 'Interim vehicle options' : 'Internal transfer'}
        open={isInterimModal || isInternalTransferModal}
        showClose
        onClose={closeModal}
      >
        <>
          {isInterimModal && <InterimVehicleOptions rowData={interimVehicleData} />}
          {isInternalTransferModal && selectedServicing && (
            <InternalTransfer rowData={selectedServicing} branchOptions={branchOptions} close={closeModal} />
          )}
        </>
      </Modal>
    </>
  );
};
