import React, { useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { StyledToastContainer } from './appWrapper.styles';
import { PulseRoutes } from '../app/app';
import { AppContextProvider, IAppContext } from '../../utils/context';

export const AppWrapper = () => {
  const [pageTitle, setPageTitle] = useState('');
  const [activeSideNav, setActiveSideNav] = useState('');
  const [showShadow, setShowShadow] = useState<boolean>(false);
  const contextValue: IAppContext = {
    pageTitle,
    setPageTitle,
    activeSideNav,
    setActiveSideNav,
    showShadow,
    setShowShadow,
  };

  return (
    <AppContextProvider value={contextValue}>
      <StyledToastContainer theme="colored" className="toastComponent" />
      <Router>
        <PulseRoutes />
      </Router>
    </AppContextProvider>
  );
};
