import styled from 'styled-components';

export const Wrapper = styled.div`
  margin-top: 20px;
  margin-left: 16px;
  display: flex;
  flex-direction: column;
  gap: 8px;

  max-height: 130px;
  overflow: auto;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #eeeff0;
    border-radius: 10px;
  }
`;

export const DamageLogItem = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
  width: fit-content;

  &:hover {
    opacity: 0.8;
    transition: 0.2s;
  }
`;

export const DamageLogTitle = styled.p`
  color: PRIMARY_PURPLE;
  font-weight: 500;
  font-size: 14px;

  max-width: 250px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
