import styled, { css } from 'styled-components';
import { PRIMARY_GREEN, PRIMARY_WHITE, SECONDARY_GREEN_30 } from '../../../common/styles/Colors';

export const StyledContainer = styled.div<{ $disabled?: boolean }>`
  position: relative;
  cursor: pointer;
  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
    `}
`;

export const StyledToggleInput = styled.input<{ $checked?: boolean }>`
  width: 40px;
  height: 20px;
  margin: 0;
  border: none;
  border-radius: 18px;
  appearance: none;
  background-color: ${(props) => (props?.$checked ? PRIMARY_GREEN : SECONDARY_GREEN_30)};
  transition: background-color 0.3s ease-in;
`;

export const StyledSlider = styled.div<{ $checked?: boolean }>`
  position: absolute;
  top: 1px;
  left: 1px;
  transform: ${(props) => (props.$checked ? 'translateX(20px)' : 'translateX(0)')};
  transition: transform 0.2s ease-in;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: ${PRIMARY_WHITE};
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  pointer-events: none;
`;
