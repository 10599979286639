import styled from 'styled-components';
import {
  PRIMARY_PURPLE,
  SECONDARY_PURPLE_30,
  PRIMARY_WHITE,
  PRIMARY_GREEN,
  STATUS_RED,
  GRAY_3,
  GRAY_2,
} from '../../../common/styles/Colors';

export const StyledInput = styled.input<{ $hasError: boolean; $align: 'left' | 'center' | 'right' }>`
  font-family: 'Avenir', sans-serif;
  border: 1px solid ${(props) => (props.$hasError ? STATUS_RED : SECONDARY_PURPLE_30)};
  text-align: ${(props) => props.$align};
  background-color: ${PRIMARY_WHITE};
  border-radius: 64px;
  min-width: 200px;
  padding: 10px 36px;
  color: ${PRIMARY_PURPLE};
  font-size: 16px;
  box-shadow: none;
  outline: none;
  margin: 0;
  font-weight: 300;

  &:focus {
    border-color: ${(props) => (props.$hasError ? STATUS_RED : PRIMARY_GREEN)};
  }

  &:disabled {
    border-color: ${GRAY_2};
    color: ${GRAY_3};
  }

  &::placeholder {
    font-family: 'Avenir', sans-serif;
    color: ${GRAY_3};
    font-size: 16px;
    font-weight: 300;
    text-shadow: none;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &[type='number'] {
    -moz-appearance: textfield;
  }
`;

export const InputError = styled.span`
  position: absolute;
  display: block;
  font-size: 11px;
  color: ${STATUS_RED};
`;
