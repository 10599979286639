import { CreateServicingPayload } from '../../models/servicing';
import api from '../../utils/api';

/**
 * Create Servicing Request (not parent ID)
 * @category API/POST
 * @param {object} servicingData - Data for the servicing creation
 * @return {Promise} Returns a promise for the api request
 */

export const createServicing = (body: CreateServicingPayload) => api.post('servicing/', body);

/**
 * Create Servicing with parentID Request
 * @category API/POST
 * @param {string} parentId - ID of the parent servicing
 * @param {object} servicingData - Data for the servicing creation
 * @return {Promise} Returns a promise for the api request
 */
export const createServicingWithParentId = (parentId: string, body: CreateServicingPayload) =>
  api.post(`servicing/${parentId}`, body);
