import api from '../../utils/api';
import { EditServicingPayload } from '../../models/servicing';
/**
 * Update Servicing Request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} id - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  notes: '',
 * }
 * updateServicing(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateServicing = (id: string, body: EditServicingPayload) => api.patch(`servicing/${id}`, body);
