import { toast } from 'react-toastify';
import { BsXCircleFill } from 'react-icons/bs';
import 'react-toastify/dist/ReactToastify.css';
import { StyledCloseButton, ToastTitle } from './toast.styles';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { Text } from '../text/text';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../common/styles/Colors';
import { decideToastBackgroundColor, decideToastColor } from '../../common/utils';
interface ToastProps {
  title: string;
  message: string;
  isAlert?: boolean;
}

const CloseButton = ({ isAlert }: { isAlert: boolean }) => {
  return (
    <div>
      <StyledCloseButton size={20} $isAlert={isAlert} />
    </div>
  );
};
export const Toast = ({ title, message, isAlert }: ToastProps) => {
  return (
    <FlexLayout vertical={!isAlert}>
      <ToastTitle weight={500} color={PRIMARY_PURPLE} variant="body6">
        {title}
      </ToastTitle>
      <Text weight={300} color={PRIMARY_PURPLE} variant="body6">
        {message}
      </Text>
    </FlexLayout>
  );
};

interface NotificationProps {
  type: 'success' | 'warn' | 'error' | 'info';
  title: string;
  message: string;
  isAlert?: boolean;
}

export const Notification = ({ type, title, message, isAlert }: NotificationProps) => {
  const color = decideToastColor(type);
  const bgColor = decideToastBackgroundColor(type);

  return toast[type](<Toast title={title} message={message} isAlert={isAlert} />, {
    style: {
      borderLeft: `5px solid ${color}`,
      borderRadius: '5px',
      borderColor: `${color}`,
      borderWidth: `${isAlert ? 4 : 0}px`,
      backgroundColor: `${isAlert ? bgColor : PRIMARY_WHITE}`,
      color: `${PRIMARY_PURPLE}`,
      padding: '5px 0',
    },
    bodyClassName: `${isAlert ? '' : 'toast-notification'}`,
    progressStyle: { background: `${color}` },
    closeButton: <CloseButton isAlert={isAlert || false} />,
    autoClose: isAlert ? 5000 : 15000,
    icon: <BsXCircleFill style={{ color: `${color}` }} />,
  });
};
