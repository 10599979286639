import React, { useState } from 'react';
import { Icon, IconWrapper, Tooltip } from './multiActionTooltip.styles';
import { Text } from '../../text/text';
import { PRIMARY_WHITE } from '../../../common/styles/Colors';

interface TooltipProps {
  id: number;
  handleClick: () => void;
  tooltip: string;
  disabled?: boolean;
}

interface MultiActionTooltipProps {
  icon: JSX.Element;
  tooltips: TooltipProps[];
}

export const MultiActionTooltip = ({ icon, tooltips }: MultiActionTooltipProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <Icon onClick={() => setShowTooltip(!showTooltip)}>
      {tooltips.length > 0 && (
        <Tooltip $show={showTooltip}>
          {tooltips.map((tooltip) => {
            return (
              <Text
                key={tooltip.id}
                color={PRIMARY_WHITE}
                variant="body7"
                weight={300}
                styled={{ whiteSpace: 'nowrap', height: '20px' }}
                onClick={tooltip.disabled ? () => tooltip.handleClick() : null}
              >
                {tooltip.disabled ? tooltip.tooltip : `No ${tooltip.tooltip}`}
              </Text>
            );
          })}
        </Tooltip>
      )}
      <IconWrapper $disabled={false}>{icon}</IconWrapper>
    </Icon>
  );
};
