import { SECONDARY_PURPLE_5 } from '../../../common/styles/Colors';
import { Text } from '../../text/text';
import { css, styled } from 'styled-components';

export const FieldHeader = styled.div<{ $numColumns: number }>`
  ${(props) => {
    return css`
      &:nth-child(${props.$numColumns}n),
      &:last-child {
        span:first-child {
          border-top-right-radius: 50px;
          border-bottom-right-radius: 50px;
        }
      }

      &:nth-child(${props.$numColumns}n + 1) {
        span:first-child {
          border-top-left-radius: 50px;
          border-bottom-left-radius: 50px;
        }
      }
    `;
  }}
`;

export const HeaderText = styled(Text)`
  line-height: 1;
  padding: 9px 32px;
  background-color: ${SECONDARY_PURPLE_5};
  white-space: nowrap;
`;

export const ValueText = styled(Text)`
  padding: 8px 32px 24px;
`;
