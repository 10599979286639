import styled from 'styled-components';
import { GRAY_10, GRAY_3, PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';

import 'react-datetime-picker/dist/DateTimePicker.css';
import 'react-calendar/dist/Calendar.css';
import 'react-clock/dist/Clock.css';

export const Container = styled.div`
  margin: 0 0 0 0;
`;

export const ErrorMessage = styled.p`
  position: absolute;
  display: block;
  font-size: 11px;
  color: ${STATUS_RED};
`;

export const StyledWrapper = styled.div<{ $hasError: boolean; $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  .react-datetime-picker__wrapper {
    border: 1px solid ${(props) => (props.$hasError ? STATUS_RED : props.$disabled ? '#d9dcdd' : GRAY_10)};
    height: 48px;
    border-radius: 50px;
    font-size: 15px;
    font-style: normal;
    width: 100%;
    color: ${(props) => (props.$disabled ? '#c4c8cb' : PRIMARY_PURPLE)};
    box-shadow: none;
    padding-left: 16px;
  }
  .react-datetime-picker__inputGroup {
    width: 100%;
  }
  .react-datetime-picker__inputGroup__input:: placeholder {
    color: ${GRAY_3};
    font-size: 16px;
    font-weight: 300;
    text-shadow: none;
  }
  .react-datetime-picker__inputGroup__divider {
    color: ${GRAY_3};
  }
  .react-datetime-picker__calendar .react-calendar {
    background-color: ${PRIMARY_PURPLE};
    border-radius: 16px;
    color: white;
    text-decoration: none;
  }
  .react-calendar__navigation__label,
  .react-calendar__navigation__label: hover {
    pointer-events: none;
    backgorund: transparent;
  }
  .react-calendar__navigation__label__labelText {
    color: white;
  }
  .react-calendar__navigation button {
    color: white;
  }
  .react-calendar__tile {
    color: white;
  }
  .react-calendar__tile: hover {
    background: transparent;
    border: 1px solid ${PRIMARY_GREEN};
    border-radius: 50%;
  }
  .react-calendar__tile--now,
  .react-calendar__tile--now:hover {
    border: 1px solid ${PRIMARY_GREEN};
    border-radius: 50%;
    background: transparent;
  }
  .react-datetime-picker__clock {
    display: none;
  }
  .react-datetime-picker__inputGroup__input:invalid {
    background: transparent;
  }
  .react-datetime-picker__inputGroup__input {
    outline: none;
  }
  .react-datetime-picker--disabled {
    background-color: transparent;
  }
`;
