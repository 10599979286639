import FullCalendar from '@fullcalendar/react';
import { EventClickArg } from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import { Container, LegendContainer, Legend, LegendDot, ClearFilter } from './calendar.styles';
import { CalendarEvents } from '../../../models/servicing';

interface FullCalendarProps {
  eventClick: (e: EventClickArg) => void;
  events: CalendarEvents[];
  clearExpiryDates: () => void;
  phvMotDataCount: number | undefined;
}

export interface StyledPhvMotExpiryLegendProps {
  $expiryType: string;
}

export const Calendar = ({ eventClick, events, phvMotDataCount, clearExpiryDates }: FullCalendarProps) => {
  return (
    <Container>
      <LegendContainer>
        <Legend $expiryType="PHV-expiry">
          <LegendDot $expiryType="PHV-expiry" />
          PHV
        </Legend>
        <Legend $expiryType="MOT-expiry">
          <LegendDot $expiryType="MOT-expiry" />
          MOT
        </Legend>
        {phvMotDataCount && phvMotDataCount > 0 ? (
          <ClearFilter onClick={() => clearExpiryDates()}>Clear</ClearFilter>
        ) : null}
      </LegendContainer>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        events={events}
        displayEventTime={false}
        eventDisplay="list-item"
        eventClick={eventClick}
        height="95%"
      />
    </Container>
  );
};
