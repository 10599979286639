import { Driver } from '../../models/driver';
import { apiSlice } from '../../api/baseApi';

interface GetDriversParams {
  query: string;
  refetch: boolean;
}

interface GetDriversResponse {
  count: number;
  data: Driver[];
}

export const drivers = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAndMergeDrivers: builder.query<GetDriversResponse, GetDriversParams>({
      query: ({ query }) => `driver${query && `?${query}`}`,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetAndMergeDriversQuery } = drivers;
