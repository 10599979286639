import currency from 'currency.js';
import { uberCleanAirPackages } from '../common/variables/options';
import { VehicleAgreementDetails } from '../models/vehicle';
/*
 * * Calculations of payment plan figures for R2B and Rental Plans * *
 */

const carAndChargeAmount = 39.0; // static 39£

const extraFee = 200.0;

interface EVDetails {
  carAndChargeBoolean: boolean;
  uberCleanAirAmount: number;
}

export const calculateR2BPlanSummary = (vehicle: VehicleAgreementDetails | undefined, params: EVDetails | null) => {
  const { carAndChargeBoolean, uberCleanAirAmount } = params ?? {};
  /*
   * Total Contract Value = total due value on the vehicle
   */
  const totalContractValue = vehicle?.total_due;
  /*
   * totalUberCleanAmount(from user)
   */
  const totalUberCleanAirAmount = uberCleanAirAmount;
  /*
   * Total Car And Charge Amount = Car and Charge Amount(from user) * Agreement Length
   */
  const totalCarAndChargeAmount = carAndChargeBoolean
    ? currency(carAndChargeAmount).multiply(vehicle?.agreement_length_weeks ?? 0).value
    : 0;
  /*
   * Total Contract Value After Car and Charge = Total due + Total Car And Charge Amount
   */
  const totalContractAfterCarAndCharge = carAndChargeBoolean
    ? currency(totalContractValue ?? '0')
        .add(totalCarAndChargeAmount ?? 0)
        .add(extraFee).value
    : currency(totalContractValue ?? '0').add(extraFee).value;
  /*
   * Total Contract Value After Uber Clean Air = Total due + Total Uber Clean Air Amount
   */
  const totalContractAfterUberCleanAir = currency(totalContractAfterCarAndCharge).subtract(
    totalUberCleanAirAmount ?? 0
  ).value;
  /*
   * Headline Weekly Price = Weekly amount + 39£ (if car and charge package added)
   */
  const headlineWeeklyPrice = carAndChargeBoolean
    ? currency(vehicle?.agreement_value_weeks ?? '0').add(carAndChargeAmount).value
    : vehicle?.agreement_value_weeks;
  /*
   * Weekly Uber Clean Air Discount = Total Uber Clean Air Amount / Agreement Length
   */
  const weeklyUberCleanAirDiscount = vehicle?.agreement_length_weeks
    ? currency(uberCleanAirAmount ?? 0).divide(vehicle?.agreement_length_weeks).value
    : 0;
  /*
   * Weekly Rental Less EV Assist = Headline Weekly Price / Weekly Uber Air Discount
   */
  const weeklyRentalLessEVAssist = currency(headlineWeeklyPrice ?? 0).subtract(weeklyUberCleanAirDiscount).value;
  /*
   * Final Contract Value = Contract Value after all deductions and additions
   */
  const finalContractValue = carAndChargeBoolean
    ? currency(totalContractValue ?? '0')
        .add(totalCarAndChargeAmount)
        .add(extraFee)
        .subtract(totalUberCleanAirAmount ?? 0).value
    : currency(totalContractValue ?? '0')
        .add(extraFee)
        .subtract(totalUberCleanAirAmount ?? 0).value;
  /*
   * VAT Calculations /  VAT Percent = VAT Value / Total Due
   */
  const vatAmount = vehicle?.vat_value || 0;
  const vatPercent = vehicle?.vat_value ? currency(vehicle?.vat_value).divide(vehicle?.total_due).value * 100 : 20;
  const formattedReturn = {
    agreement_length_weeks: Number(vehicle?.agreement_length_weeks),
    total_contract_value: Number(totalContractValue).toFixed(2),
    final_contract_value: Number(finalContractValue).toFixed(2),
    total_car_and_charge_amount: Number(totalCarAndChargeAmount).toFixed(2),
    total_contract_after_car_and_charge: Number(totalContractAfterCarAndCharge).toFixed(2),
    total_contract_after_uber_clean_air: Number(totalContractAfterUberCleanAir).toFixed(2),
    total_uber_clean_air_amount: Number(totalUberCleanAirAmount).toFixed(2),
    headline_weekly_price: Number(headlineWeeklyPrice).toFixed(2),
    uber_clean_air_discount: Number(weeklyUberCleanAirDiscount).toFixed(2),
    weekly_rental_less_ev_assist: Number(weeklyRentalLessEVAssist).toFixed(2),
    vat_amount: Number(vatAmount).toFixed(2),
    vat_percent: Number(vatPercent).toFixed(2),
  };

  return formattedReturn;
};

export const calculateRentalPlanSummary = (vehicle: VehicleAgreementDetails, params: EVDetails) => {
  const { carAndChargeBoolean, uberCleanAirAmount } = params;

  /*
   * Total Car And Charge Amount = Static 39£
   */
  const totalCarAndChargeAmount = carAndChargeBoolean ? carAndChargeAmount : 0;
  /*
   * Headline Weekly Price = Weekly amount + 39£ (if car and charge package added)
   */
  const headlineWeeklyPrice = carAndChargeBoolean
    ? currency(vehicle.rental_value).add(carAndChargeAmount).value
    : vehicle.rental_value;
  /*
   * Weekly Uber Clean Air Discount = Selected Uber Clean Air package value / Number of weeks of the specific package
   * Packages:
   *  1- 100£ for 13 weeks
   *  2- 150£ for 4 weeks
   */
  const uberCleanAirWeeks = uberCleanAirPackages?.find((e) => e?.value === uberCleanAirAmount)?.weeks;
  const weeklyUberCleanAirDiscount = uberCleanAirAmount
    ? currency(uberCleanAirAmount).divide(uberCleanAirWeeks ?? 0)
    : 0;
  /*
   * Weekly Rental Less EV Assist = Headline Weekly Price / Weekly Uber Air Discount
   */
  const weeklyRentalLessEVAssist = currency(headlineWeeklyPrice).subtract(weeklyUberCleanAirDiscount).value;
  /*
   * VAT Calculations -  VAT Percent = VAT Value of the vehicle(if it's set) otherwise it's 20%
   */
  const vatPercent = +currency(vehicle.vat_value).divide(vehicle.total_due) * 100 || 20;

  const formattedReturn = {
    total_car_and_charge_amount: Number(totalCarAndChargeAmount).toFixed(2),
    total_uber_clean_air_amount: Number(uberCleanAirAmount).toFixed(2),
    headline_weekly_price: Number(headlineWeeklyPrice).toFixed(2),
    uber_clean_air_discount: Number(weeklyUberCleanAirDiscount).toFixed(2),
    weekly_rental_less_ev_assist: Number(weeklyRentalLessEVAssist).toFixed(2),
    vat_percent: Number(vatPercent).toFixed(2),
    agreement_length_weeks: '',
    total_contract_value: '',
    final_contract_value: '',
  };

  return formattedReturn;
};
