import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IDriverDetailsSection {
  driver_id?: string;
  title?: string;
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_post_code?: string;
  address_city?: string;
  dvla_front_id?: string;
  dvla_back_id?: string;
  dvla_back?: string;
  pco_id?: string;
  dvla_no?: string;
  poa_id?: string;
  poa_expiry_date?: string;
  trips_last_30_days?: number;
  total_trips?: number;
  uber_rating?: string;
  dvla_test_date?: string;
  dvla_expiry_date?: string;
  pco_no?: string;
  pco_expiry_date?: string;
  pco_cert_expiry_date?: string;
  pco_cert_id?: string;
}

const driverDetailsSlice = createSlice({
  name: 'driverDetails',
  initialState: {
    driver_id: '',
    title: '',
    first_name: '',
    middle_name: '',
    last_name: '',
    address_line_1: '',
    address_line_2: '',
    address_post_code: '',
    address_city: '',
    dvla_front_id: '',
    dvla_back_id: '',
    pco_id: '',
    dvla_no: '',
    poa_id: '',
    poa_expiry_date: '',
    trips_last_30_days: 0,
    total_trips: 0,
    uber_rating: '',
    dvla_test_date: '',
    dvla_expiry_date: '',
    pco_no: '',
    pco_expiry_date: '',
    pco_cert_expiry_date: '',
    pco_cert_id: '',
    dvla_front: '',
    dvla_back: '',
    pco: '',
    pco_cert: '',
    poa: '',
    dvla_back_s3_url: '',
    dvla_front_s3_url: '',
    pco_s3_url: '',
    poa_s3_url: '',
    pco_cert_s3_url: '',
  },
  reducers: {
    updateDriverDetailsStoreState(state, action: PayloadAction<IDriverDetailsSection>) {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateDriverDetailsStoreState } = driverDetailsSlice.actions;
export default driverDetailsSlice.reducer;
