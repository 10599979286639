import { apiSlice } from '../../api/baseApi';
import { Transfer } from '../../models/transfer';

interface ListAndMergeTransfersParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeTransfersResponse {
  count: number;
  data: Transfer[];
}

export const transfers = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeTransfers: builder.query<ListAndMergeTransfersResponse, ListAndMergeTransfersParams>({
      query: ({ query }) => `transfer${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useListAndMergeTransfersQuery } = transfers;
