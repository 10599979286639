import { CreateInternalTransfer, Transfer } from '../../models/transfer';
import api from '../../utils/api';

/**
 * Create Transfer Request
 * @category API/POST
 * @param {object} data - Data for transfer
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  application_id: '',
 * }
 * createTransfer(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createTransfer = (data: Transfer) => {
  return api.post('transfer', data);
};

/**
 * Create Internal Transfer Request
 * @category API/POST
 * @param {object} data - Data for transfer
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
   internal_transfer_type: InternalTransferType;
  transfer_id?: string;
  service_id?: string;
  from_branch_id: string;
  to_branch_id: string;
  notes: string;
 * }
 * createInternalTransfer(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createInternalTransfer = (data: CreateInternalTransfer) => {
  return api.post('transfer/internal/create', data);
};
