import React from 'react';
import { Flex } from './flexLayout.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { BaseProps, ChildrenProps, CssSize } from '../../../utils/props';

/**
 * @itemsX specifies the alignment of items horizontally: start | center | end | space-between | space-around | stretch
 * @itemsY specifies the alignment of items vertically: start | center | end | space-between | space-around | stretch
 * @vertical boolean that determines direction: column or row. Accepted values: true | false | undefined
 * @inline if true display inline-flex, otherwise display: flex
 * @flexWrap specifies wrapping of content. Values: wrap | wrap-reverse | undefined
 * @gap specifies gap between items. Accepts numbers or px strings e.g. 10px
 */

export type FlexItemsAlignment =
  | 'stretch'
  | 'start'
  | 'center'
  | 'end'
  | 'space-around'
  | 'space-between'
  | 'space-evenly';

export interface FlexLayoutCompatibilityProps {
  /**
   * [Compatibility] Safari doesn't handle paddings/margins properly when an overflow occurs.
   * This prop ensures the component looks the same in every browser by adding a fake pseudoelements with the correct size simulating padding.
   */
  paddingX?: CssSize;
  /**
   * [Compatibility] Safari doesn't handle paddings/margins properly when an overflow occurs.
   * This prop ensures the component looks the same in every browser by adding a fake pseudoelements with the correct size simulating padding.
   */
  paddingY?: CssSize;
}

export interface FlexLayoutProps extends FlexLayoutCompatibilityProps, BaseProps, ChildrenProps {
  /**
   * Determines whether the flex box should display inline.
   */
  inline?: boolean;
  /**
   * If true sets the CSS property flex-direction to column, which determines the direction of the flex items inside this component.
   */
  vertical?: boolean;
  /**
   * Determines the alignment of the children items on the X axis, regardless of flex direction.
   */
  itemsX?: FlexItemsAlignment;
  /**
   * Determines the alignment of the children items on the Y axis, regardless of flex direction.
   */
  itemsY?: FlexItemsAlignment;
  /**
   * Sets whether the child flex items are allowed to wrap or must stay on a single line. Maps to the flex-wrap CSS property.
   */
  wrap?: boolean | 'reverse';
  /**
   * Gap between child items
   */
  gap?: CssSize;
}

const FlexDirectionContext = React.createContext<FlexLayoutProps['vertical']>(undefined);

export interface StyledFlexLayoutProps {
  $inline: boolean;
  $vertical: boolean;
  $flexWrap: boolean | 'reverse';
  $gap?: CssSize;
  $itemsX?: FlexItemsAlignment;
  $itemsY?: FlexItemsAlignment;
  $paddingX?: CssSize;
  $paddingY?: CssSize;
}

export const FlexLayout = withStyledProps(
  ({ itemsX, itemsY, vertical: verticalProp, wrap = false, gap, inline = false, ...props }: FlexLayoutProps) => {
    const vertical = verticalProp ?? false;
    return (
      <FlexDirectionContext.Provider value={vertical}>
        <Flex
          as="div"
          $itemsX={itemsX}
          $itemsY={itemsY}
          $inline={inline}
          $vertical={vertical}
          $flexWrap={wrap}
          $gap={gap}
          {...props}
        />
      </FlexDirectionContext.Provider>
    );
  }
);
