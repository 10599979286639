import { styled } from 'styled-components';
import { Link } from 'react-router-dom';
import { BsEye, BsEyeSlash } from 'react-icons/bs';
import { MdMailOutline } from 'react-icons/md';
import { TextField } from '../../uiComponents/inputs/textField/textField';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_30 } from '../../common/styles/Colors';

export const StyledTextField = styled(TextField)`
  margin: 14px 0;
  width: 100%;
  height: 48px;
  color: ${SECONDARY_PURPLE_30};

  &::placeholder {
    font-size: 12px;
  }
`;

export const StyledEyeIcon = styled(BsEye)`
  position: absolute;
  right: 16px;
  top: 12px;
  color: ${SECONDARY_PURPLE_30};
  cursor: pointer;
`;

export const StyledSlashEyeIcon = styled(BsEyeSlash)`
  position: absolute;
  right: 16px;
  top: 12px;
  color: ${SECONDARY_PURPLE_30};
  cursor: pointer;
`;

export const FormContainer = styled.form`
  width: 460px;
`;

export const StyledMailIcon = styled(MdMailOutline)`
  position: absolute;
  right: 16px;
  top: 12px;
  color: ${SECONDARY_PURPLE_30};
`;

export const InputContainer = styled.div`
  position: relative;
  width: 100%;
  margin-top: 24px;
`;

export const StyledLink = styled(Link)`
  align-self: flex-start;
  color: ${PRIMARY_GREEN};
  font-size: 12px;
  font-weight: 300;
  line-height: 16px;
  margin: 0 16px;
`;

export const ActionGroup = styled.div`
  display: flex;
  align-items: end;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  min-width: 165px;
  margin-top: 32px;
`;

export const GoogleButton = styled.button`
  cursor: pointer;
  padding: 10px 16px 10px 42px;
  border: none;
  border-radius: 3px;

  color: #757575;
  font-size: 14px;
  font-weight: 500;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-repeat: no-repeat;
  background-position: 12px 11px;

  transition: 0.2s;

  &:hover {
    opacity: 0.8;
  }
`;

export const LoginBackground = styled.img`
  width: 100%;
  margin-top: -60px;
`;

export const OttoLogo = styled.img`
  margin-bottom: 32px;
`;

export const StyledH2 = styled.h2`
  font-size: 28px;
  line-height: 38px;
  font-weight: 800;
  text-align: center;
  margin-bottom: 32px;
  color: ${PRIMARY_WHITE};
`;

export const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: ${PRIMARY_PURPLE};
  align-items: center;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 60px;
  align-items: center;
  z-index: 99;
`;
