import { Application } from '../../models/application';
import { apiSlice } from '../../api/baseApi';

interface ListAndMergeOrdersListParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeOrdersListResponse {
  count: number;
  data: Application[];
}

export const ordersList = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeOrdersList: builder.query<ListAndMergeOrdersListResponse, ListAndMergeOrdersListParams>({
      query: ({ query }) => `application${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useListAndMergeOrdersListQuery } = ordersList;
