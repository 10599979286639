export const titleOptions = [
  {
    value: 'Mr.',
    label: 'Mr.',
  },
  {
    value: 'Mrs.',
    label: 'Mrs.',
  },
  {
    value: 'Ms.',
    label: 'Ms.',
  },
  {
    value: 'Miss',
    label: 'Miss',
  },
  {
    value: 'Dr.',
    label: 'Dr.',
  },
];

export const departmentOptions = [
  {
    value: 'FULFILLMENT',
    label: 'Fulfillment',
  },
  {
    value: 'SERVICES',
    label: 'Services',
  },
  {
    value: 'ASSESSMENT',
    label: 'Assessment',
  },
  {
    value: 'FINANCE',
    label: 'Finance',
  },
  {
    value: 'SALES',
    label: 'Sales',
  },
  {
    value: 'CLAIMS',
    label: 'Claims',
  },
  {
    value: 'ENQUIRIES',
    label: 'Enquiries',
  },
  {
    value: 'OPERATIONS',
    label: 'Operations',
  },
  {
    value: 'MARKETING',
    label: 'Marketing',
  },
  {
    value: 'VANS',
    label: 'Vans',
  },
];
