import styled, { css } from 'styled-components';
import { Spinner } from '../../uiControls/spinner/spinner';
import { PRIMARY_PURPLE, STATUS_RED, PRIMARY_GREEN } from '../../../common/styles/Colors';

export const TextFieldLabel = styled.label<{ $isRequired?: boolean }>`
  display: block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
  margin-bottom: 8px;

  ${(props) => {
    if (props.$isRequired) {
      return css`
        &:after {
          content: '*';
          position: relative;
          margin-left: 5px;
          color: ${STATUS_RED};
          font-size: 14px;
        }
      `;
    }
    return null;
  }}
`;

export const StyledDiv = styled.div`
  position: relative;
`;

export const StyledSpinner = styled(Spinner)`
  color: ${PRIMARY_GREEN};
  position: absolute;
  bottom: 20%;
  left: 90%;
  size: 16px;
`;
