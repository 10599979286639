import { AxiosError } from 'axios';
import { Notification } from '../uiComponents/toast/toast';

export type ErrorType = DOMException | AxiosError | null | undefined;

export const handleAPIError = (error: ErrorType, customErrorMessage = 'Unknown error occurred'): void => {
  if (error instanceof DOMException && error.name === 'AbortError') {
    // Request was aborted due to unmounting
    return;
  }
  if (error && 'isAxiosError' in error && error.isAxiosError) {
    const axiosError = error as AxiosError;
    Notification({
      type: 'error',
      title: 'Error',
      message: axiosError.message || customErrorMessage,
    });
  } else {
    Notification({
      type: 'error',
      title: 'Error',
      message: customErrorMessage,
    });
  }
};
