// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { BsEye, BsArrowLeftRight, BsExclamationTriangle } from 'react-icons/bs';
import moment from 'moment';
// COMPONENTS & STYLES
import { VehicleLocationForm } from './VehicleLocationForm';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { vehicleListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { FilterInput } from '../../../uiComponents/table/tableFilters/tableFilters.styles';
import {
  vehicleStatuses,
  vehicleTagTypes,
  vehicleSourceOptions,
  usedVehicleOptions,
} from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTagCell,
  TableTextCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../uiComponents/text/text';
// API CALLS & MODELS
import { exportAllVehicles } from '../../../api/get/vehicle.get';
import { getVehicleTypesSelect } from '../../../api/get/vehicleType.get';
import { Vehicle, VehicleTypeSelectResponse } from '../../../models/vehicle';
// HOOKS & UTILS & COMMONS
import {
  ASSIGNED,
  AVAILABLE,
  AWAITING_EVALUATION,
  IN_SERVICING,
  NO_SALE,
  STAFF,
  UNINSURED,
  VEHICLE_COMPLETED,
} from '../../../consts/vehicle';
import { VEHICLES } from '../../../consts/routes';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';
import { getAllBranches } from '../../../api/get/branch.get';
import { Branch } from '../../../models/branch';
import { useListAndMergeVehiclesListQuery } from '../../../api/listAndMerge/listAndMergeVehiclesApiSlice';

export const VehicleList = () => {
  const navigate = useNavigate();
  const [branchOptions, setBranchOptions] = useState<OptionList[]>();
  const [isVehicleLocationModal, setIsVehicleLocationModal] = useState<boolean>(false);
  const [manufacturerOptions, setManufacturerOptions] = useState<OptionList[]>([]);
  const [selectedBranches, setSelectedBranches] = useState<OptionList[]>([]);
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedTags, setSelectedTags] = useState<OptionList[]>([]);
  const [selectedVehicle, setSelectedVehicle] = useState<Vehicle | null>(null);
  const [selectedVehicleSource, setSelectedVehicleSource] = useState<OptionList[]>([]);
  const [vehicleConditionSelected, setVehicleConditionSelected] = useState<OptionList>(usedVehicleOptions[0]);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const { register, reset, watch } = useForm({
    defaultValues: { mot_exp: '', phv_exp: '' },
  });
  const motExp = watch('mot_exp');
  const phvExp = watch('phv_exp');

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: vehiclesList,
    isFetching: isVehiclesListFetching,
    refetch,
  } = useListAndMergeVehiclesListQuery({ query: queryStringState, refetch: refetchData });

  const onChangeVehicleLocationClick = (transfer: Vehicle) => {
    setSelectedVehicle(transfer);
    setIsVehicleLocationModal(true);
  };

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Manufacturer"
          multiValues={selectedManufacturer}
          options={manufacturerOptions}
          title="Manufacturer"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'physical_branch_id',
      element: (
        <DropDownFilter
          name="physical-location"
          placeholder="Physical location"
          multiValues={selectedBranches}
          options={branchOptions ?? []}
          title="Physical location"
          onChange={(items) => setSelectedBranches(items as OptionList[])}
        />
      ),
    },
    {
      name: 'tags',
      element: (
        <DropDownFilter
          name="tags"
          placeholder="Tags"
          multiValues={selectedTags}
          options={vehicleTagTypes}
          title="Tags"
          onChange={(items) => setSelectedTags(items as OptionList[])}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Status"
          options={vehicleStatuses}
          multiValues={selectedStatus}
          title="Status"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'vehicle_source',
      element: (
        <DropDownFilter
          name="vehicle_source"
          placeholder="Vehicle source"
          options={vehicleSourceOptions}
          multiValues={selectedVehicleSource}
          title="Vehicle source"
          onChange={(items) => setSelectedVehicleSource(items as OptionList[])}
        />
      ),
    },
    {
      name: 'vehicle_condition',
      element: (
        <DropDownFilter
          name="vehicle_condition"
          placeholder="New or used"
          options={usedVehicleOptions}
          value={vehicleConditionSelected}
          singleSelection
          title="New or used"
          onChange={(item) => setVehicleConditionSelected(item as OptionList)}
        />
      ),
    },
    {
      name: 'mot_exp',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            MOT expiry
          </Text>
          <FilterInput type="date" {...register('mot_exp')} />
        </div>
      ),
    },
    {
      name: 'phv_exp',
      element: (
        <div>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            PHV expiry
          </Text>
          <FilterInput type="date" {...register('phv_exp')} />
        </div>
      ),
    },
  ];

  const handleCurrentTag = (tag: string | null) => {
    if (tag === null) return null;
    if (tag === UNINSURED) {
      return [
        <FlexLayout itemsX="center" gap={5}>
          <div>{tag}</div>
          <BsExclamationTriangle style={{ marginTop: '2px' }} />
        </FlexLayout>,
      ];
    } else {
      return [tag];
    }
  };

  const handleGetVehiclesResponse = useCallback(
    (count: number, vehicles: Vehicle[]) => {
      const vehicleRows = vehicles?.map((vehicle: Vehicle) => {
        return {
          rowData: { data: vehicle },
          cells: [
            <TableTextCell value={vehicle?.vrm ?? '-'} />,
            <TableTextCell value={vehicle?.manufacturer ?? '-'} />,
            <TableTextCell value={vehicle?.color} />,
            <TableTextCell
              value={branchOptions?.find((branch) => branch?.value === vehicle?.physical_branch_id)?.label ?? '-'}
            />,
            <TableTagCell tags={[vehicle?.vehicle_status]} />,
            <TableTagCell
              tags={handleCurrentTag(vehicle?.current_tag)}
              isWarning={vehicle.current_tag === UNINSURED}
            />,
            <TableTextCell value={vehicle?.mot_exp ? moment(vehicle?.mot_exp).format('DD MMM YYYY') : '-'} />,
            <TableTextCell value={vehicle?.phv_exp ? moment(vehicle?.phv_exp).format('DD MMM YYYY') : '-'} />,
            <TableTextCell
              value={vehicle?.is_sec_immob === null ? 'Not installed' : vehicle?.is_sec_immob ? 'Active' : 'Inactive'}
            />,
            <FlexLayout gap={4} itemsY="center">
              {[ASSIGNED, AVAILABLE, AWAITING_EVALUATION, IN_SERVICING, NO_SALE, STAFF, VEHICLE_COMPLETED].includes(
                vehicle?.vehicle_status
              ) && (
                <ActionIcon
                  onClick={() => onChangeVehicleLocationClick(vehicle)}
                  disabled={[UNINSURED].includes(vehicle.condition)}
                  tooltip={[UNINSURED].includes(vehicle.condition) ? 'Vehicle uninsured' : 'Change vehicle location'}
                  icon={<BsArrowLeftRight size={20} color={PRIMARY_PURPLE} />}
                />
              )}
              <ActionIcon
                onClick={() => navigate(`${VEHICLES}/${vehicle?.id}`, { state: VEHICLES })}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View vehicle"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehicleRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData, branchOptions]
  );

  const closeModal = () => {
    refetchToFirstPage();
    setIsVehicleLocationModal(false);
  };

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
  }, []);

  useEffect(() => {
    const control = new AbortController();
    getVehicleTypesSelect(control.signal).then((response: { data: VehicleTypeSelectResponse }) => {
      const manufacturers: OptionList[] = response?.data?.manufacturers?.map((manufacturer) => {
        return {
          value: manufacturer?.value,
          label: manufacturer?.text,
        };
      });
      setManufacturerOptions(manufacturers);
    });
  }, []);

  useEffect(() => {
    setSortingColumn('created_date');
    setSortAscending(false);
  }, [setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'manufacturer',
        options: selectedManufacturer,
        clause: '$in',
      },
      {
        columnName: 'physical_branch_id',
        options: selectedBranches,
      },
      {
        columnName: 'current_tag',
        options: selectedTags,
      },
      {
        columnName: 'vehicle.vehicle_status',
        options: selectedStatus,
      },
      {
        columnName: 'vehicle.vehicle_source',
        options: selectedVehicleSource,
      },
      {
        columnName: 'mot_exp',
        options: { value: motExp ?? '', label: motExp ?? '' },
      },
      {
        columnName: 'phv_exp',
        options: { value: phvExp ?? '', label: phvExp ?? '' },
      },
      {
        columnName: 'used',
        options: vehicleConditionSelected,
      },
    ]);
  }, [
    setTableFilters,
    selectedStatus,
    selectedManufacturer,
    selectedVehicleSource,
    selectedBranches,
    selectedTags,
    vehicleConditionSelected,
    motExp,
    phvExp,
  ]);

  useEffect(() => {
    if (vehiclesList) {
      handleGetVehiclesResponse(vehiclesList.count, vehiclesList.data);
    }
  }, [vehiclesList, queryStringState, handleGetVehiclesResponse]);

  const onClearClick = useCallback(() => {
    setSelectedStatus([]);
    setSelectedVehicleSource([]);
    setSelectedManufacturer([]);
    setVehicleConditionSelected(usedVehicleOptions[0]);
    setSelectedBranches([]);
    reset({ mot_exp: '', phv_exp: '' });
  }, [reset]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isVehiclesListFetching}
        header="Vehicle list"
        onColumnHeaderClick={(columnId: string) => {
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
        }}
        sortAscending={sortAscending}
        columns={vehicleListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={exportAllVehicles}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onRowClick={(vehicle: { data: Vehicle }) => navigate(`${VEHICLES}/${vehicle?.data?.id}`, { state: VEHICLES })}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
      />
      <Modal
        styled={{ minWidth: 450, overflow: 'hidden', minHeight: 500 }}
        title="Change vehicle location"
        open={isVehicleLocationModal}
        showClose
        onClose={() => setIsVehicleLocationModal(false)}
      >
        <VehicleLocationForm vehicle={selectedVehicle} close={closeModal} />
      </Modal>
    </>
  );
};
