import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { DriverDetailsForm } from './formsAndDocs/driverDetailsForm';
import { DriverAdditionalDetailsForm } from './formsAndDocs/driverAdditionalDetailsForm';
import { DriverDocs } from './formsAndDocs/driverDocs';
import { SectionHeader } from '../sectionHeader';
import { DriverDetailsGrid, Separator } from '../../order/order.styles';
import { SectionProps } from '../../order/order';
import { DRIVERS } from '../../../../consts/routes';
import { DIGITAL_ONBOARDED } from '../../../../consts/status';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { createDriverUploadedDocument, submitAdditionalDriverDetails } from '../../../../api/post/driver.post';
import { PRIMARY_GREEN } from '../../../../common/styles/Colors';
import { CustomerProfile, DriverDocumentType, IDriverUploadRequest } from '../../../../models/driver';
import { renderNotification } from '../../../../utils/utils';
import { handleAPIError, ErrorType } from '../../../../utils/handleAPIError';
import { Notification } from '../../../../uiComponents/toast/toast';
import { useAppDispatch, useAppSelector } from '../../../../store-hooks';
import { getDriverByDVLA } from '../../../../api/get/driver.get';
import { updateCustomerDetailsState } from '../../../../features/driver/customerProfileSlice';

export function DriverDetailsSection({ isComplete, isLocked, progress, checkProgress }: SectionProps) {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(!(progress?.driver_profile_status === DIGITAL_ONBOARDED));
  const [submitLoading, setSubmitLoading] = useState<boolean>(false);
  const customerProfileFields = useAppSelector((state) => state.customerProfile);
  const dispatch = useAppDispatch();
  const {
    register,
    setError,
    clearErrors,
    getValues,
    setValue,
    reset,
    watch,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CustomerProfile>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const pcoNo: string = watch('pco_no') || '';
  const dvlaExpiry: string = watch('dvla_expiry_date') || '';
  const pcoCertExpiry: string | undefined = watch('pco_cert_expiry_date');
  const pcoExpiry: string | undefined = watch('pco_expiry_date');
  const poaExpiry: string | undefined = watch('poa_expiry_date');
  const addressLine1: string | undefined = watch('address_line_1');
  const addressPostCode: string | undefined = watch('address_post_code');
  const addressCity: string | undefined = watch('address_city');

  const pcoNoValid: boolean = pcoNo !== '' && !errors?.pco_no;
  const dvlaExpiryValid: boolean = dvlaExpiry !== '' && !errors?.dvla_expiry_date;
  const pcoExpiryValid: boolean = pcoExpiry !== '' && !errors?.pco_expiry_date;
  const pcoCertExpiryValid: boolean = pcoCertExpiry !== '' && !errors?.pco_cert_expiry_date;
  const poaExpiryValid: boolean = poaExpiry !== '' && !errors?.poa_expiry_date;
  const addressValid: boolean = addressLine1 !== '' && !errors?.address_line_1;
  const cityValid: boolean = addressCity !== '' && !errors?.address_city;
  const postCodeValid: boolean = addressPostCode !== '' && !errors?.address_post_code;

  const canSubmit =
    customerProfileFields.dvla_back_s3_url &&
    !customerProfileFields.dvla_back_is_loading &&
    customerProfileFields.dvla_front_s3_url &&
    !customerProfileFields.dvla_front_is_loading &&
    customerProfileFields.pco_cert_s3_url &&
    !customerProfileFields.pco_cert_is_loading &&
    customerProfileFields.pco_s3_url &&
    !customerProfileFields.pco_is_loading &&
    customerProfileFields.poa_s3_url &&
    !customerProfileFields.poa_is_loading &&
    pcoNoValid &&
    dvlaExpiryValid &&
    pcoExpiryValid &&
    pcoCertExpiryValid &&
    poaExpiryValid &&
    addressValid &&
    cityValid &&
    postCodeValid;

  const getDriverByDvlaNumber = useCallback(
    (value: string) => {
      function populateFields(profile: CustomerProfile) {
        setValue('driver_id', profile?.id);
        setValue(
          'dvla_test_date',
          profile?.dvla_test_date ? moment(profile?.dvla_test_date)?.format('YYYY-MM-DD') : ''
        );
        setValue(
          'dvla_expiry_date',
          profile?.dvla_expiry_date ? moment(profile?.dvla_expiry_date)?.format('YYYY-MM-DD') : ''
        );
        setValue('title', profile?.title ? profile?.title : '');
        setValue('first_name', profile?.first_name ? profile?.first_name : '');
        setValue('middle_name', profile?.middle_name ? profile?.middle_name : '');
        setValue('last_name', profile?.last_name ? profile?.last_name : '');
        setValue('address_line_1', profile?.address_line_1 ? profile?.address_line_1 : '');
        setValue('address_line_2', profile?.address_line_2 ? profile?.address_line_2 : '');
        setValue('address_post_code', profile?.address_post_code);
        setValue('address_city', profile?.address_city);
        setValue('dvla_front_id', profile?.dvla_front_id);
        setValue('dvla_back_id', profile?.dvla_back_id);
        setValue('dvla_back', profile?.dvla_back);
        setValue('pco_id', profile?.pco_id);
        setValue('pco_no', profile?.pco_no);
        setValue(
          'pco_expiry_date',
          profile?.pco_expiry_date ? moment(profile?.pco_expiry_date)?.format('YYYY-MM-DD') : ''
        );
        setValue('pco_cert_id', profile?.pco_cert_id);
        setValue(
          'pco_cert_expiry_date',
          profile?.pco_cert_expiry_date ? moment(profile?.pco_cert_expiry_date)?.format('YYYY-MM-DD') : ''
        );
        setValue('poa_id', profile?.poa_id);
        setValue(
          'poa_expiry_date',
          profile?.poa_expiry_date ? moment(profile?.poa_expiry_date)?.format('YYYY-MM-DD') : ''
        );
        setValue('uber_rating', profile?.uber_rating ?? '');
        setValue('trips_last_30_days', profile?.trips_last_30_days ?? 0);
        setValue('total_trips', profile?.total_trips ?? 0);

        dispatch(updateCustomerDetailsState(profile));
      }

      getDriverByDVLA(value).then(
        (response: { data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }) => {
          if (response?.data) {
            populateFields(response?.data);
          } else {
            reset({
              dvla_no: value,
              dvla_test_date: '',
              dvla_expiry_date: '',
              pco_no: '',
              pco_expiry_date: '',
              pco_cert_expiry_date: '',
              poa_expiry_date: '',
              total_trips: 0,
              trips_last_30_days: 0,
            });
          }
        }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reset]
  );

  const preparePayloadToCreateDocuments = () => {
    const { dvla_expiry_date, poa_expiry_date, pco_cert_expiry_date, pco_expiry_date }: CustomerProfile = getValues();
    return [
      {
        document_category: 'DRIVER',
        category_id: customerProfileFields.id ?? '',
        document_type: DriverDocumentType.DVLA_FRONT,
        type: customerProfileFields.dvla_front_file_type ?? '',
        s3_url: customerProfileFields.dvla_front_s3_url,
        expiry_date: dvla_expiry_date ?? '',
      },
      {
        document_category: 'DRIVER',
        category_id: customerProfileFields.id ?? '',
        document_type: DriverDocumentType.DVLA_BACK,
        type: customerProfileFields.dvla_back_file_type ?? '',
        s3_url: customerProfileFields.dvla_back_s3_url,
        expiry_date: dvla_expiry_date ?? '',
      },
      {
        document_category: 'DRIVER',
        category_id: customerProfileFields.id ?? '',
        document_type: DriverDocumentType.PCO,
        type: customerProfileFields.pco_file_type ?? '',
        s3_url: customerProfileFields.pco_s3_url,
        expiry_date: pco_expiry_date ?? '',
      },
      {
        document_category: 'DRIVER',
        category_id: customerProfileFields.id ?? '',
        document_type: DriverDocumentType.PCO_CERT,
        type: customerProfileFields.pco_cert_file_type ?? '',
        s3_url: customerProfileFields.pco_cert_s3_url,
        expiry_date: pco_cert_expiry_date ?? '',
      },
      {
        document_category: 'DRIVER',
        category_id: customerProfileFields.id ?? '',
        document_type: DriverDocumentType.POA,
        type: customerProfileFields.poa_file_type ?? '',
        s3_url: customerProfileFields.poa_s3_url,
        expiry_date: poa_expiry_date ?? '',
      },
    ] as IDriverUploadRequest[];
  };

  const saveDocuments = async (documents: IDriverUploadRequest[]) => {
    // save
    try {
      for await (const doc of documents) {
        if (!doc.type) {
          delete doc.type;
        }
        await createDriverUploadedDocument(doc);
      }
    } catch (err) {
      Notification({
        type: 'error',
        title: 'Upload failed',
        message: `${err}`,
      });
      return [];
    }
  };

  useEffect(() => {
    if (progress?.dvla_document_no) {
      getDriverByDvlaNumber(progress.dvla_document_no);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [progress.dvla_document_no]);

  const onSubmit = async (submitValues: CustomerProfile) => {
    try {
      setSubmitLoading(true);
      const mountDocumentsPayload = await preparePayloadToCreateDocuments();
      await saveDocuments(mountDocumentsPayload);
      const payload = {
        ...submitValues,
        dvla_no: progress?.dvla_document_no,
        app_id: progress?.application_id,
      };
      const { data } = await submitAdditionalDriverDetails(payload);
      data.driver_id
        ? renderNotification('success', 'Success', 'Driver details have been successfully submitted')
        : renderNotification('error', 'Error', 'Failed to submit driver details', false);
      setIsCollapsed(true);
      setSubmitLoading(false);
      checkProgress?.();
    } catch (err) {
      setSubmitLoading(false);
      handleAPIError(err as ErrorType);
    }
  };

  return (
    <CollapsiblePanel
      header={
        <SectionHeader
          title="Driver details"
          completedText="Completed"
          isComplete={isComplete}
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <SectionHeader title="Driver details" isComplete={isComplete} isLocked={isLocked} expanded />
        <DriverDetailsForm
          errors={errors}
          register={register}
          setError={(name, msg) => setError(name, msg)}
          clearErrors={(name) => clearErrors(name)}
          setValue={(name, value) => setValue(name, value)}
          control={control}
          driverData={{
            title: progress?.driver_title,
            first_name: progress?.driver_first_name,
            middle_name: progress?.driver_middle_name,
            last_name: progress?.driver_last_name,
            driver_id: progress.driver_id,
            address_line_1: progress?.address_line_1,
            address_line_2: progress?.address_line_2,
            address_city: progress.address_city,
            post_code: progress.post_code,
          }}
        />
        <DriverDocs
          control={control}
          errors={errors}
          isDigitalOnb={progress.driver_profile_status === DIGITAL_ONBOARDED}
        />
        <Separator />
        <DriverAdditionalDetailsForm errors={errors} register={register} />
        <FlexLayout styled={{ marginTop: 27 }} itemsX="end">
          <PrimaryButton styled={{ minWidth: 186, height: 40 }} disabled={!canSubmit} onClick={handleSubmit(onSubmit)}>
            {submitLoading ? <Spinner size={20} color={PRIMARY_GREEN} /> : 'Submit'}
          </PrimaryButton>
        </FlexLayout>
        <DriverDetailsGrid
          numColumns={5}
          headers={[
            'Title:',
            'First name:',
            'Middle name:',
            'Last name:',
            'Date of birth:',
            'Mobile phone:',
            'Email:',
            'Address line 1:',
            'Address line 2:',
            'City:',
            'Post code:',
            'National insurance number:',
          ]}
          values={[
            progress?.driver_title ?? '-',
            progress?.driver_first_name,
            progress?.driver_middle_name ?? '-',
            progress?.driver_last_name,
            moment(progress?.driver_date_of_birth)?.format('DD MMM YYYY'),
            progress?.driver_mobile,
            progress?.email,
            progress?.address_line_1,
            progress?.address_line_2 ?? '-',
            progress?.address_city,
            progress?.post_code,
            progress?.national_insurance_number ?? '-',
          ]}
        />
        <FlexLayout itemsX="end" styled={{ marginTop: 24 }}>
          <PrimaryButton onClick={() => navigate(`${DRIVERS}/${progress?.driver_id}`)} styled={{ width: 192 }}>
            View driver
          </PrimaryButton>
        </FlexLayout>
      </div>
    </CollapsiblePanel>
  );
}
