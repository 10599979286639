import { AssignReplacementVehiclePayload } from '../../models/transfer';
import { GeneralPermissionLetterPolicy } from '../../models/permissionLetter';
import { AssignVehiclePayload, ChooseVehicleModelPayload, UnassignVehiclePayload } from '../../models/vehicle';
import api from '../../utils/api';

/**
 * Choose Model Request
 * @category API/POST
 * @param {object} cityData - Data for chooseModel
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  vehicle_type_id: '',
 *  application_id: '',
 * }
 * chooseModel(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Errors
 * });
 */

export const chooseModel = (
  body: ChooseVehicleModelPayload
): Promise<{ data: { 'set application vehicle_type': { rows: { id: string }[] } } }> =>
  api.post('vehicle/choose-model', body);

/**
 *  Evaluate and assign vehicle request
 * @category API/POST
 * @param {object} payload - Vehicle data
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  agreement_value_weeks: '',
 *  agreement_length_weeks: '',
 *  mileage: '',
 *  accident_excess: '',
 * }
 * evaluateAndAssign(values).then((payload) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const evaluateAndAssign = (payload: AssignVehiclePayload) => api.post('/vehicle/evaluate-and-assign/', payload);

/**
 *  Evaluate and assign courtesy vehicle request
 * @category API/POST
 * @param {object} payload - Vehicle data
 * @return {Promise} Returns a promise for the api request
 */
export const assignCourtesyVrm = (payload: AssignReplacementVehiclePayload) =>
  api.post('/vehicle/assign-courtesy-vrm/', payload);

/**
 * Unassign vehicle from the application request
 * @category API/POST
 * @param {object} payload - application data(id)
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  applicationId: ''
 * }
 * unassignVehicle(payload).then(() => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * }).error((err) => {
 * // Error
 * });
 */
export const unassignVehicle = (payload: UnassignVehiclePayload) => api.post('/vehicle/un-assign-vehicle/', payload);

export const clearVehicleModel = (payload: UnassignVehiclePayload) =>
  api.post('/vehicle/clear-vehicle-model/', payload);

export const unassignCourtesy = (payload: { courtesy_id: string; vehicle_id: string }) =>
  api.post('/vehicle/un-assign-courtesy/', payload);

/**
 * Generate permission letter
 * @category API/POST
 * @param {object} payload - vehicle data(id)
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  vehicleId: ''
 * }
 * generatePermissionLetter(payload).then(() => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * }).error((err) => {
 * // Error
 * });
 */
export const generatePermissionLetter = (params: GeneralPermissionLetterPolicy) =>
  api.post('/vehicle/generatePermissionLetter', params);
