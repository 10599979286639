import styled from 'styled-components';
import { FlexLayout } from '../flexLayout/flexLayout';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';

export const NavigationPillContainer = styled(FlexLayout)<{ $isSecondaryNav?: boolean }>`
  height: 40px;
  border: ${(props) => (props.$isSecondaryNav ? `1px solid ${SECONDARY_PURPLE_70}` : `1px solid ${PRIMARY_PURPLE}`)};
  border-radius: 54px;
  cursor: pointer;
  flex: 1;
`;

export const Pill = styled(FlexLayout)<{ $selected: boolean; $isSecondaryNav?: boolean }>`
  height: 100%;
  min-width: 170px;
  font-size: 14px;
  line-height: 1;
  background-color: ${(props) =>
    props?.$selected ? (props.$isSecondaryNav ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE) : 'transparent'};
  border-radius: 54px;
  color: ${(props) =>
    props?.$selected ? PRIMARY_WHITE : props.$isSecondaryNav ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE};
  flex: 1;
`;
