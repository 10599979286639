export const CONGESTION_CHARGES = 'Congestion charges';
export const DART_CHARGES = 'Dart charges';
export const EXCESS_MILEAGE = 'Excess mileage';

export const CHARGES_APPROVED = 'APPROVED';
export const CHARGES_IN_SERVICING = 'IN-SERVICING';
export const CHARGES_INVALID_ACCOUNT = 'INVALID-ACCOUNT';
export const CHARGES_INVALID_DRIVER = 'INVALID-DRIVER';
export const CHARGES_INVALID_VRM = 'INVALID-VRM';
export const CHARGES_INVOICED = 'INVOICED';
export const CHARGES_ORDERED = 'ORDERED';
export const CHARGES_PAID = 'PAID';
export const CHARGES_PENDING = 'PENDING';
export const CHARGES_REJECTED = 'REJECTED';
export const CHARGES_STAFF = 'STAFF';
export const CHARGES_VAN = 'VAN';
