import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { FieldGrid } from '../../../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { Tag } from '../../../../../../uiComponents/customComponents/tag/tag';
import { TextArea } from '../../../../../../uiComponents/inputs/textArea/textArea';
import { updateDriverTags } from '../../../../../../api/patch/driver.patch';
import { DriverTag, DriverTagsPayload } from '../../../../../../models/driver';
import { statusColors } from '../../../../../../common/utils';
import { handleAPIError, ErrorType } from '../../../../../../utils/handleAPIError';
import { renderNotification } from '../../../../../../utils/utils';

interface EditSubServicingProps {
  tag: DriverTag;
  onFormSubmit: () => void;
}

export const EditReasonForm = ({ tag, onFormSubmit }: EditSubServicingProps) => {
  const [processing, setProcessing] = useState<boolean>(false);

  const {
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<DriverTag>({
    defaultValues: { ...tag },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = useCallback(
    async (submitValues: DriverTagsPayload) => {
      setProcessing(true);
      try {
        if (tag?.driver_tag_id) {
          const { tag_reason } = submitValues;
          const payload: DriverTagsPayload = { tag_reason };
          const { data } = await updateDriverTags(tag?.driver_tag_id, payload);
          data?.length > 0
            ? renderNotification('success', 'Success', 'Reason has been successfully updated')
            : renderNotification('error', 'Error', 'Failed to update tag’s reason', false);
        }
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        handleAPIError(err as ErrorType);
      }
      onFormSubmit();
    },
    [onFormSubmit, tag?.driver_tag_id]
  );

  return (
    <>
      <FieldGrid
        numColumns={5}
        headers={['Tag:', 'Date added:', 'Date resolved:', 'Created by', 'Resolved by', 'Status:']}
        values={[
          <Tag color={statusColors[tag?.tag_name.toUpperCase()]}>{tag?.tag_name.toUpperCase()}</Tag>,
          tag?.created_date ? moment(tag?.created_date).format('DD MMM YYYY') : '-',
          tag?.date_resolved ? moment(tag?.date_resolved).format('DD MMM YYYY') : '-',
          tag?.created_by ?? '-',
          tag?.resolved_by ?? '-',
          <Tag color={statusColors[tag?.resolution_status]}>{tag?.resolution_status}</Tag>,
        ]}
      />
      <div style={{ marginTop: 16, width: '80%' }}>
        <TextArea
          {...register?.('tag_reason', {
            required: 'Reason is required',
          })}
          error={errors?.tag_reason}
          label="Reason"
          placeholder="Supply a reason"
          required
          styled={{ height: 104 }}
        />
      </div>
      <FlexLayout styled={{ marginTop: 20 }} itemsX="end" itemsY="end">
        <PrimaryButton isProcessing={processing} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
