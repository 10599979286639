import { Branch, BranchPayload } from '../../models/branch';
import api from '../../utils/api';

/**
 * Update Branch Request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} id - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  has_sales: '',
 *  branch_name: '',
 * }
 * updateBranch(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateBranch = (payload: { id: string; body: BranchPayload }): Promise<{ data: Branch }> =>
  api.patch(`internal/branch/${payload.id}`, payload.body);
