import * as React from 'react';
import { X, ChevronDown, Check } from 'lucide-react';

import { Badge } from '../badge/badge';
import { Command, CommandGroup, CommandItem, CommandList } from '../command/command';
import { Command as CommandPrimitive } from 'cmdk';
import { cn } from '../../../lib/utils';
import { VariantProps, cva } from 'class-variance-authority';

export interface Option {
  value: string;
  label: string;
  info?: string;
  node?: React.ReactNode;
}

const selectVariants = cva(
  'group flex min-h-10 w-full shadow-xs px-3.5 py-1.5 rounded-lg border border-utility-gray-300 bg-white text-base font-light placeholder:text-utility-gray-500 text-utility-gray-900 hover:placeholder:text-utility-gray-900 focus-within:outline-none',
  {
    variants: {
      variant: {
        default: 'focus-within:border-utility-brand-300 focus-within:shadow-brand-md',
        error: '!border-utility-error-300 focus-within:border-utility-error-300 focus-within:shadow-error-md',
      },
      dimension: {
        sm: 'min-h-10 px-3.5 py-1.5',
        md: '!min-h-11 px-4 py-1.5',
      },
    },
    defaultVariants: {
      variant: 'default',
      dimension: 'sm',
    },
  }
);

export interface SelectProps extends VariantProps<typeof selectVariants> {
  options: Option[];
  placeholder?: string;
  multiple?: boolean;
  className?: string;
  startAdornment?: React.ReactNode;
  defaultSelected?: Option[];
  value?: Option[];
  onSelect?: (value: Option[]) => void;
  disabled?: boolean;
}

export function Select({
  options,
  placeholder = '',
  multiple = false,
  className,
  variant,
  dimension,
  startAdornment,
  defaultSelected,
  value,
  onSelect,
  disabled,
}: SelectProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [open, setOpen] = React.useState(false);
  const [selected, setSelected] = React.useState<Option[]>(value || defaultSelected || []);
  const [inputValue, setInputValue] = React.useState('');
  const handleUnselect = (option: Option) => {
    const opts = selected.filter((s) => s.value !== option.value);
    setSelected(opts);
    onSelect?.(opts);
  };

  const handleSelect = (option: Option, multiple: boolean) => {
    if (multiple) {
      if (selected.filter((s) => s.value === option.value).length > 0) {
        handleUnselect(option);
      } else {
        const value = [...selected, option];
        setSelected(value);
        onSelect?.(value);
      }
    } else {
      setSelected([option]);
      onSelect?.([option]);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    const input = inputRef.current;
    if (input) {
      if (e.key === 'Delete' || e.key === 'Backspace') {
        if (input.value === '') {
          const newSelected = [...selected];
          newSelected.pop();
          setSelected(newSelected);
          onSelect?.(newSelected);
          return newSelected;
        }
      }
      if (e.key === 'Escape') {
        input.blur();
      }
    }
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
        setOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const isSelected = selected?.length > 0;

  return (
    <Command onKeyDown={handleKeyDown}>
      <div
        ref={containerRef}
        className={cn(
          selectVariants({ variant, dimension, className }),
          !multiple ? 'flex items-center justify-between' : 'flex items-center flex-wrap gap-1',
          disabled
            ? '!cursor-not-allowed !bg-utility-gray-50 !text-utility-gray-500 hover:!text-utility-gray-500 hover:placeholder:!text-utility-gray-500'
            : ''
        )}
      >
        {startAdornment && <div className="mr-2">{startAdornment}</div>}
        {multiple && (
          <>
            {selected.map((option, index) => (
              <Badge data-testid={`select-value-${index}`} key={option.value} variant="fill" size="sm" shape="square">
                {option.node && <div>{option.node}</div>}
                {option.label}
                <button
                  className="ml-1 rounded-full outline-none ring-offset-background focus:ring-2 focus:ring-ring focus:ring-offset-2"
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      handleUnselect(option);
                    }
                  }}
                  onMouseDown={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                  onClick={() => handleUnselect(option)}
                  disabled={disabled}
                >
                  <X className="h-3 w-3 text-muted-foreground hover:text-foreground" />
                </button>
              </Badge>
            ))}
          </>
        )}
        <CommandPrimitive.Input
          data-testid="select-input"
          ref={inputRef}
          value={inputValue}
          onValueChange={setInputValue}
          onClick={() => {
            setOpen(!open);
          }}
          placeholder={!multiple ? selected[0]?.label || placeholder : placeholder}
          className={cn(
            'flex-1 bg-transparent outline-none text-base font-light placeholder:text-utility-gray-500',
            isSelected && !multiple ? 'placeholder:text-utility-gray-900 font-light' : null,
            !multiple ? 'cursor-pointer caret-transparent' : null
          )}
          disabled={disabled}
        />
        {!multiple && (
          <button
            className="ml-2 rounded-full outline-none"
            onClick={(e) => {
              e.preventDefault();
              setOpen(!open);
            }}
            disabled={disabled}
          >
            <ChevronDown className={`h-4 w-4 text-muted-foreground ${open ? 'rotate-180' : ''}`} />
          </button>
        )}
      </div>
      <div className={`relative ${!multiple ? '' : 'hidden'}`}>
        <CommandList data-testid="select-list">
          {open && options.length > 0 ? (
            <div className="absolute top-2 z-10 w-full rounded-md border bg-popover text-popover-foreground animate-in shadow-dropdown-shadow !border-utility-gray-200">
              <CommandGroup className="h-full overflow-auto max-h-[200px]">
                {options.map((option: Option, index) => (
                  <CommandItem
                    data-testid={`select-list-item-${index}`}
                    key={option.value}
                    onMouseDown={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onSelect={() => {
                      setInputValue('');
                      if (multiple) {
                        handleSelect(option, true);
                      } else {
                        handleSelect(option, false);
                        setOpen(false);
                      }
                    }}
                    className="cursor-pointer flex items-center justify-between hover:!bg-utility-gray-50"
                  >
                    <div className="flex items-center gap-2">
                      {option.node && <div>{option.node}</div>}
                      <span className="text-utility-gray-900 font-medium">{option.label}</span>
                      {option.info && <span className="text-utility-gray-600 font-light">{option.info}</span>}
                    </div>
                    {selected?.some((s) => s.value === option.value) && (
                      <Check className="h-4 w-4 text-utility-brand-600" />
                    )}
                  </CommandItem>
                ))}
              </CommandGroup>
            </div>
          ) : null}
        </CommandList>
      </div>
      {multiple && (
        <div className="relative">
          <CommandList data-testid="select-list">
            {open && options.length > 0 ? (
              <div className="absolute top-2 z-10 w-full rounded-md border bg-popover text-popover-foreground animate-in">
                <CommandGroup className="h-full overflow-auto">
                  {options.map((option, index) => (
                    <CommandItem
                      data-testid={`select-list-item-${index}`}
                      key={option.value}
                      onMouseDown={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                      }}
                      onSelect={() => {
                        setInputValue('');
                        if (multiple) {
                          handleSelect(option, true);
                        } else {
                          handleSelect(option, false);
                          setOpen(false);
                        }
                      }}
                      className="cursor-pointer flex items-center justify-between hover:!bg-utility-gray-50"
                    >
                      <div className="flex items-center gap-2">
                        {option.node && <div>{option.node}</div>}
                        <span className="text-utility-gray-900 font-medium">{option.label}</span>
                        {option.info && <span className="text-utility-gray-600 font-light">{option.info}</span>}
                      </div>
                      {selected?.some((s) => s.value === option.value) && (
                        <Check className="h-4 w-4 text-utility-brand-600" />
                      )}
                    </CommandItem>
                  ))}
                </CommandGroup>
              </div>
            ) : null}
          </CommandList>
        </div>
      )}
    </Command>
  );
}
