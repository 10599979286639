import { VehicleModelForm, VehicleModelFormType } from '../vehicleModelForm/vehicleModelForm';
import {
  useGetColorsListQuery,
  useGetFuelListQuery,
  useGetVehicleModelByIdQuery,
} from '../../../../../api/vehicleModel/vehicleModelApiSlice';
import { useGetVehicleManufacturerListQuery } from '../../../../../api/contracts/contractsDetailsApiSlice';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const EditVehicleModel = () => {
  const { id } = useParams();
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const { data: vehicleModel } = useGetVehicleModelByIdQuery({ query: id });
  const [data, setData] = useState<VehicleModelFormType>();

  useEffect(() => {
    if (manufacturerList?.data && fuelList?.data && colorsList?.data && vehicleModel?.data) {
      const manufacturer_id = manufacturerList.data
        ?.filter((item: { id: string; name: string }) => item.id === vehicleModel.data.manufacturer_id)
        .map((opt: { id: string; name: string }) => {
          return {
            value: opt.id,
            label: opt.name,
          };
        });
      const fuel_types = vehicleModel.data.fuel_types.map((item: string) => {
        return {
          value: fuelList.data.find((opt: { id: string; name: string }) => opt.id === item)?.id,
          label: fuelList.data.find((opt: { id: string; name: string }) => opt.id === item)?.name,
        };
      });

      const exterior_colors = vehicleModel.data.exterior_colors.map((item: string) => {
        return {
          value: colorsList.data.find((opt: { id: string; name: string }) => opt.id === item)?.id,
          label: colorsList.data.find((opt: { id: string; name: string }) => opt.id === item)?.name,
        };
      });
      setData({
        ...vehicleModel.data,
        manufacturer_id,
        fuel_types,
        exterior_colors,
      });
    }
  }, [manufacturerList, fuelList, colorsList, vehicleModel]);

  return <>{data && <VehicleModelForm defaultValues={data} isEdit />}</>;
};
