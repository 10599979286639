import React from 'react';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_5, PRIMARY_GREEN, STATUS_RED } from '../../../../../common/styles/Colors';
import { getColor } from './utils';

interface TelematicsSubcardProps {
  title: string;
  icon?: JSX.Element;
  score: number;
  scoreDifference?: number;
  skillLevel?: string;
}

export const TelematicsSmallCard = ({ title, icon, score, scoreDifference, skillLevel }: TelematicsSubcardProps) => {
  return (
    <FlexLayout
      styled={{
        backgroundColor: `${SECONDARY_PURPLE_5}`,
        borderRadius: '16px',
        color: `${PRIMARY_PURPLE}`,
        margin: '10px 0',
        padding: 10,
      }}
      itemsY="start"
      gap={1}
      vertical
    >
      <Text variant="body6" weight={500}>
        {title}
      </Text>
      <FlexLayout styled={{ marginTop: '10px' }} itemsX="start" gap={10} vertical>
        {title === 'Safety score' ? (
          <>
            <FlexLayout
              styled={{ color: scoreDifference && scoreDifference > 0 ? PRIMARY_GREEN : STATUS_RED }}
              itemsY="center"
              gap={3}
            >
              {icon}
              {scoreDifference || 0}
            </FlexLayout>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={800}>
              {`${score} / 100`}
            </Text>
          </>
        ) : (
          <Text variant="body6" color={score ? getColor(score) : ''} weight={800}>
            {skillLevel ? skillLevel : ''}
          </Text>
        )}
      </FlexLayout>
    </FlexLayout>
  );
};
