import styled from 'styled-components';
import { PRIMARY_GREEN, PRIMARY_WHITE } from '../../../common/styles/Colors';

export const StyledRadioInput = styled.input`
  position: relative;
  width: 20px;
  height: 20px;
  margin: 0;
  border: none;
  border-radius: 50%;
  border: 1px solid ${PRIMARY_GREEN};
  background-color: ${PRIMARY_WHITE};
  appearance: none;
  flex-shrink: 0;
  cursor: pointer;

    &:checked{
      background-color: ${PRIMARY_GREEN};
      border: 1px solid transparent;

      + .dot > div {
        display: inherit;
      }

      &::after {
        content: '✔';
        color: ${PRIMARY_WHITE};
        font-family: "Avenir";
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        position: absolute;
        left: -1px;
        top: 0;
        width: 20px;
        height: 20px;
      }

  &:disabled{
    opacity: 0.3;
    cursor: not-allowed;
  }
`;
