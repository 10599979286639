import { OptionList } from '../utils/props';

export const OPT_FOR_CAREER_BOOST = 'OPTED-FOR-CAREER-BOOST';
export const PART_EXCHANGE = 'PART-EXCHANGE';

export const newOrderLondonTags: OptionList[] = [
  { value: 'none', label: 'None' },
  { value: OPT_FOR_CAREER_BOOST, label: 'Opt for career boost' },
  { value: 'PART-EXCHANGE', label: 'Part exchange' },
];

export const newOrderNationalTags: OptionList[] = [
  { value: 'none', label: 'None' },
  { value: PART_EXCHANGE, label: 'Part exchange' },
];
