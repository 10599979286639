import { useCallback, useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../order/order';
import { SectionHeader } from './sectionHeader';
import { getAllVehicleTypes } from '../../../api/get/vehicleType.get';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { TableTextCell, getQueryString } from '../../../uiComponents/table/tableUtils/tableUtils';
import { vehicleModelColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { ModifiedVehicleType } from '../../../models/vehicle';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { Table } from '../../../uiComponents/table/table';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { chooseModel, clearVehicleModel } from '../../../api/post/vehicle.post';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { Text } from '../../../uiComponents/text/text';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';

interface ModelProps extends SectionProps {
  onModelSelect: () => void;
}

export const ModelSection = ({ isComplete, isLocked, progress, checkProgress, onModelSelect }: ModelProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [choosingModel, setChoosingModel] = useState<boolean>(false);
  const [vehicleTypes, setVehicleTypes] = useState<ModifiedVehicleType[]>();
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();
  const applicationId: string = progress?.application_id;
  const [confirmModal, setConfirmModal] = useState<JSX.Element>();

  const selectVehicleType = useCallback(
    (applicationId: string, vehicleTypeId: string) => {
      setChoosingModel(true);
      chooseModel({ application_id: applicationId, vehicle_type_id: vehicleTypeId }).then(() => {
        onModelSelect();
      });
    },
    [onModelSelect]
  );

  const updateVehicleRows = useCallback(
    (vehicleModels?: ModifiedVehicleType[]) => {
      const vehicleTypeRows = vehicleModels?.map((vehicleType: ModifiedVehicleType) => {
        return {
          rowData: { data: vehicleType },
          cells: [
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.manufacturer} />,
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.model} />,
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.spec ?? '-'} />,
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.model_year ?? '-'} />,
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.colors?.map((color) => color).join(', ')} />,
            <TableTextCell color={SECONDARY_PURPLE_70} value={vehicleType?.fuel_type ?? '-'} />,
            <PrimaryButton
              styled={{ width: 96, height: 24 }}
              onClick={() => {
                setIsCollapsed(true);
                selectVehicleType(applicationId, vehicleType?.id ?? '');
              }}
            >
              Select
            </PrimaryButton>,
          ],
        };
      });
      if (vehicleTypeRows) {
        setTableData([...vehicleTypeRows]);
      }
    },
    [applicationId, selectVehicleType, setTableData]
  );

  useEffect(() => {
    setChoosingModel(false);
    updateVehicleRows(vehicleTypes);
  }, [progress, vehicleTypes, updateVehicleRows]);

  const handleGetModelsResponse = useCallback(
    (count: number, models: ModifiedVehicleType[]) => {
      updateVehicleRows(models);
      setTotalRows(count);
    },
    [setTotalRows, updateVehicleRows]
  );

  const fetchVehicleTypes = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllVehicleTypes(queryString, controller.signal).then(
        (response: { count: number; data: ModifiedVehicleType[] }) => {
          setVehicleTypes(response.data);
          handleGetModelsResponse(response?.count, response?.data);
        }
      );
    },
    [handleGetModelsResponse]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean
    ) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchVehicleTypes(queryString);
    },
    [fetchVehicleTypes, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    if (!isCollapsed && !tableData) {
      applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
    }
  }, [isCollapsed, tableData, pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, applyFilters]);

  return (
    <>
      <CollapsiblePanel
        styled={{ marginTop: 16 }}
        locked={isLocked}
        header={
          <FlexLayout itemsY="center" gap={8}>
            <SectionHeader
              title="Choose model"
              completedText={`${progress?.manufacturer} ${progress?.model ?? ''}`}
              isComplete={isComplete}
              isLocked={isLocked}
              expanded={false}
            />
            {choosingModel && <Spinner color={PRIMARY_PURPLE} size={16} />}
          </FlexLayout>
        }
        expanded={!isCollapsed}
        onCollapse={() => {
          setIsCollapsed(!isCollapsed);
        }}
      >
        <div>
          <FlexLayout gap={8} itemsY="center">
            <SectionHeader
              title="Choose model"
              completedText={`${progress?.manufacturer} ${progress?.model ?? ''}`}
              isComplete={isComplete}
              isLocked={isLocked}
              expanded
            />
            {choosingModel && <Spinner color={PRIMARY_PURPLE} size={16} />}
          </FlexLayout>
          <Table
            variant="standard"
            header={
              <FlexLayout itemsX="space-between">
                <Text variant="h4" weight={500} color={PRIMARY_PURPLE}>
                  Available models
                </Text>
                {progress?.vehicle_type_id && (
                  <FlexLayout itemsX="end" vertical>
                    <Text
                      variant="body7"
                      color={PRIMARY_PURPLE}
                      weight={300}
                      block
                    >{`Current selection: ${progress?.manufacturer} ${progress?.model}`}</Text>
                    <SecondaryButton
                      onClick={() =>
                        setConfirmModal(
                          <ConfirmationModal
                            title="This will clear the current model and unassign the vehicle from the driver. Are you sure?"
                            isOpen
                            confirmButtonCaption="Yes"
                            closeButtonCaption="No"
                            preConfirm={() =>
                              clearVehicleModel({
                                application_id: progress?.application_id,
                                vehicle_id: progress?.vehicle_id,
                              }).then(() => {
                                setConfirmModal(undefined);
                                checkProgress?.();
                              })
                            }
                            onClose={() => setConfirmModal(undefined)}
                          />
                        )
                      }
                      styled={{ marginTop: 8 }}
                    >
                      Clear
                    </SecondaryButton>
                  </FlexLayout>
                )}
              </FlexLayout>
            }
            tableTheme="purple"
            embedded
            onColumnHeaderClick={(columnId: string) =>
              applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
            }
            sortAscending={sortAscending}
            columns={vehicleModelColumns}
            rows={tableData}
            totalRows={totalRows}
            rowsPerPage={numRowsPerPage}
            currentPageNumber={pageNumber}
            sortingColumn={sortingColumn}
            onSearchChange={(value: string) => {
              setSearchString(value);
              applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
            }}
            filterQuery={filterQuery}
            goToPage={(pageNumber: number) => {
              goToPageNumber(pageNumber);
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }}
            onApplyClick={() => applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending)}
            onNumRowsPerPageChange={(value: number) => {
              setNumRowsPerPage(value);
              goToPageNumber(0);
              applyFilters(0, value, searchString, sortingColumn, sortAscending);
            }}
            styled={{ marginTop: 32 }}
          />
        </div>
      </CollapsiblePanel>
      {confirmModal}
    </>
  );
};
