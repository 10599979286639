import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { OptionList } from '../../../utils/props';
import { ApplicationProgress } from '../../../models/application';
import { PaymentStartDateArea } from '../order/order.styles';

interface PaymentStartDateSectionProps {
  confirmedPaymentStartDate?: string;
  onDateSelect: (startDate: OptionList) => void;
  progress: ApplicationProgress;
}

export const PaymentStartDateSection = ({
  confirmedPaymentStartDate,
  progress,
  onDateSelect,
}: PaymentStartDateSectionProps) => {
  return (
    <PaymentStartDateArea>
      <Text variant="body4" color={PRIMARY_PURPLE} weight={800}>
        Payment start date
      </Text>
      <FlexLayout itemsY="center" gap={16} styled={{ marginTop: 32 }}>
        {!confirmedPaymentStartDate && (
          <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
            Choose a payment start date:
          </Text>
        )}
        {confirmedPaymentStartDate ? (
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            {confirmedPaymentStartDate}
          </Text>
        ) : (
          <DropDown
            name="payment_start_date"
            placeholder="Payment start date"
            options={progress?.available_payment_start_dates}
            defaultValue={progress?.available_payment_start_dates?.[0]}
            onSelect={(option) => onDateSelect(option as OptionList)}
          />
        )}
      </FlexLayout>
    </PaymentStartDateArea>
  );
};
