import { PRIMARY_GREEN } from '../../../../common/styles/Colors';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { styled } from 'styled-components';

export const Image = styled.img`
  max-width: 200px;
  width: auto;
  height: auto;
`;

export const CarColour = styled(FlexLayout)`
  border: 1px solid ${PRIMARY_GREEN};
  border-radius: 16px;
  padding: 16px;
  margin-left: 16px;
`;

export const Card = styled.div`
  border-radius: 10px;
  padding: 24px;
  margin: 32px;
  background-color: #ffffff;
`;

export const StyledDiv = styled.div<{ marginTop: number }>`
  margin-top: ${({ marginTop }) => marginTop}px;
`;
