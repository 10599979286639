import styled from 'styled-components';
import { PRIMARY_GREEN } from '../../../common/styles/Colors';

export const ButtonWrapper = styled.button`
  all: unset;

  display: flex;
  align-items: center;
  justify-content: center;

  position: fixed;
  right: 0;
  bottom: 24px;

  background-color: ${PRIMARY_GREEN};
  padding: 20px;
  padding-right: 16px;
  border-radius: 100px 0 0 100px;
  cursor: pointer;
  transform: translateX(100%);
  transition: transform 400ms ease;

  &.visible {
    transform: translateX(0%);
  }
`;
