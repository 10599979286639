export const OPEN = 'OPEN';
export const VEHICLE_ASSIGNED = 'VEHICLE-ASSIGNED';
export const COLLECTION_ARRANGED = 'COLLECTION-ARRANGED';
export const DECLARATION_COMPLETED = 'DECLARATION-COMPLETED';
export const COMPLETED = 'COMPLETED';
export const CANCELLED = 'CANCELLED';
export const REJECTED = 'REJECTED';
export const THEORY_COMPLETED = 'THEORY-COMPLETED';
export const PRACTICAL_PASSED = 'PRACTICAL-PASSED';
export const PRACTICAL_FAILED = 'PRACTICAL-FAILED';

export const LIABILITY_QUESTIONS = [
  'Do you suffer from diabetes or use insulin?',
  'Do you wear glasses?',
  'Do you, or any immediate family members, suffer from epilepsy?',
  'Have you developed a medical condition in the last year?',
  'Have you suffered from anxiety or depression?',
  'Do you have any type of sleep disorder?',
  "Have you been involved in an accident or made any insurance claim in the last 3 years, whether you fault or someone else's fault?",
  'Have you been convicted of, or have any pending motor offences in the last 5 years?',
  'Have you been convicted of, or have any pending criminal convictions in the last 5 years?',
];

export const DVSA_URL = 'https://www.viewdrivingrecord.service.gov.uk/driving-record/licence-number';

export const DOWNPAYMENT_SUCCEEDED = 'SUCCEEDED';
export const DOWNPAYMENT_AWAITING = 'AWAITING';
export const DOWNPAYMENT_PENDING = 'PENDING';
export const DOWNPAYMENT_FAILED = 'FAILED';

export const BURST_OPTIONS = [
  { weeksValue: 26, weeks: '26 weeks', months: '6 months' },
  { weeksValue: 52, weeks: '52 weeks', months: '1 year' },
  { weeksValue: 66, weeks: '66 weeks', months: '15 months' },
  { weeksValue: 78, weeks: '78 weeks', months: '1½ years' },
  { weeksValue: 104, weeks: '104 weeks', months: '2 years' },
  { weeksValue: 130, weeks: '130 weeks', months: '2½ years' },
  { weeksValue: 156, weeks: '156 weeks', months: '3 years' },
  { weeksValue: 182, weeks: '182 weeks', months: '3½ years' },
  { weeksValue: 208, weeks: '208 weeks', months: '4 years' },
  { weeksValue: 234, weeks: '234 weeks', months: '4½ years' },
  { weeksValue: 260, weeks: '260 weeks', months: '5 years' },
  { weeksValue: 286, weeks: '286 weeks', months: '5½ years' },
  { weeksValue: 312, weeks: '312 weeks', months: '6 years' },
  { weeksValue: 338, weeks: '338 weeks', months: '6½ years' },
  { weeksValue: 364, weeks: '364 weeks', months: '7 years' },
];
