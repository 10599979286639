import React, { useCallback, useEffect } from 'react';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { Table } from '../../../../uiComponents/table/table';
import { driverActivePlanColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { TableTextCell } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { CustomerPlanDetails } from '../../../../models/driver';

export const DriverActivePlans = ({ plans }: { plans: CustomerPlanDetails[] }) => {
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetPlansResponse = useCallback(
    (plans: CustomerPlanDetails[]) => {
      const planRows = plans?.map((plan: CustomerPlanDetails) => {
        return {
          rowData: { data: plan },
          cells: [
            <TableTextCell value={plan?.subId || '-'} />,
            <TableTextCell value={plan?.name || '-'} />,
            <TableTextCell value={plan?.tcv || '-'} />,
            <TableTextCell value={plan?.outstandingBalance || '-'} />,
            <TableTextCell value={plan?.subStatus || '-'} />,
          ],
        };
      });
      setTableData(planRows);
      setTotalRows(planRows.length);
    },
    [setTotalRows, setTableData]
  );

  useEffect(() => {
    if (plans) {
      handleGetPlansResponse(plans);
    }
  }, [setTableFilters, handleGetPlansResponse, plans]);

  return (
    <Table
      variant="compact"
      tableTheme="purple"
      embedded
      onColumnHeaderClick={(_) => {}}
      sortAscending={sortAscending}
      columns={driverActivePlanColumns}
      rows={tableData}
      totalRows={totalRows}
      rowsPerPage={numRowsPerPage}
      currentPageNumber={pageNumber}
      sortingColumn={sortingColumn}
      filters={[]}
      filterQuery={filterQuery}
      goToPage={(pageNumber: number) => {
        goToPageNumber(pageNumber);
      }}
      onNumRowsPerPageChange={(value: number) => {
        setNumRowsPerPage(value);
        goToPageNumber(0);
      }}
    />
  );
};
