import api from '../../utils/api';
import { ModifiedVehicleType } from '../../models/vehicle';
/**
 * Log In Request
 * @category API/POST
 * @param {object} loginData - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * LogIn(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const updateVehicleType = (id: string, payload: ModifiedVehicleType) => api.patch(`vehicle-type/${id}`, payload);
