import { ACCIDENT, MOT, PHV_PROBLEM, REPAIR_OTHER, REPAIR_WARRANTY, REPO, SWAP, TERMINATED } from './transfer';
import { OptionList } from '../utils/props';

export const LEUMI = 'LEUMI';
export const OTTO_CAR = 'OTTOCAR';

export const conRepReasons: OptionList[] = [
  {
    value: ACCIDENT,
    label: 'Accident',
  },
  {
    value: REPAIR_WARRANTY,
    label: 'Repair: warranty',
  },
  {
    value: REPAIR_OTHER,
    label: 'Repair: non-warranty',
  },
  {
    value: MOT,
    label: 'MOT',
  },
  {
    value: PHV_PROBLEM,
    label: 'PHV issue',
  },
  {
    value: SWAP,
    label: 'Swap',
  },
  {
    value: REPO,
    label: 'Repo',
  },
  {
    value: TERMINATED,
    label: 'Terminate',
  },
];

export const LIVE = 'LIVE';
export const PAID_OFF = 'PAID-OFF';
export const AFTERCARE = 'AFTERCARE';
export const ASSIGNED = 'ASSIGNED';
export const AVAILABLE = 'AVAILABLE';
export const ORDERED = 'ORDERED';
export const AUCTION = 'AUCTION';
export const ARCHIVED = 'ARCHIVED';
export const AWAITING_EVALUATION = 'AWAITING-EVALUATION';
export const VEHICLE_COMPLETED = 'COMPLETED';
export const COURTESY_ASSIGNED = 'COURTESY-ASSIGNED';
export const IN_SERVICING = 'IN-SERVICING';
export const NO_SALE = 'NO-SALE';
export const SOLD = 'SOLD';
export const STAFF = 'STAFF';
export const STOLEN = 'STOLEN';
export const WITH_3RD_PARTY = 'WITH-3RD-PARTY';

export const HEAVILY_DAMAGED = 'HEAVILY-DAMAGED';
export const WRITE_OFF = 'WRITE-OFF';
export const GOOD_WORKING_ORDER = 'GOOD-WORKING-ORDER';
export const UNINSURED = 'UNINSURED';

export const vehicleStatusOptions: OptionList[] = [
  {
    value: LIVE,
    label: 'Live',
  },
  {
    value: AVAILABLE,
    label: 'Available',
  },
  {
    value: ASSIGNED,
    label: 'Assigned',
  },
  {
    value: ORDERED,
    label: 'Ordered',
  },
  {
    value: AWAITING_EVALUATION,
    label: 'Awaiting evaluation',
  },
  {
    value: NO_SALE,
    label: 'Do not sell',
  },
  {
    value: SOLD,
    label: 'Sold',
  },
  {
    value: ARCHIVED,
    label: 'Archived',
  },
  {
    value: AUCTION,
    label: 'Auction',
  },
  {
    value: STAFF,
    label: 'Staff',
  },
  {
    value: STOLEN,
    label: 'Stolen',
  },
  {
    value: COURTESY_ASSIGNED,
    label: 'Courtesy assigned',
  },
  {
    value: PAID_OFF,
    label: 'Paid off',
  },
  {
    value: IN_SERVICING,
    label: 'In servicing',
  },
  {
    value: AFTERCARE,
    label: 'Aftercare',
  },
  {
    value: WITH_3RD_PARTY,
    label: 'With 3rd party',
  },
];

export const vehicleConditionOptions: OptionList[] = [
  {
    value: GOOD_WORKING_ORDER,
    label: 'Good working order',
  },
  {
    value: HEAVILY_DAMAGED,
    label: 'Heavily damaged',
  },
  {
    value: WRITE_OFF,
    label: 'Write off',
  },
  {
    value: UNINSURED,
    label: 'Uninsured',
  },
];

export const MECHANICAL = 'MECHANICAL';
export const BODYWORK = 'BODYWORK';

export const damageTypeOptions: OptionList[] = [
  {
    value: MECHANICAL,
    label: 'Mechanical',
  },
  {
    value: BODYWORK,
    label: 'Bodywork',
  },
  {
    value: GOOD_WORKING_ORDER,
    label: 'Good Working Order',
  },
  {
    value: UNINSURED,
    label: 'Uninsured',
  },
];
