import { DamageLogPayload, DamageResolutionPayload } from '../../models/transfer';
import api from '../../utils/api';

/**
 * Update CR Request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} id - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  transfer_type: '',
 * }
 * updateConditionReport(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateConditionReport = (payload: { id: string; body: { id: string } }) => {
  return api.patch(`condition_report/${payload.id}`, payload.body);
};

/**
 * Submit edit damage log
 * @category API/PATCH
 * @param {object} payload - Data for submitting a damage log
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * condition_report_id,
 * damaged_type,
 * damaged_area,
 * damage_description,
 * damage_detail,
 * files
 * }
 * editDamageLog(body).then((res) => {
 * // Promise resolved
 * });
 */
export const editDamageLog = (id: string, payload: DamageLogPayload) => api.patch(`damage-log/${id}`, payload);

/**
 * Submit resolve damage log
 * @category API/PATCH
 * @param {object} payload - Data for submitting a damage log
 * @return {Promise}  Returns a promise for the api request
 * @example
 * const payload = {
 * id,
 * }
 * resolveDamageLog(body).then((res) => {
 * // Promise resolved
 * });
 */
export const resolveDamageLog = (payload: DamageResolutionPayload) => api.patch('damage-log/resolve', payload);
