import { CreateVehicleBatch } from '../../models/vehicle';
import api from '../../utils/api';

/**
 * Create Batch Request
 * @category API/POST
 * @param {object} body - Data for the batch creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  quantity: '',
 *  vehicle_type_id: '',
 * ...
 * }
 * createBatch(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createBatch = (payload: CreateVehicleBatch) => {
  return api.post('batch/', payload);
};
