export const AGREEMENT_LIVE = 'LIVE';
export const AGREEMENT_PAID_OFF = 'PAID-OFF';
export const AGREEMENT_DROPPED = 'DROPPED';
export const AGREEMENT_FROZEN = 'FROZEN';
export const AGREEMENT_TERMINATED = 'TERMINATED';
export const AGREEMENT_COMPLETED = 'COMPLETED';
export const AGREEMENT_CANCELLED = 'CANCELLED';
export const AGREEMENT_AFTERCARE_REVIEW = 'AFTERCARE-REVIEW';
export const AGREEMENT_PAUSED = 'PAUSED';
export const AGREEMENT_DEC_COMPLETED = 'DECLARATION-COMPLETED';

export const AGREEMENT_SIGNED = 'SIGNED';
export const AGREEMENT_SIGNATURE_PENDING = 'SIGNATURE PENDING';
