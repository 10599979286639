import { ButtonWrapper } from './scrollToTopButton.styles';
import { BsArrowUpCircle } from 'react-icons/bs';
import { PRIMARY_WHITE } from '../../../common/styles/Colors';
import { useEffect, useState } from 'react';
import { useThrottledCallback } from 'use-debounce';

export function ScrollToTopButton() {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  function handleScrollToTop() {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
  }

  function toggleVisibility() {
    if (window.scrollY > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }

  const throttledToggleVisibility = useThrottledCallback(toggleVisibility, 400);

  useEffect(() => {
    window.addEventListener('scroll', throttledToggleVisibility);
    return () => {
      window.removeEventListener('scroll', throttledToggleVisibility);
    };
  }, [throttledToggleVisibility]);

  return (
    <ButtonWrapper onClick={handleScrollToTop} className={isVisible ? 'visible' : ''} title="Go to top">
      <BsArrowUpCircle color={PRIMARY_WHITE} size="24px" />
    </ButtonWrapper>
  );
}
