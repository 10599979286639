import api from '../../utils/api';

/**
 * Update agreement Request
 * @category API/PATCH
 * @param {object} agreementId
 * @param {object} payload data for plan
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  agreement_status: '',
 * }
 * updateAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateAgreement = (id: string, payload: { agreement_status?: string; aftercare_eligible?: boolean }) =>
  api.patch(`agreement/${id}`, payload);
