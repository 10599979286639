import React, { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { Text } from '../../../../uiComponents/text/text';
import { calculateRentalPlanSummary, calculateR2BPlanSummary } from '../../../../utils/vehicleFigures';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { isBurst, isR2B } from '../../../../consts';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { updateApplication } from '../../../../api/patch/application.patch';
import { EV_RENTAL_MAP } from '../../../../consts/application';
import { useEvPackagesContext } from './evPackagesContext';

export const PlanSummary = () => {
  const evPackagesContext = useEvPackagesContext();
  const [submitting, setSubmitting] = useState<boolean>(false);
  const formValues = evPackagesContext?.isRental
    ? calculateRentalPlanSummary(evPackagesContext?.vehicleDetails, evPackagesContext)
    : calculateR2BPlanSummary(evPackagesContext?.vehicleDetails, evPackagesContext);

  const { register } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { ...formValues },
  });

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    if (evPackagesContext?.applicationId) {
      updateApplication(evPackagesContext?.applicationId, {
        bp_card_id: '',
        car_and_charge: evPackagesContext?.isCarAndChargeChecked ?? false,
        driver_id: evPackagesContext?.driverId,
        has_uber_clean_fund: evPackagesContext?.isUberCleanChecked ?? false,
        shell_card_id: evPackagesContext?.shellCardNumber,
        uber_clean_air_amount: evPackagesContext?.uberCleanAirAmount.toFixed(2),
        uber_clean_air_confirmed: true,
        uber_clean_discount_applied_weeks: evPackagesContext?.isUberCleanChecked
          ? isR2B(evPackagesContext?.applicationType)
            ? evPackagesContext?.discountedWeeks
            : EV_RENTAL_MAP[evPackagesContext?.uberCleanAirAmount.toFixed(2)] ?? 0
          : 0,
      }).then(() => {
        evPackagesContext?.onConfirm?.();
        setSubmitting(false);
      });
    }
  }, [evPackagesContext]);

  return (
    <>
      {!evPackagesContext?.isRental && (
        <GridLayout template={3} gap={24}>
          <TextField disabled label="Number of weeks" type="text" {...register('agreement_length_weeks')} />
          <TextField disabled label="Initial contract value" type="text" {...register('total_contract_value')} />
          <TextField disabled label="Final contract value" type="text" {...register('final_contract_value')} />
        </GridLayout>
      )}
      {evPackagesContext?.isRental ? (
        <div style={{ marginTop: 30 }}>
          <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
            Car and change & Uber clean air
          </Text>
          <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
            <TextField
              disabled
              label="Total car and charge Amount"
              type="text"
              {...register('total_car_and_charge_amount')}
            />
            <TextField
              disabled
              label="Total Uber clean air amount"
              type="text"
              {...register('total_uber_clean_air_amount')}
            />
          </GridLayout>
        </div>
      ) : (
        <>
          <div style={{ marginTop: 30 }}>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
              After car and charge package
            </Text>
            <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
              <TextField
                disabled
                label="Total car and charge amount"
                type="text"
                {...register('total_car_and_charge_amount')}
              />
              <TextField
                disabled
                label="Total contract value + car and charge plan"
                type="text"
                {...register('total_contract_after_car_and_charge')}
              />
            </GridLayout>
          </div>
          <div style={{ marginTop: 30 }}>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
              After Uber clean air
            </Text>
            <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
              <TextField
                disabled
                label="Total Uber clean air amount"
                type="text"
                {...register('total_uber_clean_air_amount')}
              />
              <TextField
                disabled
                label="Total contract calue - Uber clean air"
                type="text"
                {...register('total_contract_after_uber_clean_air')}
              />
            </GridLayout>
          </div>
        </>
      )}
      <div style={{ marginTop: 30 }}>
        <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
          Weekly figures
        </Text>
        <GridLayout styled={{ marginTop: 20 }} template={3} gap={24}>
          <TextField disabled label="Headline weekly price" type="text" {...register('headline_weekly_price')} />
          {isBurst(evPackagesContext?.vehicleDetails?.agreementType ?? '') && evPackagesContext?.discountedWeeks ? (
            <>
              <TextField
                name="uber_clean_air_discount"
                type="text"
                label="Weekly Uber clean air discount"
                disabled
                value={`${(evPackagesContext?.uberCleanAirAmount / evPackagesContext?.discountedWeeks)?.toFixed(2)} for the first ${evPackagesContext?.discountedWeeks} weeks`}
              />
              <TextField
                name="weekly_rental_less_ev_assist"
                disabled
                label="Weekly rental - assist"
                type="text"
                value={`${(
                  +(evPackagesContext?.vehicleDetails?.agreement_value_weeks ?? '0') -
                  evPackagesContext?.uberCleanAirAmount / evPackagesContext?.discountedWeeks
                )?.toFixed(2)} for the first ${evPackagesContext?.discountedWeeks} weeks`}
              />
            </>
          ) : (
            <>
              <TextField
                disabled
                label="Weekly Uber clean air discount"
                type="text"
                {...register('uber_clean_air_discount')}
              />
              <TextField
                disabled
                label="Weekly rental - assist"
                type="text"
                {...register('weekly_rental_less_ev_assist')}
              />
            </>
          )}
        </GridLayout>
      </div>
      <FlexLayout itemsX="end" itemsY="end" gap={8} styled={{ marginTop: 16 }}>
        <SecondaryButton
          disabled={submitting}
          styled={{ marginRight: '12px', padding: '0px 36px' }}
          onClick={() => evPackagesContext?.onClose()}
        >
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={!evPackagesContext?.canSubmit}
          isProcessing={submitting}
          styled={{ width: 154, marginTop: 24 }}
          onClick={() => onSubmit()}
        >
          Confirm
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
