import { Control, FieldValues } from 'react-hook-form';
import { ConditionReportSignOffSection, ProgressBackground, UploadProgressBar } from './styles';
import { ChecklistProps } from '../../conditionReport';
import { SignatureScreen } from '../../../../../uiComponents/customComponents/signature/signature';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextArea } from '../../../../../uiComponents/inputs/textArea/textArea';
import { Text } from '../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_5 } from '../../../../../common/styles/Colors';
import { useAppSelector } from '../../../../../store-hooks';
import { useEffect } from 'react';

interface ConditionReportSignOffProps extends ChecklistProps {
  uploadProgress: number;
  uploadingImages: boolean;
}

export const ConditionReportSignOff = ({
  control,
  uploadProgress,
  uploadingImages,
  register,
  setValue,
}: ConditionReportSignOffProps) => {
  const conditionReportData = useAppSelector((state) => state.conditionReport.draft);

  useEffect(() => {
    if (conditionReportData?.notes) {
      setValue('notes', conditionReportData?.notes as unknown as string);
    }
  }, [conditionReportData?.s3_images_url, conditionReportData?.notes, setValue]);

  return (
    <ConditionReportSignOffSection>
      <Text variant="body6" weight={500} color={PRIMARY_PURPLE} block styled={{ marginBottom: 24 }}>
        Condition report sign off
      </Text>
      <GridLayout template={2} gap={24}>
        <SignatureScreen
          name="signature"
          styled={{ width: '100%' }}
          control={control as unknown as Control<FieldValues>}
          required={{
            required: 'Please sign and click "Submit Signature"',
          }}
        />
        <div>
          <TextArea
            label="Notes"
            {...register?.('notes')}
            styled={{ height: 138, borderColor: PRIMARY_PURPLE, backgroundColor: SECONDARY_PURPLE_5 }}
          />
        </div>
      </GridLayout>
      {uploadingImages && (
        <ProgressBackground>
          <UploadProgressBar $progress={uploadProgress}>
            <Text
              variant="body8"
              color={PRIMARY_WHITE}
              weight={500}
              align="right"
              block
              styled={{ paddingRight: 16, marginTop: 8 }}
            >
              {`${uploadProgress}%`}
            </Text>
          </UploadProgressBar>
        </ProgressBackground>
      )}
    </ConditionReportSignOffSection>
  );
};
