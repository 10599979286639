import React, { useEffect, useState } from 'react';
import { LoginBackground, LoginContainer, OttoLogo, Form } from './login.styles';
import { Outlet, useNavigate } from 'react-router-dom';
import { getCurrentUser } from '../../utils/utils';

export const LoginLayout = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const loadUser = async () => {
    setIsLoading(true);
    const currentUser = await getCurrentUser();
    if (currentUser) {
      navigate('/app/dashboard');
      return;
    }
    setIsLoading(false);
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isLoading) {
    return;
  }

  return (
    <LoginContainer>
      <Form>
        <OttoLogo src="/logo_white.svg" width={154} />
        <Outlet />
      </Form>
      <LoginBackground src="/images/loginCurve.svg" width="100%" />
    </LoginContainer>
  );
};
