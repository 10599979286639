import { useCallback, useState } from 'react';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../../order/order';
import { SectionHeader } from '../sectionHeader';
import { LiabilityReview } from './liabilityReview';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { Text } from '../../../../uiComponents/text/text';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { CustomerProfile } from '../../../../models/driver';
import { SignatureScreen } from '../../../../uiComponents/customComponents/signature/signature';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { submitDeclaration } from '../../../../api/post/application.post';
import { IN_SERVICE_RENTAL } from '../../../../consts';
import { Li, TermsAndConditions, Ul } from '../../order/order.styles';

export const DeclarationSection = ({ isComplete, isLocked, progress, checkProgress }: SectionProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [signature, setSignature] = useState<string>();
  const [submitting, setSubmitting] = useState<boolean>();
  const [signatureConfirmed, setSignatureConfirmed] = useState<boolean>(false);
  const { control } = useForm<CustomerProfile>();
  const applicationId: string = progress?.application_id;

  const onSubmit = useCallback(() => {
    setSubmitting(true);
    const file = [
      {
        contents: signature ?? '',
        fileName: 'SIGNATURE',
        type: 'image/png',
      },
    ];

    submitDeclaration({ application_id: applicationId, files: file })
      .then(() => {
        checkProgress?.();
        setSubmitting(false);
      })
      .catch(() => {
        setSubmitting(false);
      });
  }, [checkProgress, applicationId, signature]);
  return (
    <CollapsiblePanel
      styled={{ marginTop: 16 }}
      locked={isLocked}
      header={
        <SectionHeader
          title="Declaration"
          isComplete={isComplete}
          completedText="Completed"
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader title="Declaration" isComplete={isComplete} isLocked={isLocked} expanded />
        {progress?.application_type !== IN_SERVICE_RENTAL && (
          <LiabilityReview dvlaNumber={progress?.dvla_document_no} />
        )}
        <TermsAndConditions>
          <Text styled={{ marginTop: 24 }} variant="body6" weight={500} color={PRIMARY_PURPLE} block>
            Terms and conditions
          </Text>
          <Ul>
            <Li>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                I understand I must not use the vehicle in a manner which Otto Car considers dangerous, this also
                includes driving the vehicle under the influence of any substances, allow modifications to the vehicle
                without Otto Car’s prior authorisation, take to any garage other than a specified Otto Car repair centre
                without prior authorisation, use the vehicle for criminal activity and/or allow for another person to
                partake in criminal activity from the vehicle, smoke in the vehicle or allow any other person to smoke
                whilst in the vehicle.
              </Text>
            </Li>
            <Li>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                I understand I must notify Otto Car of any driving offences or any points incurred on my license, notify
                Otto Car of any form of criminal convictions, state if any medical conditions are diagnosed which could
                affect my driving ability, use the vehicle in an reasonable manner during varied weather conditions,
                ensure the vehicle is locked and secured at all times, do not leave the vehicle running unattended.
              </Text>
            </Li>
            <Li>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                I understand the Rent to Buy agreement agreement is in my name and I am the only person with
                authorisation by Otto Car Ltd and insured to drive the vehicle. This will remain for the full duration
                of the contract.
              </Text>
            </Li>
            <Li>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                I will be hiring the vehicle from Otto Car Ltd; and that I had the freedom to hire a vehicle from
                another source, which may not have required installation of the above mentioned systems and all the
                above mentioned conditions.
              </Text>
            </Li>
            {progress?.application_type === IN_SERVICE_RENTAL && (
              <>
                <Li>
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                    I also agree to return the loan vehicle within 48 hours once my vehicle is ready for collection.
                  </Text>
                </Li>
                <Li>
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                    Please ensure all required items are removed from the vehicle before handing over the key. Any
                    personal items or equipment left in the vehicle are done so at the owners risk. Your vehicle may
                    have to leave our site for repairs to be affected.
                  </Text>
                </Li>
                <Li>
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>
                    I acknowledge that there are no other defects or additional damage other than that indicated.
                  </Text>
                </Li>
              </>
            )}
          </Ul>
        </TermsAndConditions>
        {!isCollapsed && (
          <SignatureScreen
            control={control as unknown as Control<FieldValues>}
            name="declaration_signature"
            required={{
              required: 'Please sign the declaration and click "Submit Signature"',
            }}
            onSignatureSave={(signature: string) => setSignature(signature)}
            onConfirmSignature={(confirmed: boolean) => setSignatureConfirmed(confirmed)}
            styled={{ width: 462 }}
          />
        )}
        <FlexLayout itemsX="end">
          <PrimaryButton
            isProcessing={submitting}
            disabled={isComplete || !signatureConfirmed}
            onClick={() => onSubmit()}
            styled={{ minWidth: 158 }}
          >
            Submit declaration
          </PrimaryButton>
        </FlexLayout>
      </div>
    </CollapsiblePanel>
  );
};
