import { GenericAbortSignal } from 'axios';
import { Employee, EmployeeDepartment, PulseUser } from '../../models/employee';
import api from '../../utils/api';

export const getEmployeeByEmail = (email: string): Promise<{ data: PulseUser }> =>
  api.get(`employee/employee-by-email/${email}`);

/**
 * Get all departments
 * @category API/GET
 * @param {object} getEmployee - Returns all the departments
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllDepartment().then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllDepartments = (): Promise<{ data: { department: EmployeeDepartment }[] }> =>
  api.get('employee/department/all');

/**
 * Get all employees request
 * @category API/GET
 * @param {object} getAllEmployees - Returns all employees in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllEmployees(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllEmployees = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Employee[] }> => api.get(`employee${query && `?${query}`}`, { signal });
