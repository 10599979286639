import styled from 'styled-components';

export const SignatureWrapper = styled.div`
  max-width: 1000px;
  display: grid;
  grid-template-columns: repeat(2, minmax(260px, 1fr));
  gap: 25px;
  margin-top: 18px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
