import { css, styled } from 'styled-components';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';

export const VehicleDetailsCard = styled.div<{ $isDamaged?: boolean }>`
  background-color: ${PRIMARY_PURPLE};
  border-radius: 16px;
  padding: 32px;
  width: 440px;

  ${(props) => {
    if (props.$isDamaged) {
      return css`
        box-shadow: 0 0 0 8px ${STATUS_RED} inset;
      `;
    }
    return null;
  }}
`;

export const VehicleForm = styled.form`
  margin-top: 24px;
`;

export const TransferLocationDropDown = styled.div`
  flex: 1;
`;
