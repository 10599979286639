import { GenericAbortSignal } from 'axios';
import { Branch } from '../../models/branch';
import api from '../../utils/api';

/**
 * Get single branch request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getBranch(branchId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getBranch = (id: string): Promise<{ data: Branch }> => api.get(`internal/branch${id}`);

/**
 * Get all branches request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllBranches(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllBranches = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Branch[] }> => api.get(`internal/branch${query && `?${query}`}`, { signal });

/**
 * Get branches by city request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getBranchesByCity(cityId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getBranchesByCity = (cityId: string, query = ''): Promise<{ count: number; data: Branch[] }> =>
  api.get(`internal/branch/${cityId}/city${query && `?${query}`}`);

/**
 * Check duplicate branches name
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * checkBranchNameDuplicates(name).then((res) => {
 *  // Promise resolved
 * });
 */
export const checkBranchNameDuplicates = (name: string) => {
  return api.get(`internal/duplicate-branch/${name}`);
};

export default {
  getAllBranches,
  getBranch,
  getBranchesByCity,
  checkBranchNameDuplicates,
};
