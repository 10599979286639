import { useCallback, useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { Servicing } from '../../../models/servicing';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import moment from 'moment';
import { VehicleInfoProps } from '../vehiclePage/vehiclePage';
import { vehicleServicingsColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { getAllServicings } from '../../../api/get/servicing.get';

export const VehicleServicing = ({ vehicle }: VehicleInfoProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();
  const vehicleId: string = vehicle?.vehicle_id;

  const handleGetServicingsResponse = useCallback(
    (count: number, servicings: Servicing[]) => {
      const servicingRows = servicings?.map((servicing: Servicing) => {
        return {
          rowData: { data: servicing },
          cells: [
            <TableTextCell value={servicing?.branch_name} />,
            <TableTagCell tags={servicing?.servicing_type} />,
            <TableTextCell
              value={servicing?.start_date ? moment(servicing?.start_date)?.format('DD MMM YYYY') : '-'}
            />,
            <TableTextCell value={servicing?.end_date ? moment(servicing?.end_date)?.format('DD MMM YYYY') : '-'} />,
          ],
        };
      });
      setTableData(servicingRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchServicingList = useCallback(
    (queryString: string) => {
      getAllServicings(queryString).then((response: { count: number; data: Servicing[] }) => {
        handleGetServicingsResponse(response.count, response.data);
      });
    },
    [handleGetServicingsResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, searchString: string, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchServicingList(queryString);
    },
    [fetchServicingList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('start_date');
    fetchServicingList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:ASC&filter=vehicle_id$eq=${vehicleId}`);
  }, [fetchServicingList, setSortingColumn, vehicleId]);

  useEffect(() => {
    setTableFilters([{ columnName: 'vehicle_id', options: { value: vehicleId, label: vehicleId } }]);
  }, [setTableFilters, vehicleId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          Servicings
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          Servicings
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          header="Servicings"
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={vehicleServicingsColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          onSearchChange={(value: string) => {
            setSearchString(value);
            applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
          }}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, searchString, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
