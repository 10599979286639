import { NewNote } from '../../models/notes';
import api from '../../utils/api';

/**
 * Create Note Request
 * @category API/POST
 * @param {object} data - Data for note creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *    note_content: 'Hello Test',
      category_id,
    };
 * }
 * createNote(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createNote = (params: NewNote) => {
  return api.post('note/', params);
};

export default { createNote };
