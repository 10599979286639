import { FuelType, VehicleType } from '../models/vehicle';
import { InputType } from '../uiComponents/inputs/textInput/textInput';

export interface EditFields<T> {
  label: string;
  name: keyof T;
  type: InputType | 'select' | 'checkbox' | 'text-area';
  placeholder: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  validate?: { validation: (v: string) => boolean | string };
  required?: string;
  disabled?: boolean;
  options?: { value: string; label: string }[];
}

export interface FuelOption {
  value: FuelType;
  label: FuelType;
}
type Month = {
  label: string;
  value: string;
};

export const fuelTypeOptions: FuelOption[] = [
  {
    value: 'EV',
    label: 'EV',
  },
  {
    value: 'HYBRID',
    label: 'HYBRID',
  },
  {
    value: 'DIESEL',
    label: 'DIESEL',
  },
  {
    value: 'PLUG-IN',
    label: 'PLUG-IN',
  },
];

export const months: Month[] = [
  {
    label: '1',
    value: '1',
  },
  {
    label: '2',
    value: '2',
  },
  {
    label: '3',
    value: '3',
  },
  {
    label: '4',
    value: '4',
  },
  {
    label: '5',
    value: '5',
  },
  {
    label: '6',
    value: '6',
  },
  {
    label: '7',
    value: '7',
  },
  {
    label: '8',
    value: '8',
  },
  {
    label: '9',
    value: '9',
  },
  {
    label: '10',
    value: '10',
  },
  {
    label: '11',
    value: '11',
  },
  {
    label: '12',
    value: '12',
  },
];

export const vehicleTypeEdit: EditFields<VehicleType>[] = [
  {
    label: 'Manufacturer',
    name: 'manufacturer',
    type: 'text',
    placeholder: 'ex. Toyota',
  },
  {
    label: 'Model',
    name: 'model',
    type: 'text',
    placeholder: 'ex. Prius',
  },
  {
    label: 'Fuel Type',
    name: 'fuel_type',
    type: 'select',
    placeholder: 'Select from list',
    options: fuelTypeOptions,
  },
  {
    label: 'Spec',
    name: 'spec',
    type: 'text',
    placeholder: 'ex. 2L 86Kwh',
  },
  {
    label: 'Year',
    name: 'model_year',
    type: 'number',
    placeholder: 'Year',
  },
  {
    label: 'Agreement Length (weeks)',
    name: 'agreement_length_weeks',
    type: 'number',
    placeholder: 'Agreement Length (weeks)',
  },
  {
    label: 'Agreement Value Weeks',
    name: 'agreement_value_weeks',
    type: 'number',
    placeholder: 'Agreement Value Weeks',
  },
  {
    label: 'Net Purchase price (by otto)',
    name: 'net_purchase_price_otto',
    type: 'number',
    placeholder: 'Net Purchase price (by otto)',
  },
  {
    label: 'Government Grant Value',
    name: 'government_grant_value',
    type: 'number',
    placeholder: 'Government Grant Value',
  },
  {
    label: 'List price ex VAT',
    name: 'list_price_ex_vat',
    type: 'number',
    placeholder: 'List price ex VAT',
  },
  {
    label: 'Deposit',
    name: 'deposit',
    type: 'text',
    placeholder: 'Deposit',
  },
  {
    label: 'Gross Value',
    name: 'gross_value',
    type: 'number',
    placeholder: 'Gross Value',
  },
  {
    label: 'NET Value',
    name: 'net_value',
    type: 'number',
    placeholder: 'NET Value',
  },
  {
    label: 'VAT Value',
    name: 'vat_value',
    type: 'number',
    placeholder: 'VAT Value',
  },
  {
    label: 'Service Interval (Mileage)',
    name: 'service_interval_mileage',
    type: 'number',
    placeholder: 'Service Interval (Mileage)',
  },
  {
    label: 'Service Interval (Months)',
    name: 'service_interval_months',
    type: 'number',
    placeholder: 'Service Interval (Months',
  },
  {
    label: 'Breakdown Cover (months)',
    name: 'breakdown_cover_months',
    type: 'number',
    placeholder: 'Service Interval (Months',
  },
  {
    label: 'Sales Points',
    name: 'sales_points',
    type: 'text-area',
    placeholder: 'Type here',
  },
  {
    label: 'Sales Paragraph',
    name: 'sales_paragraph',
    type: 'text-area',
    placeholder: 'Type here',
  },
];
