import styled from 'styled-components';
import { PRIMARY_PURPLE, PRIMARY_WHITE, PRIMARY_GREEN, STATUS_RED } from '../../../../common/styles/Colors';

export const StyledDiv = styled.div`
  color: ${PRIMARY_PURPLE};

  span {
    border-radius: 8px;
    color: ${PRIMARY_WHITE};
    font-size: 12px;
    margin-left: 8px;
    padding: 4px 8px;
    text-transform: uppercase;
    text-align: center;
  }

  .green-text {
    background-color: ${PRIMARY_GREEN};
  }

  .red-text {
    background-color: ${STATUS_RED};
  }
`;
