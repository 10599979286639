import { withStyledProps } from '../../../utils/colorUtils';
import { PRIMARY_WHITE } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { Tooltip } from './actionIcon.styles';

interface IconTooltipProps {
  showTooltip: boolean;
  tooltip: string;
}

export const IconTooltip = withStyledProps(({ showTooltip, tooltip, ...props }: IconTooltipProps) => {
  return (
    <Tooltip $show={showTooltip} onClick={(e) => e.stopPropagation()} {...props}>
      <Text color={PRIMARY_WHITE} variant="body7" weight={300} styled={{ whiteSpace: 'nowrap' }}>
        {tooltip}
      </Text>
    </Tooltip>
  );
});
