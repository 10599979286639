import { AFTERCARE_FULL, AFTERCARE_INSURANCE } from './plans';

export const aftercareAgreementTypes = [
  {
    label: 'Aftercare insurance',
    value: AFTERCARE_INSURANCE,
  },
  {
    label: 'Full aftercare',
    value: AFTERCARE_FULL,
  },
];

export const cancellationOptions = [
  {
    label: 'Customer service issue',
    value: 'CUSTOMER SERVICE ISSUE',
  },
  {
    label: 'Health concerns',
    value: 'HEALTH CONCERNS',
  },
  {
    label: 'Selling car privately',
    value: 'SELLING CAR PRIVATELY',
  },
  {
    label: 'Moving to private insurance',
    value: 'MOVING TO PRIVATE INSURANCE',
  },
  {
    label: 'Quitting PCO industry',
    value: 'QUITTING PCO INDUSTRY',
  },
  {
    label: 'Moving homes',
    value: 'MOVING HOMES',
  },
  {
    label: 'Banned by DVLA',
    value: 'BANNED BY DVLA',
  },
  {
    label: 'Other (please fill out in notes)',
    value: 'OTHER',
  },
];
