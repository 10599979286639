import React, { useState } from 'react';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { AftercareContact, AftercareReview, AftercareSetup } from '../../../models/aftercare';
import { Notification } from '../../../uiComponents/toast/toast';
import { editAftercareSetup } from '../../../api/patch/aftercare.patch';
import { createAftercareSetup } from '../../../api/post/aftercare.post';
import { aftercareAgreementTypes } from '../../../consts/aftercare';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { decimalRegex, numberWithoutLeadingZeros } from '../../../utils/validations';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import moment from 'moment';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { completeAgreement } from '../../../api/post/agreement.post';
import { AGREEMENT_AFTERCARE_REVIEW } from '../../../consts/agreement';

interface AftercareSetupValues {
  aftercare_type: string;
  aftercare_start_date: string;
  aftercare_price: string;
  aftercare_length_weeks: string;
  notes?: string;
  aftercare_id?: string;
  original_agreement_id?: string;
}

export const AftercareSetupForm = ({
  aftercare,
  onClose,
  isInEdit,
  onSubmit = () => {},
}: {
  aftercare: AftercareContact | AftercareReview;
  onClose: () => void;
  isInEdit: boolean;
  onSubmit: () => void;
}) => {
  const defaultValues: AftercareSetupValues = isInEdit
    ? {
        aftercare_id: (aftercare as AftercareReview).id,
        notes: (aftercare as AftercareReview).notes,
        aftercare_start_date: (aftercare as AftercareReview).aftercare_start_date
          ? moment((aftercare as AftercareReview).aftercare_start_date)?.format('YYYY-MM-DD')
          : '',
        aftercare_type: (aftercare as AftercareReview).aftercare_type,
        original_agreement_id: (aftercare as AftercareReview).agreement_id,
        aftercare_length_weeks: (aftercare as AftercareReview).aftercare_length_weeks,
        aftercare_price: (aftercare as AftercareReview).aftercare_price,
      }
    : {
        notes: '',
        original_agreement_id: (aftercare as AftercareContact).agreement_id,
        aftercare_price: '',
        aftercare_length_weeks: '',
        aftercare_type: '',
        aftercare_start_date: '',
      };
  const {
    handleSubmit,
    control,
    register,
    formState: { errors },
  } = useForm<AftercareSetupValues, string>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: defaultValues,
  });
  const [loading, setLoading] = useState<boolean>(false);
  const onFormSubmit = async (submitValues: AftercareSetupValues) => {
    setLoading(true);
    const handleResponse: () => void = () => {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Aftercare setup successful',
        isAlert: true,
      });
      onSubmit();
      onClose();
    };

    const payload: AftercareSetup = {
      ...submitValues,
      aftercare_length_weeks: parseInt(submitValues.aftercare_length_weeks),
    };
    const callback: (payload: AftercareSetup) => Promise<{ data: { id: string } }> = isInEdit
      ? editAftercareSetup
      : createAftercareSetup;
    callback(payload)
      .then(() => {
        if (isInEdit) {
          handleResponse();
        } else {
          completeAgreement({
            agreement_id: defaultValues?.original_agreement_id ?? '',
            status: AGREEMENT_AFTERCARE_REVIEW,
          }).then(() => {
            handleResponse();
          });
        }
      })
      .catch((error: { message: string }) => {
        Notification({ type: 'error', title: 'Error', message: error.message, isAlert: true });
      })
      .finally(() => {
        onClose();
        setLoading(false);
      });
  };
  return (
    <form>
      <FlexLayout gap={20} vertical>
        <GridLayout template={2} gap={16}>
          <div>
            <TextFieldLabel $isRequired={true}>Agreement type</TextFieldLabel>
            <DropDown
              placeholder={'Agreement type'}
              options={aftercareAgreementTypes}
              name="aftercare_type"
              control={control as unknown as Control<FieldValues>}
              error={errors?.aftercare_type}
            />
          </div>
          <TextField
            label="Agreement start date"
            {...register('aftercare_start_date', {
              required: 'Agreement start date is a required field',
            })}
            required
            type="date"
            error={errors?.aftercare_start_date}
          />
          <FlexLayout itemsX="space-between" gap={16}>
            <TextField
              label="Duration"
              {...register('aftercare_length_weeks', {
                required: 'Duration is a required field',
                pattern: {
                  value: numberWithoutLeadingZeros,
                  message: 'The duration should be a number',
                },
              })}
              required
              type="number"
              placeholder="Agreement duration"
              error={errors?.aftercare_length_weeks}
            />
            <TextField
              label="Price"
              {...register('aftercare_price', {
                required: 'Price is a required field',
                pattern: {
                  value: decimalRegex,
                  message: 'The price should be a decimal number',
                },
              })}
              required
              type="number"
              placeholder="Weekly amount"
              error={errors?.aftercare_price}
            />
          </FlexLayout>
          <TextField placeholder={''} label="Notes" {...register('notes')} type="text" error={errors?.notes} />
        </GridLayout>
        <FlexLayout itemsX="end">
          <PrimaryButton isProcessing={loading} onClick={handleSubmit(onFormSubmit)}>
            Submit
          </PrimaryButton>
        </FlexLayout>
      </FlexLayout>
    </form>
  );
};
