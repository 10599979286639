import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AxiosError } from 'axios';
import {
  ActionGroup,
  FormContainer,
  GoogleButton,
  InputContainer,
  StyledEyeIcon,
  StyledH2,
  StyledLink,
  StyledMailIcon,
  StyledPrimaryButton,
  StyledSlashEyeIcon,
  StyledTextField,
} from './login.styles';
import { PrimaryButton } from '../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { InputType } from '../../uiComponents/inputs/textInput/textInput';
import { Notification } from '../../uiComponents/toast/toast';
import { forgotPassword, requestPasswordReset } from '../../api/post/auth.post';
import { LoginDetails } from '../../models/employee';
import { emailFormat } from '../../utils/validations';
import { DASHBOARD, LOGIN } from '../../consts/routes';
import { getAuthService } from '../../api/cognito/auth.service';
import { getEmployeeByEmail } from '../../api/get/employee.get';
import { useAppDispatch } from '../../store-hooks';
import { updatePulseUserState } from '../../features/pulseUser/pulseUserSlice';

interface LoginProps {
  formName: 'login' | 'forgot-password' | 'reset-password';
}

interface LoginFormFields {
  email: string;
  password: string;
  confirmPassword: string;
}

interface ResetPasswordResponse {
  id?: string;
  message?: string;
}

export const Login = ({ formName }: LoginProps) => {
  const authService = getAuthService();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm<LoginFormFields>({
    mode: 'all',
  });

  const navigate = useNavigate();
  const [passwordType, setPasswordType] = useState<InputType>('password');
  const [showEmailSentConfirmation, setShowEmailSentConfirmation] = useState<boolean>(false);
  const { id } = useParams();

  const togglePasswordReveal = () => {
    setPasswordType(passwordType === 'password' ? 'text' : 'password');
  };

  const googleLogin = async () => {
    let currentCognitoUser = await authService.getCurrentAuthUser();
    if (!currentCognitoUser) {
      await authService.loginInWithGoogle();
    }
    currentCognitoUser = await authService.getCurrentAuthUser();
    if (currentCognitoUser) {
      const currentSession = await authService.fetchCurrentAuthSession();
      if (
        currentSession &&
        currentSession.tokens &&
        currentSession.tokens.idToken &&
        currentSession.tokens.idToken.payload &&
        currentSession.tokens.idToken.payload.email
      ) {
        const employee = await getEmployeeByEmail(currentSession.tokens.idToken.payload.email as string);
        dispatch(updatePulseUserState(employee.data));
        navigate(DASHBOARD);
      }
    }
  };

  const loginWithEmailPassword = async (email: string, password: string) => {
    try {
      await authService.loginWithUsername(email, password);
      const currentCognitoUser = await authService.getCurrentAuthUser();
      if (currentCognitoUser) {
        const currentSession = await authService.fetchCurrentAuthSession();
        if (
          currentSession &&
          currentSession.tokens &&
          currentSession.tokens.idToken &&
          currentSession.tokens.idToken.payload &&
          currentSession.tokens.idToken.payload.email
        ) {
          const employee = await getEmployeeByEmail(currentSession.tokens.idToken.payload.email as string);
          dispatch(updatePulseUserState(employee.data));
          navigate(DASHBOARD);
        }
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };

  const onSubmit = (values: LoginDetails) => {
    const loginSubmit = () => {
      loginWithEmailPassword(values.email, values.password)
        .then(() => {
          setLoading(false);
          navigate(DASHBOARD);
        })
        .finally(() => setLoading(false));
    };

    const forgotPasswordSubmit = () => {
      requestPasswordReset(values.email)
        .then(() => {
          setLoading(false);
          setShowEmailSentConfirmation(true);
        })
        .catch((error: AxiosError<ResetPasswordResponse>) =>
          setError('email', { type: 'server', message: error.response?.data?.message }, { shouldFocus: true })
        )
        .finally(() => setLoading(false));
    };

    const resetPasswordSubmit = () => {
      forgotPassword(id ?? '', values)
        .then(() => {
          Notification({
            type: 'success',
            title: 'Password changed',
            message: 'You can now log in with your new credentials',
            isAlert: true,
          });
          navigate(LOGIN);
        })
        .finally(() => setLoading(false));
    };

    if (formName === 'login') {
      loginSubmit();
    } else if (formName === 'forgot-password') {
      forgotPasswordSubmit();
    } else {
      resetPasswordSubmit();
    }
    /**
     * login
     * save token in local storage
     * push to admin dashboard
     */
    setLoading(true);
  };

  useEffect(() => {
    setShowEmailSentConfirmation(false);
  }, [formName]);

  const getButtonLabel = useCallback(() => {
    if (formName === 'login') {
      return 'Login';
    } else if (formName === 'forgot-password') {
      return 'Reset password';
    } else {
      return 'Confirm';
    }
  }, [formName]);

  return (
    <FormContainer onSubmit={handleSubmit(onSubmit)}>
      {showEmailSentConfirmation ? (
        <FlexLayout itemsX="center" vertical>
          <StyledH2>An email has been sent with a link to change your password.</StyledH2>
          <PrimaryButton isGreen onClick={() => navigate(LOGIN)}>
            Return to login
          </PrimaryButton>
        </FlexLayout>
      ) : (
        <>
          <StyledH2>Welcome Back</StyledH2>
          {(formName === 'login' || formName === 'forgot-password') && (
            <InputContainer>
              <StyledTextField
                {...register('email', {
                  required: 'You must specify an email',
                  pattern: {
                    value: emailFormat,
                    message: 'Wrong email format',
                  },
                  validate: (value) =>
                    value.endsWith('@ottocar.co.uk') && value !== 'eng-admin@ottocar.co.uk'
                      ? 'Please use Google Sign-In for Otto Car email addresses.'
                      : true,
                })}
                label=""
                name="email"
                type="email"
                placeholder="Enter Email Address"
                error={errors.email}
              />
              <StyledMailIcon size={24} />
            </InputContainer>
          )}
          {(formName === 'login' || formName === 'reset-password') && (
            <InputContainer>
              <StyledTextField
                {...register('password', {
                  required: 'You must specify a password',
                  minLength: 5,
                })}
                label=""
                placeholder={formName === 'login' ? 'Password' : 'New Password'}
                name="password"
                type={passwordType as InputType}
                error={errors.password}
              />
              {passwordType === 'password' ? (
                <StyledEyeIcon onClick={togglePasswordReveal} size={24} />
              ) : (
                <StyledSlashEyeIcon onClick={togglePasswordReveal} size={24} />
              )}{' '}
            </InputContainer>
          )}
          {formName === 'reset-password' && (
            <InputContainer>
              <StyledTextField
                {...register('confirmPassword', {
                  required: 'You must specify a password',
                  minLength: 5,
                })}
                label=""
                placeholder={'Confirm Password'}
                name="confirmPassword"
                type={passwordType as InputType}
                error={errors.password}
              />
              {passwordType === 'password' ? (
                <StyledEyeIcon onClick={togglePasswordReveal} size={24} />
              ) : (
                <StyledSlashEyeIcon onClick={togglePasswordReveal} size={24} />
              )}
            </InputContainer>
          )}
          <div>
            {(formName === 'login' || formName === 'forgot-password') && (
              <StyledLink to={formName === 'login' ? '/forgot-password' : '/login'}>
                {formName === 'login' ? 'Forgot password?' : 'Back to login'}
              </StyledLink>
            )}
          </div>
          <ActionGroup>
            <GoogleButton type="button" onClick={googleLogin}>
              Sign in with Google
            </GoogleButton>
            <StyledPrimaryButton isGreen onClick={handleSubmit(onSubmit)} isProcessing={loading}>
              {getButtonLabel()}
            </StyledPrimaryButton>
          </ActionGroup>
        </>
      )}
    </FormContainer>
  );
};
