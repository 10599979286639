import React, { useCallback, useEffect, useState } from 'react';
import { PolicyVehiclesReviewPayload } from '../../../models/insurancePolicy';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { policyVehiclesColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { TableTagCell, TableTextCell, downloadXLSX } from '../../../uiComponents/table/tableUtils/tableUtils';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsDownload, BsEye } from 'react-icons/bs';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { useNavigate } from 'react-router-dom';
import { VEHICLES } from '../../../consts/routes';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { DownloadAction } from './ReviewPolicy.styles';
import { Text } from '../../../uiComponents/text/text';
import { getAllInsurancePolicyVehicles } from '../../../api/get/insurance.get';

interface ReviewPolicyFormProps {
  onContinue: () => void;
  policyId: string;
}

export const ReviewPolicy = ({ onContinue, policyId }: ReviewPolicyFormProps) => {
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  const navigate = useNavigate();

  const {
    setTotalRows,
    setTableData,
    goToPageNumber,
    setNumRowsPerPage,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const handleGetPolicyVehiclesList = useCallback(
    (count: number, vehicles: PolicyVehiclesReviewPayload[]) => {
      const vehiclesData = vehicles?.map((vehicle: PolicyVehiclesReviewPayload) => {
        return {
          rowData: { data: vehicle, rowColour: PRIMARY_WHITE },
          cells: [
            <TableTextCell value={vehicle?.vrm} />,
            <TableTextCell value={vehicle?.insurance_company} />,
            <TableTextCell value={vehicle.driver_name} />,
            <TableTextCell value={vehicle.plan} />,
            <TableTextCell value={vehicle.vehicle} />,
            <TableTagCell tags={[vehicle.vehicle_status]} />,
            <FlexLayout gap={16}>
              <ActionIcon
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View vehicle"
                onClick={() => navigate(`${VEHICLES}/${vehicle.vehicle_id}`, { replace: false })}
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehiclesData);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, navigate]
  );

  const getPolicyVehiclesList = useCallback(
    (count: number, values: PolicyVehiclesReviewPayload[]) => {
      handleGetPolicyVehiclesList(count, values);
    },
    [handleGetPolicyVehiclesList]
  );

  const applyFilters = useCallback(
    async (pageNumber: number, numRowsPerPage: number) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const { data } = await getAllInsurancePolicyVehicles(policyId);
      const pagination = data.slice(pageNumber * numRowsPerPage, pageNumber * numRowsPerPage + numRowsPerPage);
      getPolicyVehiclesList(pagination.length, pagination);
    },
    [policyId, getPolicyVehiclesList, setTableData, goToPageNumber]
  );

  useEffect(() => {
    applyFilters(pageNumber, numRowsPerPage);
  }, [applyFilters, pageNumber, numRowsPerPage]);

  const onDownloadClick = useCallback(() => {
    setIsDownloading(true);
    getAllInsurancePolicyVehicles(policyId).then(
      (response: { count?: number; data: PolicyVehiclesReviewPayload[] }) => {
        downloadXLSX(response.data, 'new download');
        setIsDownloading(false);
      }
    );
  }, [policyId]);

  return (
    <>
      <FlexLayout itemsX="end" itemsY="center" styled={{ marginBottom: 16 }}>
        <DownloadAction gap={8} onClick={() => (isDownloading ? null : onDownloadClick())}>
          {isDownloading ? (
            <Spinner size={16} color={SECONDARY_PURPLE_70} />
          ) : (
            <BsDownload size={16} color={SECONDARY_PURPLE_70} />
          )}
          <Text variant="body7" color={SECONDARY_PURPLE_70} weight={300}>
            Download CSV
          </Text>
        </DownloadAction>
        <PrimaryButton onClick={onContinue}>Continue</PrimaryButton>
      </FlexLayout>
      <Table
        columns={policyVehiclesColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(pageNumber, numRowsPerPage);
        }}
        sortingColumn={sortingColumn}
        filterQuery={filterQuery}
        sortAscending={sortAscending}
        onColumnHeaderClick={() => {}}
        variant="compact"
        embedded
        overflowY="auto"
        maxHeight="calc(80vh - 300px)"
      />
    </>
  );
};
