import { useCallback, useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../../order/order';
import { SectionHeader } from '../sectionHeader';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { TableFilter, TableTextCell, getQueryString } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Vehicle } from '../../../../models/vehicle';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { Table } from '../../../../uiComponents/table/table';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_70, STATUS_RED } from '../../../../common/styles/Colors';
import { getAllAssignableVehicles } from '../../../../api/get/vehicle.get';
import { vehicleColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { Modal } from '../../../../uiComponents/modals/modal';
import { AssignVehicleForm } from './assignVehicleForm';
import {
  CAR_HIRE_NATIONAL,
  COURTESY,
  IN_SERVICE_RENTAL,
  OTTO_CAR,
  RENTAL,
  UNINSURED,
  isR2BNew,
  isR2BUsed,
} from '../../../../consts';
import { Text } from '../../../../uiComponents/text/text';
import { OptionList } from '../../../../utils/props';
import { vehicleSourceOptions } from '../../../../uiComponents/table/tableFilters/tableFilterOptions';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { Tag } from '../../../../uiComponents/customComponents/tag/tag';
import { useNavigate } from 'react-router-dom';
import { assignCourtesyVrm, unassignCourtesy, unassignVehicle } from '../../../../api/post/vehicle.post';
import { VehicleSourceFilter } from '../../order/order.styles';
import { VEHICLES } from '../../../../consts/routes';
import { Notification } from '../../../../uiComponents/toast/toast';
import { AgreementType } from '../../../../models/agreement';

let currentVehicleTypeId: string;
let currentVehicleId: string;
let plan: string;
interface AssignVehicleProps extends SectionProps {
  replacementApplicationId?: string;
  onAssignVehicle: () => void;
}

export const AssignVehicleSection = ({
  isComplete,
  isLocked,
  progress,
  replacementApplicationId,
  onAssignVehicle,
}: AssignVehicleProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [selectedVehicleId, setSelectedVehicleId] = useState<string>();
  const [showAssignVehicleModal, setShowAssignVehicleModal] = useState<boolean>(false);
  const [selectedVehicleSource, setSelectedVehicleSource] = useState<OptionList>(vehicleSourceOptions[0]);
  const [vehicleList, setVehicleList] = useState<Vehicle[]>();
  const vehicleTypeId: string = progress?.vehicle_type_id;
  const applicationType: AgreementType = progress?.application_type;
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    getSortDirection,
    setNumRowsPerPage,
    setSortingColumn,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const applicationId: string = progress?.application_id;
  const vehicleId: string = progress?.vehicle_id;
  const driverId: string = progress?.driver_id;
  const agreementId: string | null = progress?.agreement_id;
  const city: string = progress?.city_name;

  useEffect(() => {
    const vehicleRows = vehicleList?.map((vehicle: Vehicle) => {
      return {
        rowData: { data: vehicle },
        cells: [
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.vrm ?? '-'} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.manufacturer} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.model} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.spec ?? '-'} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.model_year ?? '-'} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.color} />,
          <TableTextCell color={SECONDARY_PURPLE_70} value={vehicle?.vehicle_source ?? '-'} />,
          <>
            {city === 'London' && vehicle?.condition === UNINSURED ? (
              <Tag styled={{ width: 120 }} color={STATUS_RED}>
                Uninsured
              </Tag>
            ) : (
              <PrimaryButton
                styled={{ width: 96, height: 24 }}
                disabled={vehicleId != null}
                onClick={() => {
                  if (applicationType === COURTESY && agreementId && replacementApplicationId) {
                    assignCourtesyVrm({
                      courtesy_id: replacementApplicationId,
                      driver_id: driverId,
                      vehicle_id: vehicle?.vehicle_id,
                      agreement_id: agreementId,
                    }).then(() => {
                      Notification({
                        type: 'success',
                        title: 'Success',
                        message: 'Vehicle was successfully assigned.',
                      });
                      onAssignVehicle();
                      setIsCollapsed(true);
                    });
                  } else {
                    setSelectedVehicleId(vehicle?.vehicle_id);
                    setShowAssignVehicleModal(true);
                  }
                }}
              >
                Select
              </PrimaryButton>
            )}
          </>,
        ],
      };
    });
    if (vehicleRows) {
      setTableData(vehicleRows);
    }
  }, [
    vehicleList,
    vehicleId,
    setTableData,
    replacementApplicationId,
    applicationType,
    onAssignVehicle,
    driverId,
    agreementId,
    city,
  ]);

  useEffect(() => {
    setSortingColumn('');
  }, [setSortingColumn]);

  const handleGetVehiclesResponse = useCallback(
    (count: number, vehicleList: Vehicle[]) => {
      setVehicleList(vehicleList);
      setTotalRows(count);
    },
    [setTotalRows]
  );

  const fetchVehicles = useCallback(
    (vehicleTypeId: string, queryString: string) => {
      const controller = new AbortController();
      getAllAssignableVehicles(vehicleTypeId, queryString, controller.signal).then(
        (response: { count: number; data: Vehicle[] }) => {
          handleGetVehiclesResponse(response?.count, response?.data);
        }
      );
    },
    [handleGetVehiclesResponse]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      vehicleSource: OptionList
    ) => {
      const determineFilters = () => {
        const vehicleFilters: TableFilter[] = [];

        if (isR2BNew(plan)) {
          vehicleFilters.push({ columnName: 'used', options: { value: 'false', label: 'false' } });
        } else if (isR2BUsed(plan)) {
          vehicleFilters.push({ columnName: 'used', options: { value: 'true', label: 'true' } });
        } else if (plan === RENTAL || plan === CAR_HIRE_NATIONAL) {
          vehicleFilters.push({ columnName: 'vehicle_source', options: { value: OTTO_CAR, label: 'Otto car' } });
        } else if (plan === IN_SERVICE_RENTAL) {
          vehicleFilters.push({ columnName: 'used', options: { value: 'true', label: 'true' } });
          vehicleFilters.push({ columnName: 'vehicle_source', options: { value: OTTO_CAR, label: 'Otto car' } });
        } else if (replacementApplicationId != null) {
          vehicleFilters.push({ columnName: 'vehicle_source', options: { value: OTTO_CAR, label: 'Otto car' } });
        }

        if (vehicleSource.value !== 'all') {
          vehicleFilters.push({
            columnName: 'vehicle_source',
            options: { value: vehicleSource.value, label: vehicleSource.label },
          });
        }
        return vehicleFilters;
      };

      goToPageNumber(pageNumber);

      const filters = determineFilters();
      const queryString = getQueryString(filters, rowsPerPage, pageNumber, searchString, sortingColumn, sortAscending);
      fetchVehicles(vehicleTypeId, queryString);
    },
    [fetchVehicles, goToPageNumber, replacementApplicationId, vehicleTypeId]
  );

  useEffect(() => {
    if (
      (!isCollapsed && !tableData) ||
      ((vehicleTypeId !== currentVehicleTypeId || plan !== applicationType || vehicleId !== currentVehicleId) &&
        !isCollapsed)
    ) {
      currentVehicleTypeId = vehicleTypeId;
      currentVehicleId = vehicleId;
      plan = applicationType;
      applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending, selectedVehicleSource);
    }
  }, [
    isCollapsed,
    tableData,
    selectedVehicleSource,
    numRowsPerPage,
    vehicleTypeId,
    vehicleId,
    searchString,
    sortingColumn,
    applicationType,
    sortAscending,
    applyFilters,
  ]);

  const unAssign = useCallback(() => {
    if (applicationType === COURTESY && replacementApplicationId) {
      unassignCourtesy({ courtesy_id: replacementApplicationId, vehicle_id: vehicleId }).then(() => {
        onAssignVehicle();
      });
    } else {
      unassignVehicle({ application_id: applicationId, vehicle_id: vehicleId }).then(() => {
        onAssignVehicle();
      });
    }
  }, [applicationId, vehicleId, onAssignVehicle, applicationType, replacementApplicationId]);

  return (
    <CollapsiblePanel
      styled={{ marginTop: progress?.application_type === COURTESY ? 0 : 16 }}
      locked={isLocked}
      header={
        <SectionHeader
          title="Assign vehicle"
          completedText={progress?.vrm}
          isComplete={isComplete}
          isLocked={isLocked}
          expanded={false}
        />
      }
      expanded={!isCollapsed}
      onCollapse={() => {
        setIsCollapsed(!isCollapsed);
      }}
    >
      <div>
        <SectionHeader
          title="Assign vehicle"
          completedText={`${progress?.vrm} (${progress?.vehicle_source})`}
          isComplete={isComplete}
          isLocked={isLocked}
          expanded
        />
        <Table
          variant="standard"
          header={
            <div>
              <FlexLayout gap={16} itemsX="space-between" itemsY="center">
                <div>
                  <Text variant="body4" color={PRIMARY_PURPLE} weight={500} block>
                    {progress?.driver_name}
                  </Text>
                  {progress?.vrm && progress?.vehicle_id && (
                    <FlexLayout itemsY="center" gap={4} styled={{ marginTop: 16 }}>
                      <Text
                        styled={{ textDecoration: 'underline', cursor: 'pointer' }}
                        variant="body7"
                        color={PRIMARY_PURPLE}
                        weight={300}
                        onClick={() => navigate(`${VEHICLES}/${progress?.vehicle_id}`)}
                      >
                        {progress?.vrm}
                      </Text>
                      <Text variant="body8" color={PRIMARY_PURPLE} weight={300}>
                        {`(${progress?.vehicle_source ?? '-'})`}
                      </Text>
                    </FlexLayout>
                  )}
                </div>
                {progress?.vehicle_id && <SecondaryButton onClick={() => unAssign()}>Unassign vehicle</SecondaryButton>}
              </FlexLayout>
              {plan !== IN_SERVICE_RENTAL && plan !== COURTESY && plan !== RENTAL && plan !== CAR_HIRE_NATIONAL && (
                <VehicleSourceFilter>
                  <TextFieldLabel>Vehicle source</TextFieldLabel>
                  <DropDown
                    name="vehicle_source"
                    placeholder="Vehicle source"
                    options={vehicleSourceOptions}
                    defaultValue={vehicleSourceOptions[0]}
                    onSelect={(option) => {
                      setSelectedVehicleSource(option as OptionList);
                      applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending, option as OptionList);
                    }}
                    styled={{ width: 240 }}
                  />
                </VehicleSourceFilter>
              )}
            </div>
          }
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(
              pageNumber,
              numRowsPerPage,
              searchString,
              columnId,
              getSortDirection(columnId),
              selectedVehicleSource
            )
          }
          sortAscending={sortAscending}
          columns={vehicleColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          onSearchChange={(value: string) => {
            setSearchString(value);
            applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending, selectedVehicleSource);
          }}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, selectedVehicleSource);
          }}
          onApplyClick={() =>
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, selectedVehicleSource)
          }
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, searchString, sortingColumn, sortAscending, selectedVehicleSource);
          }}
          styled={{ marginTop: 32 }}
        />
        <Modal
          open={showAssignVehicleModal}
          onClose={() => setShowAssignVehicleModal(false)}
          showClose
          title="Evaluate and assign vehicle"
          styled={{ width: '90vw', minWidth: 400, maxWidth: 800 }}
        >
          <div>
            {selectedVehicleId && (
              <AssignVehicleForm
                vehicleId={selectedVehicleId}
                application={progress}
                onClose={(refetchData: boolean) => {
                  if (refetchData) {
                    onAssignVehicle();
                    setIsCollapsed(true);
                  }
                  setShowAssignVehicleModal(false);
                }}
              />
            )}
          </div>
        </Modal>
      </div>
    </CollapsiblePanel>
  );
};
