export const APPLICATION_REJECTED = 'REJECTED';
export const APPLICATION_BRANCH_SELECTED = 'BRANCH-SELECTED';
export const APPLICATION_PLAN_SELECTED = 'PLAN-SELECTED';
export const APPLICATION_VEHICLE_SELECTED = 'VEHICLE-SELECTED';
export const APPLICATION_LIABILITY_COMPLETED = 'LIABILITY-COMPLETED';
export const APPLICATION_THEORY_COMPLETED = 'THEORY-COMPLETED';
export const APPLICATION_PRACTICAL_PASSED = 'PRACTICAL-PASSED';
export const APPLICATION_PRACTICAL_FAILED = 'PRACTICAL-FAILED';
export const APPLICATION_DOCUMENTS_CHECKED = 'DOCUMENTS-CHECKED';
export const APPLICATION_DECLARATION_COMPLETED = 'DECLARATION-COMPLETED';
export const APPLICATION_DOWNPAYMENT_SUCCEEDED = 'DOWNPAYMENT-SUCCEEDED';
export const APPLICATION_DOWNPAYMENT_FAILED = 'DOWNPAYMENT-FAILED';
export const APPLICATION_DOWNPAYMENT_PENDING = 'DOWNPAYMENT-PENDING';
export const APPLICATION_VEHICLE_ASSIGNED = 'VEHICLE-ASSIGNED';
export const APPLICATION_COLLECTION_ARRANGED = 'COLLECTION-ARRANGED';
export const APPLICATION_COMPLETED = 'COMPLETED';
export const APPLICATION_SCRIVE_SENT = 'SCRIVE-SENT';
export const APPLICATION_CANCELLED = 'CANCELLED';
export const APPLICATION_OPEN = 'OPEN';
export const EV_RENTAL_MAP: Record<string, number> = {
  '0.00': 0,
  '300.00': 13,
  '100.00': 4,
  '900.00': 12,
};
