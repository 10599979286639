import { NothingToShow } from './noResults.styles';
import { Text } from '../../text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';

export const NoResults = () => (
  <NothingToShow itemsX="center" vertical>
    <img src="/images/noData.svg" width={147} />
    <Text variant="body8" weight={300} color={PRIMARY_PURPLE}>
      There is currently no data to display
    </Text>
  </NothingToShow>
);
