import styled, { css } from 'styled-components';
import { GRAY_1 } from '../../common/styles/Colors';

export const StickyContainer = styled.div<{ $showShadow: boolean }>`
  top: 104px;
  z-index: 125;
  padding-bottom: 16px;
  padding-left: 32px;
  background-color: ${GRAY_1};
  ${(props) => {
    if (props.$showShadow) {
      return css`
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      `;
    }
  }}
`;
