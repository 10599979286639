import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';

interface DriverButtonGroupProps {
  loading: boolean;
  setIsMileageModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsFreshdeskOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export interface ButtonDataItem {
  label: string;
  onClick: () => void;
  isDisabled?: boolean;
  isRed?: boolean;
}

export const DriverButtonGroup = ({ loading, setIsMileageModalOpen, setIsFreshdeskOpen }: DriverButtonGroupProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  const buttonData: ButtonDataItem[] = [
    {
      label: 'View mileage',
      onClick: React.useCallback(() => {
        setIsMileageModalOpen(true);
      }, [setIsMileageModalOpen]),
    },
    {
      label: 'View freshdesk tickets',
      onClick: React.useCallback(() => {
        setIsFreshdeskOpen(true);
      }, [setIsFreshdeskOpen]),
    },
    {
      label: 'Send SMS (3cx)',
      onClick: React.useCallback(() => {
        window.open('https://ottocar.brightstar.cloud:5001/webclient/#/chat/create/external', '_blank');
      }, []),
    },
  ];

  return (
    <FlexLayout styled={{ marginBottom: 16 }} itemsX="end" gap={10}>
      {buttonData.map((button, index) => (
        <SecondaryButton key={index} disabled={loading} onClick={button.onClick}>
          {button.label}
        </SecondaryButton>
      ))}
      <PrimaryButton disabled={loading} onClick={() => navigate(state ?? -1)}>
        Back
      </PrimaryButton>
    </FlexLayout>
  );
};
