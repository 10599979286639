import React from 'react';
import { StyledRadioInput } from './radioButton.styles';
import { withStyledProps } from '../../../utils/colorUtils';

interface RadioButtonProps {
  id: string;
  name: string;
  disabled?: boolean;
  value?: string;
  defaultChecked?: boolean;
  onCheck?: (checked: boolean) => void;
}

export const RadioButton = withStyledProps(
  React.forwardRef<HTMLInputElement, RadioButtonProps>(
    ({ id, name, disabled, value, defaultChecked, onCheck, ...props }: RadioButtonProps, ref) => {
      return (
        <StyledRadioInput
          ref={ref}
          id={id}
          type="radio"
          name={name}
          disabled={disabled}
          value={value}
          defaultChecked={defaultChecked}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheck?.(e?.target?.checked)}
          {...props}
        />
      );
    }
  )
);
