import React from 'react';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Tag } from '../../../../uiComponents/customComponents/tag/tag';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { statusColors } from '../../../../common/utils';

interface DriverTagsProps {
  tags: string[] | undefined;
}

export const DriverTags = ({ tags }: DriverTagsProps) => {
  if (!tags || tags.length === 0) {
    return null; // Return null if tags are not present or empty
  }

  return (
    <FlexLayout styled={{ marginTop: '8px' }} wrap itemsY="center">
      {tags.map((tag: string, index: number) => (
        <Tag key={`${tag}_${index}`} color={statusColors[tag] ?? PRIMARY_PURPLE}>
          {tag}
        </Tag>
      ))}
    </FlexLayout>
  );
};
