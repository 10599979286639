import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { StickyContainer } from './pageRoutesSelector.styles';
import { NavigationPill } from '../../uiComponents/layouts/navigationPill/navigationPill';
import { OptionList } from '../../utils/props';
import { withStyledProps } from '../../utils/colorUtils';

interface PageRoutesSelectorProps {
  routes: OptionList[];
  isSecondary?: boolean;
}

export const PageRoutesSelector = withStyledProps(({ routes, isSecondary, ...props }: PageRoutesSelectorProps) => {
  const location = useLocation();
  const pathName = location?.pathname;
  const navigate = useNavigate();
  const [selectedRoute, setSelectedRoute] = useState<OptionList | undefined>(
    routes?.find((route: OptionList) => location.pathname?.includes(route?.value))
  );

  useEffect(() => {
    const newPath = routes?.find((route: OptionList) => pathName?.includes(route?.value));

    if (newPath) {
      setSelectedRoute(newPath);
    }
  }, [pathName, routes]);

  return (
    <StickyContainer $showShadow={false} {...props}>
      <NavigationPill
        options={routes}
        isSecondaryNav={isSecondary}
        selected={selectedRoute}
        onSelect={(option?: OptionList) => {
          setSelectedRoute(option);
          if (option) {
            navigate(option?.value);
          }
        }}
      />
    </StickyContainer>
  );
});
