import { PulseUser } from '../../models/employee';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const pulseUserSlice = createSlice({
  name: 'pulseUserSlice',
  initialState: {} as unknown as PulseUser,
  reducers: {
    updatePulseUserState(state, action: PayloadAction<PulseUser>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updatePulseUserState } = pulseUserSlice.actions;
export default pulseUserSlice.reducer;
