import { Aftercare, AftercareSetup, CallbackRequest } from './../../models/aftercare';
import api from '../../utils/api';
/**
 * confirm afercare agreement
 * @category API/POST
 * @return {Promise}  Returns a promise for the api request
 * @example
 * confirmAftercareSetup().then((res) => {
 * // Promise resolved
 * });
 */
export const confirmAftercareSetup = (params: { aftercare_id: string }): Promise<{ data: Aftercare[] }> =>
  api.post('/aftercare/confirm', params);

/**
 * Create aftercare setup Request
 * @category API/PATCH
 * @param {object} payload data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  aftercare_id: '',
 * }
 * createAftercareSetup(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createAftercareSetup = (payload: AftercareSetup) => api.post('aftercare/create', payload);

/**
 * Create callback Request
 * @category API/PATCH
 * @param {object} payload data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  aftercare_id: '',
 * }
 * registerCallbackRequest(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const registerCallbackRequest = (payload: CallbackRequest) => api.post('aftercare/contact-list', payload);

/**
 * Complete aftercare Request
 * @category API/PATCH
 * @param {object} payload data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  aftercare_id: '',
 * }
 * completeAftercare(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const completeAftercare = (payload: { aftercare_id: string }) => api.post('aftercare/confirm', payload);
