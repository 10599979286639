import { styled } from 'styled-components';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_30 } from '../../../../../common/styles/Colors';

export const FuelLevel = styled.div`
  width: 100%;
`;

export const Slider = styled.input<{ $value: number; $min?: number; $max?: number }>`
  display: block;
  appearance: none;
  width: 100%;
  margin: 0;
  height: 13px;
  cursor: pointer;
  -webkit-appearance: none;

  &:focus {
    outline: none;
  }

  &::-webkit-slider-runnable-track {
    width: 100%;
    height: 13px;
    background: ${(props) => {
      const denominator = ((props?.$max ?? 100) - (props?.$min ?? 0)) / 100;
      return `linear-gradient(to right, ${PRIMARY_PURPLE} ${(props?.$value - (props?.$min ?? 0)) / denominator}%, ${SECONDARY_PURPLE_30} ${(props?.$value - (props?.$min ?? 0)) / denominator}%)`;
    }};
    border-radius: 100px;
  }

  &::-webkit-progress-value {
    background-color: ${PRIMARY_PURPLE};
  }

  &::-webkit-slider-thumb {
    position: relative;
    appearance: none;
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    background-color: ${PRIMARY_GREEN};
    border-radius: 50%;
    border: 0;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 150ms;
  }

  &::-moz-range-track {
    width: 100%;
    height: 13px;
    border-radius: 100px;
    background-color: ${SECONDARY_PURPLE_30};
  }

  &::-moz-range-progress {
    width: 100%;
    height: 13px;
    border-radius: 100px;
    background-color: ${PRIMARY_PURPLE};
  }

  &::-moz-range-thumb {
    appearance: none;
    margin: 0;
    height: 24px;
    width: 24px;
    background: ${PRIMARY_GREEN};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
  }

  &::-ms-track {
    width: 100%;
    height: 13px;
    border: 0;
    color: transparent;
    background: transparent;
  }

  &::-ms-fill-lower {
    background-color: ${SECONDARY_PURPLE_30};
  }

  &::-ms-fill-upper {
    background-color: ${PRIMARY_PURPLE};
  }

  &::-ms-thumb {
    appearance: none;
    height: 24px;
    width: 24px;
    background-color: ${PRIMARY_GREEN};
    border-radius: 100%;
    border: 0;
    transition: background-color 150ms;
    top: 0;
    margin: 0;
    box-shadow: none;
  }

  &:hover,
  &:focus {
    &::-webkit-slider-thumb {
      background-color: ${PRIMARY_GREEN};
    }
    &::-moz-range-thumb {
      background-color: ${PRIMARY_GREEN};
    }
    &::-ms-thumb {
      background-color: ${PRIMARY_GREEN};
    }
  }
`;

export const ConditionReportSignOffSection = styled.div`
  margin-top: 16px;
  padding: 32px;
  border-radius: 16px;
  background-color: ${PRIMARY_WHITE};
`;

export const ProgressBackground = styled.div`
  position: relative;
  margin-top: 16px;
  height: 32px;
  border-radius: 16px;
  background-color: ${SECONDARY_PURPLE_30};
`;

export const UploadProgressBar = styled.div<{ $progress: number }>`
  position: absolute;
  height: 32px;
  width: ${(props) => `${props?.$progress}%`};
  border-radius: 16px;
  background-color: ${PRIMARY_PURPLE};
  transition: width 0.2s ease-in;
`;
