import React, { useState, useCallback } from 'react';
import { FieldError, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import moment from 'moment';
import { SERVICING_COMPLETE } from '../../../consts/servicing';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { Notification } from '../../../uiComponents/toast/toast';
import { updateServicing } from '../../../api/patch/servicing.patch';
import { Servicing } from '../../../models/servicing';
import { handleAPIError, ErrorType } from '../../../utils/handleAPIError';
import { validateFutureDateFormat } from '../../../utils/validations';

interface FinishSubServicingProps {
  servicing?: Servicing;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const FinishSubServicing = ({ servicing, onFormSubmit, onClose }: FinishSubServicingProps) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { id = '' } = servicing || {};

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { ...servicing, end_date: moment().format('YYYY-MM-DD') },
  });

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values: FieldValues) => {
      const { service_cost: serviceCost, end_date: endDate } = values;
      setIsLoading(true);
      try {
        const payload = {
          service_cost: parseInt(serviceCost, 10),
          end_date: endDate,
          servicing_status: SERVICING_COMPLETE,
        };
        updateServicing(id, payload).then(() => {
          setIsLoading(false);
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Subservicing has been successfully finished',
          });
          close();
        });
      } catch (err) {
        handleAPIError(err as ErrorType);
        setIsLoading(false);
      }
      onFormSubmit();
    },
    [id, onFormSubmit]
  );

  return (
    <>
      <TextField
        {...register('end_date', {
          required: 'End date cannot be empty',
          validate: (value: string) => validateFutureDateFormat(value, true),
        })}
        error={errors['end_date'] as FieldError}
        label="End date"
        name="end_date"
        loading={isLoading}
        required
        type="date"
      />
      <TextField
        styled={{ marginTop: 20 }}
        {...register('service_cost', {
          required: 'Service cost cannot be empty',
        })}
        error={errors['service_cost'] as FieldError}
        label="Service cost"
        loading={isLoading}
        name="service_cost"
        placeholder="£200"
        required
        type="number"
      />
      <FlexLayout styled={{ marginTop: 20 }} itemsX="end" itemsY="end">
        <SecondaryButton styled={{ marginRight: '10px' }} isProcessing={isLoading} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={isLoading} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
