import styled from 'styled-components';
import { Card } from '../../../drivers/driver/driverInfoCard/driverInfoCard.styles';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { PRIMARY_BLACK, PRIMARY_PURPLE, PRIMARY_WHITE, STATUS_RED } from '../../../../common/styles/Colors';

export const ActionCard = styled(Card)`
  background-color: ${PRIMARY_WHITE};
`;

export const LinkText = styled.div`
  font-size: 14px;
  line-height: 18px;
  margin-top: 16px;
  color: ${PRIMARY_BLACK};
`;

export const StyledButton = styled(PrimaryButton)<{ $isRed?: boolean }>`
  font-size: 16px;
  margin-bottom: 16px;
  width: 100%;
  background-color: ${(props) => (props.$isRed ? STATUS_RED : PRIMARY_PURPLE)};
`;
