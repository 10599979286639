import { css, styled } from 'styled-components';

export const CheckContainer = styled.div<{ $disabled: boolean }>`
  flex: 1;

  ${(props) => {
    if (props.$disabled) {
      return css`
        pointer-events: none;
        opacity: 0.3;
      `;
    }
  }}
`;
