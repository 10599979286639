import React, { useState } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import {
  StyledSecondaryButton,
  StyledTextField,
  StyledUploaderInput,
  StyledFieldContainer,
} from './createEditPolicyForm.styles';
import {
  CreateEditPolicyPayload,
  InsurancePolicy,
  ModifiedInsurancePolicy,
  PolicyVehicles,
} from '../../../models/insurancePolicy';
import { createInsurancePolicy } from '../../../api/post/insurance.post';
import { updateInsurancePolicy } from '../../../api/patch/insurance.patch';
import { Notification } from '../../../uiComponents/toast/toast';
import { DateTimePickerComponent } from '../../../uiComponents/customComponents/dateTimePicker/dateTimePicker';
import { InputChips } from '../../../uiComponents/inputChips/inputChips';
import { fileNameTimeStampFormat, renderNotification } from '../../../utils/utils';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { isPolicyNumberExistsing } from '../../../api/get/insurance.get';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { OptionList } from '../../../utils/props';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { getFileService } from '../../../api/cognito/file.service';
import { set } from 'date-fns';
import { useAppSelector } from '../../../store-hooks';

interface CreateEditPolicyFormProps {
  allInsurancePolicies: ModifiedInsurancePolicy[];
  isInEdit: boolean;
  values?: ModifiedInsurancePolicy | null;
  insuranceCompanyOptions: OptionList[];
  onFormSubmit: (data: PolicyVehicles) => void;
  onClose: () => void;
}

export const CreateEditPolicyForm = ({
  allInsurancePolicies,
  isInEdit,
  values,
  insuranceCompanyOptions,
  onClose,
  onFormSubmit,
}: CreateEditPolicyFormProps) => {
  const getColorsList = () => {
    if (!values?.vrm_number_plates || !values!.vrm_number_plates[0]) return [];
    return values?.vrm_number_plates.map((item) => {
      return { value: item };
    });
  };

  const getDefaultHour = (date: string | undefined) => {
    if (date) {
      const d = new Date(date);
      return d.getHours();
    } else {
      return 0;
    }
  };

  const getDefaultMinutes = (date: string | undefined) => {
    if (date) {
      const d = new Date(date);
      return d.getMinutes();
    } else {
      return 0;
    }
  };

  const [isConfirmEditModalOpen, setIsConfirmEditModalOpen] = useState<boolean>(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const [isRenewPolicyModalOpen, setIsRenewPolicyModalOpen] = useState<boolean>(false);
  const [startDateHours, setStartDateHours] = useState<number>(getDefaultHour(values?.start_date));
  const [startDateMinutes, setStartDateMinutes] = useState<number>(getDefaultMinutes(values?.start_date));
  const [endDateHours, setEndDateHours] = useState<number>(getDefaultHour(values?.end_date));
  const [endDateMinutes, setEndDateMinutes] = useState<number>(getDefaultMinutes(values?.end_date));
  const pulseUser = useAppSelector((state) => state.pulseUser);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const fileService = getFileService();

  const {
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm<InsurancePolicy>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues:
      isInEdit && values
        ? { ...values, vrm_number_plates: getColorsList() }
        : {
            insurance_company: '',
            policy_number: '',
            start_date: '',
            end_date: '',
            policy_certificate_url: undefined,
            policy_date_format: '',
          },
  });

  const onSubmit = async (values: InsurancePolicy) => {
    if (!isInEdit && !isEdit) {
      const policyExists = await isPolicyNumberExistsing(values.policy_number.toUpperCase());

      if (policyExists.data.exists) {
        setIsRenewPolicyModalOpen(true);
        return;
      }
    }

    const { insurance_company, policy_number, start_date, end_date, vrm_number_plates, policy_certificate } = values;
    const id = allInsurancePolicies.find((policy) => policy.policy_number === policy_number)?.id || '';
    const insuranceCompanyId =
      insuranceCompanyOptions.find(
        (company) => company.value === insurance_company || company.label === insurance_company
      )?.value || '';
    const startDate = set(new Date(start_date), { hours: startDateHours, minutes: startDateMinutes });
    const endDate = set(new Date(end_date), { hours: endDateHours, minutes: endDateMinutes });

    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      setError('end_date', { type: 'custom', message: 'End date must be greater than start date' });
      return;
    }

    if (vrm_number_plates && vrm_number_plates.length === 0) {
      setError('vrm_number_plates', { type: 'custom', message: 'Number plate range is a required field' });
      return;
    }

    let invalidVrmFormat = false;
    vrm_number_plates.forEach((number_plate) => {
      if (!/[0-9]{2}/.test(number_plate.value) && !/[a-zA-Z]{2}[0-9]{2}[a-zA-Z]{3}/.test(number_plate.value)) {
        invalidVrmFormat = true;
        setError('vrm_number_plates', { type: 'custom', message: 'Invalid number plate format found' });
      }
    });
    if (invalidVrmFormat) return;

    const fileName = 'CERTIFICATE';
    const fileNameWithTimeStamp = fileNameTimeStampFormat(fileName);

    const path = `DOCUMENTS/INSURANCE/${policy_number}/${fileName}/${fileNameWithTimeStamp}`;

    setIsFormSubmitting(true);

    let s3_url;
    if (policy_certificate) {
      try {
        const result = await fileService.uploadFileToStorage({
          path,
          data: policy_certificate,
        });
        s3_url = result.path;
      } catch (error: any) {
        renderNotification('error', 'Error', `${error.message}.`, true);
        setIsFormSubmitting(false);
        return;
      }
    }

    const payload: CreateEditPolicyPayload = {
      id,
      body: {
        company_id: insuranceCompanyId,
        policy_number: policy_number.toUpperCase(),
        start_date: startDate?.toISOString(),
        end_date: endDate?.toISOString(),
        vrm_number_plates: vrm_number_plates.map((plateRule: { value: string }) => plateRule.value),
        policy_date_format: 'DD/MM/YYYY [at] HH:mm',
        policyCertificateUrl: s3_url,
        created_by: pulseUser ? `${pulseUser.first_name} ${pulseUser.last_name}` : '',
      },
    };

    const createEditPromise = !id ? createInsurancePolicy : updateInsurancePolicy;

    createEditPromise(payload)
      .then((result) => {
        Notification({
          type: 'success',
          title: 'Success',
          message: `Policy has been successfully ${isInEdit ? 'updated' : 'created'}`,
          isAlert: true,
        });
        onFormSubmit({
          policy_id: result.data.policy_id.toUpperCase(),
          policy_number: policy_number.toUpperCase(),
          vehiclesListByVrms: result.data.vehiclesListByVrms,
        } as PolicyVehicles);
      })
      .catch(() =>
        Notification({
          type: 'error',
          title: 'Error',
          message: `Error ${isInEdit ? 'updating' : 'creating'} policy`,
          isAlert: true,
        })
      )
      .finally(() => {
        setIsFormSubmitting(false);
        setIsRenewPolicyModalOpen(false);
      });
  };

  const hoursOptions = () => {
    const result: Array<OptionList> = [];
    for (let i = 0; i < 24; i++) {
      result.push({
        value: i.toString(),
        label: i > 9 ? i.toString() : `0${i}`,
      } as OptionList);
    }
    return result;
  };

  const minutesOptions = () => {
    const result: Array<OptionList> = [];
    for (let i = 0; i < 60; i++) {
      result.push({
        value: i.toString(),
        label: i > 9 ? i.toString() : `0${i}`,
      } as OptionList);
    }
    return result;
  };

  const getDefaultInsuranceCompany = () => {
    const result = insuranceCompanyOptions.find((company) => company.label === values?.insurance_company);
    return result?.value;
  };

  return (
    <>
      <GridLayout template={2} gap="32px 24px">
        <StyledFieldContainer vertical>
          <TextFieldLabel $isRequired>Insurance company</TextFieldLabel>
          <DropDown
            name="insurance_company"
            required={{
              required: 'Insurance company is a required field',
            }}
            value={getDefaultInsuranceCompany()}
            error={errors.insurance_company}
            placeholder="Enter company name"
            options={insuranceCompanyOptions}
            control={control as unknown as Control<FieldValues>}
            useLabel
          />
        </StyledFieldContainer>
        <StyledTextField
          {...register('policy_number', {
            required: 'Policy number is a required field',
            validate: (val) => /^[a-zA-Z0-9-]*$/.test(val) || 'Invalid value',
          })}
          label="Policy number"
          error={errors.policy_number}
          placeholder="Enter policy number"
          type="text"
          required
        />

        <DateTimePickerComponent
          control={control}
          name="start_date"
          label="Start date"
          errors={errors.start_date}
          format="dd/MM/y"
          onDateChange={() => clearErrors('end_date')}
        />
        <div>
          <TextFieldLabel>Time</TextFieldLabel>
          <FlexLayout itemsX="space-between" gap="10px" styled={{ marginTop: '-8px' }}>
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name="startDateHours"
              defaultValue={startDateHours.toString()}
              options={hoursOptions()}
              placeholder="--"
              onSelect={(item) => setStartDateHours(parseInt((item as OptionList).value))}
              control={control as unknown as Control<FieldValues>}
            />
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name="startDateMinutes"
              defaultValue={startDateMinutes.toString()}
              options={minutesOptions()}
              placeholder="--"
              onSelect={(item) => setStartDateMinutes(parseInt((item as OptionList).value))}
              control={control as unknown as Control<FieldValues>}
            />
          </FlexLayout>
        </div>

        <DateTimePickerComponent
          control={control}
          name="end_date"
          label="End date"
          errors={errors.end_date}
          format="dd/MM/y"
          onDateChange={() => clearErrors('end_date')}
        />
        <div>
          <TextFieldLabel>Time</TextFieldLabel>
          <FlexLayout itemsX="space-between" gap="10px" styled={{ marginTop: '-8px' }}>
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name="endDateHours"
              defaultValue={endDateHours.toString()}
              options={hoursOptions()}
              placeholder="--"
              onSelect={(item) => setEndDateHours(parseInt((item as OptionList).value))}
              control={control as unknown as Control<FieldValues>}
            />
            <DropDown
              styled={{ width: '230px', marginTop: '8px' }}
              name="endDateMinutes"
              defaultValue={endDateMinutes.toString()}
              options={minutesOptions()}
              placeholder="--"
              onSelect={(item) => setEndDateMinutes(parseInt((item as OptionList).value))}
              control={control as unknown as Control<FieldValues>}
            />
          </FlexLayout>
        </div>
        <StyledUploaderInput
          control={control as unknown as Control<FieldValues>}
          name="policy_certificate"
          error={errors?.policy_certificate}
          label="Upload certificate"
        />
        <InputChips
          name="vrm_number_plates"
          label=""
          header="Number plate range"
          register={register}
          control={control}
          required={{
            required: 'Number plate range are a required field',
          }}
          addCallback={() => clearErrors('vrm_number_plates')}
          removeCallback={() => clearErrors('vrm_number_plates')}
          error={errors.vrm_number_plates as FieldError}
        />
      </GridLayout>

      <FlexContainer style={{ marginTop: 20 }} itemsX="end">
        <StyledSecondaryButton onClick={() => onClose()}>Cancel</StyledSecondaryButton>
        <PrimaryButton
          isProcessing={isFormSubmitting}
          onClick={isInEdit ? () => setIsConfirmEditModalOpen(true) : handleSubmit(onSubmit)}
        >
          Submit
        </PrimaryButton>
      </FlexContainer>

      <ConfirmationModal
        title={'Are you sure you want to edit this policy?'}
        isOpen={isConfirmEditModalOpen}
        onClose={() => setIsConfirmEditModalOpen(false)}
        onConfirm={() => {
          handleSubmit(onSubmit)();
          setIsConfirmEditModalOpen(false);
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'This policy already exists. Do you want to edit this policy'}
        isOpen={isRenewPolicyModalOpen}
        onClose={() => {
          setIsRenewPolicyModalOpen(false);
          onClose();
        }}
        onConfirm={() => {
          setIsEdit(true);
          handleSubmit(onSubmit)();
        }}
        confirmButtonCaption={'Edit'}
        closeButtonCaption={'Cancel'}
      />
    </>
  );
};
