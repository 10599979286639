import React, { useState } from 'react';
import {
  DriverSearch,
  ReassignFormRoot,
  ReassignLabel,
  ReassignText,
  ReassignTitle,
  SearchButton,
} from './reassignForm.styles';
import { ReassignExcessMileagePayload } from '../../../../../api/patch/additionalCharges.patch';
import { Excess } from '../../../../../models/excessMileage';
import { TextInput } from '../../../../../uiComponents/inputs/textInput/textInput';
import { Spinner } from '../../../../../uiComponents/uiControls/spinner/spinner';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { PrimaryButton } from '../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FiSearch } from 'react-icons/fi';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { useForm } from 'react-hook-form';
import { getDriverByName } from '../../../../../api/get/driver.get';

interface ReassignFormInfo {
  excess?: Excess;
  onClose: () => void;
  onSubmit: (submitValues: ReassignExcessMileagePayload) => void;
}

interface ReassignDriverForm {
  driver: string;
}

export const ReassignForm = ({ excess, onClose, onSubmit }: ReassignFormInfo) => {
  const [loadingDriverDetails, setLoadingDriverDetails] = useState<boolean>(false);
  const [processing, setProcessing] = useState<boolean>(false);
  const [driverId, setDriverId] = useState<string>();

  const {
    register,
    getValues,
    setError,
    setFocus,
    formState: { errors },
  } = useForm<ReassignDriverForm>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onFormSubmit = () => {
    setProcessing(true);
    const submitValues = {
      weeklyMileageIds: [excess?.weekly_mileage_id],
      toDriverId: driverId,
    } as ReassignExcessMileagePayload;
    onSubmit(submitValues);
  };

  const getDriverDetailsByName = () => {
    setLoadingDriverDetails(true);
    getDriverByName(getValues('driver'))
      .then((res) => {
        setLoadingDriverDetails(false);
        if (res.data.length === 0) {
          setError('driver', { type: 'custom', message: 'Driver not found' });
        } else if (res.data.length > 1) {
          setError('driver', { type: 'custom', message: 'Multiple drivers found with provided name' });
        } else {
          setDriverId(res.data[0].id);
          setFocus('driver');
        }
      })
      .catch(() => {
        setLoadingDriverDetails(false);
        setError('driver', { type: 'custom', message: 'Unexpected error ocurred' });
      });
  };

  return (
    <ReassignFormRoot>
      <ReassignLabel>Currently assigned to:</ReassignLabel>
      <ReassignText>{excess?.driver_name}</ReassignText>
      <ReassignTitle>Reassign to:</ReassignTitle>
      <DriverSearch>
        <TextInput
          placeholder="Reassign to:"
          error={errors.driver}
          {...register('driver', {
            required: 'Driver name is required',
            onChange: () => setDriverId(undefined),
          })}
          styled={{ width: '100%' }}
        />
        {loadingDriverDetails ? (
          <Spinner styled={{ position: 'absolute', right: 24, top: 13 }} size={16} color={PRIMARY_PURPLE} />
        ) : (
          <SearchButton itemsX="center" itemsY="center" onClick={getDriverDetailsByName}>
            <FiSearch color={PRIMARY_GREEN} size={24} />
          </SearchButton>
        )}
      </DriverSearch>
      <FlexLayout itemsX="end" styled={{ marginTop: 20 }} gap="10px">
        <SecondaryButton onClick={onClose}>Cancel</SecondaryButton>
        <PrimaryButton onClick={onFormSubmit} isProcessing={processing} disabled={!driverId}>
          Reassign
        </PrimaryButton>
      </FlexLayout>
    </ReassignFormRoot>
  );
};
