import api from '../../utils/api';

/**
 * Log In Request
 * @category API/POST
 * @param {object} loginData - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * LogIn(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const deleteVehicleType = (id: string) => {
  return api.delete(`vehicle-type/${id}`);
};

export default {
  deleteVehicleType,
};
