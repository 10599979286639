import { OptionList } from '../utils/props';

export const plans: OptionList[] = [
  {
    value: 'R2B',
    label: 'R2B',
  },
  {
    value: 'rental',
    label: 'PCO car hire',
  },
  {
    value: 'friendly-finance',
    label: 'Friendly finance',
  },
];

export const R2B_USED = 'R2B-USED';
export const R2B_USED_NATIONAL = 'R2B-USED-NATIONAL';
export const R2B_USED_NATIONAL_PLUS_8 = 'R2B-USED-NATIONAL-PLUS-8';
export const R2B_USED_PLUS_8 = 'R2B-USED-PLUS-8';
export const R2B_USED_BURST = 'R2B-USED-BURST';
export const R2B_CLASSIC = 'R2B-CLASSIC';
export const R2B_NEW_NATIONAL = 'R2B-NEW-NATIONAL';
export const R2B_NEW_BURST = 'R2B-NEW-BURST';
export const R2B_NEW_NATIONAL_PLUS_8 = 'R2B-NEW-NATIONAL-PLUS-8';
export const R2B_CLASSIC_PLUS_8 = 'R2B-CLASSIC-PLUS-8';
export const FRIENDLY_FINANCE = 'FRIENDLY-FINANCE';
export const RENTAL = 'RENTAL';
export const IN_SERVICE_RENTAL = 'IN-SERVICE-RENTAL';
export const CAR_HIRE_NATIONAL = 'CAR-HIRE-NATIONAL';
export const PCO_CAR_HIRE_FLEX = 'PCO-CAR-HIRE-FLEX';
export const AFTERCARE_FULL = 'AFTERCARE-FULL';
export const AFTERCARE_INSURANCE = 'AFTERCARE-INSURANCE';
export const COURTESY = 'COURTESY';

const R2BNewPlans = [R2B_NEW_NATIONAL, R2B_NEW_NATIONAL_PLUS_8, R2B_CLASSIC, R2B_CLASSIC_PLUS_8, R2B_NEW_BURST];
const R2bNationalPlans = [R2B_NEW_NATIONAL_PLUS_8, R2B_USED_NATIONAL_PLUS_8, R2B_NEW_NATIONAL, R2B_USED_NATIONAL];
const RENTALPlans = [RENTAL, IN_SERVICE_RENTAL, CAR_HIRE_NATIONAL, PCO_CAR_HIRE_FLEX];

export const isR2B = (plan: string) => plan.indexOf('R2B-') > -1;

export const isR2BNew = (plan: string) => R2BNewPlans.includes(plan);

export const isR2BNational = (plan: string) => R2bNationalPlans.includes(plan);

export const isPlus8 = (plan: string) => plan.indexOf('PLUS-8') > -1;

export const isBurst = (plan: string) => plan.indexOf('BURST') > -1;

export const isR2BUsed = (plan: string) => plan.indexOf('R2B-USED') > -1;

export const isRental = (plan: string) => RENTALPlans.includes(plan);

export const isAftercare = (plan: string) => plan.indexOf('AFTERCARE-') > -1;

export interface PlanOption {
  logo: string;
  id: string;
  title: string;
  regions: string[];
  description: string;
}

export const londonPlans: PlanOption[] = [
  {
    logo: '/images/rentalLogo.svg',
    id: RENTAL,
    title: 'PCO car hire',
    regions: ['London'],
    description: 'We offer a minimum rental of 8 weeks. We want to help you earn a living the right way.',
  },
  {
    logo: '/images/r2bNewLogo.svg',
    id: R2B_CLASSIC_PLUS_8,
    title: 'R2B classic plus 8',
    regions: ['London'],
    description:
      'Be in complete control with car ownership. Join our family of PCO drivers who have chosen the Rent 2 Buy scheme.',
  },
  {
    logo: '/images/r2bUsedLogo.svg',
    id: R2B_USED_PLUS_8,
    title: 'R2B used plus 8',
    regions: ['London'],
    description: 'Our Used Rent 2 Buy Vehicles are subject to availability.',
  },
  {
    logo: '/images/burst.svg',
    id: R2B_NEW_BURST,
    title: 'R2B new Burst',
    regions: ['London'],
    description: 'An R2B new +8 plan where you choose how to spread your clean air discount.',
  },
  {
    logo: '/images/burst.svg',
    id: R2B_USED_BURST,
    title: 'R2B used Burst',
    regions: ['London'],
    description: 'An R2B used +8 plan where you choose how to spread your clean air discount.',
  },
];

export const nationalPlans: PlanOption[] = [
  {
    logo: '/images/rentalLogo.svg',
    id: CAR_HIRE_NATIONAL,
    title: 'Car hire national',
    regions: ['national'],
    description: 'We offer a minimum rental of 8 weeks. We want to help you earn a living the right way.',
  },
  {
    logo: '/images/r2bNewLogo.svg',
    id: R2B_NEW_NATIONAL_PLUS_8,
    title: 'R2B new national plus 8',
    regions: ['London', 'national'],
    description:
      'Be in complete control with car ownership. Join our family of PCO drivers who have chosen the Rent 2 Buy scheme.',
  },
  {
    logo: '/images/r2bUsedLogo.svg',
    id: R2B_USED_NATIONAL_PLUS_8,
    title: 'R2B used national plus 8',
    regions: ['London', 'national'],
    description: 'Our Used Rent 2 Buy Vehicles are subject to availability.',
  },
];

export const planOptions: OptionList[] = [
  {
    value: 'london',
    label: 'London',
  },
  {
    value: 'national',
    label: 'National',
  },
];
