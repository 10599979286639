import React from 'react';
import { StyledSecondaryButton } from './secondaryButton.styles';
import { Spinner } from '../../uiControls/spinner/spinner';
import { PRIMARY_GREEN } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';

interface SecondaryButtonProps {
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isProcessing?: boolean;
  children?: string | JSX.Element;
}

export const SecondaryButton = withStyledProps(
  ({ disabled, onClick, isProcessing, children, ...props }: SecondaryButtonProps) => {
    return (
      <StyledSecondaryButton
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          e.stopPropagation();
          onClick?.(e);
        }}
        disabled={disabled}
        $isProcessing={isProcessing}
        {...props}
      >
        <>{isProcessing ? <Spinner color={PRIMARY_GREEN} size={24} /> : children}</>
      </StyledSecondaryButton>
    );
  }
);
