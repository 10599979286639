import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { apiSlice } from './api/baseApi';
import driverDetailsSlice from './features/driver/driverDetailsSlice';
import customerProfileSlice from './features/driver/customerProfileSlice';
import vehicleConditionReportListSlice from './features/vehicle/vehicleConditionReportListSlice';
import conditionReportSlice from './features/condition-report/conditionReportSlice';
import pulseUserSlice from './features/pulseUser/pulseUserSlice';
export const store = configureStore({
  reducer: {
    conditionReport: conditionReportSlice,
    vehicleConditionreportList: vehicleConditionReportListSlice,
    driverDetails: driverDetailsSlice,
    customerProfile: customerProfileSlice,
    pulseUser: pulseUserSlice,
    [apiSlice.reducerPath]: apiSlice.reducer,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(apiSlice.middleware),
});

setupListeners(store.dispatch);
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
