import React, { useState } from 'react';

import { BatchVehicle } from '../../../models/vehicle';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { Notification } from '../../../uiComponents/toast/toast';
import { updateVehicle } from '../../../api/patch/vehicle.patch';
import { VINRegex, VRMRegex } from '../../../utils/validations';

interface EditVehicleProps {
  values?: BatchVehicle;
  close: (action: 'cancel' | 'submit') => void;
}

export const EditVehicleBatchForm = ({ values, close }: EditVehicleProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<BatchVehicle>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...values,
      available_on: moment(values?.available_on).format('YYYY-MM-DD'),
    },
  });

  const onSubmit = (submitValues: BatchVehicle) => {
    setLoading(true);
    const { vin, vrm } = submitValues;
    const payload: BatchVehicle = {
      vin,
      vrm,
    };
    if (values?.id) {
      updateVehicle(values?.id, payload)
        .then(() => {
          setLoading(false);
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Vehicle has been successfully updated.',
          });
          close('submit');
        })
        .catch(() => {
          setLoading(false);
          Notification({
            type: 'error',
            title: 'Error',
            message: 'Error updating vehicle.',
          });
        });
    }
  };

  return (
    <>
      <GridLayout template={2} gap={18}>
        <TextField
          {...register('vin', {
            required: 'VIN cannot be an empty field.',
            pattern: {
              value: VINRegex,
              message: 'Invalid VIN number',
            },
          })}
          placeholder="VIN number"
          required
          label="VIN"
          type="text"
          error={errors.vin}
        />
        <TextField
          {...register('available_on', {
            required: 'Available Date cannot an empty field.',
          })}
          required
          label="Available Date"
          type="date"
          disabled
          error={errors.available_on}
        />
        <TextField
          {...register('vrm', {
            required: 'VRM cannot be an empty field.',
            pattern: {
              value: VRMRegex,
              message: 'Invalid VRM please letters A-Z and numbers 0-9',
            },
          })}
          placeholder="VRM"
          required
          label="VRM"
          type="text"
          error={errors.vrm}
        />
      </GridLayout>
      <FlexLayout itemsX="end">
        <SecondaryButton disabled={loading} onClick={() => close('cancel')} styled={{ marginRight: '10px' }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={loading} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
