import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { Text } from '../../../../../uiComponents/text/text';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { CustomerProfile } from '../../../../../models/driver';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { handleIntegerRangeError, validateUberRating } from '../../../../../utils/validations';

interface DriverDetailsProps {
  errors: FieldErrors<CustomerProfile>;
  register: UseFormRegister<CustomerProfile>;
}

export const DriverAdditionalDetailsForm = ({ errors, register }: DriverDetailsProps) => {
  return (
    <div style={{ marginTop: 24 }}>
      <Text variant="body4" color={PRIMARY_PURPLE} weight={800}>
        Additional details
      </Text>
      <GridLayout styled={{ marginTop: 32 }} template={2} gap="16px 38px">
        <TextField
          {...register('uber_rating', {
            validate: {
              validUberRating: (value) => validateUberRating(value),
            },
          })}
          label="Uber rating"
          type="number"
          placeholder="Uber rating"
          error={errors?.uber_rating}
        />
        <TextField
          {...register('total_trips', {
            validate: {
              handleIntegerRangeError: (value) => handleIntegerRangeError(value),
            },
          })}
          label="Total Uber trips"
          type="number"
          placeholder="Total Uber trips"
          error={errors?.total_trips}
        />
        <TextField
          {...register('trips_last_30_days', {
            validate: {
              handleIntegerRangeError: (value) => handleIntegerRangeError(value),
            },
          })}
          label="Number of trips in the last 30 days"
          type="number"
          placeholder="Trips in the last 30 days"
          error={errors?.trips_last_30_days}
        />
      </GridLayout>
    </div>
  );
};
