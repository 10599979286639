import React, { useState } from 'react';
import { BsCheckCircle, BsCircle, BsPlusCircleFill } from 'react-icons/bs';
import { CollapsiblePanel } from '../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../../common/styles/Colors';
import { PrimaryButton } from '../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { Modal } from '../../../../../uiComponents/modals/modal';
import { DamageReportForm } from './damageReportForm/damageReportForm';
import { DamageReportItem } from '../../../../../consts/conditionReport';

interface DamageLogCheckInfo {
  isComplete: boolean;
  onSubmit: (submitValues: DamageReportItem) => void;
}

export const DamageLogCheck = ({ isComplete, onSubmit }: DamageLogCheckInfo) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [openDamageLogModal, setOpenDamageLogModal] = useState<boolean>(false);
  const [submittingDamage, setSubmittingDamage] = useState<boolean>(false);

  const onFormSubmit = async (submitValues: DamageReportItem) => {
    setSubmittingDamage(true);
    await onSubmit(submitValues);
    setOpenDamageLogModal(false);
    setSubmittingDamage(false);
  };

  return (
    <>
      <CollapsiblePanel
        header={
          <FlexLayout gap={16} itemsY="center">
            {isComplete ? (
              <BsCheckCircle color={PRIMARY_GREEN} size={32} />
            ) : (
              <BsCircle size={32} color={PRIMARY_PURPLE} />
            )}
            <div>
              <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                Damage Log
              </Text>
              {isComplete && (
                <Text variant="body7" color={PRIMARY_GREEN} weight={300} block>
                  Completed
                </Text>
              )}
            </div>
          </FlexLayout>
        }
        expanded={!isCollapsed}
        onCollapse={() => setIsCollapsed(!isCollapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
            Damage Log
          </Text>
          <FlexLayout styled={{ marginTop: 24 }} gap={24}>
            <PrimaryButton onClick={() => setOpenDamageLogModal(true)}>
              <FlexLayout gap={8}>
                <BsPlusCircleFill color={PRIMARY_GREEN} size={16} />
                <Text variant="body7" color={PRIMARY_WHITE} weight={500}>
                  Create damage report
                </Text>
              </FlexLayout>
            </PrimaryButton>
          </FlexLayout>
        </div>
      </CollapsiblePanel>
      <Modal
        title="Damage report"
        open={openDamageLogModal}
        onClose={() => {
          setOpenDamageLogModal(false);
        }}
        showClose={true}
        styled={{ minWidth: 375 }}
      >
        <DamageReportForm
          onClose={() => {
            setOpenDamageLogModal(false);
          }}
          onSubmit={onFormSubmit}
          submittingDamage={submittingDamage}
        />
      </Modal>
    </>
  );
};
