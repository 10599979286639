import { PRIMARY_GREEN, STATUS_ORANGE, STATUS_RED } from '../../../../../common/styles/Colors';

export const getCurrentAndLastWeekRanges = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();

  const daysAgo = [-3, -4, -5, -6, 0, -1, -2][currentDay];
  const daysTo = [3, 2, 1, 0, 6, 5, 4][currentDay];

  const formatMonth = (month: number) => {
    return (month + 1).toString().length === 1 ? `0${month + 1}` : month + 1;
  };

  // This week begin day and end days
  const thisWeekBeginDay = new Date(currentDate.getTime() - Math.abs(daysAgo) * 24 * 60 * 60 * 1000);
  const thisWeekEndDay = new Date(currentDate.getTime() + daysTo * 24 * 60 * 60 * 1000);

  // Last week begin and end days
  const lastWeekBeginDay = new Date(currentDate.getTime() - (Math.abs(daysAgo) + 7) * 24 * 60 * 60 * 1000);
  const lastWeekEndDay = new Date(lastWeekBeginDay.getTime() + 6 * 24 * 60 * 60 * 1000);

  // This week formatted begin and end days
  const beginningOfThisWeek = `${thisWeekBeginDay.getDate()}/${formatMonth(
    thisWeekBeginDay.getMonth()
  )}/${thisWeekBeginDay.getFullYear()}`;

  const endOfThisWeek = `${thisWeekEndDay.getDate()}/${formatMonth(
    thisWeekEndDay.getMonth()
  )}/${thisWeekEndDay.getFullYear()}`;

  // Last week formatted begin and end days
  const beginningOfLastWeek = `${lastWeekBeginDay.getDate()}/${formatMonth(
    lastWeekBeginDay.getMonth()
  )}/${lastWeekBeginDay.getFullYear()}`;

  const endOfLastWeek = `${lastWeekEndDay.getDate()}/${formatMonth(
    lastWeekEndDay.getMonth()
  )}/${lastWeekEndDay.getFullYear()}`;

  return [`${beginningOfThisWeek} - ${endOfThisWeek}`, `${beginningOfLastWeek} - ${endOfLastWeek}`];
};

export const getColor = (score: number) => {
  if (score > 90) {
    return PRIMARY_GREEN;
  }
  if (score >= 75 && score <= 90) {
    return STATUS_ORANGE;
  }
  return `${STATUS_RED}`;
};
