import { NewConditionReport, TransferFields } from '../../models/transfer';
import { Vehicle } from '../../models/vehicle';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface ConditionReport {
  transfer: TransferFields;
  draft: NewConditionReport;
  vehicle: Vehicle;
}

const conditionReportSlice = createSlice({
  name: 'conditionReportSlice',
  initialState: {} as unknown as ConditionReport,
  reducers: {
    updateConditionReportState(state, action: PayloadAction<ConditionReport>) {
      return {
        ...state,
        ...action.payload,
      };
    },
  },
});

export const { updateConditionReportState } = conditionReportSlice.actions;
export default conditionReportSlice.reducer;
