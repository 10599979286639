import { styled } from 'styled-components';
import { TextVariant, TextStyleProps, TextSize } from './text';

const getStyles = (variant: TextVariant, props: TextStyleProps) => {
  return `color: ${props.$color};font-size: ${TextSize[variant ?? 'body7']};font-weight: ${props.$weight};display: ${
    props.$block ? 'block' : 'inline'
  };text-align: ${props?.$align ?? 'left'}`;
};

export const H1 = styled.h1<TextStyleProps>`
  ${(props) => getStyles('h1', props)};
`;

export const H2 = styled.h2<TextStyleProps>`
  ${(props) => getStyles('h2', props)};
`;

export const H3 = styled.h2<TextStyleProps>`
  ${(props) => getStyles('h3', props)};
`;

export const H4 = styled.h2<TextStyleProps>`
  ${(props) => getStyles('h4', props)};
`;

export const StyledText = styled.span<TextStyleProps>`
  ${(props) => getStyles(props.$variant ?? 'body7', props)};
`;
