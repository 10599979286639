import React, { forwardRef } from 'react';
import { StyledMenu, MenuOption } from './menu.styles';
import { withStyledProps } from '../../utils/colorUtils';

/**
 * @DropdownMenu props:
 * options - array of object { value: 'val', label: '1' }
 * onOptionSelect: function that returns selected option (option) => void
 * ...props styled props
 */

interface MenuProps {
  className?: string;
  onOptionSelect: (option: { label: string; value: string }) => void;
  options: { value: string; label: string }[];
}

export const Menu = withStyledProps(
  forwardRef<HTMLDivElement, MenuProps>(({ className, options, onOptionSelect }: MenuProps, forwardRef) => {
    return (
      <StyledMenu ref={forwardRef} className={className}>
        {options?.map((option) => (
          <MenuOption key={option?.value} onClick={() => onOptionSelect(option)}>
            {option?.label}
          </MenuOption>
        ))}
      </StyledMenu>
    );
  })
);
