import {
  CSSObjectWithLabel,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import { OptionList } from '../../../utils/props';
import {
  GRAY_2,
  GRAY_3,
  PRIMARY_WHITE,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_5,
  SECONDARY_PURPLE_70,
  SECONDARY_PURPLE_90,
} from '../../../common/styles/Colors';
import { lighten } from '../../../utils/colorUtils';
import { styled } from 'styled-components';

export const filterDropdownStyles: StylesConfig<string | OptionList, boolean, GroupBase<string | OptionList>> = {
  control: (
    base: CSSObjectWithLabel,
    props: ControlProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    let borderCol;

    if (props.isDisabled) {
      borderCol = GRAY_2;
    } else if (props.isFocused) {
      borderCol = SECONDARY_PURPLE_30;
    } else {
      borderCol = SECONDARY_PURPLE_70;
    }
    return {
      ...base,
      backgroundColor: props.isDisabled ? GRAY_2 : SECONDARY_PURPLE_90,
      borderColor: borderCol,
      color: SECONDARY_PURPLE_5,
      cursor: props.isDisabled ? 'default' : 'pointer',
      height: 24,
      minHeight: 24,
      borderRadius: 50,
      fontSize: '12px',
      fontWeight: 300,
      boxShadow: 'none',
      '&:hover': {
        borderColor: SECONDARY_PURPLE_30,
      },
    };
  },
  valueContainer: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      padding: '0 0 0 8px',
    };
  },
  singleValue: (
    base: CSSObjectWithLabel,
    props: SingleValueProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    return {
      ...base,
      color: props.isDisabled ? GRAY_3 : SECONDARY_PURPLE_5,
      fontWeight: 300,
      fontSize: '12px',
    };
  },
  multiValue: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      display: 'none',
    };
  },
  multiValueLabel: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: PRIMARY_WHITE,
    };
  },
  multiValueRemove: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      backgroundColor: 'transparent',
      '&:hover': {
        backgroundColor: 'transparent',
        color: PRIMARY_WHITE,
      },
    };
  },
  clearIndicator: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: PRIMARY_WHITE,
      padding: '0 8px',
      '&:hover': {
        color: PRIMARY_WHITE,
      },
    };
  },
  indicatorsContainer: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      padding: '0 8px 0 0',
      div: {
        padding: 0,
      },
    };
  },
  dropdownIndicator: (
    base: CSSObjectWithLabel,
    props: DropdownIndicatorProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    let fillCol;

    if (props.isDisabled) {
      fillCol = GRAY_3;
    } else if (props.isFocused) {
      fillCol = SECONDARY_PURPLE_30;
    } else {
      fillCol = SECONDARY_PURPLE_70;
    }
    return {
      ...base,
      fill: fillCol,
      color: fillCol,
      '&:hover': {
        color: SECONDARY_PURPLE_30,
      },
    };
  },
  menu: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      boxShadow: 'none',
      marginTop: 2,
      backgroundColor: SECONDARY_PURPLE_90,
      borderRadius: 16,
      border: `1px solid ${SECONDARY_PURPLE_70}`,
    };
  },
  menuList: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      border: SECONDARY_PURPLE_30,
      borderRadius: 16,
      padding: 0,
    };
  },
  option: (
    base: CSSObjectWithLabel,
    props: OptionProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    let bgColor = '';

    if (props.isSelected) {
      bgColor = lighten(SECONDARY_PURPLE_90, 5);
    } else if (props.isFocused) {
      bgColor = lighten(SECONDARY_PURPLE_90, 5);
    } else {
      bgColor = SECONDARY_PURPLE_90;
    }
    return {
      ...base,
      backgroundColor: bgColor,
      color: props.isSelected ? PRIMARY_WHITE : SECONDARY_PURPLE_5,
      cursor: props.isDisabled ? 'default' : 'pointer',
      fontWeight: props.isSelected ? 500 : 300,
      borderRadius: 8,
      marginLeft: 1,
      marginRight: 1,
      fontSize: '12px',
      transition: 'background-color 0.2s ease-in-out',
      '&:active': {
        backgroundColor: SECONDARY_PURPLE_70,
      },
    };
  },
  placeholder: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: SECONDARY_PURPLE_70,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    };
  },
  input: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: PRIMARY_WHITE,
    };
  },
  noOptionsMessage: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: SECONDARY_PURPLE_5,
      fontSize: '14px',
    };
  },
};

export const SelectedItems = styled.div`
  margin-top: 4px;
`;
