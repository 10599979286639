import { GenericAbortSignal } from 'axios';
import { Servicing, PhvMotResponse } from '../../models/servicing';
import api from '../../utils/api';

/**
 * Get all servicings request
 * @category API/GET
 * @param {object} getAllServicings - Returns all servicings in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllServicings(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllServicings = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Servicing[] }> => api.get(`servicing${query && `?${query}`}`, { signal });

/**
 * Get all servicings list request
 * @category API/GET
 * @param {object} getServicingList - Returns all servicings in the system for vehicles that are currently checked in
 * @return {Promise} Returns a promise for the api request
 * @example
 * getServicingList(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getServicingList = (id: string, query = ''): Promise<{ count: number; data: Servicing[] }> =>
  api.get(`servicing/list/${id}${query && `?${query}`}`);

/**
 * Get single servicing request
 * @category API/GET
 * @param {object} getServicing - Returns the servicing data for an servicing id
 * @return {Promise} Returns a promise for the api request
 * @example
 * getServicing(servicingId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getServicing = (id: string) => api.get(`servicing/${id}`);

/**
 * API call for getting all phv and mot expiry dates that are available in the calendar.
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getPhvAndMotExpiry().then((res) => {
 *  // Promise resolved
 * });
 * @response
 * {
 *  mot_exp: {[2022-09-10], [2022-06-10], [2022-09-23] }
 *  phv_exp: {[2022-09-10], [2022-06-10], [2022-09-23] }
 * }
 */
export const getPhvAndMotExpiry = (): Promise<{ data: PhvMotResponse }> => api.get('/servicing/get-phv-mot-exp');

/**
 * API call for getting servicing details for a single vehicle
 * @category API/GET
 * @param {string}
 * @return {Promise} Returns a promise for the api request
 * @example
 * getSingleServicing(servicingId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getSingleServicing = (servicingId: string) => api.get(`/servicing/${servicingId}`);

export const getSingleServicingByVehicle = (vehicleId: string) => api.get(`/servicing/vehicle/${vehicleId}`);

export const getAllServicingsForVehicle = (vehicleId: string) => api.get(`/servicing/all/vehicle/${vehicleId}`);
