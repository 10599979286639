import React from 'react';
import { ModalOverlay, ModalComponent, Header, CloseButton, ModalContent } from './modal.styles';
import { withStyledProps } from '../../utils/colorUtils';
import { Text } from '../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { FiX } from 'react-icons/fi';

interface ModalProps {
  children: JSX.Element;
  open: boolean;
  onClose: () => void;
  showClose?: boolean;
  title?: string;
}
export const Modal = withStyledProps(({ children, open, onClose, showClose, title, ...props }: ModalProps) => {
  return (
    <>
      {open ? (
        <ModalOverlay itemsX="center" itemsY="center">
          <ModalComponent {...props}>
            <Header itemsX="space-between" itemsY="center">
              <Text variant="h4" color={PRIMARY_PURPLE} weight={800}>
                {title ?? ''}
              </Text>
              {showClose && (
                <CloseButton itemsX="center" itemsY="center" onClick={() => onClose()}>
                  <FiX color={PRIMARY_PURPLE} size={24} />
                </CloseButton>
              )}
            </Header>
            <ModalContent>{children}</ModalContent>
          </ModalComponent>
        </ModalOverlay>
      ) : (
        <></>
      )}
    </>
  );
});
