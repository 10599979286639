import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { Text } from '../../../../../uiComponents/text/text';
import { UploaderInput } from '../../../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { MandatoryPhotosContainer } from './styles';
import { Fragment } from 'react/jsx-runtime';

interface FileFieldError {
  file?: {
    message: string;
    type: string;
    ref: {
      name: string;
    };
  };
}

interface FormErrors {
  mandatoryPhotos?: FileFieldError[];
}

export function MandatoryPhotos() {
  const { control, formState, register } = useFormContext();
  const errors = formState.errors as FormErrors;

  const { fields } = useFieldArray({
    control,
    name: 'mandatoryPhotos',
  });

  return (
    <div>
      <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
        Mandatory Photos
      </Text>
      <MandatoryPhotosContainer>
        {fields.map((item: any, index) => {
          return (
            <Fragment key={item.id}>
              <UploaderInput
                key={item.id}
                label={item?.label}
                name={`mandatoryPhotos.${index}.file`}
                control={control}
                error={errors.mandatoryPhotos?.[index]?.file}
                aditionalInfo={item?.aditionalInfo}
                required={item?.required}
                isImageOnly
              />
              <input type="hidden" {...register(`mandatoryPhotos.${index}.name`)} value={item?.name} />
              <input type="hidden" {...register(`mandatoryPhotos.${index}.label`)} value={item?.label} />
              <input type="hidden" {...register(`mandatoryPhotos.${index}.required`)} value={item?.required} />
              <input type="hidden" {...register(`mandatoryPhotos.${index}.s3Folder`)} value={item?.s3Folder} />
            </Fragment>
          );
        })}
      </MandatoryPhotosContainer>
    </div>
  );
}
