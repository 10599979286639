import React, { useCallback, SyntheticEvent } from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormClearErrors,
  UseFormRegister,
  UseFormSetError,
  UseFormSetValue,
} from 'react-hook-form';
import { PanelTitle } from '../createNewDriver/createNewDriver.styles';
import { ethnicityList } from '../../../../consts/driver';
import { titleOptions } from '../../../../consts/user';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { verifyEmailAddressExists, verifyMobileNumberExists } from '../../../../api/get/driver.get';
import { CustomerProfile } from '../../../../models/driver';
import {
  emailFormat,
  mobileNumberFormat,
  nationalInsurance,
  ukPostCode,
  validAgeDateFormat,
} from '../../../../utils/validations';

interface DriverDetailsProps {
  errors: FieldErrors<CustomerProfile>;
  register: UseFormRegister<CustomerProfile>;
  setError: UseFormSetError<CustomerProfile>;
  clearErrors: UseFormClearErrors<CustomerProfile>;
  getValues: () => CustomerProfile;
  setValue: UseFormSetValue<CustomerProfile>;
  control: Control<CustomerProfile>;
}

export const DriverDetails = ({
  errors,
  register,
  setError,
  clearErrors,
  getValues,
  setValue,
  control,
}: DriverDetailsProps) => {
  const validateEmailAddressIsAvailable = useCallback(
    (e: SyntheticEvent) => {
      const emailVal = (e.target as HTMLInputElement).value;
      if (emailVal.length) {
        verifyEmailAddressExists(emailVal).then(({ data }) => {
          if (data >= 1) {
            setError('email', { message: 'This email address is in use' });
          }
        });
      }
    },
    [setError]
  );

  const validateMobileNumberIsAvailable = useCallback(
    (areaCode: string, mobileNumber: string) => {
      if (areaCode && mobileNumber) {
        verifyMobileNumberExists(areaCode, mobileNumber).then(({ data }) => {
          if (data >= 1) {
            setError('mobile_phone', { message: 'This mobile number is already in use' });
          } else {
            clearErrors('mobile_phone');
          }
        });
      }
    },
    [setError, clearErrors]
  );

  return (
    <>
      <PanelTitle>Driver details</PanelTitle>
      <GridLayout template={2} gap="16px 38px">
        <div>
          <TextFieldLabel $isRequired>Title</TextFieldLabel>
          <DropDown
            control={control as unknown as Control<FieldValues>}
            options={titleOptions}
            placeholder="Title"
            name="title"
            error={errors?.title}
            required={{
              required: 'Title is a required field',
            }}
          />
        </div>
        <TextField
          {...register('first_name', {
            required: 'First name is a required field',
          })}
          required
          label="First name"
          type="text"
          placeholder="First name"
          error={errors?.first_name}
        />
        <TextField
          {...register('middle_name')}
          label="Middle name"
          type="text"
          placeholder="Middle name"
          error={errors?.middle_name}
        />
        <TextField
          {...register('last_name', {
            required: 'Last name is a required field',
          })}
          required
          label="Last name"
          type="text"
          placeholder="Last name"
          error={errors?.last_name}
        />
        <TextField
          {...register('date_of_birth', {
            required: 'Date of birth is a required field',
            validate: (value: string | undefined) => {
              if (!value) {
                return 'Date of birth is a required field';
              }
              if (!(new Date(value) < new Date(validAgeDateFormat))) {
                return 'Driver must be older than 25';
              }
              return true;
            },
          })}
          required
          label="Date of birth"
          type="date"
          error={errors?.date_of_birth}
        />
        <TextField
          {...register('email', {
            required: 'Email is a required field',
            pattern: {
              value: emailFormat,
              message: 'Please enter a valid email address',
            },
            onBlur: validateEmailAddressIsAvailable,
          })}
          required
          label="Email"
          type="email"
          placeholder="Email"
          error={errors?.email}
        />
        <div>
          <TextFieldLabel $isRequired>Country calling code</TextFieldLabel>
          <DropDown
            placeholder="Select code"
            options={[
              { value: '+44', label: '+44' },
              { value: '+33', label: '+33' },
            ]}
            required={{
              required: 'Country calling code is a required field',
            }}
            control={control as unknown as Control<FieldValues>}
            name="no_area_code"
            error={errors.no_area_code}
          />
        </div>
        <TextField
          {...register('mobile_phone', {
            required: 'Mobile number is a required field',
            pattern: {
              value: mobileNumberFormat,
              message: 'Please enter a valid phone number in the format 7123123123',
            },
            onBlur: (e: SyntheticEvent) =>
              validateMobileNumberIsAvailable(getValues().no_area_code ?? '', (e.target as HTMLInputElement).value),
          })}
          required
          label="Mobile number"
          type="text"
          placeholder="Mobile number"
          error={errors?.mobile_phone}
        />
        <TextField
          {...register('address_line_1', {
            required: 'Address line 1 is a required field',
          })}
          required
          label="Address line 1"
          type="text"
          placeholder="Address line 1"
          error={errors?.address_line_1}
        />
        <TextField
          {...register('address_line_2')}
          label="Address line 2"
          type="text"
          placeholder="Address line 2"
          error={errors?.address_line_2}
        />
        <TextField
          {...register('address_post_code', {
            required: 'Post Code is a required field',
            onChange: (e: SyntheticEvent) => {
              return setValue('address_post_code', (e.target as HTMLInputElement).value.toUpperCase());
            },
            pattern: {
              value: ukPostCode,
              message: 'Invalid postcode',
            },
          })}
          required
          label="Post code"
          type="text"
          placeholder="Post code"
          error={errors?.address_post_code}
        />
        <TextField
          {...register('address_city', {
            required: 'City is required',
          })}
          label="City"
          placeholder="City"
          type="text"
          error={errors?.address_city}
          required
        />
        <div>
          <TextFieldLabel $isRequired>Country</TextFieldLabel>
          <DropDown
            placeholder="Select country"
            options={[{ value: '5aa0543d-eaa3-4115-a6f2-17a5fa9b7afe', label: 'United Kingdom' }]}
            required={{
              required: 'Country is a required field',
            }}
            control={control as unknown as Control<FieldValues>}
            name="country_id"
            error={errors.country_id}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Ethnicity</TextFieldLabel>
          <DropDown
            placeholder="Select ethnicity"
            options={ethnicityList}
            required={{
              required: 'Ethnicity is a required field.',
            }}
            name="ethnicity"
            error={errors.ethnicity}
            control={control as unknown as Control<FieldValues>}
          />
        </div>
        <TextField
          {...register('national_insurance_number', {
            required: 'National insurance is a required field.',
            pattern: {
              value: nationalInsurance,
              message: 'Please enter a valid insurance number in the format QQ123456C',
            },
          })}
          required
          label="National insurance number"
          type="text"
          placeholder="National insurance number"
          error={errors?.national_insurance_number}
        />
      </GridLayout>
    </>
  );
};
