import { RefObject, SyntheticEvent, useEffect, useRef } from 'react';

/**
 * Example, used in components like Dialogs and Popovers so they can close
 * when a user clicks outside them
 */
function isValidEvent(event: any, ref: RefObject<Element>) {
  if (event.button > 0) {
    return false;
  }

  // If the event target is no longer in the document
  if (event.target) {
    const { ownerDocument } = event.target;
    if (!ownerDocument || !ownerDocument.documentElement.contains(event.target)) {
      return false;
    }
  }

  return ref.current && !ref.current.contains(event.target);
}

export function useInteractOutside(ref: RefObject<Element>, callback: (e: SyntheticEvent) => void, disabled = false) {
  const stateRef = useRef({
    isPointerDown: false,
    ignoreEmulatedMouseEvents: false,
    onInteractOutside: callback,
  });

  const state = stateRef.current;

  state.onInteractOutside = callback;

  useEffect(() => {
    if (disabled) {
      return;
    }

    const onPointerDown = (e: any) => {
      if (isValidEvent(e, ref)) {
        state.isPointerDown = true;
      }
    };

    // Use pointer events if available. Otherwise, fall back to mouse and touch events.
    if (typeof PointerEvent !== 'undefined') {
      const onPointerUp = (e: any) => {
        if (state.isPointerDown && isValidEvent(e, ref)) {
          state.isPointerDown = false;
          state.onInteractOutside(e);
        }
      };

      // Changing these to capture phase fixed combobox
      document.addEventListener('pointerdown', onPointerDown, true);
      document.addEventListener('pointerup', onPointerUp, true);

      return () => {
        document.removeEventListener('pointerdown', onPointerDown, true);
        document.removeEventListener('pointerup', onPointerUp, true);
      };
    }

    const onMouseUp = (e: any) => {
      if (state.ignoreEmulatedMouseEvents) {
        state.ignoreEmulatedMouseEvents = false;
      } else if (state.isPointerDown && isValidEvent(e, ref)) {
        state.isPointerDown = false;
        state.onInteractOutside(e);
      }
    };

    const onTouchEnd = (e: any) => {
      state.ignoreEmulatedMouseEvents = true;
      if (state.isPointerDown && isValidEvent(e, ref)) {
        state.isPointerDown = false;
        state.onInteractOutside(e);
      }
    };

    document.addEventListener('mousedown', onPointerDown, true);
    document.addEventListener('mouseup', onMouseUp, true);
    document.addEventListener('touchstart', onPointerDown, true);
    document.addEventListener('touchend', onTouchEnd, true);

    return () => {
      document.removeEventListener('mousedown', onPointerDown, true);
      document.removeEventListener('mouseup', onMouseUp, true);
      document.removeEventListener('touchstart', onPointerDown, true);
      document.removeEventListener('touchend', onTouchEnd, true);
    };
  }, [ref, state, disabled]);
}
