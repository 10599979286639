import { GenericAbortSignal } from 'axios';
import { Aftercare, AftercareContact, AftercareReview, UpcomingAftercare } from '../../models/aftercare';
import api from '../../utils/api';

/**
 * Get all agreements request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAllUpcomingAgreements = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: UpcomingAftercare[] }> =>
  api.get(`/aftercare/upcoming${query && `?${query}`}`, { signal });

/**
 * Get all aftercare agreements request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAftercareAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAftercareAgreements = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Aftercare[] }> => api.get(`/aftercare/list${query && `?${query}`}`, { signal });

/**
 * Get all aftercare agreements request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAftercareReviewAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAftercareReviewAgreements = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: AftercareReview[] }> =>
  api.get(`/aftercare/in-review${query && `?${query}`}`, { signal });

/**
 * Get all aftercare agreements request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAftercareByOriginalAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAftercareByOriginalAgreement = (id: string): Promise<{ count: number; data: Aftercare[] }> =>
  api.get(`/aftercare/original-agreement/${id}`);

/**
 * Get all aftercare contacts request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAftercareContactList(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAftercareContactList = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: AftercareContact[] }> =>
  api.get(`/aftercare/contact-list${query && `?${query}`}`, { signal });
