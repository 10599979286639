import React, { useCallback, useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { IoIosWarning } from 'react-icons/io';
import moment, { Moment, Duration } from 'moment';
import {
  driver12WeekRule,
  driver4WeekRule,
  driver8WeekRule,
  getUpcomingThursday,
  holidayLengthsOld,
  holidayLengthsNew,
  skipPaymentLength,
  noHolidayText,
} from './utils';
import {
  ReasonContainer,
  ConfirmationCircle,
  DropdownContainer,
  Eligibility,
  EligibilityText,
  Errors,
  SpinnerContainer,
  ValidationText,
  ViolationText,
} from './paymentToolSetUp.styles';
import { DatePickerComponent } from '../datePickerComponent/datePickerComponent';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { FieldGrid } from '../../layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { TextArea } from '../../inputs/textArea/textArea';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { Text } from '../../text/text';
import { DropDown } from '../../uiControls/dropDown/dropDown';
import { Spinner } from '../../uiControls/spinner/spinner';
import { getOrdwaySubscriptionDetails } from '../../../api/get/driver.get';
import { updateHolidayBatchWithHolidays, updateFreezeBatchWithFreezes } from '../../../api/patch/driver.patch';
import { createHolidayBatchWithHolidays, createFreezeBatchWithFreezes } from '../../../api/post/driver.post';
import {
  DriverPaymentFreeze,
  BookedFreezeDetails,
  EditFreezePayload,
  NewFreezePayload,
  OnSubmitFreezeValues,
} from '../../../models/freeze';
import {
  DriverHoliday,
  DriverPaymentHoliday,
  DriverHolidayDetails,
  EditHolidayPayload,
  NewHolidayPayload,
  BookedHolidayDetails,
  OnSubmitHolidayValues,
  OrdwayHolidaySubscriptionDetails,
} from '../../../models/holiday';
import { isBurst, isPlus8 } from '../../../consts/plans';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_YELLOW } from '../../../common/styles/Colors';
import { COMPLETED_STATUS } from '../../../consts/status';
import { OptionList } from '../../../utils/props';
import { isTimeAfterLondon } from '../../../utils/validations';

export interface PaymentToolSetUpProps {
  itemToEdit?: DriverPaymentHoliday | DriverPaymentFreeze;
  driverDetails: DriverHolidayDetails;
  bookedDates: (BookedHolidayDetails | BookedFreezeDetails)[];
  isHolidayTool?: boolean;
}

export const PaymentToolSetUp = ({
  driverDetails,
  bookedDates,
  itemToEdit,
  isHolidayTool,
}: PaymentToolSetUpProps): JSX.Element => {
  const [subscriptionDetails, setSubscriptionDetails] = useState<OrdwayHolidaySubscriptionDetails>();
  const [subscriptionDetailsLoading, setSubscriptionDetailsLoading] = useState<boolean>(false);
  const [selectedPlan, setSelectedPlan] = useState<string>();
  const [availablePaymentStartDates, setAvailablePaymentStartDates] = useState<Date[]>([]);
  const [dateSelectedBeforeNextAvailable, setDateSelectedBeforeNextAvailable] = useState<boolean>(false);
  const [nextAvailableDateFromLastCompleted, setNextAvailableDateFromLastCompleted] = useState<Moment>();
  const [dateAlreadyBooked, setDateAlreadyBooked] = useState<boolean>(false);
  const [submittingPaymentSetUp, setSubmittingPaymentSetUp] = useState<boolean>(false);
  const [paymentSetUpComfirmed, setPaymentSetUpComfirmed] = useState<
    EditHolidayPayload | NewHolidayPayload | EditFreezePayload | NewFreezePayload
  >();

  const {
    handleSubmit,
    register,
    control,
    watch,
    formState: { errors },
    setValue,
  } = useForm<EditHolidayPayload | NewHolidayPayload>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: itemToEdit && {
      ...itemToEdit,
      commencement_date: moment(itemToEdit.commencement_date).toDate(),
      override_reason:
        itemToEdit?.override_reason
          ?.split('\n')
          ?.find((r) => r.includes('Reason: '))
          ?.split(': ')?.[1] ?? '',
    },
  });

  const commencementDate: string | Moment | Date = watch('commencement_date');
  const overrideReason: string | undefined = watch('override_reason');
  const holidayLength: number | undefined = watch('holiday_length');

  const onNewPlan: boolean =
    isPlus8(driverDetails?.agreement_type?.toUpperCase()) || isBurst(driverDetails?.agreement_type?.toUpperCase());

  const holidayLengthOptions: OptionList[] = onNewPlan ? holidayLengthsNew : holidayLengthsOld;

  const totalHolidayAvailable: number =
    (subscriptionDetails?.holiday_eligibility?.rollover ?? 0) +
    (subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining ?? 0);

  const firstAvailableHolidayDate: Moment | undefined =
    subscriptionDetails && moment(subscriptionDetails?.holiday_eligibility?.firstAvailableHolidayDate);
  const nextAvailableHolidayDate: Moment | undefined = moment(getUpcomingThursday(), 'day');

  const isFirstDateInFuture: boolean | undefined =
    firstAvailableHolidayDate && firstAvailableHolidayDate?.isAfter(moment(getUpcomingThursday()), 'day');
  const isNextDateInFuture: boolean | undefined =
    nextAvailableHolidayDate && nextAvailableHolidayDate?.isAfter(moment(getUpcomingThursday()), 'day');

  const arrearsValue: number = subscriptionDetails ? +subscriptionDetails.arrears : 0;

  const noHolidayAvailable: boolean = (holidayLength ?? 0) > totalHolidayAvailable;

  const inEligibleFor4WeekBreak: boolean =
    holidayLength === 4 && !subscriptionDetails?.holiday_eligibility?.eligibleFor4Weeks;

  const approvalNeeded: boolean =
    inEligibleFor4WeekBreak || noHolidayAvailable || dateSelectedBeforeNextAvailable || arrearsValue > 0;

  const getAvailableStartDates = useCallback(
    ({ subscriptions, billing_schedule_items, holiday_eligibility }: OrdwayHolidaySubscriptionDetails): void => {
      const termEndDate: Moment = moment(subscriptions?.current_term_end_date);
      const availableStartDates: Date[] = [getUpcomingThursday()];

      // Get all available Thursdays up until week before end of agreement end date
      for (let index = 1; index <= billing_schedule_items?.remaining_total_weeks; index += 1) {
        const nextThursday: string = moment(availableStartDates?.at(-1))?.add(7, 'days').toISOString();
        const daysTillEnd: Duration = moment.duration(termEndDate?.diff(nextThursday));
        const dateForCalendar: Date = new Date(nextThursday);

        if (Math.round(daysTillEnd.asDays()) >= 6) {
          availableStartDates.push(dateForCalendar);
        }
      }

      let bookedDate: BookedHolidayDetails | BookedFreezeDetails | undefined;
      for (let index = availableStartDates.length - 1; index >= 0; index -= 1) {
        bookedDate = bookedDates?.find(
          (holiday) =>
            moment(holiday?.commencement_date).isSame(
              `${moment(availableStartDates[index]).format('YYYY-MM-DDT00:00:00.000')}Z`
            ) && (holiday as BookedHolidayDetails)?.holiday_batch_id !== itemToEdit?.id
        );

        if (bookedDate) {
          availableStartDates.splice(index, 1);
        }
      }

      setAvailablePaymentStartDates(availableStartDates);

      const firstAvailableDate: Moment = moment(holiday_eligibility?.firstAvailableHolidayDate);
      const nextAvailableDate: Moment = moment(holiday_eligibility?.nextAvailableHolidayDate);

      const firstAvailableIndex: number = isHolidayTool
        ? availableStartDates?.findIndex((date) => moment(date).isSame(firstAvailableDate, 'day'))
        : 0;

      const nextAvailableIndex: number = isHolidayTool
        ? availableStartDates?.findIndex((date) => moment(date).isSame(nextAvailableDate, 'day'))
        : 0;

      if (!itemToEdit) {
        if (!onNewPlan) {
          // If driver on old R2B plan
          setValue('commencement_date', availableStartDates?.[0]);
          // New plans
        } else if (moment(nextAvailableDate).isAfter(moment(firstAvailableDate))) {
          setValue('commencement_date', availableStartDates?.[nextAvailableIndex < 0 ? 0 : nextAvailableIndex]);
        } else {
          setValue('commencement_date', availableStartDates?.[firstAvailableIndex < 0 ? 0 : firstAvailableIndex]);
        }
      }

      const completed: DriverHoliday[] = [];
      holiday_eligibility?.currentPeriodHoliday?.batches?.forEach((batch) => {
        batch?.holidays?.forEach((hol) => {
          if (hol.holiday_status === COMPLETED_STATUS) {
            completed.push(hol);
          }
        });
      });

      if (completed.length > 0) {
        setNextAvailableDateFromLastCompleted(moment(completed.at(-1)?.commencement_date).add(8, 'weeks'));
      } else {
        setNextAvailableDateFromLastCompleted(moment(holiday_eligibility?.firstAvailableHolidayDate));
      }
    },
    [bookedDates, itemToEdit, onNewPlan, setValue, isHolidayTool]
  );

  const getReasonString = useCallback((): string => {
    let text: string = '';
    if (arrearsValue > 0) {
      text += `* Driver is in arrears of £${arrearsValue.toFixed(2)}\n`;
    }
    if (dateSelectedBeforeNextAvailable) {
      if (moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day')) {
        text += `${driver12WeekRule}\n`;
      } else {
        text += `${driver8WeekRule}\n`;
      }
    }
    if (inEligibleFor4WeekBreak) {
      text += `${driver4WeekRule}\n`;
    }
    if (noHolidayAvailable) {
      text += noHolidayText;
    }
    return text;
  }, [
    arrearsValue,
    inEligibleFor4WeekBreak,
    dateSelectedBeforeNextAvailable,
    firstAvailableHolidayDate,
    noHolidayAvailable,
    commencementDate,
  ]);

  // Type Guards
  const isHolidayValues = (values: OnSubmitHolidayValues | OnSubmitFreezeValues): values is OnSubmitHolidayValues => {
    return (values as OnSubmitHolidayValues).holiday_length !== undefined;
  };
  const isBookedHolidayDetails = (
    datail: BookedHolidayDetails | BookedFreezeDetails
  ): datail is BookedHolidayDetails => {
    return (datail as BookedHolidayDetails).holiday_batch_id !== undefined;
  };

  const onSubmitClick = useCallback(
    (values: OnSubmitHolidayValues | OnSubmitFreezeValues): void => {
      let reason: string | undefined;
      if (!isHolidayTool) {
        reason = `Reason: ${values.override_reason}`;
      } else if (approvalNeeded && values.override_reason) {
        reason = `${getReasonString()}\nReason: ${values.override_reason}`;
      }
      const newPaymentToolParams: NewHolidayPayload | NewFreezePayload = {
        driver_id: driverDetails?.id,
        agreement_id: driverDetails?.agreement_id,
        [isHolidayTool ? 'holiday_length' : 'freeze_length']: isHolidayValues(values)
          ? Number(values.holiday_length)
          : Number(values.freeze_length),
        commencement_date: moment(values.commencement_date).format('YYYY-MM-DD'),
        override_reason: reason,
      };
      setSubmittingPaymentSetUp(true);
      if (itemToEdit) {
        const updatePaymentToolParams: EditHolidayPayload | EditFreezePayload = {
          id: itemToEdit?.id,
          driver_id: driverDetails?.id,
          [isHolidayTool ? 'holiday_length' : 'freeze_length']: isHolidayValues(values)
            ? Number(values.holiday_length)
            : Number(values.freeze_length),
          commencement_date: moment.utc(values.commencement_date).format('YYYY-MM-DD'),
          override_reason: reason,
          agreement_id: driverDetails?.agreement_id,
        };
        const apiUpdate = isHolidayTool ? updateHolidayBatchWithHolidays : updateFreezeBatchWithFreezes;
        apiUpdate(updatePaymentToolParams)
          .then(() => {
            setSubmittingPaymentSetUp(false);
            setPaymentSetUpComfirmed(updatePaymentToolParams);
          })
          .catch(() => setSubmittingPaymentSetUp(false));
      } else {
        const apiCreate = isHolidayTool ? createHolidayBatchWithHolidays : createFreezeBatchWithFreezes;
        apiCreate(newPaymentToolParams)
          .then(() => {
            setSubmittingPaymentSetUp(false);
            setPaymentSetUpComfirmed(newPaymentToolParams);
          })
          .catch(() => setSubmittingPaymentSetUp(false));
      }
    },
    [driverDetails, itemToEdit, approvalNeeded, getReasonString, isHolidayTool]
  );

  useEffect(() => {
    const commencementDates: string[] = [];
    if (holidayLength && commencementDate) {
      for (let index = 0; index < holidayLength; index += 1) {
        const nextDate: Moment = moment(commencementDate)?.add(7 * index, 'days');
        commencementDates.push(`${moment(nextDate)?.format('YYYY-MM-DDT00:00:00.000')}Z`);
      }

      setDateAlreadyBooked(
        commencementDates.some((comDate) =>
          bookedDates?.find((date) =>
            isBookedHolidayDetails(date)
              ? date.commencement_date === comDate && date.holiday_batch_id !== itemToEdit?.id
              : date.commencement_date === comDate && date.freeze_batch_id !== itemToEdit?.id
          )
        )
      );
    }
  }, [holidayLength, bookedDates, commencementDate, itemToEdit]);

  useEffect(() => {
    // Check if the date selected is before the first available date according to new rules
    // Can only take a break after 12 weeks into agreement
    // There should be an 8 week gap between holidays
    if (onNewPlan && commencementDate) {
      if (
        moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day') ||
        moment(commencementDate).isBefore(
          itemToEdit ? nextAvailableDateFromLastCompleted : nextAvailableHolidayDate,
          'day'
        )
      ) {
        setDateSelectedBeforeNextAvailable(true);
      } else {
        setDateSelectedBeforeNextAvailable(false);
      }
    }
  }, [
    commencementDate,
    onNewPlan,
    firstAvailableHolidayDate,
    nextAvailableHolidayDate,
    itemToEdit,
    nextAvailableDateFromLastCompleted,
  ]);

  useEffect(() => {
    if (driverDetails.ordway_customer_id && driverDetails.ordway_subscription_id && !subscriptionDetails) {
      setSubscriptionDetailsLoading(true);
      getOrdwaySubscriptionDetails(driverDetails?.ordway_customer_id, driverDetails?.ordway_subscription_id).then(
        ({ data }) => {
          setSubscriptionDetails(data);
          if (!selectedPlan) {
            setSelectedPlan(data?.subscriptions?.plans[0]?.effective_price);
            getAvailableStartDates(data);
          }
          setSubscriptionDetailsLoading(false);
        }
      );
    }
  }, [
    driverDetails.ordway_customer_id,
    driverDetails.ordway_subscription_id,
    selectedPlan,
    subscriptionDetails,
    getAvailableStartDates,
  ]);

  if (subscriptionDetailsLoading) {
    return (
      <SpinnerContainer>
        <Spinner color={PRIMARY_PURPLE} size={24} />
      </SpinnerContainer>
    );
  }

  if (paymentSetUpComfirmed) {
    return (
      <>
        <Eligibility>
          <ConfirmationCircle size={32} color={PRIMARY_GREEN} />
          <EligibilityText>
            {itemToEdit
              ? `${isHolidayTool ? 'Holiday' : 'Payment skip'} successfully updated`
              : `${isHolidayTool ? 'Holiday' : 'Payment skip'} successfully created`}
          </EligibilityText>
        </Eligibility>
        <FieldGrid
          numColumns={3}
          headers={['Driver:', 'Commencement date:', 'Length:']}
          values={[
            driverDetails.driver_name,
            moment(paymentSetUpComfirmed.commencement_date)?.format('DD MMM YYYY'),
            isHolidayValues(paymentSetUpComfirmed)
              ? paymentSetUpComfirmed.holiday_length ?? 'N/A'
              : paymentSetUpComfirmed.freeze_length ?? 'N/A',
          ]}
        />
      </>
    );
  }

  return (
    <>
      {subscriptionDetails && (
        <>
          <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
            Ordway summary
          </Text>
          <FieldGrid
            styled={{ paddingTop: 16 }}
            numColumns={3}
            headers={['Subscription ID:', 'Customer ID:', 'Status:']}
            values={[
              driverDetails?.ordway_subscription_id ?? '-',
              driverDetails?.ordway_customer_id ?? '-',
              subscriptionDetails?.subscriptions?.status,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Renewal term:', 'Customer name:', 'Contract term:']}
            values={[
              subscriptionDetails?.subscriptions?.renewal_term,
              driverDetails.driver_name,
              subscriptionDetails?.subscriptions?.contract_term,
            ]}
          />
          <FieldGrid
            numColumns={3}
            headers={['Effective price:', 'Current term start date:', 'Current term end date:']}
            values={[
              `£${selectedPlan}`,
              moment(subscriptionDetails?.subscriptions?.current_term_start_date)?.format('DD MMM YYYY'),
              moment(subscriptionDetails?.subscriptions?.current_term_end_date)?.format('DD MMM YYYY'),
            ]}
          />
          <FieldGrid
            numColumns={1}
            headers={['Remaining contract balance:']}
            values={[`£${subscriptionDetails?.billing_schedule_items?.unbilled_subscription_amount ?? '0.00'}`]}
          />

          {isHolidayTool && (
            <>
              <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
                Holiday summary
              </Text>
              <FieldGrid
                styled={{ paddingTop: 16 }}
                numColumns={2}
                headers={['Annual holiday allowance:', 'Current unpaid balance:']}
                values={[
                  subscriptionDetails?.holiday_eligibility?.totalHolidayAllowance,
                  `£${subscriptionDetails?.arrears}`,
                ]}
              />

              {subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? (
                <FieldGrid
                  numColumns={1}
                  headers={[
                    `Holiday weeks taken in previous period: ${moment(
                      subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodStartDate
                    ).format('DD MMM YYYY')} 
          to ${moment(subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.periodEndDate).format(
            'DD MMM YYYY'
          )}`,
                  ]}
                  values={[subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday?.numberOfWeeksTaken]}
                />
              ) : (
                <FieldGrid
                  numColumns={1}
                  headers={[
                    `Holiday weeks taken in this period (including upcoming): ${moment(
                      subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate
                    ).format('DD MMM YYYY')} 
            to ${moment(subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodEndDate).format(
              'DD MMM YYYY'
            )}`,
                  ]}
                  values={[subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.numberOfWeeksTaken]}
                />
              )}

              <FieldGrid
                numColumns={1}
                headers={[
                  onNewPlan
                    ? 'Holiday remaining:'
                    : `Holiday accrued this period: ${moment(
                        subscriptionDetails?.holiday_eligibility?.currentPeriodHoliday?.periodStartDate
                      ).format('DD MMM YYYY')} 
        to today`,
                ]}
                values={[
                  subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining < 0
                    ? 0
                    : subscriptionDetails?.holiday_eligibility?.holidayWeeksRemaining,
                ]}
              />

              {!onNewPlan && (
                <>
                  <FieldGrid
                    numColumns={subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday ? 2 : 1}
                    headers={
                      subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                        ? ['Rollover weeks available:', 'Total holiday available:']
                        : ['Total holiday available:']
                    }
                    values={
                      subscriptionDetails?.holiday_eligibility?.lastPeriodHoliday
                        ? [
                            subscriptionDetails?.holiday_eligibility?.rollover,
                            totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable,
                          ]
                        : [totalHolidayAvailable < 0 ? 0 : totalHolidayAvailable]
                    }
                  />
                </>
              )}
            </>
          )}

          {isHolidayTool && onNewPlan && (isFirstDateInFuture || isNextDateInFuture) && (
            <ValidationText>
              * A valid reason is needed if selecting a date before&nbsp;
              {itemToEdit ? (
                nextAvailableDateFromLastCompleted?.format('DD MMM YYYY')
              ) : (
                <>
                  {nextAvailableHolidayDate && nextAvailableHolidayDate.isAfter(firstAvailableHolidayDate)
                    ? nextAvailableHolidayDate?.format('DD MMM YYYY')
                    : firstAvailableHolidayDate?.format('DD MMM YYYY')}
                </>
              )}
            </ValidationText>
          )}

          <Text variant="body5" color={PRIMARY_PURPLE} weight={800}>
            {itemToEdit ? 'Edit' : 'New'} {isHolidayTool ? 'holiday' : 'payment skip'}
          </Text>
          <FlexLayout styled={{ width: '100%', margin: '16px 0 30px' }} gap={20} itemsX="space-between">
            <DatePickerComponent
              control={control}
              name="commencement_date"
              label={`${isHolidayTool ? 'Holiday' : 'Payment skip'} commencement date:`}
              includedDates={availablePaymentStartDates}
              rules={{
                required: true,
              }}
              errors={errors}
              dayClassName={(date) => {
                if (itemToEdit) {
                  if (moment(date).isBefore(nextAvailableDateFromLastCompleted, 'day')) {
                    return 'needsApproval';
                  }
                  return 'approved';
                }
                if (
                  moment(date)?.isBefore(firstAvailableHolidayDate, 'day') ||
                  moment(date)?.isBefore(nextAvailableHolidayDate, 'day')
                ) {
                  return 'needsApproval';
                }
                return 'approved';
              }}
            />
            <DropdownContainer>
              <TextFieldLabel $isRequired>Number of weeks:</TextFieldLabel>
              <DropDown
                name="holiday_length"
                options={isHolidayTool ? holidayLengthOptions : skipPaymentLength}
                placeholder="Holiday length"
                control={control as unknown as Control<FieldValues>}
                required={{
                  required: true,
                }}
                error={errors.holiday_length}
              />
            </DropdownContainer>
          </FlexLayout>

          {approvalNeeded && !!isHolidayTool && (
            <ReasonContainer>
              <Eligibility>
                <IoIosWarning size={24} color={STATUS_YELLOW} />
                <ViolationText>
                  The driver does not meet all the criteria for a holiday, so a reason needs to be provided why an
                  exception was made.
                </ViolationText>
              </Eligibility>

              <TextArea
                label="Override reason"
                {...register('override_reason')}
                name="override_reason"
                placeholder="Override reason"
                required={approvalNeeded}
                error={errors?.override_reason}
              />
            </ReasonContainer>
          )}

          {!isHolidayTool && (
            <ReasonContainer>
              <TextArea
                label="Notes"
                {...register('override_reason')}
                name="override_reason"
                placeholder="Notes"
                required={true}
                error={errors?.override_reason}
              />
            </ReasonContainer>
          )}

          {!!isHolidayTool && (
            <Errors>
              {(isFirstDateInFuture || isNextDateInFuture) && onNewPlan && dateAlreadyBooked && (
                <ViolationText>
                  The holiday dates selected conflict with an already booked holiday. Please choose a different
                  commencement date or change the holiday length.
                </ViolationText>
              )}
              {dateSelectedBeforeNextAvailable && commencementDate && (
                <ViolationText>
                  {moment(commencementDate).isBefore(firstAvailableHolidayDate, 'day')
                    ? driver12WeekRule
                    : driver8WeekRule}
                </ViolationText>
              )}
              {noHolidayAvailable && <ViolationText>{noHolidayText}</ViolationText>}
              {arrearsValue > 0 && (
                <ViolationText>{`* Driver's account is in arrears of £${subscriptionDetails?.arrears}`}</ViolationText>
              )}
              {inEligibleFor4WeekBreak && <ViolationText>{driver4WeekRule}</ViolationText>}
            </Errors>
          )}

          <PrimaryButton
            onClick={handleSubmit(onSubmitClick)}
            isProcessing={submittingPaymentSetUp}
            styled={{ padding: '0px 38px' }}
            disabled={
              (isHolidayTool && approvalNeeded && !overrideReason) ||
              !commencementDate ||
              dateAlreadyBooked ||
              (isTimeAfterLondon(15, 30) && moment(commencementDate).isSame(moment().add(1, 'days'), 'day'))
            }
          >
            {itemToEdit ? 'Update' : 'Apply'}
          </PrimaryButton>
        </>
      )}
    </>
  );
};
