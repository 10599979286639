import { styled } from 'styled-components';
import { BsExclamationCircle } from 'react-icons/bs';
import { Text } from '../../text/text';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';

export const StyledText = styled.div`
  margin: 32px 0;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
  text-align: center;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  display: inherit;
  min-width: 165px;
  margin-right: 8px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  display: inherit;
  min-width: 165px;
`;

export const StyledExclamationIcon = styled(BsExclamationCircle)`
  margin: 15px;
`;

export const StyledSubtitle = styled(Text)`
  text-align: center;
`;
