import React from 'react';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { Spinner } from '../../../../../uiComponents/uiControls/spinner/spinner';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../../../common/styles/Colors';

interface AccountHeaderProps {
  loading: boolean;
  inArrears: boolean;
  arrears: any;
}

export const AccountHeader = ({ loading, inArrears, arrears }: AccountHeaderProps) => {
  return (
    <>
      <FlexLayout itemsY="center" gap={16}>
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
          Driver accounts and payments
        </Text>
        {loading && <Spinner color={PRIMARY_PURPLE} size={24} />}
      </FlexLayout>
      {inArrears && (
        <Text variant="body7" weight={500} color={STATUS_RED}>
          {`In arrears of £${arrears ?? ''}`}
        </Text>
      )}
    </>
  );
};
