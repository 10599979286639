import { FileType, NewVehicleTypePayload } from '../../models/vehicle';
import api from '../../utils/api';

/**
 * Create Vehicle Type
 * @category API/POST
 * @param {object} body - vehicle type data
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  colors: [],
 *  manufacturer: '',
 *  model: '',
 *  spec: '',
 * ...,
 * }
 * createVehicleType(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const createVehicleType = (body: NewVehicleTypePayload) => api.post('vehicle-type', body);

export const uploadVehicleTypePhoto = (id: string, body: { files: FileType[] }) =>
  api.post(`vehicle-type/${id}/photo`, body);
