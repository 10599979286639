import { GenericAbortSignal } from 'axios';
import { InternalTransferInfo, Transfer, TransferFields } from '../../models/transfer';
import api from '../../utils/api';

/**
 * Get single transfer request
 * @category API/GET
 * @param {object} getTransfer - Returns the branch data for an branch id
 * @return {Promise} Returns a promise for the api request
 * @example
 * getTransfer(branchId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getTransfer = (id: string): Promise<{ data: Transfer }> => api.get(`transfer/${id}`);

/**
 * Get all transfers request
 * @category API/GET
 * @param {object} getAllTransfers - Returns all branches in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllTransfers(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllTransfers = (
  query = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Transfer[] }> => api.get(`transfer${query && `?${query}`}`, { signal });

/**
 * Get all internal transfers request
 * @category API/GET
 * @param {object} getAllTransfers - Returns all internal transfer in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllInternalTransfers(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllInternalTransfers = (query = ''): Promise<{ count: number; data: InternalTransferInfo[] }> =>
  api.get(`transfer/internal/list${query && `?${query}`}`);

/**
 * Send transfers as email
 * @category API/GET
 * @param {object} exportAllTransfers - Returns all branches in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * exportAllTransfers(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const exportAllTransfers = (query: string = ''): Promise<{ data: boolean }> =>
  api.get(`transfer/export${query && `?${query}`}`);

/**
 * Send transfers as email
 * @category API/GET
 * @param {object} getTransferByConditionReportId - Returns all branches in the system
 * @return {Promise} Returns a promise for the api request
 * @example
 * getTransferByConditionReportId(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getTransferByConditionReportId = (id: string = ''): Promise<{ data: TransferFields }> =>
  api.get(`/transfer/by/condition-report-id/${id}`);
