import { Driver, DriverTagsPayload } from '../../models/driver';
import { EditFreezePayload } from '../../models/freeze';
import { EditHolidayPayload } from '../../models/holiday';
import api from '../../utils/api';

/**
 * Update Driver
 * @category API/POST
 * @param {string} id - Driver id to be updated
 * @param {object} payload - Data for the driver to be updated.
 * @return {Promise} Returns a promise for the api request
 * Update driver by driver id request
 * @category API/PATCH
 * @param {object} payload - Data api to update the driver with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 * ...fields
 * }
 * updateDriver(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const updateDriver = (id: string, payload: Driver): Promise<{ data: object }> =>
  api.patch(`driver/${id}`, payload);

export const updateHolidayBatchWithHolidays = (payload: EditHolidayPayload): Promise<{ data: object }> =>
  api.patch('/holiday', payload);

export const cancelHoliday = (batchId: string, id: string): Promise<void> =>
  api.patch(`/holiday/cancel/${batchId}/${id}`).then(() => {});

export const cancelHolidayBatch = (batchId: string): Promise<void> => api.patch(`/holiday/cancel-batch/${batchId}`);

export const updateFreezeBatchWithFreezes = (payload: EditFreezePayload): Promise<{ data: object }> =>
  api.patch('/freeze', payload);

export const cancelFreeze = (batchId: string, id: string): Promise<void> =>
  api.patch(`/freeze/cancel/${batchId}/${id}`);

export const cancelFreezeBatch = (batchId: string): Promise<void> => api.patch(`/freeze/cancel-batch/${batchId}`);

export const updateDriverTags = (driverTagId: string, payload: DriverTagsPayload) =>
  api.patch(`/driver/driver-tag/${driverTagId}`, payload);
