import { InsurancePolicy } from '../../models/insurancePolicy';
import api from '../../utils/api';

/**
 * Delete insurance policy request
 * @category API/DELETE
 * @param {object} id - policy id for the user to delete the policy
 * @return {Promise} Returns a promise for the api request
 * @example
 * deleteInsurancePolicy(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteInsurancePolicy = (id: string): Promise<{ data: InsurancePolicy }> =>
  api.delete(`insurance/policy/${id}`);
