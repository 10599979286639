import { GenericAbortSignal } from 'axios';
import { Agreement } from '../../models/agreement';
import api from '../../utils/api';

/**
 * Get single agreement request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAgreement = (id: string = ''): Promise<{ data: Agreement }> => api.get(`agreement/${id}`);

/**
 * Get all agreements request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAllAgreements = (
  query: string = '',
  signal: GenericAbortSignal
): Promise<{ count: number; data: Agreement[] }> => api.get(`agreement${query && `?${query}`}`, { signal });

/**
 * export all agreements to email
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * exportAllAgreements(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const exportAllAgreements = (query = '') => api.get(`agreement/export${query && `?${query}`}`);

export const getAgreementsByServicingId = (servicingId: string) => api.get(`agreement/servicing/${servicingId}`);
