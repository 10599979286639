// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsEye } from 'react-icons/bs';
import moment from 'moment';
// COMPONENTS & STYLES
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { agreementListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { agreementStatuses, agreementTypes } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTagCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
// API CALLS & MODELS
import { getAllBranches } from '../../../api/get/branch.get';
import { exportAllAgreements } from '../../../api/get/agreement.get';
import { Agreement } from '../../../models/agreement';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { OptionList } from '../../../utils/props';
import { AGREEMENTS } from '../../../consts/routes';
import { SECONDARY_PURPLE_90 } from '../../../common/styles/Colors';
import { Branch } from '../../../models/branch';
import { APP_CONTEXT } from '../../../utils/context';
import { AGREEMENT_TERMINATED } from '../../../consts/agreement';
import { useGetAndMergeAgreementsQuery } from '../../../api/listAndMerge/listAndMergeAgreementsApiSlice';

export const AgreementList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [selectedStatus, setSelectedStatus] = useState<OptionList[]>([]);
  const [selectedAgreementType, setSelectedAgreementType] = useState<OptionList[]>([]);
  const [selectedBranch, setSelectedBranch] = useState<OptionList[]>([]);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);

  const {
    data: agreements,
    isLoading: isAgreementsLoading,
    isFetching: isAgreementsFetching,
  } = useGetAndMergeAgreementsQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'date-range',
      element: (
        <DateRangeFilter
          title="Agreement start date range:"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
    {
      name: 'status',
      element: (
        <DropDownFilter
          name="status"
          placeholder="Select status"
          options={agreementStatuses}
          multiValues={selectedStatus}
          title="Agreement status:"
          onChange={(items) => setSelectedStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'agreement_type',
      element: (
        <DropDownFilter
          name="agreement_type"
          placeholder="Select agreement type"
          multiValues={selectedAgreementType}
          options={agreementTypes}
          title="Agreement type:"
          onChange={(items) => setSelectedAgreementType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'branch',
      element: (
        <DropDownFilter
          name="branch"
          placeholder="Select branch"
          multiValues={selectedBranch}
          options={branchOptions}
          title="Branch:"
          onChange={(items) => setSelectedBranch(items as OptionList[])}
        />
      ),
    },
  ];

  const handleGetAgreementsResponse = useCallback(
    (count: number, agreements: Agreement[]) => {
      const agreementRows = agreements?.map((agreement: Agreement) => {
        return {
          rowData: { data: agreement },
          cells: [
            <TableTextCell value={agreement?.driver_name} />,
            <TableTextCell value={agreement?.vrm} />,
            <TableTextCell value={agreement?.agreement_type} />,
            <TableTextCell value={agreement?.ordway_subscription_id} />,
            <TableTextCell value={agreement?.branch_name} />,
            <TableTagCell tags={[agreement?.agreement_status]} />,
            <TableTextCell value={agreement?.start_date ? moment(agreement?.start_date).format('DD MMM YYYY') : '-'} />,
            <TableTextCell
              value={
                agreement?.agreement_status === AGREEMENT_TERMINATED && agreement?.termination_date
                  ? moment(agreement?.termination_date).format('DD MMM YYYY')
                  : agreement?.end_date
                    ? moment(agreement?.end_date).format('DD MMM YYYY')
                    : '-'
              }
            />,
            <ActionIcon
              onClick={() => navigate(`${AGREEMENTS}/${agreement?.agreement_id}`, { state: AGREEMENTS })}
              icon={<BsEye size={24} color={SECONDARY_PURPLE_90} />}
              tooltip="View agreement"
            />,
          ],
        };
      });
      setTableData(agreementRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('agreementListPage');
    setPageTitle('Agreements');
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response.data.map((branch: Branch) => {
        return { value: branch.branch_id, label: branch.branch_name };
      });
      setBranchOptions(branches);
    });
    setSortingColumn('start_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      {
        columnName: 'agreement.start_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
      {
        columnName: 'agreement_status',
        options: selectedStatus,
      },
      {
        columnName: 'agreement_type',
        options: selectedAgreementType,
      },
      {
        columnName: 'branch.id',
        options: selectedBranch,
      },
    ]);
  }, [selectedAgreementType, selectedBranch, selectedStatus, dateRangeFilter, setTableFilters]);

  useEffect(() => {
    if (agreements) {
      handleGetAgreementsResponse(agreements.count, agreements.data);
    }
  }, [agreements, queryStringState, handleGetAgreementsResponse]);

  const onClearClick = useCallback(() => {
    setDateRangeFilter([]);
    setSelectedStatus([]);
    setSelectedAgreementType([]);
    setSelectedBranch([]);
  }, [setDateRangeFilter]);

  return (
    <Table
      isInfitineScroll={true}
      isLoading={isAgreementsFetching || isAgreementsLoading}
      header="Agreement list"
      onColumnHeaderClick={(columnId: string) => {
        applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId));
      }}
      disableApply={invalidDates}
      sortAscending={sortAscending}
      columns={agreementListColumns}
      rows={tableData}
      totalRows={totalRows}
      rowsPerPage={numRowsPerPage}
      currentPageNumber={pageNumber}
      sortingColumn={sortingColumn}
      filters={filters}
      onSearchChange={(value: string) => {
        setSearchString(value);
        applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
      }}
      downloadApi={exportAllAgreements}
      filterQuery={filterQuery}
      goToPage={(pageNumber: number) => {
        goToPageNumber(pageNumber);
        applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
      }}
      onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
      onClearClick={() => onClearClick()}
      onRowClick={(agreement: { data: Agreement }) =>
        navigate(`${AGREEMENTS}/${agreement?.data?.agreement_id}`, { state: AGREEMENTS })
      }
    />
  );
};
