import React from 'react';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { StyledCheckboxInput, StyledText, StyledCheckboxContainer } from './checkbox.styles';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { SECONDARY_PURPLE_5, PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';

export const CheckboxLabel = ({ label, color }: { label: string; color?: string }) => {
  return (
    <StyledText variant="body6" color={color ? color : PRIMARY_PURPLE} weight={500}>
      {label}
    </StyledText>
  );
};

interface CheckboxProps {
  disabled?: boolean;
  defaultChecked?: boolean;
  checked?: boolean;
  name: string;
  onCheck?: (checked: boolean) => void;
  value?: string;
}

export const Checkbox = withStyledProps(
  React.forwardRef<HTMLInputElement, CheckboxProps>(
    ({ disabled, defaultChecked, checked, name, onCheck, value, ...props }: CheckboxProps, ref) => {
      return (
        <StyledCheckboxContainer {...props}>
          <StyledCheckboxInput
            ref={ref}
            type="checkbox"
            name={name}
            value={value}
            defaultChecked={defaultChecked}
            checked={checked}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onCheck?.(e?.target?.checked)}
          />
          <FlexLayout
            styled={{ height: 20, position: 'absolute', zIndex: 1 }}
            itemsX="center"
            itemsY="center"
            className="tick-icon"
          >
            <BsFillCheckCircleFill size={20} color={disabled ? SECONDARY_PURPLE_5 : PRIMARY_GREEN} />
          </FlexLayout>
        </StyledCheckboxContainer>
      );
    }
  )
);
