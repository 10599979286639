import React from 'react';
import { StyledContainer, StyledToggleInput, StyledSlider } from './toggle.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { BaseProps } from '../../../utils/props';
import { useControlledState } from '../../../utils/utils';

interface ManagedToggleProps {
  /**
   * The default state of the toggle
   */
  defaultChecked: boolean;
  /**
   * When disabled the toggle sets pointer events to none
   */
  disabled: boolean;
}

interface UnmanagedToggleProps {
  /**
   * Whether the toggle is checked or not
   */
  checked: boolean;
}

export interface ToggleProps extends BaseProps, Partial<ManagedToggleProps & UnmanagedToggleProps> {
  onChecked?: (checked: boolean) => void;
}

export const Toggle = withStyledProps(
  React.forwardRef<HTMLInputElement, ToggleProps>(
    ({ checked, defaultChecked, disabled, onChecked, ...props }: ToggleProps, ref) => {
      const [controlledChecked, setControlledChecked] = useControlledState<boolean>(
        checked,
        defaultChecked ?? false,
        onChecked
      );

      return (
        <StyledContainer>
          <StyledToggleInput
            {...props}
            ref={ref}
            type="checkbox"
            disabled={disabled}
            $checked={controlledChecked}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setControlledChecked(e.target.checked)}
          />
          <StyledSlider $checked={controlledChecked} />
        </StyledContainer>
      );
    }
  )
);
