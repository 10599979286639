import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { AxiosError } from 'axios';
import moment from 'moment';
import { DriverActivePlans } from './driverActivePlans';
import { DriverInvoices } from './driverInvoices';
import { Actions } from './utils/actions';
import { AccountHeader } from './utils/accountsHeader';
import { ColoredStatus } from './utils/coloredStatus';
import { DrivereInfoProps } from '../driverPage/driverPage';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { NoResults } from '../../../../uiComponents/table/noResults/noResults';
import { Text } from '../../../../uiComponents/text/text';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { getOrdwayDetails } from '../../../../api/get/driver.get';
import { CustomerPlanDetails, Driver, OrdwayDetails } from '../../../../models/driver';
import { PRIMARY_PURPLE, PRIMARY_GREEN, STATUS_RED } from '../../../../common/styles/Colors';

interface DriverOrdwayDetailsProps extends DrivereInfoProps {
  driver: Driver;
  checkForArrears: (isInArrears: boolean) => void;
  inArrears: boolean;
}

export const DriverOrdwayDetails = ({ driver, inArrears, checkForArrears }: DriverOrdwayDetailsProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [ordwayData, setOrdwayData] = useState<OrdwayDetails>();
  const [ordwayRequestSent, setOrdwayRequestSent] = useState<boolean>(false);
  const [activePlans, setActivePlans] = useState<CustomerPlanDetails[]>([]);
  const [isInvoicesModalOpen, setIsInvoicesModalOpen] = useState<boolean>(false);
  const [isPlansModalOpen, setIsPlansModalOpen] = useState<boolean>(false);
  const customerId: string | null | undefined = driver?.ordway_customer_id;
  const subscriptionAmount: string | undefined = ordwayData?.billing_schedule_items?.unbilled_subscription_amount;
  const arrearsAmount = Number(ordwayData?.arrears);
  const arrearsStatus = arrearsAmount > 0 ? `Arrears - £${arrearsAmount.toFixed(2)}` : 'Arrears - 0.00';
  const arrearsColor = arrearsAmount > 0 ? STATUS_RED : PRIMARY_GREEN;

  const fetchOrdwayData = useCallback(() => {
    if (customerId) {
      getOrdwayDetails(customerId)
        .then(({ data }) => {
          if (data) {
            setLoading(false);
            setOrdwayData(data);
            const arrears: number = +(data?.arrears ?? '0');
            checkForArrears(arrears > 0);
            if (data.subscriptions.length > 0) {
              const subscriptions = data.subscriptions;
              // Interate thru subs and its plans to make custom plans to avoid inner nesting
              const allPlans = [];
              for (const sub of subscriptions) {
                for (const plan of sub.plans) {
                  allPlans.push({
                    subId: sub.id,
                    name: plan.plan_name,
                    tcv: sub.total_contract_value,
                    outstandingBalance: subscriptionAmount || '0',
                    subStatus: sub.status,
                  });
                }
              }
              setActivePlans(allPlans);
            }
          }
        })
        .catch((error: AxiosError<Error>) => {
          Notification({
            type: 'error',
            title: 'Error',
            message: error?.response?.data?.message ?? 'Unknown error occurred',
          });
          setOrdwayRequestSent(false);
        });
    } else {
      checkForArrears(false);
      setLoading(false);
    }
  }, [customerId, subscriptionAmount, checkForArrears]);

  useEffect(() => {
    if (!ordwayRequestSent) {
      fetchOrdwayData();
      setOrdwayRequestSent(true);
    }
  }, [fetchOrdwayData, ordwayRequestSent]);

  useEffect(() => setOrdwayRequestSent(false), [customerId]);

  const renderSubHeader = () =>
    driver?.agreement_type ? `Ordway details - ${driver?.agreement_type}` : 'Ordway details';

  const calculateAgreementCompletion = useMemo(() => {
    const agreementCompletion =
      ordwayData?.subscriptions.length && ordwayData?.billing_schedule_items && driver?.agreement_length
        ? 100 - (ordwayData?.billing_schedule_items?.remaining_total_weeks / Number(driver.agreement_length)) * 100
        : 0;
    return Math.round(agreementCompletion);
  }, [ordwayData?.billing_schedule_items, ordwayData?.subscriptions.length, driver.agreement_length]);

  return (
    <>
      <CollapsiblePanel
        header={<AccountHeader loading={loading} inArrears={inArrears} arrears={ordwayData?.arrears} />}
        expanded={!isCollapsed}
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        styled={{ marginTop: '16px', border: inArrears ? `2px solid ${STATUS_RED}` : 'none' }}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            Driver accounts and payments
          </Text>
          {(() => {
            if (loading && driver?.ordway_customer_id) {
              return (
                <FlexLayout itemsX="center">
                  <Spinner size={32} color={PRIMARY_PURPLE} />
                </FlexLayout>
              );
            }
            if (ordwayData && driver?.ordway_customer_id) {
              return (
                <>
                  <FlexLayout styled={{ margin: '-56px 0 30px 0' }} itemsX="center">
                    <ColoredStatus status={arrearsStatus} isArrears arrearsColor={arrearsColor} />
                  </FlexLayout>
                  <>
                    <Actions
                      renderSubHeader={renderSubHeader}
                      loading={loading}
                      setIsInvoicesModalOpen={setIsInvoicesModalOpen}
                      setIsPlansModalOpen={setIsPlansModalOpen}
                    />
                    <FieldGrid
                      styled={{ margin: '16px 0' }}
                      numColumns={5}
                      headers={[
                        'Last invoice date:',
                        'Weekly amount:',
                        'Invoice status:',
                        'Ordway invoice ID:',
                        'Subscription status:',
                        'Weekly additional charges:',
                      ]}
                      values={[
                        (ordwayData?.latest_invoice?.invoice_date &&
                          moment(ordwayData?.latest_invoice.invoice_date).format('DD MMMM YYYY')) ||
                          '-',
                        ordwayData?.subscriptions[0]?.Weekly_Rental_Less_EV_Assist || '0.00',
                        ordwayData?.latest_invoice?.status ? (
                          <ColoredStatus status={ordwayData?.latest_invoice?.status} />
                        ) : (
                          '-'
                        ),
                        ordwayData?.latest_invoice?.id ? ordwayData?.latest_invoice?.id : '-',
                        <ColoredStatus status={ordwayData?.subscriptions[0]?.status} />,
                        ordwayData?.additional_charges?.total || '0.00',
                      ]}
                    />
                    <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
                      Scrive details
                    </Text>
                    <FieldGrid
                      styled={{ marginTop: 16 }}
                      numColumns={3}
                      headers={['Unbilled subscription amount::', 'Remaining total weeks::', 'Agreement completion::']}
                      values={[
                        `£ ${ordwayData?.billing_schedule_items?.unbilled_subscription_amount || '0.00'}`,
                        `${ordwayData?.billing_schedule_items?.remaining_total_weeks || '0'}`,
                        `${calculateAgreementCompletion || 0}%`,
                      ]}
                    />
                  </>
                </>
              );
            }
            return <NoResults />;
          })()}
        </div>
      </CollapsiblePanel>
      {ordwayData?.invoice_history && activePlans && (
        <Modal
          styled={{ width: '80vw', minWidth: 400 }}
          title={isInvoicesModalOpen ? 'Driver invoice history' : 'Active plans'}
          open={isInvoicesModalOpen || isPlansModalOpen}
          showClose
          onClose={() => {
            setIsInvoicesModalOpen(false);
            setIsPlansModalOpen(false);
          }}
        >
          {isInvoicesModalOpen ? (
            <DriverInvoices invoices={ordwayData.invoice_history} />
          ) : (
            <DriverActivePlans plans={activePlans} />
          )}
        </Modal>
      )}
    </>
  );
};
