import { AftercareSetup } from '../../models/aftercare';
import api from '../../utils/api';

/**
 * Edit aftercare setup Request
 * @category API/PATCH
 * @param {object} payload data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  aftercare_id: '',
 * }
 * editAftercareSetup(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const editAftercareSetup = (payload: AftercareSetup) => api.patch(`aftercare/${payload.aftercare_id}`, payload);

/**
 * Terminate aftercare Request
 * @category API/PATCH
 * @param {object} payload data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  aftercare_id: '',
 * }
 * terminateAftercare(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const terminateAftercare = (payload: {
  agreement_id: string;
  termination_note?: string;
  termination_reason: string;
}) => api.patch('aftercare/agreement/terminate', payload);
