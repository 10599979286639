import { Congestion } from '../../models/congestion';
import { apiSlice } from '../../api/baseApi';

interface GetAndMergeCongestionChargesParams {
  query: string;
  refetch: boolean;
}

interface GetAndMergeCongestionChargesResponse {
  count: number;
  data: Congestion[];
}

export const congestionCharges = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAndMergeCongestionCharges: builder.query<
      GetAndMergeCongestionChargesResponse,
      GetAndMergeCongestionChargesParams
    >({
      query: ({ query }) => `additional-charge/congestion${query && `?${query}`}`,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetAndMergeCongestionChargesQuery } = congestionCharges;
