// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsArchive, BsArrowRightCircle, BsPencil, BsPerson, BsPlusCircle } from 'react-icons/bs';
import moment from 'moment';
// COMPONENTS & STYLES
import { DriverTagsOrPenalties } from '../../../uiComponents/customComponents/DriverTagsOrPenalties';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { propectiveDriverListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  TableTagCell,
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
// API CALLS & MODELS
import { exportAllProspectDrivers } from '../../../api/get/driver.get';
import { deleteDriver } from '../../../api/delete/driver.delete';
import { Branch } from '../../../models/branch';
import { Driver } from '../../../models/driver';
// HOOKS & UTILS & COMMONS
import { ASSESSMENT_STATUS_NOT_NEEDED, ONLINE_PASSED, PRACTICAL_PASSED } from '../../../consts/assessment';
import { tagOptions, SOURCE_WEBSITE, SOURCE_SALES_AGENT } from '../../../consts/driver';
import { CREATE_ORDER, DRIVERS, NEW_DRIVER, PROSPECTIVE_DRIVERS } from '../../../consts/routes';
import { COMPLETE_STATUS, DIGITAL_ONBOARDED } from '../../../consts/status';
import { useTableFilters } from '../../../hooks/useTableFilters';
import { SECONDARY_PURPLE_90 } from '../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';
import {
  assessmentStatusOption,
  profileStatusOptions,
} from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { getAllBranches } from '../../../api/get/branch.get';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useListAndMergeProspectDriversQuery } from '../../../api/listAndMerge/listAndMergeProspectDriversApiSlice';
import { renderNotification } from '../../../utils/utils';

export const ProspectiveDriverList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [tagFilters, setTagFilters] = useState<OptionList[]>([]);
  const [branchOptions, setBranchOptions] = useState<OptionList[]>([]);
  const [selectedSalesBranch, setSelectedSalesBranch] = useState<OptionList[]>([]);
  const [selectedProfileStatus, setSelectedProfileStatus] = useState<OptionList[]>([]);
  const [selectedAssessmentStatus, setSelectedAssessmentStatus] = useState<OptionList[]>([]);
  const [isArchiveModalOpen, setIsArchiveModalOpen] = useState<boolean>(false);
  const [activeDriverId, setActiveDriverId] = useState<string>();
  const [selectedDriver, setSelectedDriver] = useState<Driver | null>(null);
  const [isSeeAllTagsModalOpen, setIsSeeAllTagsModalOpen] = useState<boolean>(false);
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: prospectDriversList,
    isFetching: isProspectDriversListFetching,
    refetch,
  } = useListAndMergeProspectDriversQuery({ query: queryStringState, refetch: refetchData });

  const filters: FilterItem[] = [
    {
      name: 'profile_status',
      element: (
        <DropDownFilter
          name="profile_status"
          placeholder="Profile Status"
          options={profileStatusOptions}
          multiValues={selectedProfileStatus}
          title="Profile status"
          onChange={(items) => setSelectedProfileStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'assessment_status',
      element: (
        <DropDownFilter
          name="assessment_status"
          placeholder="Assessment status"
          multiValues={selectedAssessmentStatus}
          options={assessmentStatusOption}
          title="Assessment status"
          onChange={(items) => setSelectedAssessmentStatus(items as OptionList[])}
        />
      ),
    },
    {
      name: 'sales_branch',
      element: (
        <DropDownFilter
          name="sales_branch"
          placeholder="Sales branch"
          multiValues={selectedSalesBranch}
          options={branchOptions}
          title="Sales branch"
          onChange={(items) => setSelectedSalesBranch(items as OptionList[])}
        />
      ),
    },
    {
      name: 'tag',
      element: (
        <DropDownFilter
          name="tag"
          placeholder="Select a tag"
          options={tagOptions}
          multiValues={tagFilters}
          title="Driver tags"
          onChange={(items) => setTagFilters(items as OptionList[])}
        />
      ),
    },
    {
      name: 'date_created',
      element: (
        <DateRangeFilter
          title="Date created"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const archiveDriver = (driverId: string) => {
    deleteDriver(driverId)
      .then(() => {
        renderNotification('success', 'Success', 'Driver profile has been successfully archived', true);
        refetchToFirstPage();
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setActiveDriverId(undefined);
      });
  };

  const handleArchiveDriver = useCallback((id: string) => {
    if (!id) return;
    setIsArchiveModalOpen(true);
    setActiveDriverId(id);
  }, []);

  const onSeeAllTagsClick = (driver: Driver) => {
    setIsSeeAllTagsModalOpen(true);
    setSelectedDriver(driver);
  };

  const isCreateOrderDisabled = (profileStatus: string | undefined, assessmentStatus: string | undefined) => {
    if (profileStatus === DIGITAL_ONBOARDED) {
      return false;
    }
    if (
      profileStatus === COMPLETE_STATUS &&
      assessmentStatus &&
      [ONLINE_PASSED, PRACTICAL_PASSED, ASSESSMENT_STATUS_NOT_NEEDED].includes(assessmentStatus)
    ) {
      return false;
    }
    return true;
  };

  const handleGetDriversResponse = useCallback(
    (count: number, drivers: Driver[]) => {
      const driverRows = drivers?.map((driver: Driver) => {
        return {
          rowData: { data: driver },
          cells: [
            <TableTextCell value={driver?.driver_name ?? '-'} />,
            driver?.driver_profile_status ? (
              <TableTagCell tags={[driver?.driver_profile_status]} />
            ) : (
              <TableTextCell value="-" />
            ),
            driver?.driver_profile_status && driver?.driver_profile_status === DIGITAL_ONBOARDED ? (
              <TableTagCell tags={[SOURCE_WEBSITE]} />
            ) : (
              <TableTagCell tags={[SOURCE_SALES_AGENT]} />
            ),
            <TableTextCell value={driver?.plan_type ?? '-'} />,
            <TableTextCell value={driver?.branch_name ?? '-'} />,
            driver?.latest_assessment_status ? (
              <TableTagCell tags={[driver?.latest_assessment_status]} />
            ) : (
              <TableTextCell value="-" />
            ),
            // Show the latest tag only
            <FlexLayout styled={{ marginRight: 16 }} gap={16}>
              {driver?.tag_type?.length && driver?.tag_type?.length > 0 ? (
                <>
                  <TableTagCell tags={driver?.tag_type?.slice(-1)} />
                  <ActionIcon
                    onClick={() => onSeeAllTagsClick(driver)}
                    icon={<BsArrowRightCircle size={24} color={SECONDARY_PURPLE_90} />}
                    tooltip="See all tags"
                  />
                </>
              ) : (
                <TableTextCell value="-" />
              )}
            </FlexLayout>,
            <TableTextCell value={moment(driver?.created_date)?.format('DD MMM YYYY')} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                icon={
                  driver?.driver_profile_status === COMPLETE_STATUS ? (
                    <BsPerson size={24} color={SECONDARY_PURPLE_90} />
                  ) : (
                    <BsPencil size={24} color={SECONDARY_PURPLE_90} />
                  )
                }
                tooltip={driver?.driver_profile_status === COMPLETE_STATUS ? 'View driver page' : 'Edit profile'}
                onClick={() => {
                  if (
                    driver.driver_profile_status &&
                    [COMPLETE_STATUS, DIGITAL_ONBOARDED].includes(driver?.driver_profile_status)
                  ) {
                    navigate(`${DRIVERS}/${driver?.id}`, { state: PROSPECTIVE_DRIVERS });
                  } else {
                    navigate(NEW_DRIVER, { state: { dvla_no: driver?.dvla_no } });
                  }
                }}
              />
              <ActionIcon
                icon={<BsArchive size={24} color={SECONDARY_PURPLE_90} />}
                tooltip="Archive profile"
                onClick={() => driver?.id && handleArchiveDriver(driver?.id)}
              />
              <ActionIcon
                disabled={isCreateOrderDisabled(driver?.driver_profile_status, driver?.latest_assessment_status)}
                icon={<BsPlusCircle size={24} color={SECONDARY_PURPLE_90} />}
                tooltip="Create order"
                onClick={() =>
                  navigate(CREATE_ORDER, { state: { dvla: driver?.dvla_no, prevRoute: PROSPECTIVE_DRIVERS } })
                }
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(driverRows);
      setTotalRows(count);
    },
    [setTotalRows, navigate, setTableData, handleArchiveDriver]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response?.data?.map((branch: Branch) => {
        return {
          value: branch?.branch_name,
          label: branch?.branch_name,
        };
      });
      setBranchOptions(branches);
    });
    setActiveSideNav('prosDriverListPage');
    setPageTitle('Prospective drivers');
    setSortingColumn('updated_date');
    setSortAscending(false);
  }, [setActiveSideNav, setSortAscending, setPageTitle, setSortingColumn, defaultString]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'driver_profile_status', options: selectedProfileStatus },
      { columnName: 'assessment_status', options: selectedAssessmentStatus },
      { columnName: 'branch_name', options: selectedSalesBranch },
      { columnName: 'driver.tag_type', options: tagFilters },
      {
        columnName: 'driver.created_date',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [
    dateRangeFilter,
    selectedAssessmentStatus,
    selectedProfileStatus,
    tagFilters,
    selectedSalesBranch,
    setTableFilters,
  ]);

  useEffect(() => {
    if (prospectDriversList) {
      handleGetDriversResponse(prospectDriversList.count, prospectDriversList.data);
    }
  }, [prospectDriversList, queryStringState, handleGetDriversResponse]);

  const onClearClick = useCallback(() => {
    setSelectedProfileStatus([]);
    setSelectedAssessmentStatus([]);
    setSelectedSalesBranch([]);
    setTagFilters([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    goToPageNumber(0);
    onClearClick();
  }, [goToPageNumber, onClearClick, refetch, defaultString, queryStringState]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isProspectDriversListFetching}
        header="Prospective driver list"
        actionButtonText="New driver"
        onActionButtonClick={() => navigate(NEW_DRIVER)}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={propectiveDriverListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={exportAllProspectDrivers}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        disableApply={invalidDates}
      />
      {selectedDriver && (
        <Modal
          styled={{ width: '80vw', minWidth: 400 }}
          title="Tags"
          open={isSeeAllTagsModalOpen}
          showClose
          onClose={() => {
            setIsSeeAllTagsModalOpen(false);
            setSelectedDriver(null);
          }}
        >
          <DriverTagsOrPenalties driver={selectedDriver} />
        </Modal>
      )}
      <ConfirmationModal
        title={'Are you sure you want to archive this driver profile?'}
        isOpen={isArchiveModalOpen}
        onClose={() => setIsArchiveModalOpen(false)}
        onConfirm={() => {
          setIsArchiveModalOpen(false);
          archiveDriver(activeDriverId!);
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
