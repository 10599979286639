import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import { GRAY_2, PRIMARY_GREEN, GRAY_9, PRIMARY_PURPLE, STATUS_RED } from '../../common/styles/Colors';

export const InputHeader = styled.div<{ $isRequired?: boolean }>`
  color: ${PRIMARY_PURPLE};
  font-size: 16px;
  font-weight: 500;
  margin: 12px 0 0 0 !important;
  line-height: 30px;

  ${(props) => {
    if (props.$isRequired) {
      return css`
        &:after {
          content: '*';
          position: relative;
          margin-left: 5px;
          color: ${STATUS_RED};
          font-size: 14px;
        }
      `;
    }
    return null;
  }}
`;

export const Hint = styled.div`
  margin-top: 5px;
  color: ${GRAY_9};
  font-size: 10px;
  font-weight: 400;
`;

export const ChipContainer = styled.div`
  margin: 0 0 0 0;
`;

export const ChipsHolder = styled.div`
  margin: 0;
`;

export const ChipInput = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  border: 0;
  outline: 0;
  padding: 0;
`;

export const Chip = styled(motion.div)`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  white-space: nowrap;
  padding: 0 16px;
  min-height: 24px;
  font-size: 10px;
  line-height: 16px;
  min-width: 70px;
  margin: 5px;
  color: ${PRIMARY_PURPLE};
  border: 1px solid ${PRIMARY_PURPLE};
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  svg {
    margin-left: 5px;
    transition: transform 0.2s ease-in-out;
  }

  &:hover {
    svg {
      transform: rotate(180deg);
    }
  }
`;

export const Icon = styled.span`
  color: ${GRAY_9};
  margin: 0 5px;
`;

export const Input = styled.div`
  display: flex;
  align-items: center;
  position: relative;
`;

export const Suggestions = styled.div`
  position: absolute;
  top: 101%;
  left: 0%;
  width: 100%;
  max-height: 250px;
  overflow: auto;
  background-color: white;
  border: 1px solid ${GRAY_2};
`;

export const Suggestion = styled.div`
  padding: 15px;
  border-bottom: 1px solid ${GRAY_2};
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  &:hover {
    background-color: ${PRIMARY_GREEN};
    color: white;
  }
  &:last-child {
    border-bottom: 0;
  }
`;

export const Validation = styled.div`
  width: 100%;
  color: grey;
  font-size: 12px;
  line-height: auto;
  margin: 0;
  padding-left: 5px;
  margin-bottom: 1%;
  overflow: visible;
  white-space: wrap;
`;
