import api from '../../utils/api';
import { DriverAdditionalDetails } from '../../models/driver';
import { ApplicationPayload } from '../../models/application';

/**
 * Set Plan Request
 * @category API/PATCH
 * @param {object} applicationId
 * @param {object} payload data for plan
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  application_id: '',
 *  application_type: '',
 * }
 * setPlan(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const setPlan = (
  applicationId: string,
  payload: { application_type: string }
): Promise<{ data: { id: string } }> => api.patch(`application/${applicationId}/set-plan`, payload);

/**
 * Update application request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} payload - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 *  has_uber_clean_confirmed: true,
 * }
 * updateApplication(id, payload).then((res) => {
 *  // Promise resolved
 * });
 */

export const updateApplication = (id: string, payload: ApplicationPayload) => api.patch(`/application/${id}`, payload);

/**
 * Update Driver
 * @category API/POST
 * @param {string} id - Driver id to be updated
 * @param {object} payload - Data for the driver to be updated.
 * @return {Promise} Returns a promise for the api request
 * Update driver by driver id request
 * @category API/PATCH
 * @param {object} payload - Data api to update the driver with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 * ...fields
 * }
 * updateDriver(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const updateDriverAdditional = (id: string, payload: DriverAdditionalDetails) => {
  return api.patch(`application/additional/${id}`, payload);
};

/**
 * Update application vehicle source
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} payload - data to update
 * @return {Promise} Returns a promise for the api request
 * chnageApplicationVehicleSource(id, payload).then((res) => {
 *  // Promise resolved
 * });
 */

export const changeApplicationVehicleSource = (id: string, payload: { vehicle_source: string }) =>
  api.patch(`/application/changeApplicationVehicleSource/${id}`, payload);

export default {
  setPlan,
  updateApplication,
  updateDriverAdditional,
  changeApplicationVehicleSource,
};
