import { AgreementType } from './agreement';
import { VehicleSource } from './vehicle';

export interface OrdwayCustomerPaymentDetails {
  account_number?: string;
  expiry?: string;
}

export const GATEWAY_OTTO_CAR = 'Ottocar';
export const GATEWAY_LEUMI = 'Leumi';

export type PaymentGateway = 'Ottocar' | 'Leumi';

export interface UpdateOrdwayPaymentGatewayPayload {
  ordway_customer_id: string;
  gateway_account_name: PaymentGateway;
}

export interface ProductPlan {
  backcharge_current_period: boolean;
  billing_day: string;
  billing_period: string;
  billing_period_start_alignment: string;
  billing_schedule_id: string;
  charge_end_date: string;
  charge_id: string;
  charge_name: string;
  charge_start_date: string;
  charge_timing: string;
  charge_type: string;
  cmrr: string;
  current_period_end_date: string;
  current_period_start_date: string;
  plan_name: string;
  plan_id: string;
}

export interface OrdwaySubscription {
  Headline_Weekly_Price: string;
  Total_Contract_Value_after_EV_Discount: string;
  Total_EV_Assistance_Over_Contract_Period: string;
  Vehicle_Type: string;
  Weekly_EV_Assistance_Discount: string;
  Weekly_Rental_Less_EV_Assist: string;
  agreement_type: AgreementType;
  agreement_url: string;
  auto_renew: boolean;
  balloon_gross_value: string;
  balloon_net_value: string;
  balloon_net_vat_percentage: string;
  balloon_vat_value: string;
  baloonpaymentdate: string;
  bill_contact_id: string;
  bill_contact_sf_id: string;
  billing_start_date: string;
  cancellation_date: string;
  car_and_charge_weekly_price: string;
  cmrr: string;
  contract_effective_date: string;
  contract_term: string;
  contract_term_weeks: string;
  created_by: string;
  created_date: string;
  currency: string;
  current_term_end_date: string;
  current_term_start_date: string;
  customer_id: string;
  customer_name: string;
  defer_start_date: boolean;
  deposit: number;
  discounted_cmrr: string;
  driver_type: string;
  government_grant_value: string;
  id: string;
  leumi_capital_percent: string;
  leumi_interest_percent: string;
  list_price_ex_vat: string;
  net_agreement_value_after_ev_assist: string;
  net_capital_finance_by_leumi: string;
  net_purchase: number;
  net_purchase_price_otto: string;
  net_vat_after_balloon: string;
  net_weekly: string;
  notes: string;
  order_placed_at: string;
  original_interest: number;
  payment_terms: string;
  plans: ProductPlan[];
  renewal_increment_percent: number;
  renewal_term: string;
  separate_invoice: boolean;
  service_start_date: string;
  shipping_contact_id: string;
  shipping_contact_sf_id: string;
  status: string;
  subscription_status: string;
  tcv: number;
  total_contract_value: string;
  updated_by: string;
  updated_date: string;
  vat_amount: string;
  vat_percent: string;
  vat_weekly: string;
  vehicle_source: VehicleSource;
  vehicle_vin: string;
  vehicle_vrm: string;
  version: number;
  version_type: string;
}

export type OrdwayCustomerStatus = 'Active' | 'Inactive';
