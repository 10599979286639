import styled from 'styled-components';
import { FlexContainer } from '../../../../core/pageTitle/pageTitle.styles';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';

export const StyledSecondaryButton = styled(SecondaryButton)`
  margin-right: 10px;
`;

export const StyledTextField = styled(TextField)`
  flex: 1;
`;

export const CheckboxFlexContainer = styled(FlexContainer)`
  margin-top: 24px;
`;

export const DropdownContainer = styled(FlexContainer)`
  flex: 1;
`;
