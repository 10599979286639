import React, { useState, useContext, useEffect, useCallback } from 'react';
import { BsPencil, BsTrash } from 'react-icons/bs';
import { MdPlaylistAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { EditVehicleTypeForm } from './editVehicleTypeForm';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { vehicleTypeColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../../uiComponents/table/tableFilters/tableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Notification } from '../../../../uiComponents/toast/toast';
import { deleteVehicleType } from '../../../../api/delete/vehicleType.delete';
import { getAllVehicleTypes } from '../../../../api/get/vehicleType.get';
import { ModifiedVehicleType } from '../../../../models/vehicle';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../../utils/context';
import { OptionList } from '../../../../utils/props';
import { useListAndMergeVehicleTypesListQuery } from '../../../../api/listAndMerge/listAndMergeVehicleTypesApiSlice';
import { VEHICLE_TYPES } from '../../../../consts/routes';

export const VehicleTypeList = () => {
  const navigate = useNavigate();
  const [isEditModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [vehicleTypeValues, setVehicleTypeValues] = useState<ModifiedVehicleType | null>(null);
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedModel, setSelectedModel] = useState<OptionList[]>([]);
  const [selectedModelYear, setSelectedModelYear] = useState<OptionList[]>([]);
  const [selectedFuelType, setSelectedFuelType] = useState<OptionList[]>([]);
  const [selectedColour, setSelectedColour] = useState<OptionList[]>([]);
  const [vehicleTypes, setVehicleTypes] = useState<ModifiedVehicleType[]>();
  const [selectedId, setSelectedId] = useState<string>();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_date:ASC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: vehicleTypesList,
    isFetching: isVehicleTypesListFetching,
    refetch,
  } = useListAndMergeVehicleTypesListQuery({ query: queryStringState, refetch: refetchData });

  const allColours: OptionList[] = [];
  for (const i of vehicleTypes ?? []) {
    for (const j of i.colors) {
      allColours?.push({ value: j, label: j });
    }
  }

  const handleGetVehicleTypeResponse = useCallback(
    (count: number, lists: ModifiedVehicleType[]) => {
      const handleEditVehicleTypeModal = (row: ModifiedVehicleType) => {
        const edit = row;
        setEditModalOpen(!isEditModalOpen);
        setVehicleTypeValues(edit);
      };
      const handleDeleteVehicleType = (row: ModifiedVehicleType) => {
        // get vehicle type id
        const { id } = row;
        setSelectedId(id);
        setIsDeleteConfirmationModalOpen(true);
      };
      const vehicleTypeList = lists?.map((list: ModifiedVehicleType) => {
        return {
          rowData: { data: list },
          cells: [
            <TableTextCell value={list.manufacturer} />,
            <TableTextCell value={list.model} />,
            <TableTextCell value={list.spec} />,
            <TableTextCell value={list.model_year} />,
            <TableTextCell value={list.colors.map((col) => col).join(', ')} />,
            <TableTextCell value={list.fuel_type} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() =>
                  navigate(`/app/fleet/vehicle-types/packages/${list.id}`, {
                    state: { queryParams: list, prevRoute: VEHICLE_TYPES },
                  })
                }
                icon={<MdPlaylistAdd color={PRIMARY_PURPLE} size={24} />}
                tooltip="Manage Pricing"
              />
              <ActionIcon
                onClick={() => handleEditVehicleTypeModal(list)}
                icon={<BsPencil color={PRIMARY_PURPLE} size={24} data-tip="Edit" />}
                tooltip="Edit"
              />
              <ActionIcon
                onClick={() => {
                  handleDeleteVehicleType(list);
                }}
                icon={<BsTrash color={PRIMARY_PURPLE} size={24} data-tip="Delete" />}
                tooltip="Delete"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehicleTypeList);
      setTotalRows(count);
    },
    [isEditModalOpen, navigate, setTableData, setTotalRows]
  );

  const manufacturers: OptionList[] = [
    ...new Map(vehicleTypes?.map((item) => [item['manufacturer'], item])).values(),
  ]?.map((vehicleType: ModifiedVehicleType) => {
    return { value: vehicleType.manufacturer, label: vehicleType.manufacturer };
  });

  const models: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model, label: vehicleType.model };
    }
  );

  const modelYears: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model_year'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model_year, label: vehicleType.model_year };
    }
  );

  const fuelTypes: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['fuel_type'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.fuel_type, label: vehicleType.fuel_type };
    }
  );

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Select a manufacturer"
          options={manufacturers}
          multiValues={selectedManufacturer}
          title="Manufacturer:"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model',
      element: (
        <DropDownFilter
          name="model"
          placeholder="Select a model"
          multiValues={selectedModel}
          options={models}
          title="Model:"
          onChange={(items) => setSelectedModel(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model_year',
      element: (
        <DropDownFilter
          name="model_year"
          placeholder="Select a model year"
          multiValues={selectedModelYear}
          options={modelYears}
          title="Model year:"
          onChange={(items) => setSelectedModelYear(items as OptionList[])}
        />
      ),
    },
    {
      name: 'fuel_type',
      element: (
        <DropDownFilter
          name="fuel_type"
          placeholder="Select a fuel type"
          multiValues={selectedFuelType}
          options={fuelTypes}
          title="Fuel type:"
          onChange={(items) => setSelectedFuelType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'colour',
      element: (
        <DropDownFilter
          name="colour"
          placeholder="Colour"
          multiValues={selectedColour}
          options={allColours}
          title="Colour"
          onChange={(items) => setSelectedColour(items as OptionList[])}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('vehicleTypesListPage');
    setPageTitle('Vehicle Types');
    setSortingColumn('created_date');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'manufacturer', options: selectedManufacturer },
      { columnName: 'model', options: selectedModel },
      { columnName: 'model_year', options: selectedModelYear },
      { columnName: 'fuel_type', options: selectedFuelType },
      { columnName: 'colors', options: selectedColour },
    ]);
  }, [setTableFilters, selectedManufacturer, selectedModel, selectedModelYear, selectedFuelType, selectedColour]);

  useEffect(() => {
    if (vehicleTypesList) {
      handleGetVehicleTypeResponse(vehicleTypesList.count, vehicleTypesList.data);
      setVehicleTypes(vehicleTypesList.data);
    }
  }, [vehicleTypesList, queryStringState, handleGetVehicleTypeResponse]);

  const onClearClick = useCallback(() => {
    setSelectedManufacturer([]);
    setSelectedModel([]);
    setSelectedModelYear([]);
    setSelectedFuelType([]);
    setSelectedColour([]);
  }, []);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  const handleConfirm = async () => {
    await deleteVehicleType(selectedId ? selectedId : '').then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Vehicle type has been successfully deleted',
      });
      refetchToFirstPage();
      setIsDeleteConfirmationModalOpen(false);
    });
  };

  const closeEditModal = (param?: string): void => {
    setEditModalOpen(!isEditModalOpen);
    if (param === 'close') {
      refetchToFirstPage();
    }
  };

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isVehicleTypesListFetching}
        header="Vehicle type list"
        actionButtonText="Create vehicle type"
        onActionButtonClick={() => {
          navigate('/app/fleet/vehicle-types/create', { state: VEHICLE_TYPES });
        }}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={vehicleTypeColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        filterQuery={filterQuery}
        filters={filters}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        downloadApi={getAllVehicleTypes}
        downloadName="Vehicle types"
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
      />
      <Modal
        title="Edit vehicle type"
        open={isEditModalOpen}
        onClose={closeEditModal}
        showClose
        styled={{ minWidth: 800, width: '80vw' }}
      >
        {vehicleTypeValues ? <EditVehicleTypeForm close={closeEditModal} values={vehicleTypeValues} /> : <div></div>}
      </Modal>
      <ConfirmationModal
        title="Are you sure that you want to delete this vehicle type?"
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        preConfirm={() => handleConfirm()}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
