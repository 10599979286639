import { MdKeyboardArrowDown } from 'react-icons/md';
import { GRAY_5 } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import { GreyPanel } from '../order/order.styles';

interface DisabledPanelProps {
  title: string;
  subTitle: string;
}

export const DisabledPanel = ({ title, subTitle }: DisabledPanelProps) => {
  return (
    <GreyPanel itemsX="space-between" itemsY="center">
      <div>
        <Text variant="body7" color={GRAY_5} weight={300} block>
          {title}
        </Text>
        <Text variant="body7" color={GRAY_5} weight={300} block>
          {subTitle}
        </Text>
      </div>
      <MdKeyboardArrowDown size={24} color={GRAY_5} />
    </GreyPanel>
  );
};
