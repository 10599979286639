import styled, { css } from 'styled-components';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextInput } from '../../../../uiComponents/inputs/textInput/textInput';
import {
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_PURPLE_70,
  STATUS_RED,
} from '../../../../common/styles/Colors';
import { darken } from '../../../../utils/colorUtils';

export const Form = styled.form`
  margin: 0 32px;
`;

export const DVLAContainer = styled.div`
  position: relative;
  flex: 0 0 448px;
  background-color: ${PRIMARY_PURPLE};
  padding: 30px 32px;
  border-radius: 16px;
`;

export const InputLabel = styled.label<{ $light: boolean }>`
  display: block;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 8px;
  color: ${(props) => (props.$light ? PRIMARY_WHITE : PRIMARY_PURPLE)};
  font-weight: 500;

  &:after {
    content: '*';
    position: absolute;
    margin-left: 5px;
    color: ${STATUS_RED};
    font-size: 14px;
  }
`;

export const DvlaInput = styled(TextInput)`
  height: 48px;
  padding: 10px 16px;
  width: 100%;

  &::placeholder {
    font-size: 16px;
  }
`;

export const PenaltyInputContainer = styled(FlexLayout)`
  margin-top: 16px;
  div:first-child {
    flex: 1;
  }
`;

export const SectionContainer = styled.div<{ $disabled: boolean }>`
  flex: 1;

  ${(props) => {
    if (props.$disabled) {
      return css`
        opacity: 0.3;
        pointer-events: none;
      `;
    }
  }}
`;

export const PanelHeader = styled.span`
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
`;

export const FieldCount = styled.span`
  font-size: 12px;
  font-weight: 300;
  line-height: 1;
  color: ${PRIMARY_PURPLE};
`;

export const PanelTitle = styled.h1`
  display: block;
  font-weight: 800;
  color: ${PRIMARY_PURPLE};
  font-size: 20px;
  line-height: 1;
  margin: 0;
  margin-bottom: 20px;
`;

export const QuestionText = styled.span`
  font-size: 16px;
  line-height: 22px;
  font-weight: 300;
  color: ${SECONDARY_PURPLE_70};
`;

export const AnswerText = styled.label`
  display: block;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
  margin-left: 8px;
  cursor: pointer;
`;

export const QuestionContainer = styled.div`
  margin-top: 28px;
`;

export const HowDidYouHearAboutUs = styled.div`
  margin-top: 16px;
  border-radius: 16px;
  padding: 26px 36px;
  background-color: ${PRIMARY_WHITE};
`;

export const SelectedPenaltyCodes = styled.div`
  margin-top: 8px;
`;

export const CodeName = styled.span`
  margin-left: 8px;
`;

export const SalesAgentText = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: ${PRIMARY_PURPLE};
`;

export const AgentUserName = styled.div`
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
`;

export const AgentIconText = styled.div`
  margin-left: 14px;
`;

export const StyledRadioInput = styled.input<{ $label: string }>`
  border-radius: 50px;
  height: 40px;
  border: 1px solid;
  border-color: ${PRIMARY_PURPLE};
  color: ${PRIMARY_PURPLE};
  font-weight: 500;
  font-size: 14px;
  text-align: center;
  background-color: ${PRIMARY_WHITE};
  box-shadow: none;
  outline: none;
  appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  flex: 1;
  transition: background-color 0.3s ease-in;

  &:hover:not(:checked) {
    background-color: ${darken(PRIMARY_WHITE, 5)};
  }

  &:checked {
    border-color: ${PRIMARY_GREEN};
    color: ${PRIMARY_WHITE};
    background-color: ${PRIMARY_GREEN};
  }

  &:after {
    content: '${(props) => props.$label}';
  }

  &:disabled {
    opacity: 0.3;
    cursor: not-allowed;
  }
`;

export const SearchButton = styled(FlexLayout)`
  position: absolute;
  right: 40px;
  top: 60px;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;
