import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { Text } from '../../../../../uiComponents/text/text';
import { FieldError, useFormContext } from 'react-hook-form';
// import { ChecklistItem, sliderLevels, vehicleInformationItems } from '../../../../../consts/conditionReport';
import { ChecklistItem, sliderLevels, vehicleInformationItems } from '../../../../../consts/conditionReport';
import { ControlledSlider } from '../../../../../uiComponents/inputs/controlledSlider/controlledSlider';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Checkbox } from '../../../../../uiComponents/uiControls/checkbox/checkbox';
import { VehicleInformationWrapper } from './styles';

export function VehicleInformation() {
  const { formState, register } = useFormContext();
  const errors = formState.errors;

  return (
    <div>
      <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
        Vehicle Information
      </Text>
      <VehicleInformationWrapper>
        <ControlledSlider label="Oil level" sliderLevels={sliderLevels} name="oilLevel" />
        <ControlledSlider label="Screen wash level" sliderLevels={sliderLevels} name="screenWashLevel" />
        <ControlledSlider label="Fuel level" sliderLevels={sliderLevels} name="fuelLevel" />
        <TextField
          type="number"
          {...register('mileage')}
          label="Mileage"
          placeholder="Mileage"
          required
          error={errors.mileage as FieldError}
          styled={{ maxWidth: 260 }}
        />
      </VehicleInformationWrapper>
      <FlexLayout gap={24} styled={{ marginTop: 40 }} wrap>
        {vehicleInformationItems?.map((item: ChecklistItem) => (
          <FlexLayout gap={8} itemsY="center" key={item?.name}>
            <Checkbox {...register('checkList')} value={item.key} />
            <Text variant="body6" weight={500} color={PRIMARY_PURPLE} styled={{ whiteSpace: 'nowrap' }}>
              {item?.label}
            </Text>
            {item.aditionalInfo && (
              <span style={{ fontSize: '10px', color: PRIMARY_PURPLE }}>{item.aditionalInfo}</span>
            )}
          </FlexLayout>
        ))}
      </FlexLayout>
    </div>
  );
}
