import React from 'react';
import { FieldError } from 'react-hook-form';
import { TextFieldLabel, StyledDiv, StyledSpinner } from './textField.styles';
import { InputType, TextInput, TextInputProps } from '../textInput/textInput';
import { withStyledProps } from '../../../utils/colorUtils';

interface TextFieldProps extends TextInputProps {
  label?: string;
  placeholder?: string;
  type: InputType;
  align?: 'left' | 'center' | 'right';
  disabled?: boolean;
  error?: FieldError;
  required?: boolean;
  defaultValue?: string | number;
  value?: string | number;
  name: string;
  onValueChange?: (value: string) => void;
  labelStyle?: React.CSSProperties;
  loading?: boolean;
}

export const TextField = withStyledProps(
  React.forwardRef<HTMLInputElement, TextFieldProps>(
    (
      {
        label,
        placeholder,
        type = 'text',
        align = 'left',
        disabled,
        error,
        labelStyle,
        required,
        name,
        defaultValue,
        value,
        onValueChange,
        loading,
        ...props
      }: TextFieldProps,
      ref
    ) => {
      return (
        <StyledDiv {...props}>
          <TextFieldLabel style={labelStyle} $isRequired={required}>
            {label}
          </TextFieldLabel>
          <TextInput
            ref={ref}
            placeholder={placeholder}
            type={type}
            name={name}
            align={align}
            disabled={disabled || loading}
            defaultValue={defaultValue}
            value={value}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onValueChange?.(e?.target?.value)}
            error={error}
            styled={{ width: '100%', minHeight: 46, padding: '10px 16px' }}
          />
          {loading && <StyledSpinner />}
        </StyledDiv>
      );
    }
  )
);
