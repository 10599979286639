import React, { useCallback, useMemo, useState } from 'react';
import { FieldError, useFieldArray, useForm } from 'react-hook-form';
import { BsTrash } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { InputType } from '../../../../uiComponents/inputs/textInput/textInput';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Notification } from '../../../../uiComponents/toast/toast';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { updateVehiclePackage } from '../../../../api/patch/vehiclePackage.patch';
import { createVehiclePackage } from '../../../../api/post/vehiclePackage.post';
import { NewVehiclePackagePayload, VehiclePackage } from '../../../../models/vehicle';

interface PackagePricingParams {
  close: (param?: string) => void;
  type: string;
  vehiclePackage: VehiclePackage | null;
}

interface EditFields {
  label: string;
  name: keyof VehiclePackage;
  type: InputType;
  placeholder: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  validate?: { validation: (v: string) => boolean | string };
  required?: string;
  disabled?: boolean;
}
interface PriceInfo {
  deposit: number | string;
  weekly_amount: number | string;
}

export const PackagePricingForm = ({ close, type, vehiclePackage }: PackagePricingParams) => {
  const [loading, setLoading] = useState<boolean>(false);
  const location = useLocation();
  const { id: vehicleTypeId } = location.state.queryParams;
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<VehiclePackage & { id: string }>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues:
      type === 'edit'
        ? { ...vehiclePackage }
        : {
            package_name: '',
            total_weeks: '',
            balloon_value: '',
            prices: [{ deposit: '', weekly_amount: '' }],
          },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'prices', // unique name for your Field Array
  });

  const addPricing = () => {
    append({
      deposit: '',
      weekly_amount: '',
    });
  };

  const onSubmit = useCallback(
    (values: VehiclePackage & { id: string }): void => {
      setLoading(true);
      const { package_name, total_weeks, balloon_value, prices, id: packageId, package_type } = values;
      // prepare payload
      const payload: NewVehiclePackagePayload = {
        balloon_value: Number(balloon_value),
        package_name,
        package_type: package_type,
        total_weeks: Number(total_weeks),
        package_status: 'ACTIVE',
        prices: prices.map((price: PriceInfo) => ({
          deposit: Number(price.deposit),
          weekly_amount: Number(price.weekly_amount),
        })),
      };
      // request
      if (type === 'create') {
        createVehiclePackage(vehicleTypeId, payload).then(() => {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Vehicle package has been successfully created',
          });
          close('close');
        });
      }
      if (type === 'edit') {
        updateVehiclePackage(vehicleTypeId, packageId, payload)
          .then(() => {
            setLoading(false);
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Vehicle package has been successfully created',
            });
            close('close');
          })
          .catch(() => {
            setLoading(false);
            Notification({
              type: 'error',
              title: 'Error',
              message: 'Error creating vehicle package',
            });
          });
      }
    },
    [type, vehicleTypeId, close]
  );

  const createPackagePricingList = useMemo((): EditFields[] => {
    return [
      {
        label: 'Package name',
        name: 'package_name',
        type: 'text',
        placeholder: 'Type here',
        required: 'Package name cannot be an empty field',
      },
      {
        label: 'Total weeks',
        name: 'total_weeks',
        type: 'number',
        placeholder: 'Type here',
        required: 'Total weeks name cannot be an empty field',
      },
    ];
  }, []);

  const handleErrors = (pricing: EditFields) => {
    if (pricing) {
      return errors[pricing?.name];
    } else {
      return undefined;
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {type === 'create' && (
          <FlexLayout styled={{ margin: '15px 0px' }} itemsX="start" itemsY="start">
            <span style={{ marginRight: '10px', display: 'flex' }}>
              <RadioButton id={'package_type'} {...register('package_type')} value={'R2B'} defaultChecked={true} />
              <TextFieldLabel style={{ marginLeft: '10px' }}>R2B</TextFieldLabel>
            </span>
          </FlexLayout>
        )}
        <GridLayout template={3} gap={18}>
          {createPackagePricingList.map((pricing: EditFields, id: number) => (
            <div key={id}>
              <TextField
                label={pricing.label}
                {...register(pricing.name, {
                  required: pricing.required,
                })}
                required
                name={pricing.name}
                type={pricing.type}
                placeholder={pricing.placeholder}
                error={handleErrors(pricing) as FieldError}
              />
            </div>
          ))}
        </GridLayout>
        {fields.map((item, index) => (
          <GridLayout key={index} styled={{ marginTop: '15px' }} template={3} gap={18}>
            <>
              <TextField
                label="Deposit"
                {...register(`prices.${index}.deposit`, {
                  required: 'Deposit cannot be empty field',
                })}
                name={`prices[${index}].deposit`}
                type="number"
                placeholder="0000.00"
                required
                error={errors.prices && errors.prices[index] && errors?.prices[index]?.deposit}
              />
              <TextField
                label="Weekly amount"
                {...register(`prices.${index}.weekly_amount`, {
                  required: 'Weekly amount cannot be empty field',
                })}
                name={`prices[${index}].weekly_amount`}
                type="number"
                placeholder="0000.00"
                required
                error={errors.prices && errors.prices[index] && errors?.prices[index]?.weekly_amount}
              />
              {index !== 0 ? (
                <FlexLayout styled={{ marginTop: '15px' }} itemsX="start" itemsY="center">
                  <BsTrash style={{ cursor: 'pointer' }} onClick={() => remove(index)} />
                </FlexLayout>
              ) : (
                <div></div>
              )}
            </>
          </GridLayout>
        ))}
        <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: '15px' }}>
          <SecondaryButton
            isProcessing={loading}
            styled={{ marginRight: '10px', padding: '0px 38px' }}
            onClick={() => addPricing()}
          >
            Add another price
          </SecondaryButton>
          <SecondaryButton
            isProcessing={loading}
            styled={{ marginRight: '10px', padding: '0px 38px' }}
            onClick={() => close('cancel')}
          >
            Cancel
          </SecondaryButton>
          <PrimaryButton onClick={handleSubmit(onSubmit)} isProcessing={loading} styled={{ padding: '0px 38px' }}>
            Submit
          </PrimaryButton>
        </FlexLayout>
      </form>
    </>
  );
};
