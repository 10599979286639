import { styled } from 'styled-components';
import { Text } from '../../../../uiComponents/text/text';
import { GRAY_3, PRIMARY_GREEN, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../common/styles/Colors';

export const TransferCard = styled.div`
  border-radius: 16px;
  padding: 32px;
  width: 440px;
  background-color: ${PRIMARY_PURPLE};
`;

export const Vrm = styled.div`
  position: relative;
`;

export const Label = styled(Text)`
  margin-top: 24px;

  &:after {
    content: '*';
    position: absolute;
    font-size: 14px;
    margin-left: 4px;
    color: ${PRIMARY_GREEN};
  }
`;

export const Reasons = styled.div`
  margin-top: 12px;
`;

export const ReasonRadio = styled.input<{ $label: string }>`
  appearance: none;
  padding: 2px 16px;
  border-radius: 24px;
  border: 1px solid ${PRIMARY_WHITE};
  margin-right: 4px;
  cursor: pointer;
  margin-bottom: 8px;
  transition: border-color 0.2s ease-in;
  width: auto;
  min-width: auto;

  &:after {
    content: '${(props) => props.$label}';
    font-size: 12px;
    color: ${PRIMARY_WHITE};
    line-height: 1;
    white-space: nowrap;
    transition: color 0.2s ease-in;
  }

  &:checked {
    background-color: ${PRIMARY_WHITE};

    &:after {
      color: ${PRIMARY_PURPLE};
    }
  }

  &:hover:not(:checked) {
    border-color: ${GRAY_3};
    &:after {
      color: ${GRAY_3};
      transition: color 0.2s ease-in;
    }
  }
`;
