import { createSelector } from '@reduxjs/toolkit';
import { DriverTag } from '../../models/driver';

import { TAG_ALL_IN_ORDER, TAG_LOW_RISK, TAG_NO_RISK, TAG_STATUS_UNRESOLVED } from '../../consts/driver';

export const selectHasUnresolvedBadTags = createSelector(
  (tags: DriverTag[] | undefined) => tags || [],
  (tags) => {
    if (!tags) return [];
    const hasUnresolvedBadTags = tags.some(
      (tag) =>
        ![TAG_ALL_IN_ORDER, TAG_NO_RISK, TAG_LOW_RISK].includes(tag.tag_name) &&
        tag.resolution_status === TAG_STATUS_UNRESOLVED
    );

    return hasUnresolvedBadTags;
  }
);
