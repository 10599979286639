import React from 'react';
import { StyledGridLayout } from './gridLayout.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { BaseProps, ChildrenProps } from '../../../utils/props';

export interface GridLayoutProps extends BaseProps, ChildrenProps {
  /**
   * Determines whether the grid should display inline.
   */
  inline?: boolean;
  /**
   * Specifies the number of rows or columns, using CSS grid-template-rows/columns, dictated by the direction prop. Also accepts a string in the normal
   * CSS grid format e.g. "1fr 2fr 1fr".
   */
  template: number | string;
  /**
   * Sets the CSS grid-gap property, which determines the gap between rows and columns
   */
  gap?: number | string;
  /**
   * Sets the grid-auto-flow CSS property to determine which direction the grid flows in. Controls the column/row count
   * set with the template
   */
  direction?: 'x' | 'y';
  /**
   * Sets the grid to include dense packing in grid-auto-flow, to fill empty spaces
   */
  dense?: boolean;
}

export const GridLayout = withStyledProps(({ inline, template, gap, direction, dense, ...props }: GridLayoutProps) => {
  return (
    <StyledGridLayout
      as="div"
      $inline={inline ?? false}
      $template={template}
      $gap={gap}
      $direction={direction ?? 'x'}
      $dense={dense}
      {...props}
    />
  );
});
