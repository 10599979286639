import { apiSlice } from '../../api/baseApi';
import { AftercareContact } from '../../models/aftercare';

interface GetAndMergeContactListParams {
  query: string;
  refetch: boolean;
}

interface GetAndMergeContactListResponse {
  count: number;
  data: AftercareContact[];
}

export const contactList = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAndMergeContactList: builder.query<GetAndMergeContactListResponse, GetAndMergeContactListParams>({
      query: ({ query }) => `/aftercare/contact-list${query && `?${query}`}`,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetAndMergeContactListQuery } = contactList;
