import { Assessment, DriverAssessmentResult, HistoricalAssessmentResponse } from '../../models/assessment';
import api from '../../utils/api';

/**
 * Get assessment request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAssessment(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAssessment = (id: string): Promise<{ data: Assessment }> => api.get(`assessment/${id}`);

/**
 * Get All assessment request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllAssessment(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getAllAssessments = (
  query: string = '',
  signal: AbortSignal
): Promise<{
  count: number;
  data: Assessment[];
}> => api.get(`assessment${query && `?${query}`}`, { signal });

/**
 * Get all assessment by Application request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAssessmentByApplication(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getAssessmentByApplication = (applicationId: string) => api.get(`assessment/${applicationId}/application`);

/**
 * Get all assessments for driver
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAssessmentByDriverId(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getHistoricalAssessmentsForDriver = (driverId: string): Promise<{ data: DriverAssessmentResult[] }> =>
  api.get(`assessment/${driverId}/historical_assessment_driver`);

/* Get all historical assessment by AssessmentId request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getHistoricalAssessment(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getHistoricalAssessment = (
  assessmentId: string | null | undefined
): Promise<{ data: HistoricalAssessmentResponse[] }> => api.get(`assessment/${assessmentId}/historical_assessment`);
