import { GenericAbortSignal } from 'axios';
import { Vehicle, BatchVehicle } from '../../models/vehicle';
import api from '../../utils/api';

/**
 * Get all vehicles request
 * @category API/GET
 * @return {Promise} Returns all vehicles
 * @example
 * getAllVehicles(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllVehicles = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Vehicle[] }> => api.get(`vehicle${query && `?${query}`}`, { signal });

/**
 * Export all vehicles to email
 * @category API/GET
 * @return {Promise} Returns all vehicles
 * @example
 * exportAllVehicles(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const exportAllVehicles = (query = '') => api.get(`vehicle/export${query && `?${query}`}`);

/**
 * Get all vehicles for phv and motrequest
 * @category API/GET
 * @return {Promise} Returns all vehicles
 * @example
 * getAllVehiclesForPHVAndMOT(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllVehiclesForPHVAndMOT = (
  query = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Vehicle[] }> => api.get(`vehicle/phv-mot${query && `?${query}`}`, { signal });

/**
 * Get all vehicles by batch id request
 * @category API/GET
 * @return {Promise} Returns vehicle list by batch id
 * @example
 * getAllVehiclesByBatchId(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllVehiclesByBatchId = (id: string): Promise<{ count: number; data: BatchVehicle[] }> =>
  api.get(`batch/${id}/vehicles`);

/**
 * Get all vehicle by vehicle id
 * @category API/GET
 * @return {Promise} Returns vehicle by vehicle id
 * @example
 * getVehicleById(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getVehicleById = (id: string): Promise<{ data: Vehicle }> => api.get(`vehicle/${id}`);

/**
 * Get all vehicle by vrm
 * @category API/GET
 * @return {Promise} Returns vehicle by vrm
 * @example
 * getVehicleByVrm(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getVehicleByVrm = (id: string): Promise<{ data: Vehicle }> => api.get(`vehicle/${id}`);

/**
 * Gets all vehicles that are assignable and applies query string filters found in input map.
 * @category API/GET
 * @return {Promise} Gets all assignable vehicles for evaluation
 * @example
 * getAllAssignableVehicles(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllAssignableVehicles = (
  vehicleTypeId?: string,
  query?: string,
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Vehicle[] }> =>
  api.get(`/vehicle/${vehicleTypeId}/get-all-assignable${query && `?${query}`}`, { signal });

/**
 * Get vehicle by vrm that contains status in paid off aftercare no sale or sold
 * @category API/GET
 * @return {Promise} Returns vehicle by vrm that contains status in paid off aftercare no sale or sold
 * @example
 * getVehicleByVrmAndStatus(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const getVehicleByVrmAndStatus = (vrm: string) => api.get(`/vehicle/vrm/${vrm}`);

/**
 * Get permission letter details
 * @category API/GET
 * @return {Promise} Returns permission letter details
 * @example
 * getPermissionLetterDetails(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getPermissionLetterDetails = (vehicleId: string) => api.get(`vehicle/getPolicyNumber/${vehicleId}`);

/**
 * send permission letter request
 * @category API/GET
 * @return {Promise} Returns email sent success message
 * @example
 * sendPermissionLetterRequest(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const sendPermissionLetterRequest = (applicationId: string) =>
  api.get(`vehicle/requestPermissionLetterGeneration/${applicationId}`);

/**
 * Get policy numbers with dates
 * @category API/GET
 * @return {Promise} Returns permission letter details
 * @example
 * getDatesByPolicyNumber(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getDatesByPolicyNumber = (policyNumber: string) =>
  api.get(`vehicle/getDatesByPolicyNumber/${policyNumber}`);

/**
 * Get mot expiry date of vehicle from dvsa
 * @category API/GET
 * @return {Promise} Returns permission letter details
 * @example
 * getDatesByPolicyNumber(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getVehicleMotExpiry = (id: string): Promise<{ data: { mot_exp: string } }> =>
  api.get(`vehicle/motExpiry/${id}`);

export const verifyVinExists = (vin: string) => api.get(`vehicle/availability/vin/${vin}`);
