import { styled } from 'styled-components';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../common/styles/Colors';

export const NavContainer = styled.div`
  position: absolute;
  left: 0;
  width: 184px;
  border-top-right-radius: 18px;
  border-bottom-right-radius: 18px;
  background-color: ${PRIMARY_PURPLE};
  padding: 24px;
`;

export const Separator = styled.div`
  height: 1px;
  margin-top: 24px;
  margin-bottom: 24px;
  background-color: ${PRIMARY_GREEN};
`;

export const TextLink = styled(Text)`
  cursor: pointer;
  white-space: nowrap;
`;
