import styled from 'styled-components';
import { SECONDARY_PURPLE_5, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';

export const SelectTemplateRoot = styled.div`
  background: ${SECONDARY_PURPLE_5};
  border-radius: 16px;
  margin-bottom: 32px;
`;

export const SelectTemplateRow = styled.div`
  padding: 10px 28px;
  border-top: 1px solid ${SECONDARY_PURPLE_30};
`;

export const SelectTemplateHeader = styled.div`
  padding: 26px 0px 11px 76px;
`;
