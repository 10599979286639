import styled, { css } from 'styled-components';
import { MdKeyboardArrowUp } from 'react-icons/md';
import { FlexLayout } from '../flexLayout/flexLayout';
import { PRIMARY_WHITE, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';

export const Panel = styled.div<{ $expanded: boolean; $completed: boolean; $disabled: boolean; $locked?: boolean }>`
  position: relative;
  display: flex;
  border-radius: ${(props) => (props.$expanded ? '16px' : '100px')};
  padding: ${(props) => (props.$expanded ? '22px 32px' : '14px 32px 14px 36px')};
  background-color: ${(props) => (props.$completed && !props.$expanded ? SECONDARY_PURPLE_30 : PRIMARY_WHITE)};
  cursor: ${(props) => (props.$expanded || props.$locked ? 'default' : 'pointer')};
  min-height: 68px;

  ${(props) => {
    if (props.$disabled) {
      return css`
        opacity: 0.3;
        pointer-events: none;
      `;
    }
    return null;
  }}
`;

export const ExpandedHeader = styled(FlexLayout)<{ $expanded: boolean }>`
  position: absolute;
  display: ${(props) => (props.$expanded ? 'flex' : 'none')};
  height: 40px;
  top: 0;
  left: 0;
  width: 100%;
  cursor: pointer;
`;

export const StyledArrowUp = styled(MdKeyboardArrowUp)`
  position: absolute;
  right: 32px;
  top: 22px;
`;

export const Content = styled.div<{ $expanded: boolean }>`
  display: ${(props) => (props.$expanded ? 'block' : 'none')};
  width: 100%;
`;
