import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { AnswerText, QuestionText, PanelTitle, QuestionContainer } from '../createNewDriver/createNewDriver.styles';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { Text } from '../../../../uiComponents/text/text';
import { STATUS_RED } from '../../../../common/styles/Colors';
import { CustomerProfile } from '../../../../models/driver';

interface AnswerProps {
  id: string;
  answer: 'Yes' | 'No';
  name: string;
  value: 'yes' | 'no';
  defaultChecked: boolean;
  register: UseFormRegister<CustomerProfile>;
}

interface QuestionProps {
  question: string;
  name: string;
  savedAnswer: 'yes' | 'no' | null;
  errors?: FieldErrors<CustomerProfile>;
  register: UseFormRegister<CustomerProfile>;
}

export const Answer = ({ id, answer, name, value, defaultChecked, register }: AnswerProps) => {
  return (
    <FlexLayout itemsY="center">
      <RadioButton
        id={id}
        disabled={false}
        value={value}
        defaultChecked={defaultChecked}
        {...register(name, { required: 'Please select an option.' })}
      />
      <AnswerText htmlFor={id}>{answer}</AnswerText>
    </FlexLayout>
  );
};

export const Question = ({ question, name, savedAnswer, errors, register }: QuestionProps) => {
  return (
    <div>
      <FlexLayout itemsX="space-between" itemsY="center" styled={{ marginTop: 15 }}>
        <QuestionText>{question}</QuestionText>
        <FlexLayout gap={30}>
          <Answer
            id={`${name}_yes`}
            answer="Yes"
            value="yes"
            name={name}
            defaultChecked={savedAnswer === 'yes'}
            register={register}
          />
          <Answer
            id={`${name}_no`}
            answer="No"
            value="no"
            name={name}
            defaultChecked={savedAnswer === 'no'}
            register={register}
          />
        </FlexLayout>
      </FlexLayout>
      {errors?.[name] && (
        <Text variant="body8" color={STATUS_RED} weight={300}>
          {errors?.[name]?.message as string}
        </Text>
      )}
    </div>
  );
};

export const LiabilityQuestionnaire = ({
  register,
  savedAnswers,
  errors,
}: {
  register: UseFormRegister<CustomerProfile>;
  savedAnswers?: boolean[] | null;
  errors?: FieldErrors<CustomerProfile>;
}) => {
  return (
    <>
      <PanelTitle>Liability questionnaire</PanelTitle>
      <QuestionContainer>
        <Question
          register={register}
          savedAnswer={savedAnswers?.[0] != null ? (savedAnswers?.[0] ? 'yes' : 'no') : null}
          question="Do you suffer from diabetes or use insulin?"
          name="insulin"
          errors={errors}
        />
        <Question
          savedAnswer={savedAnswers?.[1] != null ? (savedAnswers?.[1] ? 'yes' : 'no') : null}
          register={register}
          question="Do you wear glasses?"
          name="glasses"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[2] != null ? (savedAnswers?.[2] ? 'yes' : 'no') : null}
          question="Do you, or any immediate family members, suffer from epilepsy?"
          name="epilepsy"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[3] != null ? (savedAnswers?.[3] ? 'yes' : 'no') : null}
          question="Have you developed a medical condition in the last year?"
          name="medicalCondition"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[4] != null ? (savedAnswers?.[4] ? 'yes' : 'no') : null}
          question="Have you suffered from anxiety or depression?"
          name="anxietyDepression"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[5] != null ? (savedAnswers?.[5] ? 'yes' : 'no') : null}
          question="Do you have any type of sleep disorder?"
          name="sleepDisorder"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[6] != null ? (savedAnswers?.[6] ? 'yes' : 'no') : null}
          question="Have you been involved in an accident or made any insurance claim in the last 3 years, whether you fault or someone else's fault?"
          name="involvedAccident"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[7] != null ? (savedAnswers?.[7] ? 'yes' : 'no') : null}
          question="Have you been convicted of, or have any pending motor offences in the last 5 years?"
          name="motoringOffences"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers?.[8] != null ? (savedAnswers?.[8] ? 'yes' : 'no') : null}
          question="Have you been convicted of, or have any pending criminal convictions in the last 5 years?"
          name="criminalConvictions"
          errors={errors}
        />
      </QuestionContainer>
    </>
  );
};
