import api from '../../utils/api';

/**
 * Delete vehicle package request
 * @category API/DELETE
 * @param {object} id - Vehicle package id for the user to delete the package
 * @return {Promise} Returns a promise for the api request
 * @example

 * deleteVehiclePackage(id).then((res) => {
 *  // Promise resolved
 * });
 */

export const deleteVehiclePackage = (vehicleTypeId: string, packageId: string) => {
  return api.delete(`/vehicle-type/${vehicleTypeId}/package/${packageId}`);
};

export default {
  deleteVehiclePackage,
};
