import { GenericAbortSignal } from 'axios';
import { Note } from '../..//models/notes';
import api from '../../utils/api';

/**
 * Get all Notes request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllNotes(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllNotes = (query = '', signal: GenericAbortSignal): Promise<{ count: number; data: Note[] }> =>
  api.get(`note${query && `?${query}`}`, { signal });

/**
 * Get single note request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getNote(NoteId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getNote = (id: string): Promise<{ data: Note }> => api.get(`note${id}`);
