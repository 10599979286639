import { OptionList } from '../../../utils/props';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { DocumentCategory, DocumentType, InsuranceType, UploadDocumentPayload } from '../../../models/document';
import { DropDown } from '../../uiControls/dropDown/dropDown';
import { TextField } from '../../inputs/textField/textField';
import { UploaderInput } from '../../inputs/uploaderInput/uploaderInput';
import { useCallback, useState } from 'react';
import { fileNameTimeStampFormat, renderNotification } from '../../../utils/utils';
import { createDocument } from '../../../api/post/document.post';
import { TextFieldLabel } from '../../inputs/textField/textField.styles';
import { INSURANCE, insuranceTypes, POA, V5C, LICENSE_SUMMARY } from '../../../consts/document';
import { StyledDiv } from './document.styles';
import moment from 'moment';
import { getFileService } from '../../../api/cognito/file.service';

interface UploadDocumentForm extends FieldValues {
  document_file: File | Blob;
  document_type: DocumentType;
  document_expiry_date: string;
  insurance_type: InsuranceType;
}

interface UploadDocumentProps {
  documentTypes: OptionList[];
  categoryId: string;
  documentCategory: DocumentCategory;
  defaultDocumentType?: DocumentType;
  onClose: (fetchData?: boolean) => void;
}

export const UploadDocument = ({
  documentTypes,
  categoryId,
  documentCategory,
  defaultDocumentType,
  onClose,
}: UploadDocumentProps) => {
  const {
    control,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<UploadDocumentForm>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { document_type: defaultDocumentType, insurance_type: undefined, document_expiry_date: '' },
  });
  const [submitting, setSubmitting] = useState<boolean>(false);
  const documentType: DocumentType = watch('document_type');
  const fileService = getFileService();

  const onSubmit = useCallback(
    async (values: UploadDocumentForm) => {
      setSubmitting(true);
      const { document_type, document_file, document_expiry_date } = values;
      const fileNameWithTimeStamp = fileNameTimeStampFormat(documentCategory);
      const path = `DOCUMENTS/${documentCategory}/${categoryId}/${document_type}/${fileNameWithTimeStamp}`;

      let s3_url;
      try {
        await fileService.uploadFileToStorage({
          path,
          data: document_file,
        });
        s3_url = path;
      } catch (error: any) {
        renderNotification('error', 'Error', `${error.message}.`, true);
        setSubmitting(false);
        return;
      }

      let payload: UploadDocumentPayload = {
        category_id: categoryId,
        document_category: documentCategory,
        expiry_date: document_type !== V5C && document_type !== LICENSE_SUMMARY ? document_expiry_date : undefined,
        document_type,
        s3_url,
      };

      if (values.insurance_type) {
        payload = {
          ...payload,
          insurance_type: values.insurance_type,
        };
      }
      createDocument(payload)
        .then(() => {
          setSubmitting(false);
          onClose(true);
        })
        .catch(() => {
          setSubmitting(false);
        });
    },
    [categoryId, onClose, documentCategory, fileService]
  );

  return (
    <StyledDiv>
      {documentType !== LICENSE_SUMMARY && (
        <>
          <TextFieldLabel $isRequired>Document type</TextFieldLabel>
          <DropDown
            placeholder="Select document type"
            name="document_type"
            options={documentTypes}
            control={control as unknown as Control<FieldValues>}
            required={{ required: 'Please select a document type.' }}
            error={errors?.document_type}
          />
        </>
      )}
      {documentType === INSURANCE && (
        <StyledDiv>
          <TextFieldLabel $isRequired>Insurance type</TextFieldLabel>
          <DropDown
            placeholder="Select an insurance type"
            name="insurance_type"
            options={insuranceTypes}
            control={control as unknown as Control<FieldValues>}
            required={{ required: 'Please select an insurance type.' }}
            error={errors?.insurance_type}
          />
        </StyledDiv>
      )}
      {documentType !== V5C && documentType !== POA && documentType !== LICENSE_SUMMARY && (
        <TextField
          {...register('document_expiry_date', {
            required: 'Please provide an expiry date.',
            validate: {
              validateExpiryDate: (date: string) => {
                if (new Date(date) < new Date()) {
                  return 'Expiry date should be in the future';
                }
              },
            },
          })}
          required
          label="Document expiry date"
          type="date"
          name="document_expiry_date"
          error={errors?.document_expiry_date}
          styled={{ marginTop: 24 }}
        />
      )}
      {documentType === POA && (
        <TextField
          {...register('document_expiry_date', {
            required: 'Please provide an expiry date.',
            validate: {
              validateExpiryDate: (date: string | undefined) => {
                const isFuture: boolean = moment(date).isAfter(moment());
                const olderThan3Months: boolean = moment().diff(moment(date), 'months', true) > 3;
                if (isFuture || olderThan3Months) {
                  return 'Proof of address issue date should be within the last 3 months';
                }
              },
            },
          })}
          required
          label="Document expiry date"
          type="date"
          name="document_expiry_date"
          error={errors?.document_expiry_date}
          styled={{ marginTop: 24 }}
        />
      )}
      <UploaderInput
        required
        label="Document"
        control={control as unknown as Control<FieldValues>}
        name="document_file"
        error={errors?.document_file}
        styled={{ marginTop: 24 }}
      />
      <FlexLayout itemsX="end" styled={{ marginTop: 24 }} gap={16}>
        <SecondaryButton onClick={() => onClose()} disabled={submitting}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={submitting} onClick={handleSubmit(onSubmit)}>
          Upload
        </PrimaryButton>
      </FlexLayout>
    </StyledDiv>
  );
};
