import styled from 'styled-components';

export const MandatoryPhotosContainer = styled.div`
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, minmax(260px, 1fr));
  grid-template-rows: 2;
  gap: 24px 21px;
  margin-top: 32px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`;
