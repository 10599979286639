import { apiSlice } from '../../api/baseApi';
import { Driver } from '../../models/driver';

interface ListAndMergeProspectDriversParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeProspectDriversResponse {
  count: number;
  data: Driver[];
}

export const prospectDrivers = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeProspectDrivers: builder.query<ListAndMergeProspectDriversResponse, ListAndMergeProspectDriversParams>({
      query: ({ query }) => `prospect-drivers${query && `?${query}`}`,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useListAndMergeProspectDriversQuery } = prospectDrivers;
