// DEPENDENCIES
import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import { BsDownload, BsFilter, BsPlusCircleFill } from 'react-icons/bs';
import { FieldValues } from 'react-hook-form';
// COMPONENTS & STYLES
import { NoResults } from './noResults/noResults';
import { FilterItem, TableFilters } from './tableFilters/tableFilters';
import { PrimaryButton } from '../buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';
import { Spinner } from '../uiControls/spinner/spinner';
import { Text } from '../text/text';
import { TextInput } from '../inputs/textInput/textInput';
import {
  ColumnHeader,
  ExpandedRow,
  HeaderArea,
  Loading,
  StickyHeader,
  RowArrow,
  RowsContainer,
  SearchFilters,
  SearchIcon,
  TableActions,
  TableContainer,
  TableFooter,
  TableHeaders,
  TableRowGrid,
  SearchFilter,
  PreviousPageIcon,
  SelectRowsPerPage,
  FirstPageIcon,
  NextPageIcon,
  LastPageIcon,
  CountSpan,
} from './table.styles';
// HOOKS & UTILS & COMMONS
import { downloadXLSX } from './tableUtils/tableUtils';
import { CONGESTION_CHARGES, DART_CHARGES, EXCESS_MILEAGE } from '../../consts/additionalCharges';
import {
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_GRAY_20,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_70,
  STATUS_RED,
} from '../../common/styles/Colors';
import { AnyNotNull, withStyledProps } from '../../utils/colorUtils';
import { ConfirmationModal } from '../modals/confirmationModal/confirmationModal';
import { FaRegCheckCircle, FaRegTimesCircle } from 'react-icons/fa';
import { Checkbox } from '../../uiComponents/uiControls/checkbox/checkbox';
import { ScrollToTopButton } from '../../uiComponents/buttons/scrollToTopButton/scrollToTopButton';
import { useDebouncedCallback } from 'use-debounce';

interface TableColumn {
  id: string;
  name: string;
  sortable: boolean;
}

interface TableRow {
  rowData: { rowColour?: string; data: FieldValues };
  cells: JSX.Element[];
}

type DownloadMethod = 'email' | 'download';
export type TableVariant = 'standard' | 'compact';

interface TableProps {
  header?: string | JSX.Element;
  filters?: FilterItem[];
  columns: TableColumn[];
  rows?: TableRow[] | null;
  totalRows: number;
  filterQuery: string;
  rowsPerPage: number;
  currentPageNumber: number;
  sortAscending: boolean;
  sortingColumn?: string;
  disableApply?: boolean;
  downloadName?: string;
  variant?: TableVariant;
  tableTheme?: 'white' | 'purple';
  embedded?: boolean;
  getTableRowData?: (id: string) => Promise<JSX.Element>;
  onRowClick?: (row: any) => void;
  goToPage: (pageNumber: number) => void;
  dataDownloadMethod?: DownloadMethod;
  downloadApi?: (query: string) => Promise<{ count?: number; data: boolean | AnyNotNull }>;
  onSearchChange?: (value: string) => void;
  onApplyClick?: () => void;
  onClearClick?: () => void;
  onColumnHeaderClick: (columnName: string) => void;
  onNumRowsPerPageChange?: (value: number) => void;
  onPrimaryBtnClick?: () => void;
  onSecondaryBtnClick?: () => void;
  onTertiaryBtnClick?: () => void;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  tertiaryBtnText?: string;
  disableSecondaryBtn?: boolean;
  disableTertiaryBtn?: boolean;
  selectable?: boolean;
  onSelectAllClick?: (checked: boolean) => void;
  overflowY?: 'scroll' | 'hidden' | 'auto' | 'visible';
  maxHeight?: string;
  isLoading?: boolean;
  actionButtonText?: string;
  onActionButtonClick?: () => void;
  isInfitineScroll?: boolean;
}

export const Table = withStyledProps(
  ({
    header,
    columns,
    rows,
    totalRows,
    filters,
    filterQuery,
    rowsPerPage,
    currentPageNumber,
    sortAscending,
    sortingColumn,
    disableApply,
    dataDownloadMethod,
    downloadName,
    variant,
    tableTheme,
    embedded,
    getTableRowData,
    onRowClick,
    goToPage,
    downloadApi,
    onSearchChange,
    onApplyClick,
    onClearClick,
    onColumnHeaderClick,
    onNumRowsPerPageChange,
    onPrimaryBtnClick,
    onSecondaryBtnClick,
    onTertiaryBtnClick,
    onSelectAllClick,
    primaryBtnText,
    secondaryBtnText,
    tertiaryBtnText,
    disableSecondaryBtn,
    disableTertiaryBtn,
    selectable,
    isLoading,
    actionButtonText,
    onActionButtonClick,
    isInfitineScroll,
    ...props
  }: TableProps) => {
    const tableType: TableVariant = variant ?? 'standard';
    const onFirstPage: boolean = currentPageNumber === 0;
    const lastPageNumber: number = Math.ceil(totalRows / rowsPerPage) - 1;
    const onLastPage: boolean = currentPageNumber === lastPageNumber;
    const firstRowNumberInView: number = currentPageNumber * rowsPerPage;
    const totalRowsInView: number = firstRowNumberInView + rowsPerPage;
    const lastRowNumberInView: number = totalRowsInView > totalRows ? totalRows : totalRowsInView;
    const [showFilters, setShowFilters] = useState<boolean>(false);
    const [isDownloading, setIsDownloading] = useState<boolean>(false);
    const [confirmationModal, setConfirmationModal] = useState<JSX.Element>();

    const onDownloadClick = useCallback(() => {
      if (dataDownloadMethod === 'download') {
        setIsDownloading(true);
        downloadApi?.(filterQuery).then((response: { count?: number; data: any }) => {
          downloadXLSX(response.data, downloadName ?? 'new download');
          setIsDownloading(false);
        });
      } else {
        setIsDownloading(true);
        downloadApi?.(filterQuery)
          .then(() => {
            setConfirmationModal(
              <ConfirmationModal
                isOpen
                icon={<FaRegCheckCircle size={200} color={PRIMARY_GREEN} />}
                title="We emailed the export to your inbox."
                confirmButtonCaption="OK"
                onConfirm={() => setConfirmationModal(undefined)}
              />
            );
            setIsDownloading(false);
          })
          .catch(() => {
            setConfirmationModal(
              <ConfirmationModal
                isOpen
                icon={<FaRegTimesCircle size={200} color={STATUS_RED} />}
                title="There was a problem sending you the export."
                confirmButtonCaption="OK"
                onConfirm={() => setConfirmationModal(undefined)}
              />
            );
            setIsDownloading(false);
          });
      }
    }, [filterQuery, dataDownloadMethod, downloadName, downloadApi]);

    function scrollToHeader() {
      window.scroll({
        top: 0,
        behavior: 'smooth',
      });
    }

    function handleSortColumn(column: TableColumn) {
      if (!column.sortable) {
        return null;
      }
      scrollToHeader();
      onColumnHeaderClick(column.id);
    }

    const debouncedSearchInput = useDebouncedCallback((e: React.FocusEvent<HTMLInputElement>) => {
      scrollToHeader();
      if (onSearchChange) {
        onSearchChange(e.target.value);
      }
    }, 400);

    const observer = useRef<IntersectionObserver | null>();
    const lastRowElementRef = useCallback(
      (node: HTMLDivElement | null) => {
        if (isLoading || !rows) {
          return;
        }
        if (observer.current) {
          observer.current.disconnect();
        }

        observer.current = new IntersectionObserver((entries) => {
          if (entries[0].isIntersecting && !onLastPage && rows.length > 0) {
            goToPage(currentPageNumber + 1);
          }
        });

        if (node) observer.current.observe(node);
      },
      [isLoading, goToPage, currentPageNumber, onLastPage, rows]
    );

    return (
      <>
        <TableContainer $tableType={tableType} $theme={tableTheme} {...props}>
          <StickyHeader $stick={!embedded} $theme={tableTheme}>
            {tableType === 'standard' && (
              <>
                <HeaderArea>
                  {header}
                  {isInfitineScroll && (
                    <CountSpan>
                      {isLoading && <Spinner size={14} color={SECONDARY_PURPLE_70} styled={{ display: 'inline' }} />}
                      <Text variant="body8" color={PRIMARY_PURPLE} weight={300}>
                        {`${isLoading ? '' : totalRows}  results`}
                      </Text>
                    </CountSpan>
                  )}
                  <FlexLayout itemsX="space-between">
                    <SearchFilters itemsX="space-between" itemsY="center">
                      <FlexLayout gap={16} styled={{ postion: 'relative' }}>
                        {filters && filters?.length > 0 && (
                          <TableActions gap={8} onClick={() => setShowFilters(!showFilters)}>
                            <BsFilter size={16} color={SECONDARY_PURPLE_70} />
                            <Text variant="body7" color={SECONDARY_PURPLE_70} weight={300}>
                              Filter
                            </Text>
                          </TableActions>
                        )}
                        {downloadApi && (
                          <TableActions gap={8} onClick={() => (isDownloading ? null : onDownloadClick())}>
                            {isDownloading ? (
                              <Spinner size={16} color={SECONDARY_PURPLE_70} />
                            ) : (
                              <BsDownload size={16} color={SECONDARY_PURPLE_70} />
                            )}
                            <Text variant="body7" color={SECONDARY_PURPLE_70} weight={300}>
                              Download
                            </Text>
                          </TableActions>
                        )}
                        <TableFilters
                          hidden={!showFilters}
                          filters={filters ?? []}
                          disabledApply={disableApply}
                          onApplyClick={() => {
                            scrollToHeader();
                            onApplyClick?.();
                            setShowFilters(false);
                          }}
                          onClearClick={() => onClearClick?.()}
                          onCloseClick={() => setShowFilters(false)}
                        />
                      </FlexLayout>
                      {onSearchChange && (
                        <SearchFilter>
                          <SearchIcon size={16} color={SECONDARY_PURPLE_70} />
                          <TextInput onChange={(e) => debouncedSearchInput(e)} placeholder="Search" />
                        </SearchFilter>
                      )}
                    </SearchFilters>
                    <FlexLayout styled={{ marginTop: 32 }} itemsX="space-between">
                      {header && typeof header === 'string' && [CONGESTION_CHARGES, DART_CHARGES].includes(header) && (
                        <div>
                          {primaryBtnText && (
                            <SecondaryButton
                              styled={{ marginRight: 8 }}
                              onClick={() => onPrimaryBtnClick && onPrimaryBtnClick()}
                            >
                              {primaryBtnText}
                            </SecondaryButton>
                          )}
                          {secondaryBtnText && (
                            <PrimaryButton
                              styled={{ marginRight: 8 }}
                              onClick={() => onSecondaryBtnClick && onSecondaryBtnClick()}
                              disabled={disableSecondaryBtn}
                            >
                              {secondaryBtnText}
                            </PrimaryButton>
                          )}
                          {tertiaryBtnText && (
                            <PrimaryButton
                              onClick={() => onTertiaryBtnClick && onTertiaryBtnClick()}
                              disabled={disableTertiaryBtn}
                            >
                              {tertiaryBtnText}
                            </PrimaryButton>
                          )}
                        </div>
                      )}
                      {header && typeof header === 'string' && [EXCESS_MILEAGE].includes(header) && (
                        <div>
                          {primaryBtnText && (
                            <SecondaryButton
                              styled={{ marginRight: 8 }}
                              onClick={() => onPrimaryBtnClick && onPrimaryBtnClick()}
                            >
                              {primaryBtnText}
                            </SecondaryButton>
                          )}
                          {secondaryBtnText && (
                            <PrimaryButton
                              styled={{ marginRight: 8 }}
                              onClick={() => onSecondaryBtnClick && onSecondaryBtnClick()}
                              disabled={disableSecondaryBtn}
                            >
                              {secondaryBtnText}
                            </PrimaryButton>
                          )}
                          {tertiaryBtnText && (
                            <PrimaryButton
                              styled={{ marginRight: 8 }}
                              onClick={() => onTertiaryBtnClick && onTertiaryBtnClick()}
                              disabled={disableTertiaryBtn}
                            >
                              {tertiaryBtnText}
                            </PrimaryButton>
                          )}
                        </div>
                      )}
                      {actionButtonText && (
                        <div>
                          <PrimaryButton onClick={() => onActionButtonClick?.()}>
                            <FlexLayout gap={8}>
                              <BsPlusCircleFill color={PRIMARY_GREEN} size={16} />
                              <Text variant="body7" color={PRIMARY_WHITE} weight={500}>
                                {actionButtonText}
                              </Text>
                            </FlexLayout>
                          </PrimaryButton>
                        </div>
                      )}
                    </FlexLayout>
                  </FlexLayout>
                </HeaderArea>
              </>
            )}
            <TableHeaders
              $tableType={tableType}
              gap={8}
              template={
                selectable && rows && rows?.length > 0 ? `0.3fr ${'1fr '.repeat(columns.length)}` : columns.length
              }
            >
              {selectable && rows && rows?.length > 0 && (
                <ColumnHeader $isSortable={false} itemsY="center" styled={{ width: '30px' }}>
                  <Checkbox name="selectAll" onCheck={(checked) => onSelectAllClick && onSelectAllClick(checked)} />
                </ColumnHeader>
              )}
              {columns?.map((column: TableColumn, index: number) => (
                <ColumnHeader
                  key={`${column.id}_${index}`}
                  $isSortable={column.sortable}
                  onClick={() => handleSortColumn(column)}
                  itemsY="center"
                >
                  <Text
                    key={column.id}
                    color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                    variant="body7"
                    weight={500}
                  >
                    {column?.name}
                  </Text>
                  {column.sortable && sortingColumn === column.id && (
                    <>
                      {sortAscending ? (
                        <IoIosArrowUp
                          size={16}
                          color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                        />
                      ) : (
                        <IoIosArrowDown
                          size={16}
                          color={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_70 : PRIMARY_PURPLE}
                        />
                      )}
                    </>
                  )}
                </ColumnHeader>
              ))}
            </TableHeaders>
          </StickyHeader>
          <RowsContainer $theme={tableTheme} $overflowY={props.overflowY} $maxHeight={props.maxHeight}>
            <>
              {isInfitineScroll && isLoading && onFirstPage ? (
                <Loading itemsX="center">
                  <Spinner size={24} color={PRIMARY_PURPLE} />
                </Loading>
              ) : (
                // TODO: Remove when infinite scroll rolled out across all pages
                <>
                  {rows == null && (
                    <Loading itemsX="center">
                      <Spinner size={24} color={PRIMARY_PURPLE} />
                    </Loading>
                  )}
                </>
              )}
              {rows?.map((row: TableRow, index: number) => (
                <TableRow
                  key={`${row?.rowData?.data?.id}_${index}` ?? `${row?.rowData?.toString()}_${index}`}
                  row={row}
                  rowColour={tableTheme === 'purple' || embedded ? SECONDARY_PURPLE_30 : SECONDARY_GRAY_20}
                  onRowClick={onRowClick ? (data: AnyNotNull) => onRowClick?.(data) : undefined}
                  getTableRowExpandedData={getTableRowData}
                  selectable={selectable}
                />
              ))}
            </>
            {!isLoading && rows?.length === 0 && <NoResults />}
          </RowsContainer>
          {isInfitineScroll && !isLoading && <div ref={lastRowElementRef}></div>}
          <TableFooter
            $theme={tableTheme}
            $embedded={embedded}
            $tableType={tableType}
            itemsX={isInfitineScroll ? 'center' : 'space-between'}
            itemsY="center"
          >
            {isInfitineScroll ? (
              isLoading &&
              !onFirstPage && (
                <Loading itemsX="center">
                  <Spinner size={24} color={PRIMARY_PURPLE} />
                </Loading>
              )
            ) : (
              <>
                <FlexLayout itemsY="center" gap={8}>
                  <Text variant="body8" color={PRIMARY_PURPLE} weight={300}>
                    Rows per page
                  </Text>
                  <SelectRowsPerPage
                    $tableType={tableType}
                    onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                      onNumRowsPerPageChange && onNumRowsPerPageChange(+e.target.value)
                    }
                  >
                    <option value="50">50</option>
                    <option value="100">100</option>
                    <option value="150">150</option>
                    <option value="200">200</option>
                  </SelectRowsPerPage>
                </FlexLayout>
                <FlexLayout itemsX="space-between" itemsY="center">
                  {rows && rows?.length > 0 && (
                    <>
                      <Text styled={{ paddingRight: 20 }} variant="body8" color={PRIMARY_PURPLE} weight={300}>
                        {`Showing ${firstRowNumberInView + 1} - ${lastRowNumberInView} of ${totalRows}`}
                      </Text>
                      <FirstPageIcon
                        $disabled={onFirstPage}
                        onClick={() => (onFirstPage ? null : goToPage(0))}
                        size={20}
                      />
                      <PreviousPageIcon
                        $disabled={onFirstPage}
                        onClick={() => (onFirstPage ? null : goToPage(currentPageNumber - 1))}
                        size={20}
                      />
                      <NextPageIcon
                        $disabled={onLastPage}
                        onClick={() => (onLastPage ? null : goToPage(currentPageNumber + 1))}
                        size={20}
                      />
                      <LastPageIcon
                        $disabled={onLastPage}
                        onClick={() => (onLastPage ? null : goToPage(lastPageNumber))}
                        size={20}
                      />
                    </>
                  )}
                </FlexLayout>
              </>
            )}
          </TableFooter>
          {isInfitineScroll && <ScrollToTopButton />}
        </TableContainer>
        {confirmationModal}
      </>
    );
  }
);

interface TableRowProps {
  row: TableRow;
  rowColour: string;
  getTableRowExpandedData?: (id: string) => Promise<JSX.Element>;
  onRowClick?: (data: AnyNotNull) => void | null;
  selectable?: boolean;
}

const TableRow = ({ row, rowColour, getTableRowExpandedData, onRowClick, selectable }: TableRowProps) => {
  const [rowExpanded, setRowExpanded] = useState<boolean>(false);
  const [expandedContent, setExpandedContent] = useState<JSX.Element>();

  const handleExpandableRowClick = () => {
    setRowExpanded(!rowExpanded);
  };

  useEffect(() => {
    if (rowExpanded) {
      getTableRowExpandedData?.(row?.rowData?.data?.id).then((response: JSX.Element) => {
        setExpandedContent(response);
      });
    }
  }, [rowExpanded, getTableRowExpandedData, row]);
  return (
    <>
      <TableRowGrid
        $rowClickable={onRowClick != null || getTableRowExpandedData != null}
        $borderColur={rowColour}
        $rowColour={row?.rowData?.rowColour}
        gap={8}
        template={selectable ? `0.3fr ${'1fr '.repeat(row?.cells?.length - 1)}` : row?.cells?.length}
        onClick={() => (getTableRowExpandedData ? handleExpandableRowClick() : onRowClick?.(row?.rowData))}
      >
        {getTableRowExpandedData && <RowArrow color={PRIMARY_GREEN} size={16} $rowExpanded={rowExpanded} />}
        {row?.cells?.map((cell: JSX.Element, i: number) => <Fragment key={i}>{cell}</Fragment>)}
      </TableRowGrid>
      {rowExpanded && (
        <ExpandedRow>
          {expandedContent ? (
            expandedContent
          ) : (
            <FlexLayout itemsX="center" itemsY="center" styled={{ padding: '24px 0' }}>
              <Spinner size={24} color={PRIMARY_PURPLE} />
            </FlexLayout>
          )}
        </ExpandedRow>
      )}
    </>
  );
};
