import styled, { css } from 'styled-components';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';
import { darken } from '../../../utils/colorUtils';

export const StyledSecondaryButton = styled.button<{ $isProcessing?: boolean }>`
  min-height: 40px;
  min-width: 120px;
  background-color: ${PRIMARY_WHITE};
  color: ${PRIMARY_PURPLE};
  border: 1px solid ${PRIMARY_PURPLE};
  font-size: 14px;
  line-height: 16px;
  padding: 0 20px;
  border-radius: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease-in;

  ${(props) => {
    if (props.$isProcessing) {
      return css`
        pointer-events: none;
        cursor: default;
      `;
    }
    return null;
  }}

  &:hover:not(:disabled) {
    background-color: ${darken(PRIMARY_WHITE, 5)};
  }

  &:disabled {
    background-color: ${PRIMARY_WHITE};
    pointer-events: none;
    cursor: not-allowed;
    border: 1px solid ${SECONDARY_PURPLE_30};
    color: ${SECONDARY_PURPLE_30};
  }
`;
