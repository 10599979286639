import React from 'react';
import {
  Control,
  FieldErrors,
  FieldValues,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
  useFieldArray,
} from 'react-hook-form';
import { CheckContainer } from './checkSections.styles';
import { VehicleCheck } from './sections/vehicleCheck';
import { DriverTagsCheck } from './sections/driverTagsCheck';
import { DamageLogCheck } from './sections/damageLogCheck';
import { ConditionReportSignOff } from './sections/signOffCheck';
import { TERMINATED, TERMINATE_AND_SERVICE } from '../../../../consts';
import { ConditionReportFields, DamageReportItem, DamageResolutionItem } from '../../../../consts/conditionReport';
import { MandatoryPhotosCheck } from './sections/mandatoryPhotosCheck';
import { DamageLogReport } from './sections/damageLogReport/damageLogReport';
import { createDamageLog } from '../../../../api/post/conditionReport.post';
import { DamageLogPayload, DamageResolutionPayload, ImageArrayType } from '../../../../models/transfer';
import { editDamageLog, resolveDamageLog } from '../../../../api/patch/conditionReport.patch';
import { fileNameTimeStampFormat, renderNotification } from '../../../../utils/utils';
import moment from 'moment';
import { getFileService } from '../../../../api/cognito/file.service';
import { useAppSelector } from '../../../../store-hooks';

export interface CheckSectionsProps {
  control: Control<FieldValues>;
  disabled: boolean;
  errors: FieldErrors<ConditionReportFields>;
  uploadProgress: number;
  uploadingImages: boolean;
  reason: string | undefined;
  register: UseFormRegister<ConditionReportFields>;
  watch: UseFormWatch<ConditionReportFields>;
  previousMileage: number;
  conditionReportId: string | undefined;
  vehicle_id?: string;
  setValue: UseFormSetValue<ConditionReportFields>;
}

export const CheckSections = ({
  control,
  disabled,
  errors,
  uploadProgress,
  uploadingImages,
  reason,
  register,
  watch,
  previousMileage,
  conditionReportId,
  vehicle_id,
  setValue,
}: CheckSectionsProps) => {
  const { append, update } = useFieldArray({
    control,
    name: 'damage_logs',
  });
  const fileService = getFileService();

  const mandatoryPhotosChecklistComplete =
    watch('front') != null &&
    watch('back') != null &&
    watch('passengerSide') != null &&
    watch('driverSide') != null &&
    watch('chargeCables') != null &&
    watch('lockingNut') != null &&
    watch('repairKitTire') != null &&
    watch('dashboard') != null;

  const vehicleInformationComplete =
    watch('oilLevel') != null &&
    watch('oilLevel') !== '' &&
    watch('screenWashLevel') != null &&
    watch('screenWashLevel') !== '' &&
    watch('fuelLevel') != null &&
    watch('fuelLevel') !== '' &&
    watch('mileage') != null &&
    watch('mileage') !== '' &&
    watch('mileage') !== '0';

  const damageLogs = watch('damage_logs');
  const damageLogComplete = damageLogs != null && damageLogs.length > 0;

  const tagsChecklistComplete = watch('tags') !== undefined && watch('tags_note') !== '';
  const { transfer: transferData } = useAppSelector((state) => state.conditionReport);

  async function uploadImages(damageLogId: string, files: Array<File | Blob | ImageArrayType> | undefined) {
    const allImages: ImageArrayType[] = [];

    let index = 0;
    if (files && files.length > 0) {
      for await (const i of files) {
        if ('s3_url' in i) {
          allImages.push(i);
        } else {
          const item: File = i as File;
          const fileNameWithTimeStamp = fileNameTimeStampFormat(item.name);
          const path = `DOCUMENTS/CONDITION_REPORT/${vehicle_id}/${moment().format(
            'YYYY-MM-DD_HH-MM-SS'
          )}/DAMAGE_LOG/${damageLogId}/PHOTOS/${fileNameWithTimeStamp}`;

          let s3_url;
          try {
            const result = await fileService.uploadFileToStorage({
              path,
              data: item,
            });
            s3_url = result.path;
          } catch (error) {
            if (error instanceof Error) {
              renderNotification('error', 'Error', `${error.message}.`, true);
            } else {
              renderNotification('error', 'Error', `${error}.`, true);
            }
            return null;
          }

          allImages.push({
            fileName: `${damageLogId}_${index}`,
            type: item?.type,
            s3_url,
          });

          index += 1;
        }
      }
    }
    return allImages;
  }

  async function uploadResolutionImages(damageLogId: string, files: Array<File | Blob> | undefined) {
    const allImages: ImageArrayType[] = [];

    let index = 0;
    if (files && files.length > 0) {
      for await (const i of files) {
        const item: File = i as File;
        const fileNameWithTimeStamp = fileNameTimeStampFormat(item.name);
        const path = `DOCUMENTS/CONDITION_REPORT/${vehicle_id}/${moment().format(
          'YYYY-MM-DD_HH-MM-SS'
        )}/DAMAGE_LOG/${damageLogId}/RESOLUTION/PHOTOS/${fileNameWithTimeStamp}`;

        let s3_url;
        try {
          const result = await fileService.uploadFileToStorage({
            path,
            data: item,
          });
          s3_url = result.path;
        } catch (error) {
          if (error instanceof Error) {
            renderNotification('error', 'Error', `${error.message}.`, true);
          } else {
            renderNotification('error', 'Error', `${error}.`, true);
          }
          return null;
        }

        allImages.push({
          fileName: `${damageLogId}_${index}`,
          type: item?.type,
          s3_url,
        });

        index += 1;
      }
    }
    return allImages;
  }

  async function onAddDamageLog(submitValues: DamageReportItem) {
    const damageLogId = new Date().getTime();
    const images = await uploadImages(damageLogId.toString(), submitValues.files);

    if (images === null) {
      renderNotification('error', 'Error', 'Failed to upload some images.');
      return;
    }

    createDamageLog({
      condition_report_id: conditionReportId,
      damaged_type: submitValues.damaged_type,
      damaged_area: submitValues.damaged_area,
      damage_description: submitValues.damage_description,
      damage_detail: submitValues.damage_detail,
      images_folder: damageLogId.toString(),
      files: images,
    } as DamageLogPayload)
      .then(({ data }) => {
        renderNotification('success', 'Success', 'Damage log created successfully.');
        submitValues.id = data.id;
        append(submitValues);
      })
      .catch(() => {
        renderNotification('error', 'Error', 'There was a problem adding the damage log.');
      });
  }

  async function onEditDamageLog(index: number, submitValues: DamageReportItem) {
    const damageLogId = new Date().getTime();
    const images = await uploadImages(damageLogId.toString(), submitValues.files);

    if (images === null) {
      renderNotification('error', 'Error', 'Failed to upload some images.');
      return;
    }

    if (submitValues.id) {
      editDamageLog(submitValues.id, {
        condition_report_id: conditionReportId,
        damaged_type: submitValues.damaged_type,
        damaged_area: submitValues.damaged_area,
        damage_description: submitValues.damage_description,
        damage_detail: submitValues.damage_detail,
        images_folder: damageLogId.toString(),
        files: images,
      } as DamageLogPayload)
        .then(() => {
          renderNotification('success', 'Success', 'Damage log edited successfully.');
          update(index, submitValues);
        })
        .catch(() => {
          renderNotification('error', 'Error', 'There was a problem editing the damage log.');
        });
    }
  }

  async function onResolutionSubmit(index: number, submitValues: DamageResolutionItem, id?: string) {
    if (id) {
      resolveDamageLog({
        id: id,
        date: submitValues.date,
        supplier: submitValues.supplier,
        resolution: submitValues.resolution,
        photos: await uploadResolutionImages(id, submitValues.photos),
      } as DamageResolutionPayload)
        .then(() => {
          renderNotification('success', 'Success', 'Damage log resolved successfully.');
          if (damageLogs && damageLogs[index]) {
            damageLogs[index].resolved = true;
            update(index, damageLogs[index]);
          }
        })
        .catch(() => {
          renderNotification('error', 'Error', 'There was a problem resolving the damage log.');
        });
    }
  }

  return (
    <CheckContainer $disabled={disabled}>
      <MandatoryPhotosCheck
        isComplete={mandatoryPhotosChecklistComplete}
        control={control}
        errors={errors}
        setValue={setValue}
      />
      <VehicleCheck
        previousMileage={previousMileage}
        isComplete={vehicleInformationComplete}
        control={control}
        register={register}
        errors={errors}
        setValue={setValue}
      />
      {reason && [TERMINATED, TERMINATE_AND_SERVICE].includes(reason) && (
        <DriverTagsCheck
          control={control}
          register={register}
          isComplete={!!tagsChecklistComplete}
          errors={errors}
          setValue={setValue}
        />
      )}
      {transferData?.transfer_id === undefined && (
        <DamageLogCheck isComplete={damageLogComplete} onSubmit={onAddDamageLog} />
      )}
      {damageLogs &&
        damageLogs.map((damageLog, index) => {
          return (
            <DamageLogReport
              key={index}
              values={damageLog}
              onSubmit={(values) => onEditDamageLog(index, values)}
              onResolutionSubmit={(values) => onResolutionSubmit(index, values, damageLog.id)}
              resolved={damageLog.resolved}
            />
          );
        })}
      {transferData?.transfer_id === undefined && (
        <ConditionReportSignOff
          control={control}
          register={register}
          errors={errors}
          uploadProgress={uploadProgress}
          uploadingImages={uploadingImages}
          setValue={setValue}
        />
      )}
    </CheckContainer>
  );
};
