import { BsClipboard } from 'react-icons/bs';
import { DamageLogItem, DamageLogTitle, Wrapper } from './styles';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { DamageReportItem } from '../../../../../consts/conditionReport';
import { useState } from 'react';
import { Modal } from '../../../../../uiComponents/modals/modal';
import { ViewDamageLogItem } from '../viewDamageLogItem/viewDamageLogForm';

interface DamageLogListProps {
  damageLogs: DamageReportItem[];
}

export function DamageLogList({ damageLogs }: DamageLogListProps) {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [damageLog, setDamageLog] = useState<DamageReportItem | null>(null);

  return (
    <Wrapper>
      {damageLogs.map((damageLog, index) => {
        return (
          <DamageLogItem
            key={`${damageLog.damage_description} ${index}`}
            onClick={() => {
              setDamageLog(damageLog);
              setIsModalOpen(true);
            }}
          >
            <BsClipboard size={24} color={PRIMARY_PURPLE} />
            <DamageLogTitle>{`${damageLog.damaged_area} - ${damageLog.damage_description}`}</DamageLogTitle>
          </DamageLogItem>
        );
      })}
      <Modal
        title="Damage report"
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setDamageLog(null);
        }}
        showClose={true}
      >
        <>{damageLog && <ViewDamageLogItem damageLog={damageLog} />}</>
      </Modal>
    </Wrapper>
  );
}
