import {
  InitiateDataValidationPayload,
  PolicyPayload,
  PolicyVehicles,
  VehiclesPolicyPayload,
} from '../../models/insurancePolicy';
import api from '../../utils/api';

/**
 * Create insurance policy
 * @category API/POST
 * @param {object} policyData - Data for the policy creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * createInsurancePolicy(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createInsurancePolicy = (params: { body: PolicyPayload }): Promise<{ data: PolicyVehicles }> =>
  api.post('insurance/policy', params.body);

/**
 * Iniate data validation check
 * @category API/POST
 * @param {object} policyId - id of policy to initiate data check
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * initiateInsuranceDataValidation(id).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const initiateInsuranceDataValidation = (payload: InitiateDataValidationPayload): Promise<{ status: string }> =>
  api.post(`insurance/policy/${payload.id}/data-validation`, payload.body);

/**
 * Iniate insurance
 * @category API/POST
 * @param {object} policyId - id of policy to initiate data check
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * nitiateInsuranceRun(id).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

/**
 * Initiate Insurance Run
 * @category API/POST
 * @param {string} id - Policy ID
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * sendInsurancePolicyTestEmailTemplate(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const initiateInsuranceRun = (id: string, body: { template: number }): Promise<{ message: string }> =>
  api.post(`/insurance/policy/${id}/insurance-run`, body);

export const createInsurancePolicyVehicles = (
  id: string,
  body: VehiclesPolicyPayload
): Promise<{ data: VehiclesPolicyPayload }> => api.post(`/insurance/policy/${id}/insurance-policy-vehicles`, body);

/**
 * Send Insurance Policy Test Email Template
 * @category API/POST
 * @param {string} id - Policy ID
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * }
 * sendInsurancePolicyTestEmailTemplate(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const sendInsurancePolicyTestEmailTemplate = (
  id: string,
  body: { template: number }
): Promise<{ message: string }> => api.post(`/insurance/policy/${id}/send-test-emails-with-template`, body);
