import React, { useState } from 'react';
import { Icon, IconWrapper } from './actionIcon.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { IconTooltip } from './iconTooltip';

interface ActionIconProps {
  icon: JSX.Element;
  tooltip?: string;
  disabled?: boolean;
  onClick?: (e: React.MouseEvent) => void;
}

export const ActionIcon = withStyledProps(({ icon, tooltip, disabled, onClick, ...props }: ActionIconProps) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  return (
    <div>
      <Icon itemsX="center" itemsY="center" {...props}>
        <IconWrapper
          itemsX="center"
          itemsY="center"
          onClick={(e: React.MouseEvent) => {
            e.stopPropagation();
            if (!disabled) {
              onClick?.(e);
            }
          }}
          $disabled={disabled ?? false}
          onMouseOver={() => {
            window.setTimeout(() => {
              setShowTooltip(!disabled);
            }, 10);
          }}
          onMouseLeave={() =>
            window.setTimeout(() => {
              setShowTooltip(false);
            }, 10)
          }
        >
          {icon}
        </IconWrapper>
      </Icon>
      {tooltip && !disabled && showTooltip && <IconTooltip showTooltip={showTooltip} tooltip={tooltip} />}
    </div>
  );
});
