import { createContext, useContext } from 'react';
import { VehicleAgreementDetails } from '../../../../models/vehicle';
import { AgreementType } from '../../../../models/agreement';

export interface EvPackagesContextValue {
  vehicleDetails: VehicleAgreementDetails;
  carAndChargeBoolean: boolean;
  uberCleanAirAmount: number;
  applicationId: string;
  isCarAndChargeChecked: boolean;
  driverId: string;
  isUberCleanChecked: boolean;
  shellCardNumber?: string;
  applicationType: AgreementType;
  discountedWeeks: number;
  shellNumberError?: string | null;
  isRental: boolean;
  canSubmit: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const context = createContext<EvPackagesContextValue | null>(null);
export const { Provider: EvPackagesContext } = context;

export function useEvPackagesContext() {
  return useContext(context) ?? null;
}
