import React from 'react';
import { StyledSpinner } from './spinner.styles';
import { withStyledProps } from '../../../utils/colorUtils';
import { CssSize } from '../../../utils/props';

interface SpinnerProps {
  color?: string;
  size?: CssSize;
}

export const Spinner = withStyledProps(({ color, size, ...props }: SpinnerProps) => {
  return <StyledSpinner color={color} size={size} {...props} />;
});
