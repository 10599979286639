import { BsCheckCircle, BsExclamationCircle } from 'react-icons/bs';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { FiLock } from 'react-icons/fi';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';

interface SectionHeaderProps {
  title: string;
  isLocked: boolean;
  isComplete: boolean;
  completedText?: string;
  expanded: boolean;
}

export const SectionHeader = ({ title, isComplete, completedText, isLocked, expanded }: SectionHeaderProps) => {
  return (
    <FlexLayout itemsY="center" gap={16}>
      {isComplete ? (
        <BsCheckCircle color={PRIMARY_GREEN} size={32} />
      ) : isLocked ? (
        <FiLock color={PRIMARY_PURPLE} size={32} />
      ) : (
        <BsExclamationCircle color={PRIMARY_PURPLE} size={32} />
      )}
      <div>
        <Text variant={expanded ? 'body4' : 'body6'} color={PRIMARY_PURPLE} weight={expanded ? 800 : 500} block>
          {title}
        </Text>
        {isComplete && !expanded && (
          <Text variant="body7" weight={300} color={PRIMARY_GREEN} block>
            {completedText ?? ''}
          </Text>
        )}
      </div>
    </FlexLayout>
  );
};
