// DEPENDENCIES
import React, { useState, useEffect } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
// COMPONENTS & STYLES
import { FlexContainer } from '../../../../core/pageTitle/pageTitle.styles';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { Notification } from '../../../../uiComponents/toast/toast';
import { Checkbox, CheckboxLabel } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import {
  CheckboxFlexContainer,
  DropdownContainer,
  StyledSecondaryButton,
  StyledTextField,
} from './createEditBranchForm.styles';
// API CALLS & MODELS
import { updateBranch } from '../../../../api/patch/branch.patch';
import { createBranch } from '../../../../api/post/branch.post';
import { getAllCities } from '../../../../api/get/city.get';
import { City } from '../../../../models/city';
// UTILS & COMMONS
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { OptionList } from '../../../../utils/props';
import { addressFormat, branchNameFormat, mobileNumberFormat, ukPostCodeFormat } from '../../../../utils/validations';

interface BranchSubmitValues extends FieldValues {
  branch_name: string;
  branch_id: string;
  city_id: string;
  country_id: string;
  contact: string;
  address_line_1: string;
  address_line_2: string | null;
  post_code: string;
  has_sales: boolean;
  has_warranty: boolean;
  has_servicing: boolean;
  has_claims: boolean;
}

interface CreateEditBranchFormProps {
  isInEdit: boolean;
  values?: BranchSubmitValues | null;
  onFormSubmit: () => void;
  onClose: () => void;
}

const branchDetails = {
  branch_name: '',
  city_id: '',
  country_id: '',
  contact: '',
  address_line_1: '',
  address_line_2: null,
  post_code: '',
  has_warranty: false,
  has_sales: false,
  has_servicing: false,
  has_claims: false,
};

export const CreateEditBranchForm = ({ isInEdit, values, onClose, onFormSubmit }: CreateEditBranchFormProps) => {
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [cityOptionsLoading, setLoadingCityOptions] = useState<boolean>(true);
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
  } = useForm<BranchSubmitValues>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: isInEdit && values ? values : branchDetails,
  });

  const onSubmit = ({
    branch_name,
    branch_id,
    city_id,
    country_id,
    contact,
    address_line_1,
    address_line_2,
    post_code,
    has_sales,
    has_warranty,
    has_servicing,
  }: BranchSubmitValues) => {
    setIsFormSubmitting(true);
    const payload = {
      id: branch_id,
      body: {
        branch_name,
        city_id,
        has_sales,
        has_warranty,
        has_servicing,
        country_id,
        contact,
        address_line_1,
        address_line_2: address_line_2 || null,
        post_code,
        archived: false,
      },
    };
    const callback = isInEdit ? updateBranch : createBranch;

    callback(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Branch has been successfully updated',
          isAlert: true,
        });
        onFormSubmit();
      })
      .catch(() => Notification({ type: 'error', title: 'Error', message: 'Error creating city', isAlert: true }))
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  useEffect(() => {
    setLoadingCityOptions(true);
    getAllCities().then((results: { count: number; data: City[] }) => {
      const items = results.data.map((city: { city_id: string; city_name: string }) => {
        return { value: city.city_id, label: city.city_name.toUpperCase() };
      });
      setCityOptions(items);
      setLoadingCityOptions(false);
    });
  }, []);

  if (cityOptionsLoading) {
    return <Spinner size={20} color={PRIMARY_PURPLE} />;
  }

  return (
    <form>
      <GridLayout template={2} gap="32px 24px">
        <StyledTextField
          {...register('branch_name', {
            required: 'Branch name is a required field',
            pattern: { value: branchNameFormat, message: 'Please use only letters' },
          })}
          label="Branch name"
          error={errors.branch_name}
          type="text"
          placeholder="Branch name"
          required
        />
        <StyledTextField
          {...register('contact', {
            required: 'Contact details is a required field',
            pattern: { value: mobileNumberFormat, message: 'Please provide correct mobile number format' },
          })}
          label="Contact details"
          error={errors.contact}
          placeholder="Contact details"
          type="text"
          required
        />
        <StyledTextField
          {...register('address_line_1', {
            required: 'Address line 1 is a required field',
            pattern: { value: addressFormat, message: 'Please use only letters' },
          })}
          label="Address line"
          error={errors.address_line_1}
          type="text"
          placeholder="Address line"
          required
        />
        <StyledTextField
          {...register('address_line_2', {
            pattern: { value: addressFormat, message: 'Please use only letters' },
          })}
          label="Address Line 2"
          placeholder="Address line 2 (Optional)"
          type="text"
        />
        <DropdownContainer vertical>
          <TextFieldLabel $isRequired>{'City'}</TextFieldLabel>
          <DropDown
            name="city_id"
            options={cityOptions}
            placeholder="City"
            control={control as unknown as Control<FieldValues>}
          />
        </DropdownContainer>

        <StyledTextField
          {...register('post_code', {
            required: 'Post code is a required field',
            pattern: { value: ukPostCodeFormat, message: 'Please provide correct post code' },
          })}
          label="Post code"
          error={errors.post_code}
          placeholder="Post code"
          type="text"
          required
        />
      </GridLayout>
      <CheckboxFlexContainer gap={16}>
        <FlexContainer>
          <Checkbox {...register('has_sales')} />
          <CheckboxLabel label="Sales" />
        </FlexContainer>
        <FlexContainer>
          <Checkbox {...register('has_servicing')} />
          <CheckboxLabel label="Servicing" />
        </FlexContainer>
        <FlexContainer>
          <Checkbox {...register('has_warranty')} />
          <CheckboxLabel label="Warranty" />
        </FlexContainer>
        <FlexContainer>
          <Checkbox {...register('has_claims')} />
          <CheckboxLabel label="Claims" />
        </FlexContainer>
      </CheckboxFlexContainer>
      <FlexContainer itemsX="end">
        <StyledSecondaryButton onClick={() => onClose()}>Cancel</StyledSecondaryButton>
        <PrimaryButton isProcessing={isFormSubmitting} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexContainer>
    </form>
  );
};
