import { styled } from 'styled-components';

export const RouteContainer = styled.div`
  margin-top: 108px;
`;

export const Div = styled.div`
  position: absolute;
  top: 16px;
`;

export const ChildRouteContainer = styled.div`
  margin: 0 32px;
`;

export const Ref = styled.div`
  position: absolute;
  margin-top: -150px;
`;

export const PageContainer = styled.div`
  margin-left: 32px;
  margin-right: 32px;
  padding-bottom: 32px;
`;
