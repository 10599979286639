import { Document } from '../../models/document';
import api from '../../utils/api';

/**
 * Get single document request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getDocument(DocumentId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getDocument = (id: string = ''): Promise<{ data: Document }> => api.get(`document${id}`);

/**
 * Get all documents request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllDocuments(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllDocuments = (query: string = ''): Promise<{ count: number; data: Document[] }> =>
  api.get(`document${query && `?${query}`}`);
