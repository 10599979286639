import React, { useEffect, useRef, useState } from 'react';
import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';
import SignatureCanvas from 'react-signature-canvas';
import { PRIMARY_BLACK, PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { Text } from '../../text/text';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { withStyledProps } from '../../../utils/colorUtils';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { Container, ErrorText, SignatureBox } from './styles';

interface SignatureScreenProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  required: Omit<RegisterOptions<FieldValues, string>, 'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'>;
  control: Control<FieldValues>;
  name: TName;
  onSignatureSave?: (signature: string) => void;
  onConfirmSignature?: (isConfirmed: boolean) => void;
}

export const SignatureScreen = withStyledProps(
  <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    required,
    control,
    name,
    onSignatureSave,
    onConfirmSignature,
    ...props
  }: SignatureScreenProps<TFieldValues, TName>) => {
    const canvasRef = useRef<SignatureCanvas>(null);
    const canvasWidth = canvasRef?.current?.getCanvas()?.clientWidth;
    const canvasHeight = canvasRef?.current?.getCanvas()?.clientHeight;
    const [signed, setSigned] = useState<boolean>(false);
    const [cleared, setCleared] = useState<boolean>(false);
    const [hasContent, setHasContent] = useState<boolean>(false);

    const clear = (onChange: (value: string) => void) => {
      onChange('');
      canvasRef?.current?.clear();
      setSigned(false);
      setCleared(true);
      onConfirmSignature?.(false);
    };

    const save = (onChange: (value: string) => void) => {
      const signature = canvasRef?.current?.getTrimmedCanvas()?.toDataURL?.('image/png')?.split(',')?.[1];
      onChange(signature ?? '');
      onSignatureSave?.(signature ?? '');
      onConfirmSignature?.(true);
      setSigned(true);
    };

    useEffect(() => {
      window.setTimeout(() => {
        canvasRef?.current?.clear();
      }, 10);
    }, []);

    return (
      <Controller
        control={control}
        rules={required}
        name={name}
        render={({ field: { onChange }, fieldState: { error, invalid } }) => {
          return (
            <Container {...props}>
              <Text variant="body6" weight={500} color={PRIMARY_PURPLE} block>
                Signature
              </Text>
              <SignatureBox $hasError={invalid}>
                <SignatureCanvas
                  ref={canvasRef}
                  onBegin={() => {
                    setSigned(false);
                    setHasContent(true);
                  }}
                  onEnd={() => {
                    onConfirmSignature?.(false);
                    setCleared(false);
                  }}
                  penColor={PRIMARY_BLACK}
                  canvasProps={{
                    width: canvasWidth,
                    height: canvasHeight,
                  }}
                />
              </SignatureBox>
              {error && (
                <ErrorText variant="body8" color={STATUS_RED} weight={300}>
                  {error?.message as string}
                </ErrorText>
              )}
              <FlexLayout gap={8} styled={{ marginTop: 16 }}>
                <SecondaryButton styled={{ minWidth: 100 }} onClick={() => clear(onChange)}>
                  Clear
                </SecondaryButton>
                <PrimaryButton
                  styled={{ minWidth: 100 }}
                  onClick={() => save(onChange)}
                  disabled={signed || cleared || !hasContent}
                  isGreen
                >
                  {signed ? 'Signed' : 'Submit signature'}
                </PrimaryButton>
              </FlexLayout>
            </Container>
          );
        }}
      />
    );
  }
);
