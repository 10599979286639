import { useEffect, useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { SelectInput } from '../../../components/inputs/select-input/select-input';
import { Option } from '../../../components/ui/select/select';
import { TextInput } from '../../../components/inputs/text-input/text-input';
import { DatePicker } from '../../../components/inputs/date-pickers/date-picker/date-picker';
import { Button } from '../../../components/ui/button/button';
import { BsArrowLeft, BsFloppy, BsPencil, BsTrash, BsX } from 'react-icons/bs';
import { Switch } from '../../../components/ui/switch/switch';
import {
  useCancelContractMutation,
  useCreateContractMutation,
  useEditContractMutation,
  useGetContractTypeListQuery,
  useGetLocationListQuery,
  useGetReferenceDataListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
  useSaveContractDraftMutation,
} from '../../../api/contracts/contractsDetailsApiSlice';
import {
  ContractTypeListItem,
  LocationListItem,
  ManufacturerListItem,
  ModelListItem,
  ReferenceDataListItem,
} from '../../../models/contract';
import { Notification } from '../../../uiComponents/toast/toast';
import { useNavigate } from 'react-router-dom';
import { CONTRACTS, EDIT_CONTRACT, VIEW_CONTRACT } from '../../../consts/routes';
import { Dialog, DialogContent } from '../../../components/ui/dialog/dialog';

export interface ContractPayload {
  id: string;
  name: string;
  manufacturer: string;
  vehicle_model: string;
  contract_type_id: string;
  model_year: number;
  contract_length: number;
  vehicle_type: string;
  completes_inc_holidays: number;
  finance_length_years: number;
  total_weeks: number;
  deposit: number;
  bank_rate: number;
  profit_type: string;
  car_setup: number;
  extras: number;
  insurance_premium: number;
  insurance_excess: number;
  servicing_maintenance: number;
  mot: number;
  tracker: number;
  breakdown: number;
  misc: number;
  overheads: number;
  available_from: Date;
  comission: number;
  location: string;
  reference_data_id: string;
}

const optionSchema = z.object({
  value: z.string().min(1, 'Value is required'),
  label: z.string().min(1, 'Label is required'),
  info: z.string().optional(),
  node: z.any().optional(),
});

const contractFormSchema = z.object({
  id: z.string().optional(),
  name: z.string().min(1, 'Name is required'),
  manufacturer: z.array(optionSchema).nonempty('Manufacturer is required'),
  vehicle_model: z.array(optionSchema).nonempty('Model is required'),
  contract_type_id: z.array(optionSchema).min(1, 'Contract type is required'),
  model_year: z.array(optionSchema).nonempty('Model year is required'),
  contract_length: z.number().min(-1, 'Drivers term years is required'),
  vehicle_type: z.array(optionSchema).nonempty('Type of vehicle is required'),
  completes_inc_holidays: z.number().min(-1, 'Driver completes inc holidays is required'),
  finance_length_years: z.number().min(-1, 'Finance length years is required'),
  total_weeks: z.number().min(-1, 'Total weeks is required'),
  deposit: z.number().min(-1, 'Deposit is required'),
  bank_rate: z.number().min(-1, 'Bank rate is required'),
  profit_type: z.array(optionSchema).min(1, 'Profit type is required'),
  car_setup: z.number().min(-1, 'Car setup is required'),
  extras: z.number().min(-1, 'Extras is required'),
  insurance_premium: z.number().min(-1, 'Insurance premium is required'),
  insurance_excess: z.number().min(-1, 'Insurance excess is required'),
  servicing_maintenance: z.number().min(-1, 'Servicing maintenance is required'),
  mot: z.number().min(-1, 'Licensing is required'),
  tracker: z.number().min(-1, 'Tracker is required'),
  breakdown: z.number().min(-1, 'Breakdown is required'),
  misc: z.number().min(-1, 'Misc is required'),
  overheads: z.number().min(-1, 'Overheads is required'),
  available_from: z.date().optional(),
  commission: z.number().min(-1, 'Commission is required'),
  location: z.array(optionSchema).nonempty('Location is required'),
  reference_data_id: z.string().optional().nullable(),
  status: z.string().optional(),
});

export type ContractFormType = z.infer<typeof contractFormSchema>;

export const profitTypeList: Option[] = [
  { value: 'total_profit_percentage', label: 'Total Profit Percentage' },
  { value: 'yearly_profit_percentage', label: 'Yearly Profit Percentage' },
  { value: 'total_profit', label: 'Total Profit' },
  { value: 'yearly_profit', label: 'Yearly Profit' },
];

export const vehicleTypeList: Option[] = [
  { value: 'new', label: 'New' },
  { value: 'used', label: 'Used' },
];

export const modelYearList = (): Option[] => {
  const list = [] as Option[];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear; i >= currentYear - 10; i--) {
    list.push({ value: i?.toString(), label: i?.toString() });
  }
  return list;
};

export interface ContractFormProps {
  defaultValues?: ContractFormType;
  isEdit?: boolean;
  isViewOnly?: boolean;
}

export const ContractForm = ({ defaultValues, isEdit = false, isViewOnly = false }: ContractFormProps) => {
  const navigate = useNavigate();
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: modelList } = useGetVehicleModelListQuery({});
  const { data: contractTypeList } = useGetContractTypeListQuery({});
  const { data: locationList } = useGetLocationListQuery({});
  const { data: referenceData } = useGetReferenceDataListQuery({});
  const [saveDraft, { isLoading: saveDraftLoading }] = useSaveContractDraftMutation();
  const [createContract, { isLoading: createContractLoading }] = useCreateContractMutation();
  const [editContract, { isLoading: editContractLoading }] = useEditContractMutation();
  const [cancelContract, { isLoading: cancelContractLoading }] = useCancelContractMutation();

  const [useReferenceData, setUseReferenceData] = useState<boolean>(false);
  const [openCloseDialog, setOpenCloseDialog] = useState<boolean>(false);
  const [openCancelDialog, setOpenCancelDialog] = useState<boolean>(false);
  const isLoading = saveDraftLoading || createContractLoading || editContractLoading || cancelContractLoading;
  const [vehicleModelOptions, setVehicleModelOptions] = useState<Option[]>([]);
  const {
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors, isValid, isDirty },
  } = useForm<ContractFormType>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: zodResolver(contractFormSchema),
  });

  const manufacturers = watch('manufacturer');

  useEffect(() => {
    if (manufacturers && modelList?.data) {
      const manufacturersList = manufacturers?.map((el) => el.value);

      setVehicleModelOptions(
        modelList.data
          ?.filter((item: ModelListItem) => manufacturersList.includes(item.manufacturer_id))
          ?.map((item: ModelListItem) => {
            return { value: item.id, label: item.model };
          })
      );
      const vehicleModels = getValues('vehicle_model');
      if (vehicleModels) {
        const vehicleModelsList = modelList.data
          .filter((item: any) => vehicleModels?.map((el) => el.value).includes(item.id))
          ?.filter((item: any) => manufacturersList.includes(item.manufacturer_id))
          ?.map((item: any) => {
            return { value: item.id, label: item.model };
          });
        setValue('vehicle_model', vehicleModelsList, { shouldValidate: true, shouldDirty: true });
      }
    }
  }, [manufacturers, modelList?.data, getValues, setValue]);

  const handleChange =
    (field: keyof ContractFormType) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = event.target.value;
      if (field !== 'name') {
        setValue(field, parseFloat(value), { shouldValidate: true, shouldDirty: true });
      } else {
        setValue(field, value, { shouldValidate: true, shouldDirty: true });
      }
    };

  const handleSelectChange = (field: keyof ContractFormType) => (option: Option[]) => {
    setValue(field, option, { shouldValidate: true, shouldDirty: true });
    if (field === 'contract_type_id' && getValues().reference_data_id !== undefined) {
      handleReferenceData(true);
    }
  };

  const handleReferenceData = (checked: boolean) => {
    if (checked) {
      const contractType = getValues().contract_type_id?.[0]?.value;
      const refData: ReferenceDataListItem = referenceData.data.find(
        (item: ReferenceDataListItem) => item.contract_type_id === contractType
      );
      if (refData) {
        setValue('insurance_premium', refData.insurance_premium || 0, { shouldValidate: true, shouldDirty: true });
        setValue('insurance_excess', refData.insurance_excess || 0, { shouldValidate: true, shouldDirty: true });
        setValue('servicing_maintenance', refData.servicing_maintenance || 0, {
          shouldValidate: true,
          shouldDirty: true,
        });
        setValue('mot', refData.mot || 0, { shouldValidate: true, shouldDirty: true });
        setValue('tracker', refData.tracker || 0, { shouldValidate: true, shouldDirty: true });
        setValue('breakdown', refData.breakdown || 0, { shouldValidate: true, shouldDirty: true });
        setValue('misc', refData.misc || 0, { shouldValidate: true, shouldDirty: true });
        setValue('overheads', refData.overheads || 0, { shouldValidate: true, shouldDirty: true });
        setValue('commission', refData.commission || 0, { shouldValidate: true, shouldDirty: true });
        setValue('car_setup', refData.car_setup || 0, { shouldValidate: true, shouldDirty: true });
        setValue('extras', refData.extras || 0, { shouldValidate: true, shouldDirty: true });
        setValue('reference_data_id', refData.id?.toString(), { shouldValidate: true, shouldDirty: true });
      }
      setUseReferenceData(true);
    } else {
      setValue('reference_data_id', undefined, { shouldValidate: true, shouldDirty: true });
      setUseReferenceData(false);
    }
  };

  const onSubmit: SubmitHandler<ContractFormType> = (data) => {
    createContract({
      name: data.name,
      manufacturer: data.manufacturer?.map((val) => val.value),
      vehicle_model: data.vehicle_model?.map((val) => val.value),
      contract_type_id: data.contract_type_id?.[0]?.value,
      model_year: parseInt(data.model_year?.[0]?.value),
      contract_length: data.contract_length,
      vehicle_type: data.vehicle_type?.[0]?.value,
      completes_inc_holidays: data.completes_inc_holidays,
      finance_length_years: data.finance_length_years,
      total_weeks: data.total_weeks,
      deposit: data.deposit,
      bank_rate: data.bank_rate,
      profit_type: data.profit_type?.[0]?.value,
      car_setup: data.car_setup,
      extras: data.extras,
      insurance_premium: data.insurance_premium,
      insurance_excess: data.insurance_excess,
      servicing_maintenance: data.servicing_maintenance,
      mot: data.mot,
      tracker: data.tracker,
      breakdown: data.breakdown,
      misc: data.misc,
      overheads: data.overheads,
      available_from: data.available_from,
      commission: data.commission,
      location: data.location?.map((val) => val.value),
      reference_data_id: data.reference_data_id || null,
    })
      .unwrap()
      .then((result) => {
        Notification({
          type: 'success',
          title: 'Contract created',
          message: 'Your contract was created successfully',
        });
        navigate(VIEW_CONTRACT.replace(':id', result.data.id));
      })
      .catch((error) => {
        if (error.data.message.includes('unique_name')) {
          Notification({
            type: 'error',
            title: 'Error creating contract',
            message: `${error.status}: Name must be unique`,
          });
        } else {
          Notification({
            type: 'error',
            title: 'Error creating contract',
            message: `${error.status}: An error occurred while trying to create contract`,
          });
        }
      });
  };

  const onSaveDraft = () => {
    const data = getValues();
    saveDraft({
      id: data.id,
      name: data.name,
      manufacturer: data.manufacturer?.map((val) => val.value),
      vehicle_model: data.vehicle_model?.map((val) => val.value),
      contract_type_id: data.contract_type_id?.[0]?.value,
      model_year: parseInt(data.model_year?.[0]?.value),
      contract_length: data.contract_length,
      vehicle_type: data.vehicle_type?.[0]?.value,
      completes_inc_holidays: data.completes_inc_holidays,
      finance_length_years: data.finance_length_years,
      total_weeks: data.total_weeks,
      deposit: data.deposit,
      bank_rate: data.bank_rate,
      profit_type: data.profit_type?.[0]?.value,
      car_setup: data.car_setup,
      extras: data.extras,
      insurance_premium: data.insurance_premium,
      insurance_excess: data.insurance_excess,
      servicing_maintenance: data.servicing_maintenance,
      mot: data.mot,
      tracker: data.tracker,
      breakdown: data.breakdown,
      misc: data.misc,
      overheads: data.overheads,
      available_from: data.available_from,
      commission: data.commission,
      location: data.location?.map((val) => val.value),
      reference_data_id: data.reference_data_id || null,
    })
      .unwrap()
      .then((result) => {
        Notification({
          type: 'success',
          title: 'Contract draft saved',
          message: 'Your contract draft was saved successfully',
        });
        navigate(VIEW_CONTRACT.replace(':id', result.data.id));
      })
      .catch((error) => {
        if (error.data.message.includes('unique_name')) {
          Notification({
            type: 'error',
            title: 'Error saving contract',
            message: `${error.status}: Name must be unique`,
          });
        } else {
          Notification({
            type: 'error',
            title: 'Error saving contract',
            message: `${error.status}: An error occurred while trying to save contract draft`,
          });
        }
      });
  };
  const onEdit: SubmitHandler<ContractFormType> = (data) => {
    setOpenCloseDialog(false);
    editContract({
      id: data.id,
      name: data.name,
      manufacturer: data.manufacturer?.map((val) => val.value),
      vehicle_model: data.vehicle_model?.map((val) => val.value),
      contract_type_id: data.contract_type_id?.[0]?.value,
      model_year: parseInt(data.model_year?.[0]?.value),
      contract_length: data.contract_length,
      vehicle_type: data.vehicle_type?.[0]?.value,
      completes_inc_holidays: data.completes_inc_holidays,
      finance_length_years: data.finance_length_years,
      total_weeks: data.total_weeks,
      deposit: data.deposit,
      bank_rate: data.bank_rate,
      profit_type: data.profit_type?.[0]?.value,
      car_setup: data.car_setup,
      extras: data.extras,
      insurance_premium: data.insurance_premium,
      insurance_excess: data.insurance_excess,
      servicing_maintenance: data.servicing_maintenance,
      mot: data.mot,
      tracker: data.tracker,
      breakdown: data.breakdown,
      misc: data.misc,
      overheads: data.overheads,
      available_from: data.available_from,
      commission: data.commission,
      location: data.location?.map((val) => val.value),
      reference_data_id: data.reference_data_id || null,
    })
      .unwrap()
      .then((result) => {
        Notification({
          type: 'success',
          title: 'Contract updated',
          message: 'Your contract was updated successfully',
        });
        navigate(VIEW_CONTRACT.replace(':id', result.data.id));
      })
      .catch((error) => {
        if (error.data.message.includes('unique_name')) {
          Notification({
            type: 'error',
            title: 'Error updating contract',
            message: `${error.status}: Name must be unique`,
          });
        } else {
          Notification({
            type: 'error',
            title: 'Error updating contract',
            message: `${error.status}: An error occurred while trying to update the contract`,
          });
        }
      });
  };

  const onCancel = () => {
    setOpenCloseDialog(false);
    const data = getValues();
    cancelContract({
      id: data.id,
    })
      .unwrap()
      .then((result) => {
        Notification({
          type: 'success',
          title: 'Contract cancelled',
          message: 'Your contract was cancelled successfully',
        });
        navigate(VIEW_CONTRACT.replace(':id', result.data.id as string));
      })
      .catch((error) => {
        Notification({
          type: 'error',
          title: 'Error cancelling contract',
          message: `${error.status}: An error occurred while trying to cancelling contract`,
        });
      });
  };

  return (
    <div className="bg-white p-8 pb-0">
      <Button
        type="button"
        color="secondary"
        data-testid="back"
        onClick={() => {
          if (isDirty) {
            setOpenCloseDialog(true);
          } else {
            navigate(CONTRACTS);
          }
        }}
      >
        <BsArrowLeft />
        Back
      </Button>

      <div className="pt-8 pb-5 mb-14 border-solid border-b border-utility-gray-200">
        {isEdit ? (
          <div className="flex justify-between items-center">
            <div>
              <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
                Edit contract
              </span>
              <p className="text-sm font-light text-utility-gray-600" data-testid="title">
                ID: {getValues().id}
              </p>
            </div>
            <Button
              className="max-sm:hidden"
              type="button"
              color="secondary"
              data-testid="back-edit"
              onClick={() => {
                if (isDirty) {
                  setOpenCloseDialog(true);
                } else {
                  navigate(CONTRACTS);
                }
              }}
            >
              <BsX size="24" />
              Cancel Editing
            </Button>
          </div>
        ) : isViewOnly ? (
          <div className="flex justify-between items-center">
            <div>
              <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
                View contract
              </span>
              <p className="text-sm font-light text-utility-gray-600" data-testid="title">
                ID: {getValues().id}
              </p>
            </div>
            <Button
              className="max-sm:hidden"
              type="button"
              data-testid="edit"
              onClick={() => navigate(EDIT_CONTRACT.replace(':id', getValues().id as string))}
            >
              <BsPencil size="16" />
              Edit
            </Button>
          </div>
        ) : (
          <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
            Create new contract
          </span>
        )}
      </div>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4">
              <TextInput
                data-testid="name"
                label="Name"
                placeholder="Name"
                value={getValues().name}
                onChange={handleChange('name')}
                error={errors.name?.message}
                required
                disabled={isViewOnly}
              />
              <SelectInput
                data-testid="contract_type"
                label="Contract type"
                placeholder="Select from list"
                options={(contractTypeList?.data || [])?.map((item: ContractTypeListItem) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().contract_type_id}
                onSelect={handleSelectChange('contract_type_id')}
                error={errors.contract_type_id?.message}
                required
                disabled={isViewOnly}
              />
              <SelectInput
                data-testid="location"
                label="Location"
                placeholder="Select from list"
                options={(locationList?.data || [])?.map((item: LocationListItem) => {
                  return { value: item.city_id, label: item.city_name };
                })}
                value={getValues().location}
                onSelect={handleSelectChange('location')}
                error={errors.location?.message}
                required
                disabled={isViewOnly}
                multiple
              />
              <SelectInput
                data-testid="manufacturer"
                label="Manufacturer"
                placeholder="Select from list"
                options={(manufacturerList?.data || [])?.map((item: ManufacturerListItem) => {
                  return { value: item.id, label: item.name };
                })}
                value={getValues().manufacturer}
                onSelect={handleSelectChange('manufacturer')}
                error={errors.manufacturer?.message}
                required
                disabled={isViewOnly}
                multiple
              />
              <SelectInput
                data-testid="model"
                label="Model"
                placeholder="Select from list"
                options={vehicleModelOptions}
                value={getValues().vehicle_model}
                onSelect={handleSelectChange('vehicle_model')}
                error={errors.vehicle_model?.message}
                required
                disabled={isViewOnly}
                multiple
              />
              <SelectInput
                data-testid="model_year"
                label="Model year"
                placeholder="Select from list"
                options={modelYearList()}
                value={getValues().model_year}
                onSelect={handleSelectChange('model_year')}
                error={errors.model_year?.message}
                required
                disabled={isViewOnly}
              />
              <SelectInput
                data-testid="type_of_vehicle"
                label="Type of vehicle"
                placeholder="Select from list"
                options={vehicleTypeList}
                value={getValues().vehicle_type}
                onSelect={handleSelectChange('vehicle_type')}
                error={errors.vehicle_type?.message}
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="drivers_term_years"
                label="Driver's term years"
                placeholder="0"
                value={getValues().contract_length}
                onChange={handleChange('contract_length')}
                error={errors.contract_length?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="driver_completes_inc_holidays"
                label="Driver completes inc holidays"
                placeholder="0.00"
                value={getValues().completes_inc_holidays}
                onChange={handleChange('completes_inc_holidays')}
                error={errors.completes_inc_holidays?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="finance_length_years"
                label="Finance length years"
                placeholder="0"
                value={getValues().finance_length_years}
                onChange={handleChange('finance_length_years')}
                error={errors.finance_length_years?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="total_weeks"
                label="Total weeks"
                placeholder="0"
                value={getValues().total_weeks}
                onChange={handleChange('total_weeks')}
                error={errors.total_weeks?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="deposit"
                label="Deposit"
                placeholder="0000.00"
                value={getValues().deposit}
                onChange={handleChange('deposit')}
                error={errors.deposit?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <TextInput
                data-testid="bank_rate"
                fixedStartAdornment={<span className="h-full flex items-center">%</span>}
                label="Bank rate"
                placeholder="0"
                value={getValues().bank_rate}
                onChange={handleChange('bank_rate')}
                error={errors.bank_rate?.message}
                type="number"
                required
                disabled={isViewOnly}
              />
              <SelectInput
                data-testid="profit_type"
                label="Profit type"
                placeholder="Select profit type"
                options={profitTypeList}
                value={getValues().profit_type}
                onSelect={handleSelectChange('profit_type')}
                error={errors.profit_type?.message}
                required
                disabled={isViewOnly}
              />
              <DatePicker
                data-testid="available_from"
                label="Available from"
                placeholder="yyyy/mm/dd"
                value={getValues().available_from || undefined}
                onChange={(date) => setValue('available_from', date)}
                error={errors.available_from?.message}
                disabled={isViewOnly}
              />
            </div>
          </div>
          <div className="py-7 flex items-center gap-3">
            <Switch
              data-testid="reference_data"
              checked={getValues().reference_data_id !== undefined && getValues().reference_data_id !== null}
              disabled={
                isViewOnly ||
                !getValues().contract_type_id ||
                !referenceData ||
                !referenceData.data ||
                referenceData?.data?.length === 0
              }
              onCheckedChange={handleReferenceData}
            />
            <span className="text-base font-medium text-utility-gray-70">Use reference data for below fields?</span>
          </div>
          <div className="pb-12">
            <div className="grid lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-1 gap-4">
              <TextInput
                data-testid="insurance_premium"
                label="Insurance premium"
                value={getValues().insurance_premium}
                onChange={handleChange('insurance_premium')}
                error={errors.insurance_premium?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="insurance_excess"
                label="Insurance excess"
                value={getValues().insurance_excess}
                onChange={handleChange('insurance_excess')}
                error={errors.insurance_excess?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="servicing_maintenance"
                label="Servicing maintenance"
                value={getValues().servicing_maintenance}
                onChange={handleChange('servicing_maintenance')}
                error={errors.servicing_maintenance?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="licensing"
                label="Licensing, road tax & MOT"
                value={getValues().mot}
                onChange={handleChange('mot')}
                error={errors.mot?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="tracker"
                label="Tracker"
                value={getValues().tracker}
                onChange={handleChange('tracker')}
                error={errors.tracker?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="breakdown"
                label="Breakdown"
                value={getValues().breakdown}
                onChange={handleChange('breakdown')}
                error={errors.breakdown?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="misc"
                label="Misc"
                value={getValues().misc}
                onChange={handleChange('misc')}
                error={errors.misc?.message}
                placeholder="000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="overheads"
                label="Overheads"
                value={getValues().overheads}
                onChange={handleChange('overheads')}
                error={errors.overheads?.message}
                placeholder="0000"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="commission"
                label="Commission"
                value={getValues().commission}
                onChange={handleChange('commission')}
                error={errors.commission?.message}
                placeholder="0"
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="car_setup"
                label="Car setup"
                placeholder="0000.00"
                value={getValues().car_setup}
                onChange={handleChange('car_setup')}
                error={errors.car_setup?.message}
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
              <TextInput
                data-testid="extras"
                label="Extras"
                placeholder="0000.00"
                value={getValues().extras}
                onChange={handleChange('extras')}
                error={errors.extras?.message}
                type="number"
                required
                disabled={useReferenceData || isViewOnly}
              />
            </div>
          </div>

          {isEdit ? (
            <div className="w-full items-center flex justify-end gap-3 py-5 sticky bottom-0 bg-white border-solid border-t border-utility-gray-200">
              {defaultValues?.status !== 'CANCELLED' ? (
                <Button
                  type="button"
                  color="destructive"
                  data-testid="cancel"
                  disabled={isLoading}
                  onClick={() => setOpenCancelDialog(true)}
                  className="max-sm:w-full"
                >
                  <BsTrash />
                  Cancel contract
                </Button>
              ) : (
                <div></div>
              )}
              {defaultValues?.status !== 'ACTIVE' ? (
                <Button
                  type="button"
                  color="secondary"
                  data-testid="save"
                  disabled={!isDirty || isLoading}
                  onClick={onSaveDraft}
                  className="max-sm:w-full"
                >
                  <BsFloppy />
                  Save as Draft
                </Button>
              ) : (
                <div></div>
              )}
              <Button
                type="button"
                data-testid="update"
                disabled={!isDirty || !isValid || isLoading}
                onClick={handleSubmit(onEdit)}
                className="max-sm:w-full"
              >
                Update
              </Button>
            </div>
          ) : !isViewOnly ? (
            <div className="w-full items-center flex justify-end gap-3 py-5 sticky bottom-0 bg-white border-solid border-t border-utility-gray-200">
              <Button
                type="button"
                color="secondary"
                data-testid="save"
                disabled={!isDirty || isLoading}
                onClick={onSaveDraft}
                className="max-sm:w-full"
              >
                <BsFloppy />
                Save as Draft
              </Button>
              <Button type="submit" data-testid="submit" disabled={!isValid || isLoading} className="max-sm:w-full">
                Create
              </Button>
            </div>
          ) : isViewOnly ? (
            <div className="hidden max-sm:block w-full items-center flex justify-end gap-3 py-5 sticky bottom-0 bg-white border-solid border-t border-utility-gray-200">
              <Button
                className="w-full"
                type="button"
                data-testid="edit"
                onClick={() => navigate(EDIT_CONTRACT.replace(':id', getValues().id as string))}
              >
                <BsPencil size="16" />
                Edit
              </Button>
            </div>
          ) : null}
          <Dialog open={openCloseDialog} onOpenChange={setOpenCloseDialog}>
            <DialogContent className="max-w-[400px] max-sm:max-w-[90%]">
              <div>
                <div className="rounded-full flex bg-utility-warning-100 w-12 h-12 justify-center items-center mb-4">
                  <BsFloppy size="18" className="stroke-0.5 text-utility-warning-600" />
                </div>
                <p className="font-extrabold text-lg text-utility-gray-900 mb-1">Unsaved changes</p>
                <p className="font-light text-sm text-utility-gray-600 mb-8">Do you want to save or discard changes?</p>
              </div>
              <div className="flex gap-3">
                <Button
                  type="button"
                  color="secondary"
                  data-testid="discard"
                  size="md"
                  className="w-full"
                  onClick={() => navigate(CONTRACTS)}
                >
                  Discard
                </Button>
                <Button type="button" data-testid="save-update" size="md" className="w-full" onClick={onSaveDraft}>
                  Save and update
                </Button>
              </div>
            </DialogContent>
          </Dialog>

          <Dialog open={openCancelDialog} onOpenChange={setOpenCancelDialog}>
            <DialogContent className="max-w-[400px] max-sm:max-w-[90%]">
              <div>
                <div className="rounded-full flex bg-utility-error-100 w-12 h-12 justify-center items-center mb-4">
                  <BsTrash size="18" className="stroke-0.5 text-utility-error-600" />
                </div>
                <p className="font-extrabold text-lg text-utility-gray-900 mb-1">Cancel contract</p>
                <p className="font-light text-sm text-utility-gray-600 mb-8">
                  Are you sure you want to cancel this contract? This action cannot be undone.
                </p>
              </div>
              <div className="flex gap-3">
                <Button
                  type="button"
                  color="secondary"
                  data-testid="discard"
                  size="md"
                  className="w-full"
                  onClick={() => setOpenCancelDialog(false)}
                >
                  No! Go back
                </Button>
                <Button
                  type="button"
                  color="destructive"
                  data-testid="save-update"
                  size="md"
                  className="w-full"
                  onClick={onCancel}
                >
                  Confirm
                </Button>
              </div>
            </DialogContent>
          </Dialog>
        </form>
      </div>
    </div>
  );
};
