import { PRIMARY_GREEN, SECONDARY_PURPLE_30, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import styled from 'styled-components';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { lighten } from '../../../utils/colorUtils';

const getBorderColor = (active?: boolean, error?: boolean) => {
  if (active) {
    return PRIMARY_GREEN;
  }
  if (error) {
    return 'red';
  }
  return PRIMARY_GREEN;
};

export const UploaderContainer = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  justify-content: center;
  flex-direction: column;
  border: ${(props) => `1px dashed ${getBorderColor(props.$active, props.$error)}`};
  border-radius: 16px;
  display: flex;
  padding: 0 20px;
  height: 104px;
  margin-top: 10px;
  pointer-events: ${(props) => (props.$disabled ? 'none' : '')};

  &:focus,
  &:active {
    border: 1px dashed ${PRIMARY_GREEN};
  }
`;

export const UploadBackgroundImage = styled.img<{ $active?: boolean; $visible?: boolean }>`
  font-size: 14px;
  opacity: ${(props) => (props?.$active ? '100%' : '25%')};
  visibility: ${(props) => (props?.$visible ? 'visible' : 'hidden')};
  margin-left: 18px;
`;

export const UploaderTitle = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${SECONDARY_PURPLE_30};
`;

export const UploaderText = styled.span`
  display: block;
  color: ${SECONDARY_PURPLE_30};
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  width: 100px;
`;

export const UploadImageText = styled.div`
  margin-left: 18px;
`;

export const UploadLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
`;

export const IconContainer = styled(FlexLayout)`
  border-radius: 100px;
  width: 151px;
  height: 151px;
  border: 1px solid ${PRIMARY_PURPLE};
  background-color: ${SECONDARY_PURPLE_30};
`;

export const Image = styled.img`
  width: 150px;
  height: 150px;
  overflow: hidden;
  object-fit: cover;
  border-radius: 100px;
`;

export const FakeButton = styled(Text)`
  line-height: 1;
  background-color: ${PRIMARY_PURPLE};
  padding: 13px 32px;
  white-space: nowrap;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease-in;

  &:hover {
    background-color: ${lighten(PRIMARY_PURPLE, 15)};
  }
`;
