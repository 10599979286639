import React, { SyntheticEvent, useRef } from 'react';
import Select, { MultiValue, SingleValue } from 'react-select';
import {
  Filters,
  FilterGrid,
  CloseFiltersButton,
  FilterTitle,
  ApplyFilterButton,
  ClearFilterButton,
} from './tableFilters.styles';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { PRIMARY_PURPLE, PRIMARY_WHITE, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { OptionList } from '../../../utils/props';
import { IoClose } from 'react-icons/io5';
import { useInteractOutside } from '../../../hooks/useInteractOutside';
import { SelectedItems, filterDropdownStyles } from './filterDropdownStyles';
import { Tag } from '../../customComponents/tag/tag';
import { FilterDateInput } from '../table.styles';
import { BsCheckLg } from 'react-icons/bs';

export interface FilterItem {
  name: string;
  element: JSX.Element;
}

interface TableFilterProps {
  filters: FilterItem[];
  hidden: boolean;
  disabledApply?: boolean;
  onApplyClick: () => void;
  onClearClick: () => void;
  onCloseClick: () => void;
}

export const TableFilters = ({
  filters,
  hidden,
  disabledApply,
  onApplyClick,
  onClearClick,
  onCloseClick,
}: TableFilterProps) => {
  const ref = useRef<HTMLDivElement>(null);

  useInteractOutside(ref, (event: SyntheticEvent) => {
    if (!ref.current?.contains(event.target as HTMLDivElement)) {
      onCloseClick();
      event.stopPropagation();
      event.preventDefault();
    }
  });

  return (
    <Filters ref={ref} $hidden={hidden}>
      <FilterTitle>
        <Text variant="h4" color={PRIMARY_WHITE} weight={800}>
          Filter by:
        </Text>
        <CloseFiltersButton onClick={() => onCloseClick()}>
          <IoClose size={24} color={PRIMARY_WHITE} />
        </CloseFiltersButton>
      </FilterTitle>
      <FilterGrid gap={16} template={filters?.length}>
        {filters?.map((filter: FilterItem) => <div key={filter?.name}>{filter.element}</div>)}
      </FilterGrid>
      <FlexLayout itemsX="end" styled={{ marginTop: 16 }} gap={16}>
        <ClearFilterButton onClick={() => onClearClick()}>Clear all filters</ClearFilterButton>
        <ApplyFilterButton disabled={disabledApply} onClick={() => onApplyClick()}>
          <BsCheckLg color={PRIMARY_PURPLE} size={16} />
        </ApplyFilterButton>
      </FlexLayout>
    </Filters>
  );
};

interface DropDownFilterProps {
  title?: string;
  name: string;
  singleSelection?: boolean;
  placeholder: string;
  options: OptionList[];
  value?: SingleValue<string | OptionList> | MultiValue<string | OptionList>;
  defaultValue?: SingleValue<string | OptionList> | MultiValue<string | OptionList>;
  multiValues?: OptionList[];
  onChange: (value: SingleValue<string | OptionList> | MultiValue<string | OptionList>) => void;
}

export const DropDownFilter = ({
  title,
  name,
  singleSelection,
  placeholder,
  options,
  defaultValue,
  value,
  multiValues,

  onChange,
}: DropDownFilterProps) => {
  const removeItem = (item: OptionList) => {
    const vals: OptionList[] = multiValues?.slice() ?? [];
    const index = vals?.findIndex((i) => i?.value === item?.value);

    if (index != null && index >= 0) {
      vals?.splice(index, 1);
      onChange?.(vals);
    }
  };
  return (
    <div>
      {title && (
        <Text styled={{ marginBottom: 8 }} block variant="body6" color={PRIMARY_WHITE} weight={300}>
          {title}
        </Text>
      )}
      <Select
        isMulti={!singleSelection}
        hideSelectedOptions
        onChange={(option) => {
          onChange?.(option);
        }}
        components={{
          IndicatorSeparator: () => null,
        }}
        name={name}
        defaultValue={defaultValue}
        styles={filterDropdownStyles}
        placeholder={placeholder}
        options={options}
        value={singleSelection ? value : multiValues}
      />
      {multiValues && (
        <SelectedItems>
          {multiValues?.map((item: OptionList) => (
            <Tag key={item?.value} color={PRIMARY_WHITE} styled={{ padding: '0 0 0 8px', margin: '4px' }}>
              <FlexLayout itemsY="center" gap={8}>
                {item?.label}
                <FlexLayout
                  styled={{ width: 24, height: 24, cursor: 'pointer' }}
                  itemsX="center"
                  itemsY="center"
                  onClick={() => removeItem(item)}
                >
                  <IoClose size={16} color={PRIMARY_WHITE} />
                </FlexLayout>
              </FlexLayout>
            </Tag>
          ))}
        </SelectedItems>
      )}
    </div>
  );
};

interface DateRangeFilterProps {
  title: string;
  onFromDateChange: (value: string) => void;
  onToDateChange: (value: string) => void;
  dateRanges: string[];
}

export const DateRangeFilter = ({ title, dateRanges, onFromDateChange, onToDateChange }: DateRangeFilterProps) => {
  return (
    <div>
      <Text styled={{ marginBottom: 8 }} block variant="body6" color={PRIMARY_WHITE} weight={300}>
        {title}
      </Text>
      <FlexLayout gap={8} itemsY="center">
        <FilterDateInput
          type="date"
          placeholder="From"
          value={dateRanges?.[0] ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onFromDateChange(e?.target?.value)}
        />
        <Text variant="body6" color={SECONDARY_PURPLE_70} weight={300}>
          to
        </Text>
        <FilterDateInput
          type="date"
          placeholder="To"
          value={dateRanges?.[1] ?? ''}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => onToDateChange(e?.target?.value)}
        />
      </FlexLayout>
    </div>
  );
};
