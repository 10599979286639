import React, { useCallback, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { BsExclamationCircle } from 'react-icons/bs';
import { TAG_STATUS_RESOLVED } from '../../../../../../consts/driver';
import { PrimaryButton } from '../../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../../uiComponents/text/text';
import { TextArea } from '../../../../../../uiComponents/inputs/textArea/textArea';
import { DropDown } from '../../../../../../uiComponents/uiControls/dropDown/dropDown';
import { createDriverTags } from '../../../../../../api/post/driver.post';
import { updateDriverTags } from '../../../../../../api/patch/driver.patch';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30 } from '../../../../../../common/styles/Colors';
import { DriverTagDropDown, DriverTagsPayload } from '../../../../../../models/driver';
import { PulseUser } from '../../../../../../models/employee';
import { handleAPIError, ErrorType } from '../../../../../../utils/handleAPIError';
import { renderNotification } from '../../../../../../utils/utils';

interface CreateUpdateDriverTagsProps {
  isCreate?: boolean;
  driverId?: string;
  driverTagId?: string;
  title: string;
  dropDownOptions?: { value: string; label: string }[];
  onFormSubmit: () => void;
  user?: PulseUser;
}

export const CreateUpdateDriverTagsForm = ({
  isCreate,
  driverId,
  driverTagId,
  title,
  dropDownOptions,
  onFormSubmit,
  user,
}: CreateUpdateDriverTagsProps) => {
  const [processing, setProcessing] = useState<boolean>(false);
  const country_id = user?.country_id;
  const employee_id = user?.employee_id;

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm<DriverTagDropDown>({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = useCallback(
    async (submitValues: DriverTagDropDown) => {
      setProcessing(true);
      try {
        if (driverId && isCreate) {
          const payload: DriverTagsPayload = { driver_tag: submitValues, country_id, employee_id };
          const { data } = await createDriverTags(driverId, payload);
          data?.length > 0
            ? renderNotification('success', 'Success', 'Driver’s tag has been successfully created')
            : renderNotification('error', 'Error', 'Failed to create driver’s tag', false);
        } else if (driverTagId) {
          const { reason } = submitValues;
          const payload: DriverTagsPayload = { tag_reason: reason, resolution_status: TAG_STATUS_RESOLVED };
          const { data } = await updateDriverTags(driverTagId, payload);
          data?.length > 0
            ? renderNotification('success', 'Success', 'Driver’s tag has been successfully updated')
            : renderNotification('error', 'Error', 'Failed to update driver’s tag', false);
        }
        setProcessing(false);
      } catch (err) {
        setProcessing(false);
        handleAPIError(err as ErrorType);
      }
      onFormSubmit();
    },
    [country_id, driverId, driverTagId, employee_id, isCreate, onFormSubmit]
  );

  return (
    <FlexLayout gap={16} vertical itemsX="center">
      <BsExclamationCircle size={200} color={SECONDARY_PURPLE_30} />
      <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
        {title}
      </Text>
      {dropDownOptions && (
        <div style={{ width: '80%' }}>
          <DropDown
            control={control as unknown as Control<FieldValues>}
            error={errors?.tag_type_ids}
            multiSelect
            name="tag_type_ids"
            placeholder="Select driver tag(s)"
            options={dropDownOptions}
            required={{
              required: 'Tags are required',
            }}
          />
        </div>
      )}
      <div style={{ marginTop: 16, width: '80%' }}>
        <TextArea
          {...register?.('reason', {
            required: 'Reason is required',
          })}
          error={errors?.reason}
          label="Reason"
          placeholder="Supply a reason"
          required
          styled={{ height: 104 }}
        />
      </div>
      <FlexLayout itemsX="center">
        <PrimaryButton isProcessing={processing} onClick={handleSubmit(onSubmit)} styled={{ marginTop: 20 }}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </FlexLayout>
  );
};
