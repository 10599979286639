import { Congestion } from '../../models/congestion';
import api from '../../utils/api';

export const uploadCongestionCharges = (payload: { contents: string }) =>
  api.post('additional-charge/congestion/upload', payload);

export const updateCongestion = (id: string, payload: { vrm: string; driver_id: string | null }) =>
  api.patch(`additional-charge/congestion/${id}`, payload);

export const updatePendingCongestionChargesStatus = (payload: { congestionData: Congestion[]; status: string }) =>
  api.post('additional-charge/congestion/multipleUpdate', payload);

export const processPendingExcessMileagesCharges = (payload: { chargesIds: string[] }) =>
  api.post('additional-charge/excess-mileage/process-pending-charges', payload);

export const rejectPendingExcessMileagesCharges = (payload: { chargesIds: string[] }) =>
  api.post('additional-charge/excess-mileage/reject-pending-charges', payload);

export const initiateApprovedCharges = () => api.post('additional-charge/excess-mileage/initiate-approved-charges');

export const createNewCharges = () => api.post('additional-charge/excess-mileage/create-new-charges');
