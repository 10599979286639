import { apiSlice } from '../baseApi';

interface VehicleModelListParams {
  query: string;
  refetch: boolean;
}

interface VehicleModelListResponse {
  count: number;
  data: VehicleModelPayload[];
}

export interface VehicleModelPayload {
  id?: string;
  manufacturer_id: string;
  manufacturer?: string;
  model: string;
  fuel_types: Array<string>;
  spec?: string;
  exterior_colors: Array<string>;
  service_interval: number;
}

export const contracts = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getVehicleModelList: builder.query<VehicleModelListResponse, VehicleModelListParams>({
      query: ({ query }) => `vehicle-model/list${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getFuelList: builder.query({
      query: () => 'fuel-type/list',
    }),
    getColorsList: builder.query({
      query: () => 'exterior-colors/list',
    }),
    getVehicleModelById: builder.query({
      query: ({ query }) => `vehicle-model/${query}`,
      keepUnusedDataFor: 0.0001,
    }),
    createVehicleModel: builder.mutation({
      query: (body) => ({
        url: 'vehicle-model',
        method: 'POST',
        body,
      }),
    }),
    editVehicleModel: builder.mutation({
      query: (body) => ({
        url: 'vehicle-model',
        method: 'PATCH',
        body,
      }),
    }),
  }),
});

export const {
  useGetVehicleModelListQuery,
  useGetFuelListQuery,
  useGetColorsListQuery,
  useGetVehicleModelByIdQuery,
  useCreateVehicleModelMutation,
  useEditVehicleModelMutation,
} = contracts;
