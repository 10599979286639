import { FieldValues } from 'react-hook-form';
import { Base } from './common';
import { File } from './file';
import { OrdwaySubscription } from './ordway';
import { ApplicationStatus } from './application';
import { Assessment } from './assessment';

export interface Driver {
  address_city?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_post_code?: string;
  agreement_id?: string;
  agreement_type?: string;
  agreement_status?: string;
  agreement_length?: string;
  archived?: boolean;
  branch_name?: string;
  bp_card_id?: string | null;
  country_id?: string;
  created_date?: string;
  date_of_birth?: string;
  driver_first_and_middle_names?: string;
  driver_id?: string;
  driver_name?: string;
  driver_profile_status?: string;
  dvla_expiry_date?: string | null;
  dvla_no?: string;
  dvla_test_date?: string | null;
  dvsa_checked_date?: string | null;
  email?: string;
  first_name?: string;
  id?: string;
  last_name?: string;
  latest_assessment_status?: string;
  middle_name?: string;
  mobile_phone?: string;
  national_insurance_number?: string;
  no_area_code?: string;
  no_dvla_points?: number;
  ordway_customer_id?: string | null;
  ordway_subscription_id?: string | null;
  ordway_update_payment_method_url?: string | null;
  pco_expiry_date?: string | null;
  pco_no?: string;
  penalty_codes?: string[];
  plan_type?: string | null;
  shell_card_id?: string | null;
  signon_source?: string;
  tag_type?: TagType[] | string[] | null;
  tagData?: TagData[];
  title?: string | null;
  uber_rating?: string | number;
  vehicle_vin?: string;
}

export interface DriverDetails {
  first_name?: string;
  middle_name?: string;
  last_name?: string;
  date_of_birth?: string;
  email?: string;
  mobile_phone?: string;
  address_line_1?: string;
  address_line_2?: string;
  address_city?: string;
  address_post_code?: string;
  no_dvla_points: number;
  dvla_no?: string;
  dvla_test_date?: string;
  dvsa_checked_date?: string;
  penalty_codes: string[];
  pco_no?: string;
  national_insurance_number?: string;
  shell_card_id?: string;
  bp_card_id?: string;
}

export interface DriverAdditionalDetails {
  address_line_1: string;
  address_line_2: string;
  address_city: string;
  address_post_code: string;
  archived: boolean;
  country_id: string;
  created_date: string;
  date_of_birth: string;
  dvla_expiry_date: string | null;
  dvla_no: string;
  email: string;
  first_name: string;
  ordway_update_payment_method_url: string | null;
  id: string;
  last_name: string;
  mobile_phone: string;
  national_insurance_number: string;
  no_area_code: string;
  pco_expiry_date: string | null;
  pco_no: string;
  tag_type: TagType[] | null;
  title: string | null;
  address_country: string;
  uber_rating: number;
  signon_source: string;
  vehicle_source: string;
  files: File[] | null;
}

export interface DriverAssessment {
  passed: boolean;
  driver_id: string;
  files: File[];
}

export interface DriverPayload extends Base {
  data: Driver[];
}

export interface EditDriverPayload extends Driver {
  tag_name?: string | object;
  tag_category: string;
  category_id: string | undefined;
  reason?: string;
}

export interface TagData {
  archived: boolean;
  category_id: string;
  country_id: string;
  created_date: string;
  employee_id: string;
  id: string;
  other_reason: string | null;
  reason: string | null;
  tag_category: string;
  tag_name: string;
  tag_type_id: string;
  updated_date: string;
  visible: boolean;
}

export interface DriverTableProps {
  driverId: string;
}

export interface DriveMileageProps {
  id: string;
  map(arg0: (mileage: DriveMileageProps) => { rowData: DriveMileageProps; cells: JSX.Element[] }): undefined;
  courtesy_vehicle_mileage: string;
  courtesy_vehicle_vrm: string;
  date_range: {
    start_date: string;
    end_date: string;
  };
  excess_mileage_charge: string;
  excess_miles: string;
  holiday: boolean;
  is_courtesy: boolean;
  main_vehicle_mileage: string;
  main_vehicle_vrm: string;
  total_mileage: string;
}

export type DriverAssessmentEligibility = 'online' | 'practical' | 'none' | undefined;

export interface CustomerProfile extends FieldValues {
  address_city?: string | undefined;
  address_line_1?: string;
  address_line_2?: string;
  address_post_code?: string;
  application_status?: ApplicationStatus | null;
  assessment_type?: DriverAssessmentEligibility;
  assessment_type_data?: Assessment[];
  automatic_car?: 'yes' | 'no';
  anxietyDepression?: 'yes' | 'no';
  country_id?: string;
  criminalConvictions?: 'yes' | 'no';
  date_of_birth?: string;
  driver_id: string | undefined;
  driver_profile_status?: string;
  dvla_back?: File | Blob;
  dvla_back_id?: string;
  dvla_back_s3_url?: string;
  dvla_clean?: boolean;
  dvla_expiry_date?: string;
  dvla_front?: File | Blob;
  dvla_front_id?: string;
  dvla_front_s3_url?: string;
  dvla_no?: string;
  dvla_test_date?: string;
  eligibility_questionnaire_responses?: boolean[] | null;
  email?: string;
  ethnicity?: string;
  first_name?: string;
  id?: string;
  involvedAccident?: 'yes' | 'no';
  last_name?: string;
  liability_questionnaire_responses?: boolean[] | null;
  middle_name?: string;
  mobile_phone?: string;
  motoringOffences?: 'yes' | 'no';
  national_insurance_number?: string;
  no_area_code?: string;
  notes?: string;
  no_dvla_points?: number | '';
  ordway_customer_id?: string;
  pco?: File | Blob;
  pco_cert?: File | Blob;
  pco_cert_expiry_date?: string;
  pco_cert_id?: string;
  pco_cert_s3_url?: string;
  pco_expiry_date?: string;
  pco_id?: string;
  pco_new?: 'yes' | 'no';
  fault_accident?: 'yes' | 'no';
  more_than_3_points?: 'yes' | 'no';
  total_trips?: number;
  trips_last_30_days?: number;
  glasses?: 'yes' | 'no';
  epilepsy?: 'yes' | 'no';
  medicalCondition?: 'yes' | 'no';
  pco_no?: string;
  pco_s3_url?: string;
  penalty_codes?: string[];
  poa?: File | Blob;
  poa_expiry_date?: string;
  poa_id?: string;
  poa_s3_url?: string;
  signon_source?: string;
  sleepDisorder?: 'yes' | 'no';
  title?: string;
  under_28_or_over_65?: 'yes' | 'no';
  uber_rating?: number | string;
  dvla_front_is_loading?: boolean;
  dvla_back_is_loading?: boolean;
  pco_is_loading?: boolean;
  pco_cert_is_loading?: boolean;
  poa_is_loading?: boolean;
  dvla_front_file_type?: string;
  dvla_back_file_type?: string;
  pco_file_type?: string;
  pco_cert_file_type?: string;
  poa_file_type?: string;
}

export interface OrdwayDetails {
  additional_charges: {
    total: string;
  };
  arrears: string;
  billing_schedule_items: {
    unbilled_subscription_amount: string;
    remaining_total_weeks: number;
  };
  balance: string | number;
  id: string;
  invoice_history: CustomerInvoicesItem[];
  latest_invoice: {
    id: string;
    invoice_date: string;
    status: string;
  };
  plans: {
    plan_name: string;
  }[];
  status: string;
  subscriptions: OrdwaySubscription[];
  total_contract_value: string;
}
export interface CustomerPlanDetails {
  id?: string;
  subId: string;
  name: string;
  tcv: string;
  outstandingBalance: string;
  subStatus: string;
}

export interface CustomerInvoicesItem {
  id: string;
  invoice_date: string;
  invoice_amount: string;
  invoice_pdf_url: string;
  line_items: {
    product_name: string;
  }[];
  status: string;
}

export interface UpdateDriverSubscription {
  effective_date: string;
  ordway_subscription_id: string;
  weekly_amount: string;
}

export type ExcessMileageType = 'ONE_OFF' | 'PAYMENT_PLAN';

export interface AddExcessMileage {
  effective_date: string;
  excess_type: ExcessMileageType;
  mileage_amount: string;
  ordway_subscription_id: string;
}

export type TagType = 'PART-EXCHANGE' | 'CAREER-BOOST' | 'OPTED-FOR-CAREER-BOOST';

export type Ethnicity =
  | 'ASIAN'
  | 'ASIAN-BANGLADESHI'
  | 'ASIAN-INDIAN'
  | 'ASIAN-OTHER'
  | 'ASIAN-PAKISTANI'
  | 'BLACK'
  | 'BLACK-AFRICAN'
  | 'BLACK-CARIBBEAN'
  | 'BLACK-OTHER'
  | 'CHINESE'
  | 'CHINESE-OTHER'
  | 'MIXED-OTHER'
  | 'MIXED-WHITE-ASIAN'
  | 'MIXED-WHITE-BLACK-AFRICAN'
  | 'MIXED-WHITE-BLACK-CARIBBEAN'
  | 'WHITE'
  | 'WHITE-BRITISH'
  | 'WHITE-IRISH'
  | 'WHITE-OTHER'
  | 'DECLINE-TO-ANSWER';

export interface DriverTagType {
  id: string;
  name: string;
}

export interface DriverTagTypeResponse {
  singleSelectTagTypes: DriverTagType[];
  mutliSelectTagTypes: DriverTagType[];
}

export interface DriverTag {
  created_date: string;
  date_resolved: string;
  driver_tag_id?: string;
  employee_name: string;
  created_by: string;
  resolved_by: string;
  resolution_status: string;
  tag_name: string;
  tag_reason: string;
}

export interface DriverTagDropDown {
  tag_type_ids?: string[];
  reason: string | undefined;
}

export interface DriverTagsPayload {
  country_id?: string;
  driver_tag?: DriverTagDropDown;
  employee_id?: string;
  resolution_status?: string;
  tag_reason?: string;
}

export interface DuplicateCheck {
  email: string;
  first_name: string;
  last_name: string;
  id: string;
}

export interface DuplicatePcoCheck extends DuplicateCheck {
  pco_no: string;
}

export interface DuplicateShellCardCheck extends DuplicateCheck {
  shell_card_id: string;
}

export interface DuplicateBPId extends DuplicateCheck {
  bp_card_id: string;
}

export interface FreshdeskTicket {
  agent_name: string;
  created_at: string;
  group_id: string;
  group_name: string;
  id: string;
  priority: string;
  status: string;
  subject: string;
  tags: string;
  type: string;
}

export interface IDriverDocument {
  documentType: DriverDocumentType;
  id?: string;
  contents?: string | null;
  type: string | null;
  s3_url?: string;
  expiry_date?: string;
}

export enum DriverDocumentType {
  DVLA_FRONT = 'DVLA-FRONT',
  DVLA_BACK = 'DVLA-BACK',
  PCO = 'PCO',
  PCO_CERT = 'PCO-CERT',
  POA = 'POA',
}

export interface IDriverUploadRequest {
  category_id: string;
  document_category: string;
  expiry_date: string;
  document_type: DriverDocumentType;
  type?: string;
  fileName?: string;
  s3_url?: string;
}
