import { Servicing } from '../models/servicing';
import { VRMRegex, validateFutureDateFormat, validateStartBeforeEndDate } from '../utils/validations';
import { InputType } from '../uiComponents/inputs/textInput/textInput';
import { OptionList } from '../utils/props';

export const SERVICING_ACTIVE: string = 'ACTIVE';
export const SERVICING_COMPLETE: string = 'COMPLETE';

interface EditField<T> {
  label: string;
  name: keyof T;
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  placeholder?: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  requiredText?: string;
  type: InputType;
  validate?: Record<string, any>;
}

export const editFields: EditField<Servicing>[] = [
  {
    label: 'VRM',
    name: 'vrm',
    pattern: {
      value: VRMRegex,
      message: 'Invalid VRM please letters A-Z and numbers 0-9',
    },
    placeholder: 'AABBCCDD1234',
    requiredText: 'VRM cannot be empty',
    type: 'text',
  },
  {
    label: 'Start of service date',
    name: 'start_date',
    requiredText: 'Start date cannot be empty',
    type: 'date',
    validate: {
      validation: (value: string) => validateFutureDateFormat(value, true),
      validateStartBeforeEnd: (value: string, allValues: Servicing) =>
        validateStartBeforeEndDate(value, allValues.projected_ttr),
    },
  },
  {
    label: 'End of service date',
    name: 'projected_ttr',
    requiredText: 'End date cannot be empty',
    type: 'date',
    validate: {
      validation: (value: string) => validateFutureDateFormat(value),
      validateStartBeforeEnd: (value: string, allValues: Servicing) =>
        validateStartBeforeEndDate(allValues.start_date, value),
    },
  },
];

interface DropdownField {
  label: string;
  name: string;
  requiredText: string;
}

export const VRM: string = 'vrm';
export const BRANCH_ID: string = 'branch_id';
export const CITY_ID: string = 'city_id';
export const SERVICING_TYPE: string = 'servicing_type';

export const dropdownFields: DropdownField[] = [
  {
    label: 'Service type',
    name: SERVICING_TYPE,
    requiredText: 'Service type is required',
  },
  {
    label: 'City',
    name: CITY_ID,
    requiredText: 'City is required',
  },
  {
    label: 'Branch',
    name: BRANCH_ID,
    requiredText: 'Branch is required',
  },
];

export const IN_SERVICE_RENTAL_VEHICLE: string = 'inServiceRentalVehicle';
export const COURTESY_VEHICLE = 'courtesyVehicle';

export const interimVehicleOptions: OptionList[] = [
  { value: COURTESY_VEHICLE, label: 'Temporary vehicle' },
  { value: IN_SERVICE_RENTAL_VEHICLE, label: 'In-service rental vehicle' },
];

export const origAgrmHeaders: string[] = [
  'Vehicle:',
  'Vehicle location:',
  'Agreement:',
  'Driver:',
  'Phone:',
  'Accounts and payments:',
];

export const courtVehHeaders: string[] = ['Driver:', 'VRM:', 'Vehicle:', 'Vehicle location:'];
