import styled from 'styled-components';
import {
  STATUS_RED,
  PRIMARY_PURPLE,
  GRAY_2,
  PRIMARY_GREEN,
  SECONDARY_PURPLE_30,
  PRIMARY_WHITE,
  GRAY_3,
  SECONDARY_PURPLE_90,
  SECONDARY_GRAY_20,
  SECONDARY_PURPLE_5,
  SECONDARY_PURPLE_70,
} from '../../../common/styles/Colors';
import {
  CSSObjectWithLabel,
  ControlProps,
  DropdownIndicatorProps,
  GroupBase,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';
import { OptionList } from '../../../utils/props';

export const SelectError = styled.span`
  position: absolute;
  display: block;
  font-size: 11px;
  color: ${STATUS_RED};
`;

export const MultiSelectValue = styled.div`
  font-size: 14px;
  color: ${PRIMARY_PURPLE};
  margin-left: 11px;
`;

export const Container = styled.div`
  position: relative;
`;

export const dropDownStyles: StylesConfig<string | OptionList, boolean, GroupBase<string | OptionList>> = {
  control: (
    base: CSSObjectWithLabel,
    props: ControlProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    let borderCol;

    if (props.isDisabled) {
      borderCol = GRAY_2;
    } else if (props.isFocused) {
      borderCol = PRIMARY_GREEN;
    } else {
      borderCol = SECONDARY_PURPLE_30;
    }
    return {
      ...base,
      backgroundColor: props.isDisabled ? GRAY_2 : PRIMARY_WHITE,
      borderColor: borderCol,
      cursor: props.isDisabled ? 'default' : 'pointer',
      borderRadius: 50,
      padding: '0 16px',
      height: 48,
      boxShadow: 'none',
      '&:hover': {
        borderColor: PRIMARY_GREEN,
      },
    };
  },
  singleValue: (
    base: CSSObjectWithLabel,
    props: SingleValueProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    return {
      ...base,
      color: props.isDisabled ? GRAY_3 : SECONDARY_PURPLE_90,
      fontWeight: 500,
      fontSize: '16px',
    };
  },
  multiValue: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      display: 'flex',
    };
  },
  placeholder: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      color: SECONDARY_GRAY_20,
    };
  },
  dropdownIndicator: (
    base: CSSObjectWithLabel,
    props: DropdownIndicatorProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    let fillCol;

    if (props.isDisabled) {
      fillCol = GRAY_3;
    } else if (props.isFocused) {
      fillCol = PRIMARY_GREEN;
    } else {
      fillCol = SECONDARY_PURPLE_30;
    }
    return {
      ...base,
      fill: fillCol,
      color: fillCol,
      '&:hover': {
        color: SECONDARY_PURPLE_70,
      },
    };
  },
  indicatorSeparator: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      display: 'none',
    };
  },
  menu: (base: CSSObjectWithLabel) => {
    return { ...base, borderRadius: 16, border: `1px solid ${PRIMARY_GREEN}` };
  },
  menuList: (base: CSSObjectWithLabel) => {
    return {
      ...base,
      borderRadius: 16,
      border: 'none',
      boxShadow: 'none',
    };
  },
  option: (
    base: CSSObjectWithLabel,
    props: OptionProps<string | OptionList, boolean, GroupBase<string | OptionList>>
  ) => {
    return {
      ...base,
      height: 48,
      backgroundColor: props.isSelected ? SECONDARY_PURPLE_5 : PRIMARY_WHITE,
      color: props.isSelected ? SECONDARY_PURPLE_90 : SECONDARY_PURPLE_70,
      cursor: props.isDisabled ? 'default' : 'pointer',
      fontWeight: props.isSelected ? 500 : 300,
      transition: 'background-color 0.3s ease-in-out',
      '&:hover': {
        backgroundColor: SECONDARY_PURPLE_5,
      },
      '&:active': {
        backgroundColor: SECONDARY_PURPLE_5,
      },
    };
  },
};
