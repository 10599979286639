import { SideNav } from '../sideNav/sideNav';
import { PowerBi } from '../../powerBi/powerBi';

export const Dashboard = () => {
  const url = process.env.REACT_APP_POWER_BI_DASHBOARD;
  return (
    <>
      <SideNav />
      <PowerBi accessToken="accessToken" reportId="reportId" embedUrl={url ?? ''} />
    </>
  );
};
