import { Branch } from '../../models/branch';
import api from '../../utils/api';

/**
 * Delete branch request
 * @category API/DELETE
 * @param {object} id - branch id for the user to delete the package
 * @return {Promise} Returns a promise for the api request
 * @example
 * deleteBranch(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteBranch = (id: string): Promise<{ data: Branch }> => api.delete(`internal/branch/${id}`);
