import { PRIMARY_WHITE } from '../../../common/styles/Colors';
import styled from 'styled-components';

export const CalendarContainer = styled.div`
  position: fixed;
  z-index: 16;
  width: 40vw;
  height: 75vh;
  background-color: ${PRIMARY_WHITE};
  border-radius: 16px;
`;

export const PhvMotContainer = styled.div`
  margin-left: calc(40vw + 16px);
  gap: 16px;
  width: 100%;
`;

export const StateContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 73vh;
`;
