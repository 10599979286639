import styled from 'styled-components';

export const DamageForm = styled.form`
  min-width: 840px;
  @media (max-width: 1024px) {
    min-width: 500px;
  }
`;

export const DamageGrid = styled.form`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 27px;
  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;
