import { BsPerson, BsPersonCheck } from 'react-icons/bs';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';

interface PartyProps {
  partyLabel: string;
  partyName: string;
  isAgent: boolean;
}

export const Party = ({ partyLabel, partyName, isAgent }: PartyProps) => {
  return (
    <FlexLayout gap={16} itemsY="center">
      {isAgent ? <BsPersonCheck size={40} color={PRIMARY_PURPLE} /> : <BsPerson size={40} color={PRIMARY_PURPLE} />}
      <div>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block>
          {partyLabel}
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          {partyName}
        </Text>
      </div>
    </FlexLayout>
  );
};
