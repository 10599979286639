import { PRIMARY_GREEN, SECONDARY_PURPLE_30, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import styled from 'styled-components';

const getBorderColor = (active?: boolean, error?: boolean) => {
  if (active) {
    return PRIMARY_GREEN;
  }
  if (error) {
    return 'red';
  }
  return PRIMARY_GREEN;
};

export const UploadImageText = styled.div`
  margin-left: 18px;
`;

export const UploadLabel = styled.span`
  font-size: 16px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
`;

export const UploaderContainer = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  justify-content: center;
  flex-direction: column;
  border: ${(props) => `1px dashed ${getBorderColor(props.$active, props.$error)}`};
  border-radius: 16px;
  display: flex;
  padding: 0 20px;
  height: 104px;
  margin-top: 10px;
  pointer-events: ${(props) => (props.$disabled ? 'none' : '')};

  &:focus,
  &:active {
    border: 1px dashed ${PRIMARY_GREEN};
  }
`;

export const UploaderContainerSmall = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  justify-content: center;
  flex-direction: column;
  border: 1px dashed ${PRIMARY_PURPLE};
  border-radius: 10px;
  display: flex;
  height: 63px;
  width: 63px;
  padding: 2px;
  pointer-events: ${(props) => (props.$disabled ? 'none' : '')};
`;

export const UploadBackgroundImage = styled.img`
  height: 100%;
  width: 100%;
  border-radius: 8px;
`;

export const UploaderContainerSection = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 63px;
  margin-top: 10px;
  position: relative;
  cursor: pointer;
`;

export const UploaderFakeButton = styled.div`
  height: 63px;
  width: 63px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

export const UploaderTextPurple = styled.span`
  display: block;
  color: ${PRIMARY_PURPLE};
  font-weight: 300;
  width: 50px;
  font-size: 10px;
  line-height: 12px;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const UploaderText = styled.span`
  display: block;
  color: ${SECONDARY_PURPLE_30};
  font-weight: 300;
  font-size: 10px;
  line-height: 12px;
  width: 100px;
`;

export const UploaderTitle = styled.span`
  display: block;
  font-weight: 500;
  font-size: 14px;
  color: ${SECONDARY_PURPLE_30};
`;
