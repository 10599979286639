import { OptionList } from '../../../utils/props';

export const holidayLengthsNew: OptionList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '4', label: '4' },
];

export const holidayLengthsOld: OptionList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
];

export const skipPaymentLength: OptionList[] = [
  { value: '1', label: '1' },
  { value: '2', label: '2' },
  { value: '3', label: '3' },
  { value: '4', label: '4' },
];

export const getUpcomingThursday = (): Date => {
  const daysTillThursday: Record<string, number> = {
    Wed: 1,
    Tue: 2,
    Mon: 3,
    Sun: 4,
    Sat: 5,
    Fri: 6,
    Thu: 7,
  };

  const now: Date = new Date();
  const today: string = now.toString().split(' ')[0];

  const thursday: Date = new Date(now.setDate(now.getDate() + daysTillThursday[today]));
  return new Date(thursday.setHours(0, 0, 0));
};

export const driver12WeekRule: string = '* Driver may only take a holiday after 12 weeks into their agreement.';
export const driver8WeekRule: string = '* It has been less than 8 weeks since the driver took a payment holiday.';
export const driver4WeekRule: string = '* Driver has already taken a 4 week holiday break this period.';
export const noHolidayText: string = '* Driver does not have enough holiday available';
