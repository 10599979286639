export const uberCleanAirPackages = [
  { key: 'option_3', value: 0, weeks: 4, label: 'None' },
  { key: 'option_2', value: 100.0, weeks: 4, label: '£100 off over 4 weeks' },
  { key: 'option_1', value: 300.0, weeks: 13, label: '£300 off over 13 weeks' },
  { key: 'option_4', value: 900.0, weeks: 12, label: '£900 off over 12 weeks' },
];

export const cityOptions = [
  {
    value: 'London',
    text: 'London',
  },
  {
    value: 'Birmingham',
    text: 'Birmingham',
  },
  {
    value: 'Manchester',
    text: 'Manchester',
  },
  {
    value: 'Cardiff',
    text: 'Cardiff',
  },
];

export const freshdeskStatusOptions = [
  {
    value: '2',
    text: 'Open',
  },
  {
    value: '3',
    text: 'Pending',
  },
  {
    value: '4',
    text: 'Resolved',
  },
  {
    value: '5',
    text: 'Closed',
  },
];

export const freshdeskPriorityOptions = [
  {
    value: '1',
    text: 'Low',
  },
  {
    value: '2',
    text: 'Medium',
  },
  {
    value: '3',
    text: 'High',
  },
  {
    value: '4',
    text: 'Urgent',
  },
];
