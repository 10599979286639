import { styled } from 'styled-components';
import { ToastContainer } from 'react-toastify';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';

export const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress',
})`
  .toast {
    display: flex;
    background-color: var(--color-black);
    min-height: 50px;
    .Toastify__close-button {
      padding: 5px;
      svg {
        color: '${PRIMARY_PURPLE}';
      }
    }
  }

  .toast-notification {
    align-items: flex-start;
  }
`;
