import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Control, FieldValues } from 'react-hook-form';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { ChecklistProps } from '../../conditionReport';
import { CollapsiblePanel } from '../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { TextArea } from '../../../../../uiComponents/inputs/textArea/textArea';
import { TextFieldLabel } from '../../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../../uiComponents/uiControls/dropDown/dropDown';
import { getTagTypesByCategory } from '../../../../../api/get/driver.get';
import { DriverTagType } from '../../../../../models/driver';
import { PRIMARY_GREEN, PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../../../../common/styles/Colors';
import { handleAPIError, ErrorType } from '../../../../../utils/handleAPIError';
import { OptionList } from '../../../../../utils/props';

export const DriverTagsCheck = ({ control, isComplete, errors, register }: ChecklistProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const driverTagOptionsRef = useRef<OptionList[]>([]);

  const fetchTagTypes = useCallback(async () => {
    try {
      const response = await getTagTypesByCategory('DRIVER-TAG');
      if (response) {
        const fetchedTags = response.data?.mutliSelectTagTypes;
        driverTagOptionsRef.current = fetchedTags?.map((tag: DriverTagType) => ({
          value: tag.id,
          label: tag.name,
        }));
      }
    } catch (err) {
      handleAPIError(err as ErrorType);
    }
  }, []);

  useEffect(() => {
    fetchTagTypes();
  }, [fetchTagTypes]);

  return (
    <CollapsiblePanel
      header={
        <FlexLayout gap={16} itemsY="center">
          {isComplete ? (
            <BsCheckCircle color={PRIMARY_GREEN} size={32} />
          ) : (
            <BsCircle size={32} color={PRIMARY_PURPLE} />
          )}
          <div>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
              Termination tags and reason
            </Text>
            {isComplete && (
              <Text variant="body7" color={PRIMARY_GREEN} weight={300} block>
                Completed
              </Text>
            )}
          </div>
        </FlexLayout>
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
      styled={{ marginTop: 16 }}
    >
      <>
        <Text variant="body6" weight={500} color={PRIMARY_PURPLE} block styled={{ marginBottom: 24 }}>
          Termination tags and reason
        </Text>
        <GridLayout template={2} gap={24}>
          <div>
            <TextFieldLabel $isRequired>Select termination tag(s)</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors?.tags}
              multiSelect
              name="tags"
              options={driverTagOptionsRef.current}
              placeholder="Select driver tag(s)"
              required={{ required: 'Tags are required' }}
            />
          </div>
          <div>
            <TextArea
              {...register?.('tags_note', {
                required: 'Reason is required',
              })}
              error={errors?.tags_note}
              label="Reason"
              placeholder="Supply a reason"
              required
              styled={{ height: 138, borderColor: PRIMARY_PURPLE, backgroundColor: SECONDARY_PURPLE_5 }}
            />
          </div>
        </GridLayout>
      </>
    </CollapsiblePanel>
  );
};
