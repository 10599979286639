import React from 'react';
import { Tag } from './tag/tag';
import { statusColors } from '../../common/utils';
import { Driver } from '../../models/driver';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';

interface DriverTagsOrPenaltiesProps {
  driver: Driver | undefined;
  forCodes?: boolean;
}

export const DriverTagsOrPenalties = ({ driver, forCodes }: DriverTagsOrPenaltiesProps) => {
  let arr;
  if (driver) {
    const { tag_type: tags } = driver;
    const { penalty_codes: codes } = driver;
    arr = forCodes ? codes?.map((code) => code.toUpperCase()) : tags;
  }
  return (
    <>
      {arr?.map((tag: string, index: number) => (
        <Tag key={`${tag}_${index}`} styled={{ marginTop: 16 }} color={statusColors[tag] ?? PRIMARY_PURPLE}>
          {tag}
        </Tag>
      ))}
    </>
  );
};
