import { GridLayout } from '../gridLayout/gridLayout';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';
import { FieldHeader, HeaderText, ValueText } from './fieldGridStyles';

interface FieldGridProps {
  headers: string[];
  values: (string | number | JSX.Element)[];
  numColumns: number;
}

export const FieldGrid = withStyledProps(({ headers, values, numColumns, ...props }: FieldGridProps) => {
  return (
    <div {...props}>
      <GridLayout template={numColumns}>
        {headers?.map((header: string, index: number) => (
          <FieldHeader key={`${header}_${index}`} $numColumns={numColumns}>
            <HeaderText block variant="body7" color={PRIMARY_PURPLE} weight={300}>
              {header}
            </HeaderText>
            <ValueText variant="body7" weight={500} color={PRIMARY_PURPLE} block>
              {values[index]}
            </ValueText>
          </FieldHeader>
        ))}
      </GridLayout>
    </div>
  );
});
