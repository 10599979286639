import styled, { keyframes } from 'styled-components';
import { ImSpinner2 } from 'react-icons/im';

export const rotationKeyframes = keyframes`
  to { transform: rotate(360deg); }
  from { transform: rotate(0deg); }
`;

export const StyledSpinner = styled(ImSpinner2)`
  animation: ${rotationKeyframes} 0.6s linear infinite;
`;
