import { BelowText, RadialChart, RadialChartProgress, RadialChartTotal } from './radialChart.styles';

interface RadialProps {
  progress: number;
  strokeWidth?: number;
  middleText?: string;
  subMiddleText?: string;
  loading?: boolean;
  margin: number;
  width: number;
  belowText?: string;
}

export const Radial = ({
  progress,
  strokeWidth,
  width,
  middleText,
  subMiddleText,
  loading,
  margin,
  belowText,
}: RadialProps) => {
  const circleRadius = Math.min((width * 44.44) / 100, (width * 47.22) / 100);
  const circumference = 2 * 3.14 * circleRadius;
  const strokeLength = progress ? (circumference / 100) * progress : 0;

  const largerDimension = width * 1.2;

  return (
    <RadialChart $width={largerDimension} $margin={margin}>
      <svg viewBox={`0 0 ${width} ${width}`} width={largerDimension} height={largerDimension}>
        {loading ? (
          <text
            style={{
              fontFamily: 'Open Sans',
              letterSpacing: '0px',
              fontSize: '18px',
            }}
            x="50%"
            y="50%"
            textAnchor="middle"
            dominantBaseline="middle"
          >
            loading...
          </text>
        ) : (
          <>
            <defs>
              <linearGradient id="linear" x1="0%" y1="0%" x2="100%" y2="1%">
                <stop stopColor="#97d700" offset="0%" />
                <stop stopColor="#97d700" offset="100%" />
              </linearGradient>
            </defs>
            <RadialChartTotal
              stroke="grey"
              strokeWidth={strokeWidth}
              fill="none"
              cx={width / 2}
              cy={width / 2}
              r={circleRadius}
            />
            <text
              style={{
                fontWeight: 'bold',
                fontFamily: '"Poppins", sans-serif',
                letterSpacing: '0px',
                fontSize: '18px',
              }}
              x="50%"
              y="50%"
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {middleText}
            </text>
            <text
              style={{
                fontFamily: '"Poppins", sans-serif',
                fontSize: '12px',
                color: '#242a4d',
                letterSpacing: '0.4px',
              }}
              x="50%"
              y="60%"
              textAnchor="middle"
              dominantBaseline="middle"
            >
              {subMiddleText}
            </text>
          </>
        )}

        <RadialChartProgress
          stroke="url(#linear)"
          strokeWidth={strokeWidth}
          strokeDasharray={`${strokeLength},${circumference}`}
          strokeLinecap="round"
          fill="none"
          cx={width / 2}
          cy={width / 2}
          r={circleRadius}
        />
      </svg>
      {belowText && <BelowText>{belowText}</BelowText>}
    </RadialChart>
  );
};
