import { useCallback, useContext, useEffect, useState } from 'react';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Table } from '../../../uiComponents/table/table';
import { useTableFilters } from '../../../hooks/useTableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableFilter,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { getAllInsuranceCompanies, getAllInsurancePolices } from '../../../api/get/insurance.get';
import {
  InitiateDataValidationPayload,
  InsuranceCompany,
  ModifiedInsurancePolicy,
  PolicyVehicles,
} from '../../../models/insurancePolicy';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { BsEye, BsPencil, BsArchive, BsPlayCircle } from 'react-icons/bs';
import { PRIMARY_PURPLE, PRIMARY_WHITE, STATUS_BLUE } from '../../../common/styles/Colors';
import { insurancePolicyColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { Modal } from '../../../uiComponents/modals/modal';
import { CreateEditPolicyForm } from '../createEditPolicyForm/createEditPolicyForm';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { Notification } from '../../../uiComponents/toast/toast';
import { deleteInsurancePolicy } from '../../../api/delete/insurance.delete';
import { APP_CONTEXT } from '../../../utils/context';
import { ViewReviewPolicyForm } from '../viewReviewPolicyForm/viewReviewPolicyForm';
import { isDateWithinOneWeekBefore } from '../../../utils/utils';
import {
  initiateInsuranceDataValidation,
  initiateInsuranceRun,
  sendInsurancePolicyTestEmailTemplate,
} from '../../../api/post/insurance.post';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import { FilterInput } from '../../../uiComponents/table/tableFilters/tableFilters.styles';
import { Text } from '../../../uiComponents/text/text';
import { OptionList } from '../../../utils/props';
import { FilterOptionSection } from './policyList.styles';
import { IncludeExcludeVehiclesForm } from '../IncludeExcludeVehiclesForm/IncludeExcludeVehiclesForm';
import { ReviewPolicy } from '../reviewPolicy/ReviewPolicy';
import { SelectTemplateForm } from '../selectTemplate/SelectTemplateForm';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useListAndMergePoliciesQuery } from '../../../api/listAndMerge/listAndMergePoliciesApiSlice';
import { endOfDay, format, parseISO, startOfDay } from 'date-fns';
import { toZonedTime } from 'date-fns-tz';

export const PoliciesList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [policyValues, setPolicyValues] = useState<ModifiedInsurancePolicy | undefined>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isEditPolicy, setIsEditPolicy] = useState<boolean>(false);
  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);
  const [isViewPolicy, setIsViewPolicy] = useState<boolean>(false);
  const [isRenewPolicy, setIsRenewPolicy] = useState<boolean>(false);
  const [policyVehiclesRangeModalOpen, setPolicyVehiclesRangeModalOpen] = useState<boolean>(false);
  const [isConfirmDeleteModalOpen, setIsConfirmDeleteModalOpen] = useState<boolean>(false);
  const [isInitiateDataValidationModalOpen, setIsInitiateDataValidationModalOpen] = useState<boolean>(false);
  const [isInitiateInsuranceRunModalOpen, setIsInitiateInsuranceRunModalOpen] = useState<boolean>(false);
  const [policyNumber, setPolicyNumber] = useState<string>('');
  const [insuranceCompany, setInsuranceCompany] = useState<OptionList[]>([]);
  const [policyStartDate, setPolicyStartDate] = useState<string>('');
  const [policyEndDate, setPolicyEndDate] = useState<string>('');
  const [policyStatus, setPolicyStatus] = useState<OptionList[]>([]);
  const [insuranceCompanyOptions, setInsuranceCompanyOptions] = useState<OptionList[]>([]);
  const [allInsurancePolicies, setAllInsurancePolicies] = useState<ModifiedInsurancePolicy[]>([]);
  const [policyVehicles, setPolicyVehicles] = useState<PolicyVehicles>();
  const [isInsuranceRunTemplateModalOpen, setIsInsuranceRunTemplateModalOpen] = useState<boolean>(false);
  const flags = useFlags();

  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortAscending,
    setSortingColumn,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=start_date:DESC&filter=archived$eq=false`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: policiesList,
    isFetching: isPoliciesListFetching,
    refetch,
  } = useListAndMergePoliciesQuery({ query: queryStringState, refetch: refetchData });

  const handleGetInsurancePoliciesResponse = useCallback(
    (count: number, policies: ModifiedInsurancePolicy[]) => {
      const policyRows = policies?.map((policy: ModifiedInsurancePolicy) => {
        return {
          rowData: { data: policy, rowColour: PRIMARY_WHITE },
          cells: [
            <TableTextCell value={policy?.policy_number} />,
            <TableTextCell value={policy?.insurance_company} />,
            <TableTagCell tags={[policy.insurance_policy_status]} />,
            <TableTextCell
              value={policy?.start_date ? format(new Date(policy.start_date), "dd/MM/yyyy 'at' HH:mm") : '-'}
            />,
            <TableTextCell
              value={policy?.end_date ? format(new Date(policy.end_date), "dd/MM/yyyy 'at' HH:mm") : '-'}
            />,
            <TableTextCell
              value={
                policy?.vrm_number_plates && policy?.vrm_number_plates.length > 0
                  ? policy?.vrm_number_plates.join(', ')
                  : '-'
              }
            />,
            <FlexLayout gap={16}>
              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsViewModalOpen(true);
                  setIsViewPolicy(true);
                }}
                icon={<BsEye size={24} color={PRIMARY_PURPLE} />}
                tooltip="View policy"
              />
              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsEditPolicy(true);
                  setIsModalOpen(true);
                }}
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                tooltip="Edit policy"
              />

              <ActionIcon
                onClick={() => {
                  setPolicyValues(policy);
                  setIsConfirmDeleteModalOpen(true);
                }}
                icon={<BsArchive size={24} color={PRIMARY_PURPLE} />}
                tooltip="Archive policy"
              />

              {flags.insuranceRun && isDateWithinOneWeekBefore(policy.start_date) && (
                <ActionIcon
                  onClick={() => {
                    setPolicyValues(policy);
                    setIsInitiateInsuranceRunModalOpen(true);
                  }}
                  icon={<BsPlayCircle size={24} color={STATUS_BLUE} />}
                  tooltip="Insurance run"
                />
              )}
            </FlexLayout>,
          ],
        };
      });
      setTableData(policyRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData, flags.insuranceRun]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  const deletePolicy = async (id: string) => {
    await deleteInsurancePolicy(id)
      .then(() => {
        refetchToFirstPage();
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Policy has been successfully archived',
          isAlert: true,
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error while archiving policy',
          isAlert: true,
        });
      });
  };

  const initiateDataValidationCheck = async (policy: ModifiedInsurancePolicy) => {
    const payload: InitiateDataValidationPayload = {
      id: policy.id,
      body: {
        vrm_number_plates: policy.vrm_number_plates,
        insurance_policy_status: policy.insurance_policy_status,
        start_date: policy.start_date,
        policy_number: policy.policy_number,
      },
    };
    await initiateInsuranceDataValidation(payload).then(() => {
      Notification({
        type: 'success',
        title: 'Success',
        message:
          'Policy data review has been initiated, please keep a lookout for completion of the data review, well notify you via email',
        isAlert: true,
      });
    });
  };

  const sendTestEmail = async (id: string, template: number) => {
    sendInsurancePolicyTestEmailTemplate(id, {
      template,
    })
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Test email successfully sent',
        });
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error while sending test email',
        });
      });
  };

  const insuranceRun = async (id: string, template: number) => {
    initiateInsuranceRun(id, {
      template,
    })
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Test email successfully sent',
        });
        setIsInsuranceRunTemplateModalOpen(false);
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error while sending test email',
        });
      });
  };

  const onClearClick = useCallback(() => {
    setPolicyNumber('');
    setInsuranceCompany([]);
    setPolicyStartDate('');
    setPolicyEndDate('');
    setPolicyStatus([]);
  }, []);

  const getInsuranceCompanies = useCallback(async () => {
    const { data } = await getAllInsuranceCompanies();
    const formattedOptions = data.map((item: InsuranceCompany) => {
      return {
        value: item.id,
        label: item.name,
      } as OptionList;
    });
    setInsuranceCompanyOptions(formattedOptions);
  }, []);

  useEffect(() => {
    if (policiesList) {
      setAllInsurancePolicies(policiesList.data);
      handleGetInsurancePoliciesResponse(policiesList.count, policiesList.data);
    }
  }, [policiesList, queryStringState, handleGetInsurancePoliciesResponse]);

  useEffect(() => {
    setActiveSideNav('policiestListPage');
    setPageTitle('Policies');
    setSortingColumn('start_date');
    setSortAscending(false);
    getInsuranceCompanies();
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending, getInsuranceCompanies]);

  useEffect(() => {
    const filters: TableFilter[] = [
      {
        columnName: 'archived',
        options: { value: 'false', label: 'false' },
      },
      {
        columnName: 'policy_number',
        options: { value: policyNumber ?? '', label: policyNumber ?? '' },
      },
      { columnName: 'insurance_company', options: insuranceCompany },
    ];
    if (policyStartDate) {
      const parsedISODate = parseISO(policyStartDate);
      const startOfDayInUtc = format(toZonedTime(startOfDay(parsedISODate), 'UTC'), 'yyyy-MM-dd HH:mm');
      const endOfDayInUtc = format(toZonedTime(endOfDay(parsedISODate), 'UTC'), 'yyyy-MM-dd HH:mm');
      filters.push({
        columnName: 'start_date',
        options: [
          {
            label: startOfDayInUtc,
            value: startOfDayInUtc,
          },
          {
            label: endOfDayInUtc,
            value: endOfDayInUtc,
          },
        ],
        clause: '$btw',
      });
    }
    if (policyEndDate) {
      const parsedISODate = parseISO(policyEndDate);
      const startOfDayInUtc = format(toZonedTime(startOfDay(parsedISODate), 'UTC'), 'yyyy-MM-dd HH:mm');
      const endOfDayInUtc = format(toZonedTime(endOfDay(parsedISODate), 'UTC'), 'yyyy-MM-dd HH:mm');
      filters.push({
        columnName: 'end_date',
        options: [
          {
            label: startOfDayInUtc,
            value: startOfDayInUtc,
          },
          {
            label: endOfDayInUtc,
            value: endOfDayInUtc,
          },
        ],
        clause: '$btw',
      });
    }
    setTableFilters(filters);
  }, [setTableFilters, policyNumber, insuranceCompany, policyStartDate, policyEndDate, policyStatus]);

  const filters: FilterItem[] = [
    {
      name: 'policy_number',
      element: (
        <FilterOptionSection>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy number:
          </Text>
          <FilterInput type="text" value={policyNumber} onChange={(e) => setPolicyNumber(e.target.value)} />
        </FilterOptionSection>
      ),
    },
    {
      name: 'insurance_company',
      element: (
        <FilterOptionSection>
          <div className="mb-1.5">
            <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
              Insurance company:
            </Text>
          </div>
          <DropDownFilter
            name="insurance_company"
            placeholder="Insurance company"
            options={insuranceCompanyOptions}
            multiValues={insuranceCompany}
            onChange={(items) => setInsuranceCompany(items as OptionList[])}
          />
        </FilterOptionSection>
      ),
    },
    {
      name: 'start_date',
      element: (
        <FilterOptionSection>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy start
          </Text>
          <FilterInput type="date" value={policyStartDate} onChange={(e) => setPolicyStartDate(e.target.value)} />
        </FilterOptionSection>
      ),
    },
    {
      name: 'end_date',
      element: (
        <FilterOptionSection>
          <Text variant="body6" color={PRIMARY_WHITE} weight={300}>
            Policy end
          </Text>
          <FilterInput type="date" value={policyEndDate} onChange={(e) => setPolicyEndDate(e.target.value)} />
        </FilterOptionSection>
      ),
    },
  ];

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isPoliciesListFetching}
        header="Policies list"
        actionButtonText="Add policy"
        onActionButtonClick={() => setIsModalOpen(true)}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={insurancePolicyColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllInsurancePolices}
        downloadName="Policies"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={onClearClick}
      />

      <Modal
        title={`${isEditPolicy ? 'Edit' : 'Add new'} policy`}
        open={isModalOpen}
        showClose
        onClose={() => {
          setIsModalOpen(false);
          setPolicyValues(undefined);
          isEditPolicy && setIsEditPolicy(false);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <CreateEditPolicyForm
          allInsurancePolicies={allInsurancePolicies}
          isInEdit={isEditPolicy}
          values={policyValues}
          onClose={() => {
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
          }}
          onFormSubmit={(data: PolicyVehicles) => {
            setPolicyVehicles(data);
            setIsModalOpen(false);
            setPolicyValues(undefined);
            isEditPolicy && setIsEditPolicy(false);
            setPolicyVehiclesRangeModalOpen(true);
          }}
          insuranceCompanyOptions={insuranceCompanyOptions}
        />
      </Modal>

      <Modal
        title={isRenewPolicy ? 'Renew policy' : 'View policy'}
        open={isViewModalOpen}
        showClose
        onClose={() => {
          setIsViewModalOpen(false);
          setIsRenewPolicy(false);
          setPolicyValues(undefined);
        }}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <ViewReviewPolicyForm
          isInViewMode={isViewPolicy}
          renewPolicy={() => {
            setIsViewPolicy(false);
            setIsRenewPolicy(true);
          }}
          values={policyValues}
          onClose={() => {
            setIsViewModalOpen(false);
            setIsRenewPolicy(false);
            setPolicyValues(undefined);
          }}
          onFormSubmit={() => {
            setIsRenewPolicy(false);
            setIsViewModalOpen(false);
            setPolicyValues(undefined);
            refetchToFirstPage();
          }}
          insuranceCompanyOptions={insuranceCompanyOptions}
        />
      </Modal>

      <ConfirmationModal
        title={'Are you sure you want to archive this policy?'}
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => {
          setIsConfirmDeleteModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues?.id) {
            deletePolicy(policyValues.id);
            setIsConfirmDeleteModalOpen(false);
            setPolicyValues(undefined);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <ConfirmationModal
        title={'Are you sure you want to initiate email data check for this policy?'}
        isOpen={isInitiateDataValidationModalOpen}
        onClose={() => {
          setIsInitiateDataValidationModalOpen(false);
          setPolicyValues(undefined);
        }}
        onConfirm={() => {
          if (policyValues) {
            initiateDataValidationCheck(policyValues);
            setIsInitiateDataValidationModalOpen(false);
            setPolicyValues(undefined);
            refetchToFirstPage();
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />

      <Modal
        title={`Summary for ${policyValues?.policy_number}`}
        open={isInitiateInsuranceRunModalOpen}
        showClose
        onClose={() => {
          setIsInitiateInsuranceRunModalOpen(false);
          setPolicyValues(undefined);
        }}
        styled={{ width: '95vw', minWidth: '600px' }}
      >
        <ReviewPolicy
          policyId={policyValues?.id || ''}
          onContinue={() => {
            setIsInitiateInsuranceRunModalOpen(false);
            setIsInsuranceRunTemplateModalOpen(true);
          }}
        />
      </Modal>
      <Modal
        title={'Insurance run'}
        open={isInsuranceRunTemplateModalOpen}
        showClose
        onClose={() => {
          setIsInsuranceRunTemplateModalOpen(false);
        }}
        styled={{ minWidth: '600px' }}
      >
        <SelectTemplateForm
          onCancel={() => {
            setIsInsuranceRunTemplateModalOpen(false);
          }}
          onConfirm={(template: number) => {
            insuranceRun(policyValues?.id || '', template);
          }}
          onSendTestEmail={(template: number) => {
            sendTestEmail(policyValues?.id || '', template);
          }}
        />
      </Modal>
      <Modal
        title={policyVehicles?.policy_number}
        open={policyVehiclesRangeModalOpen}
        showClose
        onClose={() => {
          setPolicyVehiclesRangeModalOpen(false);
          setPolicyVehicles(undefined);
          refetchToFirstPage();
        }}
        styled={{ width: '95vw', minWidth: '600px' }}
      >
        <IncludeExcludeVehiclesForm
          vehicles={policyVehicles?.vehiclesListByVrms || []}
          policyId={policyVehicles?.policy_id || ''}
          onClose={() => {
            setPolicyVehiclesRangeModalOpen(false);
            setPolicyVehicles(undefined);
          }}
          refetch={refetchToFirstPage}
        />
      </Modal>
    </>
  );
};
