import styled from 'styled-components';
import { Text } from '../../text/text';
import { SECONDARY_PURPLE_5, PRIMARY_GREEN, PRIMARY_WHITE, GRAY_3 } from '../../../common/styles/Colors';

export const StyledCheckboxInput = styled.input`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  left: 0;
  margin: 0;
  border: 1px solid ${PRIMARY_GREEN};
  border-radius: 50%;
  background-color: ${PRIMARY_WHITE};
  appearance: none;
  cursor: pointer;

  &:checked {
    border: none;

    + .tick-icon > svg {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      display: inherit;
    }
    &:disabled {
      border: 1px solid ${SECONDARY_PURPLE_5};
    }
  }

  & + .tick-icon > svg {
    display: none;
    pointer-events: none;
  }

  &:disabled {
    border: 1px solid ${GRAY_3};
    background-color: ${SECONDARY_PURPLE_5};
    pointer-events: none;
    cursor: not-allowed;
  }
`;

export const StyledText = styled(Text)`
  margin: 0 5px;
`;

export const StyledCheckboxContainer = styled.div`
  position: relative;
  width: 20px;
  height: 20px;
  display: 'flex';
`;

export const CheckboxContainer = styled.div;
