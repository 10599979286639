import React from 'react';
import Color, { default as tinycolor } from 'tinycolor2';
import type * as CSS from 'csstype';
import styled from 'styled-components';

interface Keyframes {
  getName(): string;
}
type InterpolationFunction<P> = (props: P) => Interpolation<P>;
type StyledComponentInterpolation = any;
export interface AnyNotNull {}
type FalseyValue = undefined | null | false;
type FlattenInterpolation<P> = ReadonlyArray<Interpolation<P>>;
type InterpolationValue = string | number | FalseyValue | Keyframes | StyledComponentInterpolation | CSSObject;
type Interpolation<P> = InterpolationValue | InterpolationFunction<P> | FlattenInterpolation<P>;
type CSSProperties = CSS.Properties<string | number>;

type CSSPseudos = { [K in CSS.Pseudos]?: CSSObject };

interface CSSObject extends CSSProperties, CSSPseudos {
  [key: string]: CSSObject | string | number | undefined;
}

function mixColors(color1: string, color2: string, amount: number) {
  const colorInstance = Color(color1);
  const colorMix = tinycolor.mix(colorInstance.toHexString(), color2, amount);
  colorMix.setAlpha(colorInstance.getAlpha());
  return colorMix.toHex8String();
}

type StyledProp<P = AnyNotNull> =
  | ((props: P) => StyledProp<P>)
  | {
      [K in keyof CSSProperties]?: CSSProperties[K] | ((props: P) => CSSProperties[K]);
    }
  | {
      [K in keyof CSSPseudos]?: StyledProp<P>;
    }
  | {
      // in case we need any overrides, define them here
    };

export interface StyledProps<P = AnyNotNull> {
  styled?: StyledProp<P>;
}

export type WithStyledProps<P> = P & StyledProps<P>;

function removeMarkerFromObject(allProps: unknown, props: unknown): Interpolation<object> {
  if (typeof props === 'function' && props != null) {
    return removeMarkerFromObject(allProps, props(allProps));
  }

  if (typeof props === 'object' && props != null) {
    const result: Record<string, unknown> = {};
    const keys = Object.keys(props);

    for (const key of keys) {
      result[key] = removeMarkerFromObject(allProps, props[key as keyof typeof props]);
    }

    return result as CSSObject;
  }

  return props as CSSObject;
}

const StyledComponent = React.memo(styled.div<{ $styled?: any }>`
  && {
    ${(p) => p.$styled != null && removeMarkerFromObject(p, p.$styled)}
  }
`);

export function darken(color: string, amount: number) {
  return mixColors(color, '#000000', amount);
}

export function lighten(color: string, amount: number) {
  return mixColors(color, '#ffffff', amount);
}

export function withStyledProps<C extends React.ForwardRefExoticComponent<P>, P = React.ComponentProps<C>>(
  component: C
): React.ForwardRefExoticComponent<WithStyledProps<P>>;
export function withStyledProps<C extends keyof JSX.IntrinsicElements, P = React.ComponentProps<C>>(
  component: C
): React.ComponentType<WithStyledProps<P>>;
export function withStyledProps<C extends React.ComponentType<P>, P = React.ComponentProps<C>>(
  component: C
): React.ComponentType<WithStyledProps<P>>;

export function withStyledProps(Component: any) {
  return React.forwardRef((props: any, ref: any) => {
    const { styled: styledProp = {}, ...restProps } = props;

    if (Object.keys(styledProp).length === 0) {
      return <Component ref={ref} {...restProps} />;
    }

    return <StyledComponent as={Component} ref={ref} $styled={styledProp} {...restProps} />;
  }) as any;
}
