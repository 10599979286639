import React from 'react';
import { WarningHeader, WarningIcon, WarningSubTitle, WarningTitle, Wrapper } from './warningCard.styles';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';

interface WarningCardProps {
  title: string;
  subTitle: string;
  onContinue: () => void;
}

export const WarningCard = ({ title, subTitle, onContinue }: WarningCardProps) => {
  return (
    <Wrapper>
      <WarningHeader>
        <WarningIcon />
        <WarningTitle>{title}</WarningTitle>
      </WarningHeader>
      <WarningSubTitle>{subTitle}</WarningSubTitle>
      <PrimaryButton onClick={onContinue}>Continue</PrimaryButton>
    </Wrapper>
  );
};
