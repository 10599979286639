import React from 'react';
import { ButtonDataItem } from '../../driverButtons/driverButtonGroup';
import { PrimaryButton } from '../../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';

interface ActionsProps {
  renderSubHeader: () => string;
  loading: boolean;
  setIsInvoicesModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setIsPlansModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Actions = ({ renderSubHeader, loading, setIsInvoicesModalOpen, setIsPlansModalOpen }: ActionsProps) => {
  const buttonData: ButtonDataItem[] = [
    {
      label: 'View invoice history',
      onClick: () => setIsInvoicesModalOpen(true),
    },
    {
      label: 'Active payment plans',
      onClick: () => setIsPlansModalOpen(true),
    },
  ];

  return (
    <FlexLayout gap={50}>
      {buttonData.map((item, index) => (
        <FlexLayout key={index} gap={16} vertical>
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            {index === 0 ? renderSubHeader() : 'Active payment plans'}
          </Text>
          <PrimaryButton styled={{ width: index === 0 ? '100%' : 'auto' }} disabled={loading} onClick={item.onClick}>
            {item.label}
          </PrimaryButton>
        </FlexLayout>
      ))}
    </FlexLayout>
  );
};
