import React from 'react';
import { FieldErrors, UseFormRegister } from 'react-hook-form';
import { BsPersonCheckFill, BsExclamationCircle } from 'react-icons/bs';
import { Question } from './liabilityQuestionnaire';
import {
  StyledRadioInput,
  AgentIconText,
  AgentUserName,
  FieldCount,
  QuestionContainer,
  PanelTitle,
  SalesAgentText,
} from '../createNewDriver/createNewDriver.styles';
import { TextArea } from '../../../../uiComponents/inputs/textArea/textArea';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../../common/styles/Colors';
import { CustomerProfile, DriverAssessmentEligibility } from '../../../../models/driver';
import { useAppSelector } from '../../../../store-hooks';

interface AssessmentButtonProps {
  label: string;
  value: 'online' | 'practical' | 'none';
  disabled: boolean;
  register: UseFormRegister<CustomerProfile>;
  name: string;
}

const AssessmentButton = ({ label, value, disabled, register, name }: AssessmentButtonProps) => {
  return (
    <StyledRadioInput
      type="radio"
      disabled={disabled}
      value={value}
      $label={label}
      {...(register
        ? register(name, {
            required: 'Please select an assessment type.',
          })
        : {})}
    />
  );
};

interface EligibilityCheckProps {
  register: UseFormRegister<CustomerProfile>;
  onlineAssessmentRecommended: boolean;
  practicalAssessmentRecommended: boolean;
  savedAnswers?: boolean[] | null;
  errors?: FieldErrors<CustomerProfile>;
  assessmentType: DriverAssessmentEligibility;
}

export const EligibilityCheck = ({
  register,
  onlineAssessmentRecommended,
  practicalAssessmentRecommended,
  assessmentType,
  savedAnswers,
  errors,
}: EligibilityCheckProps) => {
  const pulseUser = useAppSelector((state) => state.pulseUser);

  return (
    <>
      <PanelTitle>Eligibility check</PanelTitle>
      <FlexLayout itemsY="center" styled={{ margin: '24px 0' }}>
        <BsPersonCheckFill size={30} color={PRIMARY_PURPLE} />
        <AgentIconText>
          <SalesAgentText>Sales agent</SalesAgentText>
          <AgentUserName>{pulseUser ? `${pulseUser.first_name} ${pulseUser.last_name}` : ''}</AgentUserName>
        </AgentIconText>
      </FlexLayout>
      <QuestionContainer>
        <Question
          register={register}
          savedAnswer={savedAnswers != null ? (savedAnswers?.[0] ? 'yes' : 'no') : null}
          question="Is this customer brand new to PCO driving?"
          name="pco_new"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers != null ? (savedAnswers?.[2] ? 'yes' : 'no') : null}
          question="Is the customer under 25 years old OR over 65 years of age?"
          name="under_28_or_over_65"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers != null ? (savedAnswers?.[3] ? 'yes' : 'no') : null}
          question="How many accidents, whether fault or non-fault, has the driver had in the past 3 years?"
          name="fault_accident"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers != null ? (savedAnswers?.[4] ? 'yes' : 'no') : null}
          question="Does the customer hold more than 6 points on their driving license?"
          name="more_than_3_points"
          errors={errors}
        />
        <Question
          register={register}
          savedAnswer={savedAnswers != null ? (savedAnswers?.[5] ? 'yes' : 'no') : null}
          question="Is the driver completely new to driving automatic vehicles?"
          name="automatic_car"
          errors={errors}
        />
      </QuestionContainer>
      <div>
        <FlexLayout gap={28} styled={{ marginTop: 30, marginBottom: 16 }}>
          <AssessmentButton
            label="Online assessment"
            value="online"
            register={register}
            name="assessment_type"
            disabled={!onlineAssessmentRecommended && !practicalAssessmentRecommended}
          />
          <AssessmentButton
            label="Practical assessment"
            register={register}
            value="practical"
            name="assessment_type"
            disabled={!onlineAssessmentRecommended && !practicalAssessmentRecommended}
          />
          <AssessmentButton
            label="No assessment needed"
            register={register}
            value="none"
            name="assessment_type"
            disabled={!onlineAssessmentRecommended && !practicalAssessmentRecommended}
          />
        </FlexLayout>
        {errors?.assessment_type && (
          <Text variant="body8" color={STATUS_RED} weight={300}>
            {errors?.assessment_type?.message as string}
          </Text>
        )}
      </div>
      {assessmentType === 'none' && (
        <TextArea
          label="Reason"
          required
          placeholder="Please explain why this driver does not need an assessment"
          error={errors?.notes}
          {...register('notes', { required: 'A reason is required' })}
        />
      )}
      {(onlineAssessmentRecommended || practicalAssessmentRecommended) && (
        <FlexLayout styled={{ marginTop: 16 }} itemsX="center" itemsY="center" gap={8}>
          <BsExclamationCircle size={16} color={PRIMARY_PURPLE} />
          <FieldCount>
            {onlineAssessmentRecommended && 'An online assessment is recommended for this driver.'}
            {practicalAssessmentRecommended && 'A practical assessment is recommended for this driver.'}
          </FieldCount>
        </FlexLayout>
      )}
    </>
  );
};
