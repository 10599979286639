import { CreateCityPayload } from '../../models/city';
import api from '../../utils/api';

/**
 * Create City Request
 * @category API/POST
 * @param {object} body - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  city_name: '',
 * }
 * createCity(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createCity = (params: CreateCityPayload): Promise<{ data: CreateCityPayload }> =>
  api.post('internal/city/', params);
