import { Driver } from '../../models/driver';
import api from '../../utils/api';

/**
 * Delete driver request
 * @category API/DELETE
 * @return {Promise} delete api call
 * @example
 * deleteDriver(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteDriver = (driverId: string): Promise<{ data: Driver }> => api.delete(`/driver/${driverId}`);
