import { useCallback, useEffect, useState } from 'react';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { getVehicleById } from '../../../../api/get/vehicle.get';
import { AssignVehiclePayload, Vehicle, VehicleSource } from '../../../../models/vehicle';
import { isR2B, isR2BNew, isR2BUsed, isRental } from '../../../../consts';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { getAllBranches } from '../../../../api/get/branch.get';
import { OptionList } from '../../../../utils/props';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../../common/styles/Colors';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { MultiValue, SingleValue } from 'react-select';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { evaluateAndAssign } from '../../../../api/post/vehicle.post';
import { Text } from '../../../../uiComponents/text/text';
import { AxiosError } from 'axios';
import { AssignVehicleFields, DropdownMenuAbove } from '../../order/order.styles';
import { ApplicationProgress, ApplicationStatus } from '../../../../models/application';
import { AgreementType } from '../../../../models/agreement';
import { Branch } from '../../../../models/branch';

interface AssignVehicleFormProps {
  vehicleId: string;
  application: ApplicationProgress;
  onClose: (refetchData: boolean) => void;
}

export const AssignVehicleForm = ({ vehicleId, application, onClose }: AssignVehicleFormProps) => {
  const [branches, setBranches] = useState<OptionList[]>();
  const [netValue, setNetValue] = useState<number>();
  const [vatValue, setVatValue] = useState<number>();
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [grossValue, setGrossValue] = useState<number>();
  const [dataRetrieved, setDataRetrieved] = useState<boolean>();
  const [agreementLength, setAgreementLength] = useState<number>();
  const [weeklyAmount, setWeeklyAmount] = useState<number>();
  const [deposit, setDeposit] = useState<number>();
  const [serviceInterval, setServiceInterval] = useState<number>();
  const [mileage, setMileage] = useState<number>();
  const [weeklyRentalValue, setWeeklyRentalValue] = useState<number>();
  const [vehicleSource, setVehicleSource] = useState<VehicleSource>();
  const [weeklyRentalVATPercentage, setWeeklyRentalVatPercentage] = useState<number>();
  const [vehicleTypeId, setVehicleTypeId] = useState<string>('');
  const [selectedBranch, setSelectedBranch] = useState<OptionList>();
  const [error, setError] = useState<string>();
  const applicationType: AgreementType = application?.application_type;
  const totalCredit: number = (agreementLength ?? 0) * (weeklyAmount ?? 0);
  const agreementType: AgreementType = application?.application_type;
  const applicationStatus: ApplicationStatus = application?.application_status;
  const accidentExcess = 1000;

  const fieldsValid: boolean =
    deposit != null &&
    serviceInterval != null &&
    serviceInterval > 0 &&
    accidentExcess != null &&
    mileage != null &&
    selectedBranch?.value != null;
  const canSubmit: boolean = isR2B(agreementType)
    ? agreementLength != null && agreementLength > 0 && weeklyAmount != null && weeklyAmount > 0 && fieldsValid
    : weeklyRentalValue != null && weeklyRentalVATPercentage != null && fieldsValid;

  const applicationId: string = application?.application_id;
  const driverId: string = application?.driver_id;

  useEffect(() => {
    if (dataRetrieved) {
      return;
    }
    getVehicleById(vehicleId).then((response: { data: Vehicle }) => {
      const vehicle: Vehicle = response?.data;
      if (applicationType && isR2B(applicationType)) {
        if (isR2BNew(applicationType)) {
          setNetValue(+(vehicle?.net_value ?? '0'));
          setVatValue(+(vehicle?.vat_value ?? '0'));
          setGrossValue(+(vehicle?.gross_value ?? '0'));
        }
        setAgreementLength(vehicle?.agreement_length_weeks ?? 0);
        setWeeklyAmount(+(vehicle?.agreement_value_weeks ?? '0'));
        setDeposit(+(vehicle?.deposit ?? '0'));
        setMileage(vehicle?.mileage ?? undefined);
      } else if (applicationType && isRental(applicationType)) {
        setDeposit(+(vehicle?.rental_deposit ?? '0'));
      }
      setServiceInterval(vehicle?.service_interval_mileage ?? undefined);
      setVehicleTypeId(vehicle?.vehicle_type_id);
      setVehicleSource(vehicle?.vehicle_source);
      setDataRetrieved(true);
    });
  }, [vehicleId, applicationType, dataRetrieved]);

  useEffect(() => {
    if (isR2BUsed(applicationType) && dataRetrieved) {
      const grossValue: number = totalCredit * 0.5;
      const netValue: number = grossValue / 1.2;
      setGrossValue(grossValue);
      setNetValue(netValue);
      setVatValue(grossValue - netValue);
    }
  }, [applicationType, totalCredit, agreementLength, weeklyAmount, dataRetrieved]);

  useEffect(() => {
    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      const branches: OptionList[] = response?.data?.map((branch: Branch) => {
        return { value: branch?.branch_id, label: branch?.branch_name };
      });
      setBranches(branches);
    });
  }, []);

  const onSubmit = useCallback(() => {
    const payload: AssignVehiclePayload = {
      accident_excess: accidentExcess ?? 0,
      agreement_length_weeks: agreementLength ?? 0,
      agreement_value_weeks: weeklyAmount ?? 0,
      application_id: applicationId,
      application_status: applicationStatus,
      application_type: applicationType,
      branch_id: selectedBranch?.value ?? '',
      deposit: deposit ?? 0,
      driver_id: driverId,
      gross_value: grossValue ?? 0,
      mileage: mileage ?? 0,
      net_value: netValue ?? 0,
      rental_value: weeklyRentalValue,
      service_interval: serviceInterval ?? 0,
      vat_value: vatValue ?? 0,
      vehicle_id: vehicleId,
      vehicle_type_id: vehicleTypeId,
      vehicle_source: vehicleSource,
    };
    setIsSubmitting(true);

    evaluateAndAssign(payload)
      .then(() => {
        onClose(true);
        setIsSubmitting(false);
      })
      .catch((e: AxiosError<{ message: string }>) => {
        setError(e?.response?.data?.message);
        setIsSubmitting(false);
      });
  }, [
    accidentExcess,
    agreementLength,
    weeklyAmount,
    applicationStatus,
    applicationType,
    selectedBranch,
    deposit,
    grossValue,
    mileage,
    netValue,
    weeklyRentalValue,
    vehicleSource,
    serviceInterval,
    vatValue,
    vehicleId,
    applicationId,
    vehicleTypeId,
    driverId,
    onClose,
  ]);

  return (
    <>
      {dataRetrieved && branches ? (
        <AssignVehicleFields>
          {isR2B(agreementType) && (
            <FieldGrid
              headers={['Net value', 'VAT value', 'Gross value', 'Total credit']}
              values={[
                `£${netValue?.toFixed(2)}` ?? '',
                `£${vatValue?.toFixed(2)}` ?? '',
                `£${grossValue?.toFixed(2)}` ?? '',
                `£${totalCredit.toFixed(2)}` ?? '',
              ]}
              numColumns={4}
            />
          )}
          <GridLayout styled={{ marginTop: 32 }} gap="24px 16px" template={2}>
            {isRental(agreementType) ? (
              <>
                <TextField
                  type="number"
                  name="weekly_rental_value"
                  placeholder="Weekly value"
                  label="Weekly value"
                  defaultValue={weeklyRentalValue}
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeeklyRentalValue(+e.target.value)}
                />
                <TextField
                  type="number"
                  name="weekly_vat_percentage"
                  defaultValue={weeklyRentalVATPercentage}
                  placeholder="Weekly VAT percentage"
                  label="Weekly VAT percentage"
                  required
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeeklyRentalVatPercentage(+e.target.value)}
                />
              </>
            ) : (
              <>
                <TextField
                  type="number"
                  name="agreement_length"
                  placeholder="Agreement length"
                  label="Agreement length"
                  defaultValue={agreementLength}
                  required
                  disabled={isR2BNew(agreementType)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setAgreementLength(+e.target.value)}
                />
                <TextField
                  type="number"
                  name="weekly_amount"
                  defaultValue={weeklyAmount}
                  placeholder="Weekly amount"
                  label="Weekly amount"
                  required
                  disabled={isR2BNew(agreementType)}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setWeeklyAmount(+e.target.value)}
                />
              </>
            )}
            <TextField
              type="number"
              name="deposit"
              defaultValue={deposit}
              placeholder="Deposit"
              label="Deposit"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDeposit(+e.target.value)}
            />
            <TextField
              type="number"
              name="service_interval"
              placeholder="Service interval"
              defaultValue={serviceInterval}
              label="Service interval"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setServiceInterval(+e.target.value)}
            />
            <TextField
              type="number"
              defaultValue={mileage}
              name="mileage"
              placeholder="Mileage"
              label="Mileage"
              required
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setMileage(+e.target.value)}
            />
            <div>
              <TextFieldLabel $isRequired>Branch</TextFieldLabel>
              <DropdownMenuAbove
                placeholder="Branch"
                name="branch"
                options={branches}
                onSelect={(option: SingleValue<string | OptionList> | MultiValue<string | OptionList>) =>
                  setSelectedBranch(option as OptionList)
                }
              />
            </div>
          </GridLayout>
          {error && (
            <Text styled={{ marginTop: 24 }} variant="body7" color={STATUS_RED} weight={300} block>
              {error}
            </Text>
          )}
          <FlexLayout itemsX="end" gap={16} styled={{ marginTop: 24 }}>
            <PrimaryButton
              styled={{ width: 96 }}
              onClick={() => onSubmit()}
              disabled={!canSubmit}
              isProcessing={isSubmitting}
            >
              Submit
            </PrimaryButton>
            <SecondaryButton styled={{ width: 96 }} onClick={() => onClose(false)}>
              Cancel
            </SecondaryButton>
          </FlexLayout>
        </AssignVehicleFields>
      ) : (
        <FlexLayout itemsX="center" styled={{ marginTop: 24 }}>
          <Spinner color={PRIMARY_PURPLE} size={24} />
        </FlexLayout>
      )}
    </>
  );
};
