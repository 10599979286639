import React, { useState, useEffect } from 'react';
import { FlexLayout } from '../../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../../../../../common/styles/Colors';
import { Radial } from '../../../../../../uiComponents/customComponents/radialChart/radialChart';

interface TelematicsLargeCardProps {
  title: string;
  weekRange: string;
  weeklyMileage: number;
  yearlyMileage: number;
}

export const TelematicsLargeCard = ({ title, weekRange, weeklyMileage, yearlyMileage }: TelematicsLargeCardProps) => {
  const [milageValues, setMileageValues] = useState({
    mileageNumber: 0,
    percentage: 0,
    weeklyMileageRemaining: 0,
    mileagePercentageComparedToLimit: 0,
  });

  const calculateAndSetMileageValues = (weeklyMileage: number) => {
    const mileageNumber = Number(weeklyMileage);
    const percentage = (mileageNumber / 1000) * 100;
    const weeklyMileageRemaining = 1000 - mileageNumber;
    const mileagePercentageComparedToLimit = 0;

    return setMileageValues({
      mileageNumber,
      percentage,
      weeklyMileageRemaining,
      mileagePercentageComparedToLimit,
    });
  };

  useEffect(() => {
    if (weeklyMileage) {
      calculateAndSetMileageValues(weeklyMileage);
    }
  }, [weeklyMileage]);

  return (
    <FlexLayout
      styled={{
        backgroundColor: `${SECONDARY_PURPLE_5}`,
        borderRadius: '16px',
        color: `${PRIMARY_PURPLE}`,
        marginTop: 16,
        padding: 10,
      }}
      vertical
    >
      <FlexLayout itemsX="space-between">
        <Text variant="body6" weight={500}>
          {title}
        </Text>
        <Text variant="body6" weight={500}>
          {weekRange}
        </Text>
      </FlexLayout>
      <FlexLayout itemsX="start" gap={32}>
        <div>
          <Radial
            progress={milageValues.percentage}
            middleText={`${milageValues.percentage.toFixed(1)}%`}
            width={80}
            margin={5}
          />
        </div>
        <FlexLayout styled={{ marginTop: 16 }} gap={8} vertical>
          <Text>{`${milageValues.mileageNumber} / 1000 miles`}</Text>
          <Text>{`${milageValues.weeklyMileageRemaining} miles remaining`}</Text>
          <Text>{`${yearlyMileage} miles yearly limit`}</Text>
        </FlexLayout>
      </FlexLayout>
    </FlexLayout>
  );
};
