import styled from 'styled-components';
import { motion } from 'framer-motion';
import { TextField } from '../inputs/textField/textField';
import { Card } from '../../pages/drivers/driver/driverInfoCard/driverInfoCard.styles';
import { PRIMARY_WHITE, PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../common/styles/Colors';

export const Container = styled(Card)`
  background-color: ${PRIMARY_WHITE};
`;

export const StyledTextField = styled(TextField)`
  input {
    background: ${SECONDARY_PURPLE_5};
    border-color: ${SECONDARY_PURPLE_5};
  }
`;

export const NotesHolder = styled.div`
  overflow: auto;
  overflow-x: hidden;
`;

export const NoteContent = styled(motion.div)`
  border-radius: 16px;
  border: 1px solid ${PRIMARY_PURPLE};
  color: ${PRIMARY_PURPLE};
  font-size: 14px;
  display: block;
  align-items: center;
  position: relative;
  margin: 5px 0;
  padding: 5px 15px;
  width: 100%;
`;

export const Icon = styled.div`
  display: inline-flex;
  align-items: right;
  align-content: right;
  left: 2px;
`;
