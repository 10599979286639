import { GenericAbortSignal } from 'axios';
import { Application, ApplicationProgress, ApplicationSections } from '../../models/application';
import api from '../../utils/api';

/**
 * Get all application & driver & agreement data for order process
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllApplications(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const progressChecker = (
  applicationId: string
): Promise<{ data: { progress: ApplicationProgress; sections: ApplicationSections } }> =>
  api.get(`application/${applicationId}/progress-check`);

/**
 * Get all courtesy application & driver & agreement data for order process
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllApplications(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const progressCourtesyChecker = (
  applicationId: string
): Promise<{ data: { progress: ApplicationProgress; sections: ApplicationSections } }> =>
  api.get(`application/${applicationId}/courtesy-progress-check`);

/**
 * Get  application by id request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllApplications(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getApplication = (applicationId: string) => api.get(`application/${applicationId}`);

/**
 * Get all applications request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllApplications(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const getAllApplications = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: Application[] }> => api.get(`application${query && `?${query}`}`, { signal });

export const getApplicationsByServicingId = (servicingId: string) => api.get(`application/servicing/${servicingId}`);
