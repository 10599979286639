import { models } from 'powerbi-client';
import { PowerBIEmbed } from 'powerbi-client-react';

import './powerBi.css';

interface PowerBiReportProps {
  embedUrl: string;
  accessToken: string;
  reportId: string;
}

export const PowerBi = (props: PowerBiReportProps) => {
  const { embedUrl, accessToken, reportId } = props;

  return (
    <PowerBIEmbed
      embedConfig={{
        type: 'report', // Supported types: report, dashboard, tile, visual, qna, paginated report and create
        id: reportId,
        embedUrl: embedUrl,
        accessToken: accessToken,
        tokenType: models.TokenType.Embed,
        settings: {
          panes: {
            filters: {
              expanded: false,
              visible: false,
            },
          },
          background: models.BackgroundType.Transparent,
        },
      }}
      cssClassName="report-container"
    />
  );
};
