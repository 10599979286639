import React, { useState, useCallback, useEffect, Fragment } from 'react';
import { FiEdit } from 'react-icons/fi';
import { GiCancel } from 'react-icons/gi';
import { GrNotes } from 'react-icons/gr';
import moment, { Moment } from 'moment';
import { NotesContainer } from './driverPaymentTool.styles';
import { RenderActionList } from './utils/renderActionList';
import { isHoliday, getSummaryStatus, getBookedDates, statusToString } from './utils/utils';
import { UPCOMING_STATUS, CANCELLED_STATUS, LIVE_STATUS } from '../../../../consts/status';
import { FRIENDLY_FINANCE, isR2B, isPlus8, isBurst } from '../../../../consts/plans';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { PaymentToolSetUp } from '../../../../uiComponents/customComponents/paymentToolSetUp/paymentToolSetUp';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FieldGrid } from '../../../../uiComponents/layouts/fieldGrid/fieldGrid';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../../uiComponents/modals/modal';
import { ConfirmationModal } from '../../../../uiComponents/modals/confirmationModal/confirmationModal';
import { ActionIcon } from '../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../uiComponents/table/table';
import { paymentToolTableColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableNumberCell,
  TableTagCell,
  TableTextCell,
  getQueryString,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../uiComponents/text/text';
import { getDriverFreezes, getDriverHolidays, getOrdwayDriverHolidayEgibility } from '../../../../api/get/driver.get';
import { DriverFreeze, DriverPaymentFreeze, CurrentPeriodFreezeDetails } from '../../../../models/freeze';
import {
  DriverHoliday,
  DriverPaymentHoliday,
  CurrentPeriodHolidayDetails,
  DriverOrdwayHolidayEgibilityDetails,
} from '../../../../models/holiday';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_70 } from '../../../../common/styles/Colors';
import { renderNotification } from '../../../../utils/utils';
import { useAppSelector } from '../../../../store-hooks';

interface DriverPaymentToolDetails {
  driverId: string;
  driverName: string;
  agreementId: string;
  agreementStatus: string;
  agreementType: string;
  ordwayCustomerId: string | null;
  ordwaySubscriptionId: string | null;
}

interface DriverPaymentToolProps<T extends DriverPaymentHoliday | DriverPaymentFreeze> {
  driverDetails: DriverPaymentToolDetails;
  tableHeader: string;
  batches?: T[];
  fetchAgreementData?: () => void;
  apiCancelBatch: (batchId: string) => Promise<void>;
  apiCancelBatchItem: (actionId: string, itemId: string) => Promise<void>;
  isPaymentHoliday?: boolean;
}

// DriverPaymentTool is either for Holidays or Payment Skip
export const DriverPaymentTool = <T extends DriverPaymentHoliday | DriverPaymentFreeze>({
  driverDetails,
  tableHeader,
  batches,
  fetchAgreementData,
  apiCancelBatch,
  apiCancelBatchItem,
  isPaymentHoliday,
}: DriverPaymentToolProps<T>): JSX.Element => {
  const { driverId, driverName, agreementId, agreementStatus, agreementType, ordwayCustomerId, ordwaySubscriptionId } =
    driverDetails;

  const showHoliday: boolean | string =
    agreementId && agreementStatus === LIVE_STATUS && (isR2B(agreementType) || agreementType === FRIENDLY_FINANCE);

  const showPaymentSkip: boolean | string = agreementId && agreementStatus === LIVE_STATUS;

  const holidayTotal: number = isPlus8(agreementType) || isBurst(agreementType) ? 8 : 4;

  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [isToolOpen, setIsToolOpen] = useState<boolean>(false);
  const [employeeDetails, setEmployeeDetails] = useState<string>('');
  const [actionData, setActionData] = useState<DriverPaymentHoliday[] | DriverPaymentFreeze[]>();
  const [actionSummary, setActionSummary] = useState<CurrentPeriodHolidayDetails | CurrentPeriodFreezeDetails>();
  const [isBatchModal, setIsBatchModal] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<DriverPaymentHoliday | DriverPaymentFreeze | null>(null);
  const [itemToEdit, setItemToEdit] = useState<DriverPaymentHoliday | DriverPaymentFreeze>();
  const [itemNotesOpen, setItemNotesOpen] = useState<boolean>(false);
  const [itemNotes, setItemNotes] = useState<string>('');
  const notes: string[] = itemNotes?.split('\n');

  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    setSearchString,
    getSortDirection,
    setNumRowsPerPage,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const pulseUser = useAppSelector((state) => state.pulseUser);

  const getEmployeeDetails = useCallback(
    async ({ updated_date }: DriverPaymentHoliday | DriverPaymentFreeze): Promise<void> => {
      setEmployeeDetails(
        `${pulseUser?.first_name} ${pulseUser?.last_name} on ${moment(updated_date).format('DD MMM YYYY HH:mm:ss')}`
      );
    },
    [pulseUser]
  );

  const geSummary = useCallback((): void => {
    if (ordwayCustomerId) {
      getOrdwayDriverHolidayEgibility(ordwayCustomerId).then(
        (response: { data: DriverOrdwayHolidayEgibilityDetails }) => {
          if (response?.data) {
            setActionSummary(response?.data?.currentPeriodHoliday);
          }
        }
      );
    }
  }, [ordwayCustomerId]);

  const isBeforeDeadline = ({ commencement_date }: DriverPaymentHoliday | DriverPaymentFreeze): boolean => {
    const startDate: Date = new Date(commencement_date);
    const today: Date = new Date();
    const deadline: Moment = moment(startDate).subtract(1, 'days');
    if (moment(today).isBefore(deadline, 'day') || (moment(today).isSame(deadline, 'day') && today.getHours() < 16)) {
      return true;
    }
    return false;
  };

  const handleGetResponse = useCallback(
    (data: (DriverPaymentHoliday | DriverPaymentFreeze)[]): void => {
      const rows = data?.map((item) => {
        let finalWeek: DriverHoliday | DriverFreeze | undefined;
        let hasUpcoming: boolean;
        let hasCancelled: boolean;

        if (isHoliday(item)) {
          // Handle DriverPaymentHoliday
          finalWeek = item?.holidays?.filter((holiday) => holiday.holiday_status !== CANCELLED_STATUS)?.at(-1);
          hasUpcoming = item?.holidays?.some((holiday) => holiday.holiday_status === UPCOMING_STATUS);
          hasCancelled = item?.holidays?.some((holiday) => holiday.holiday_status === CANCELLED_STATUS);
        } else {
          // Handle DriverPaymentFreeze
          finalWeek = item?.freezes?.filter((freeze) => freeze.status_code !== CANCELLED_STATUS)?.at(-1);
          hasUpcoming = item?.freezes?.some((freeze) => freeze.status_code === UPCOMING_STATUS);
          hasCancelled = item?.freezes?.some((freeze) => freeze.status_code === CANCELLED_STATUS);
        }

        const lengthValue = isHoliday(item) ? item.holiday_length : item.freeze_length;

        return {
          rowData: { data: item },
          cells: [
            <TableTextCell
              value={`${item.vrm} ${item.agreement_type} (${item.agreement_status})`}
              color={SECONDARY_PURPLE_70}
            />,
            <TableTextCell value={moment(item.commencement_date).format('DD MMM YYYY')} color={SECONDARY_PURPLE_70} />,
            <TableTextCell
              value={finalWeek ? moment(finalWeek.commencement_date).add(6, 'days').format('DD MMM YYYY') : 'N/A'}
              color={SECONDARY_PURPLE_70}
            />,
            <TableNumberCell value={lengthValue} color={SECONDARY_PURPLE_70} />,
            <TableTagCell tags={[getSummaryStatus(item)]} />,
            <FlexLayout gap={16}>
              {isBeforeDeadline(item) && hasUpcoming && item.agreement_status === LIVE_STATUS && !hasCancelled && (
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setItemToEdit(item);
                    setIsToolOpen(true);
                  }}
                  icon={<FiEdit size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Edit batch"
                />
              )}

              {isBeforeDeadline(item) && hasUpcoming && item.agreement_status === LIVE_STATUS && (
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsBatchModal(true);
                    setSelectedItem(item);
                  }}
                  icon={<GiCancel size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Cancel batch"
                />
              )}

              {item.override_reason && (
                <ActionIcon
                  onClick={(e) => {
                    e.stopPropagation();
                    setItemNotes(item.override_reason ?? '');
                    setItemNotesOpen(true);
                    getEmployeeDetails(item);
                  }}
                  icon={<GrNotes size={24} color={PRIMARY_PURPLE} />}
                  tooltip="Notes"
                />
              )}
            </FlexLayout>,
          ],
        };
      });

      setTableData(rows);
      setTotalRows(rows.length);
      setActionData(data as DriverPaymentHoliday[] | DriverPaymentFreeze[]);
    },
    [setTotalRows, setTableData, setActionData, getEmployeeDetails]
  );

  const fetchList = useCallback(
    (queryString: string): void => {
      if (batches) {
        handleGetResponse(batches);
      } else {
        if (isPaymentHoliday) {
          getDriverHolidays(queryString).then((response: { data: DriverPaymentHoliday[] }) => {
            handleGetResponse(response.data);
          });
        } else {
          getDriverFreezes(queryString).then((response: { data: DriverPaymentFreeze[] }) => {
            const updatedData = response?.data?.map((batch) => ({
              ...batch,
              freezes: batch?.freezes?.map((freeze: DriverFreeze) => ({
                ...freeze,
                status_code: statusToString(freeze.status_code),
              })),
            }));
            handleGetResponse(updatedData);
          });
        }
      }
      if (ordwayCustomerId) {
        geSummary();
      }
    },
    [handleGetResponse, geSummary, batches, ordwayCustomerId, isPaymentHoliday]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean
    ): void => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchList(`${driverId}?${queryString}`);
    },
    [fetchList, setTableData, goToPageNumber, tableFilters, driverId]
  );

  const cancelActionBatch = useCallback(
    async (actionBatch: DriverPaymentHoliday | DriverPaymentFreeze) => {
      try {
        await apiCancelBatch(actionBatch?.id);
        if (batches) {
          fetchAgreementData?.();
        } else {
          const getActions = isPaymentHoliday ? getDriverHolidays : getDriverFreezes;
          getActions(driverId).then((response: { data: (DriverPaymentHoliday | DriverPaymentFreeze)[] }) => {
            handleGetResponse(response.data);
          });
          fetchList(driverId);
        }
        geSummary();
        renderNotification('success', 'Success', 'Batch cancelled successfully');
        return true;
      } catch (err) {
        renderNotification('error', 'Error', `Failed to cancel the batch with error: ${err}`, false);
      }
    },
    [driverId, fetchAgreementData, geSummary, handleGetResponse, batches, apiCancelBatch, isPaymentHoliday, fetchList]
  );

  useEffect(() => {
    if (tableData == null) {
      setSortingColumn('commencement_date');
      fetchList(driverId);
    }
  }, [fetchList, setSortingColumn, tableData, driverId]);

  useEffect(() => {
    setTableData(undefined);
  }, [setTableData, driverId]);

  return (
    <>
      <CollapsiblePanel
        header={
          <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
            {isPaymentHoliday ? 'Payment holidays' : 'Payment skip'}
          </Text>
        }
        expanded={!isCollapsed}
        onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
        styled={{ marginTop: 16 }}
      >
        <div>
          <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
            {tableHeader}
          </Text>
          <FlexLayout style={{ marginBottom: '16px' }} itemsX="space-between">
            <div>
              {actionSummary && showHoliday && isPaymentHoliday && (
                <FieldGrid
                  numColumns={3}
                  headers={['Total weeks:', 'Remaining weeks:', 'Weeks taken:']}
                  values={[
                    holidayTotal,
                    actionSummary?.numberOfWeeksTaken,
                    holidayTotal - actionSummary?.numberOfWeeksTaken < 0
                      ? 0
                      : holidayTotal - actionSummary?.numberOfWeeksTaken,
                  ]}
                />
              )}
            </div>
            {showHoliday && isPaymentHoliday && (
              <FlexLayout style={{ height: '40px' }} itemsX="end">
                <PrimaryButton onClick={() => setIsToolOpen(true)}>Book holidays</PrimaryButton>
              </FlexLayout>
            )}
            {showPaymentSkip && !isPaymentHoliday && (
              <FlexLayout style={{ height: '40px' }} itemsX="end">
                <PrimaryButton onClick={() => setIsToolOpen(true)}>Skip payment</PrimaryButton>
              </FlexLayout>
            )}
          </FlexLayout>
          <Table
            variant="compact"
            tableTheme="purple"
            embedded
            header={tableHeader}
            onColumnHeaderClick={(columnId: string) =>
              applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
            }
            sortAscending={sortAscending}
            columns={paymentToolTableColumns}
            rows={tableData}
            totalRows={totalRows}
            rowsPerPage={numRowsPerPage}
            currentPageNumber={pageNumber}
            sortingColumn={sortingColumn}
            filters={[]}
            getTableRowData={(id: string) => {
              const expandedDriverHoliday = tableData?.filter(
                (holidayBatch) => holidayBatch.rowData.data.id === id
              )?.[0].rowData.data;
              return Promise.resolve(
                <RenderActionList
                  actionsData={expandedDriverHoliday}
                  getDriverActionSummary={geSummary}
                  fetchData={() => (batches ? fetchAgreementData?.() : fetchList(driverId))}
                  apiCancelBatchItem={apiCancelBatchItem}
                  isHolidayAction={isPaymentHoliday}
                />
              );
            }}
            onSearchChange={(value: string) => {
              setSearchString(value);
              applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
            }}
            filterQuery={filterQuery}
            goToPage={(pageNumber: number) => {
              goToPageNumber(pageNumber);
              applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
            }}
            onNumRowsPerPageChange={(value: number) => {
              setNumRowsPerPage(value);
              goToPageNumber(0);
              applyFilters(0, value, searchString, sortingColumn, sortAscending);
            }}
            styled={{ marginTop: 24 }}
          />
        </div>
      </CollapsiblePanel>

      <Modal
        title={`${itemToEdit ? 'Edit' : 'New'} ${isPaymentHoliday ? 'holiday' : 'payment skip'}`}
        open={isToolOpen}
        onClose={() => {
          setIsToolOpen(false);
          setItemToEdit(undefined);
          if (batches) {
            fetchAgreementData?.();
          } else {
            fetchList(driverId);
          }
          geSummary();
        }}
        showClose
      >
        <PaymentToolSetUp
          driverDetails={{
            ordway_customer_id: ordwayCustomerId,
            ordway_subscription_id: ordwaySubscriptionId,
            agreement_type: agreementType,
            id: driverId,
            agreement_id: agreementId,
            driver_name: driverName,
          }}
          itemToEdit={itemToEdit}
          bookedDates={getBookedDates(actionData ?? [])}
          isHolidayTool={isPaymentHoliday}
        />
      </Modal>

      <Modal title="Override details" open={itemNotesOpen} onClose={() => setItemNotesOpen(false)} showClose>
        <NotesContainer>
          {isPaymentHoliday ? 'The following holiday rules were broken but have been overridden:' : ''}
          {notes?.map((note, i) => (
            <Fragment key={note + i.toString()}>
              {note === '' ? <br /> : <NotesContainer>{note}</NotesContainer>}
            </Fragment>
          ))}
          <div>{`Authorised by ${employeeDetails}`}</div>
        </NotesContainer>
      </Modal>

      {selectedItem && (
        <ConfirmationModal
          title={`Are you sure you want to cancel this ${isPaymentHoliday ? 'holiday' : 'freeze'} batch?`}
          isOpen={isBatchModal}
          onClose={() => {
            setIsBatchModal(false);
            setSelectedItem(null);
          }}
          onConfirm={() => {
            cancelActionBatch(selectedItem);
            setIsBatchModal(false);
          }}
          confirmButtonCaption={'Yes'}
          closeButtonCaption={'No'}
        />
      )}
    </>
  );
};
