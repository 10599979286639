import { AssessmentResponse } from '../../models/assessment';
import { DriverAssessment, DriverTagDropDown } from '../../models/driver';
import api from '../../utils/api';

/**
 * POST assessment by Application request
 * @category API/POST
 * @return {Promise} Returns a promise for the api request
 * @example
 * PostAssessment(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const onlineAssessment = (
  assessmentTypeId: string | null | undefined,
  payload: { assessment_id?: string; driver_id?: string; score?: number; notes?: string }
): Promise<{
  data: AssessmentResponse;
}> => api.patch(`assessment/${assessmentTypeId}/online`, payload);

export const createPracticalAssessment = (
  assessmentId: string | null | undefined,
  payload: {
    driver_id: string | undefined;
    driver_tag: DriverTagDropDown;
    result: string;
    document_type: string;
  }
): Promise<{ data: AssessmentResponse }> => api.patch(`assessment/${assessmentId}/practical`, payload);

export const assessmentNotNeeded = (
  assessmentId: string,
  payload: {
    notes?: string;
  }
): Promise<{
  data: AssessmentResponse;
}> => api.patch(`assessment/${assessmentId}/assessmentNotNeeded`, payload);

export const updateAssessment = (assessmentId: string, payload: DriverAssessment) => {
  return api.patch(`assessment/${assessmentId}`, payload);
};
