import React from 'react';
import { StyledTag } from './tag.styles';
import { withStyledProps } from '../../../utils/colorUtils';

interface TagProps {
  color: string;
  children: JSX.Element | string;
}

export const Tag = withStyledProps(({ color, children, ...props }: TagProps) => {
  return (
    <StyledTag $color={color} {...props}>
      {children}
    </StyledTag>
  );
});
