import styled, { css } from 'styled-components';
import { GRAY_2, PRIMARY_GREEN, SECONDARY_PURPLE_30, SECONDARY_PURPLE_5, STATUS_RED } from '../../common/styles/Colors';
import { GridLayout } from '../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';

export const ResultIndicator = styled(FlexLayout)<{ $result: string }>`
  border-radius: 50px;
  margin-top: 8px;
  width: 158px;
  height: 40px;

  ${(props) => {
    if (props?.$result === 'PASSED') {
      return css`
        background-color: ${PRIMARY_GREEN};
      `;
    } else if (props?.$result === 'FAILED') {
      return css`
        background-color: ${STATUS_RED};
      `;
    } else {
      return css`
        background-color: ${GRAY_2};
      `;
    }
  }}
`;

export const Heading = styled(GridLayout)`
  background: ${SECONDARY_PURPLE_5};
  border-radius: 50px;
  padding: 5px;
  margin-bottom: 10px;
  text-align: left;
`;

export const AssessmentNotes = styled.div`
  flex: 1;
`;

export const NotesContainer = styled.div`
  border-radius: 16px;
  border: 1px solid ${SECONDARY_PURPLE_30};
  padding: 16px;
  margin-top: 8px;
  min-height: 110px;
`;

export const AssessmentContainer = styled.div`
  margin-top: 16px;
`;
