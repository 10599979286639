import styled, { css } from 'styled-components';
import { withPx } from '../../../utils/props';

function resolveGridTemplate(templateValue: number | string): string {
  if (typeof templateValue === 'string') {
    return templateValue;
  }
  return `repeat(${templateValue}, 1fr)`;
}

export const StyledGridLayout = styled.div<{
  $inline: boolean;
  $template: number | string;
  $gap: number | string | undefined;
  $direction: 'x' | 'y' | undefined;
  $dense: boolean | undefined;
}>`
  display: ${({ $inline }) => ($inline ? 'inline-grid' : 'grid')};
  vertical-align: top;

  ${({ $template, $direction, $dense }) => {
    if ($template == null) {
      return undefined;
    }

    if ($direction === 'y') {
      return css`
        grid-template-rows: ${resolveGridTemplate($template)};
        grid-auto-flow: column ${$dense && 'dense'};
      `;
    }

    return css`
      grid-template-columns: ${resolveGridTemplate($template)};
      grid-auto-flow: row ${$dense && 'dense'};
    `;
  }}

  ${({ $gap }) =>
    $gap &&
    css`
      grid-gap: ${withPx($gap)};
    `}
`;
