import { FRIENDLY_FINANCE, REPO, SWAP, TERMINATED, TERMINATE_AND_SERVICE, isR2B } from '../../../consts';
import { Vehicle } from '../../../models/vehicle';

export const terminatedAgreementReasonText = (vehicleData: Vehicle, reasonForTerminate: string) => {
  const accountUpToDate = 'Driver’s account is paid up-to-date and no refund needs to be made.';
  let text: string = '';

  if (isR2B(vehicleData?.agreement_type ?? '') || vehicleData?.agreement_type === FRIENDLY_FINANCE) {
    text =
      vehicleData?.arrears === '0.00'
        ? accountUpToDate
        : `Please note that the driver's account is in arrears of £${vehicleData?.arrears}`;
    return text;
  }

  const arrears: number = +(vehicleData?.arrears ?? '0');
  const refund: number = +(vehicleData?.available_refund_amount ?? '0');
  const driverNetAmount: number = refund - arrears;

  if (!isR2B(vehicleData?.agreement_type ?? '')) {
    if (reasonForTerminate === TERMINATED || reasonForTerminate === TERMINATE_AND_SERVICE) {
      if (driverNetAmount === 0) {
        return accountUpToDate;
      }
      const message = `Please note that the driver’s account is in arrears of £${arrears.toFixed(2)} and 
        a weekly amount refund of £${refund.toFixed(2)} needs to be made towards the driver’s account.`;

      const conclusion =
        Math.sign(driverNetAmount) === 1
          ? `Therefore we owe the driver £${driverNetAmount}.`
          : `Therefore the driver owes us £${Math.abs(driverNetAmount).toFixed(2)}.`;
      return `${message} ${conclusion}`;
    }

    if (reasonForTerminate === REPO || reasonForTerminate === SWAP) {
      text =
        vehicleData?.arrears === '0.00'
          ? accountUpToDate
          : `Please note that the driver’s account is in arrears of £${arrears.toFixed(2)}.`;
      return text;
    }
  }

  return text;
};
