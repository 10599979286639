import { css, keyframes, styled } from 'styled-components';
import { GRAY_5 } from '../../../common/styles/Colors';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';

const tooltipframes = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

export const Icon = styled(FlexLayout)`
  position: relative;
  width: 40px;
  height: 40px;
  z-index: 1;
`;

export const IconWrapper = styled(FlexLayout)<{ $disabled: boolean }>`
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.3 : 1)};
  width: 100%;
  height: 100%;
`;

export const Tooltip = styled.div<{ $show: boolean; $noOffsetX?: boolean }>`
  position: absolute;
  border-radius: 16px;
  padding: 4px 16px;
  background-color: ${GRAY_5};
  opacity: ${(props) => (props.$show ? 1 : 0)};
  top: -12px;
  transform: translateX(calc(-50% + 16px));
  z-index: 0;
  pointer-events: none;

  ${(props) => {
    if (props?.$show) {
      return css`
        animation: ${tooltipframes} 0.3s linear 1;
      `;
    }
    return null;
  }}

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    bottom: -6px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: ${GRAY_5};
  }
`;
