import { AiOutlineWarning } from 'react-icons/ai';
import styled from 'styled-components';

export const Wrapper = styled.div`
  background-color: #fffff0;
  border: 1px solid #e4d00a;
  border-radius: 5px;
  padding: 20px;
  position: relative;
  margin-top: 20px;
`;

export const WarningHeader = styled.div`
  display: flex;
  align-items: center;
`;

export const WarningIcon = styled(AiOutlineWarning)`
  font-size: 24px;
  color: #721c24;
  margin-right: 10px;
`;

export const WarningTitle = styled.h2`
  font-size: 18px;
  color: #721c24;
  margin: 0;
`;

export const WarningSubTitle = styled.p`
  font-size: 14px;
  color: #721c24;
  margin: 10px 0 30px 0px;
`;
