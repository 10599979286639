import { ForgotPassword, LoginDetails, LoginResponse } from '../../models/employee';
import api from '../../utils/api';

/**
 * Log In Request
 * @category API/POST
 * @param {object} loginData - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * LogIn(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const logIn = (loginData: LoginDetails): Promise<{ data: LoginResponse }> => api.post('user/login', loginData);

export const requestPasswordReset = (email: string) => api.post('user/request-password-reset', { email, web: true });

export const forgotPassword = (tokenId: string, payload: ForgotPassword) =>
  api.post(`user/forgot-password/${tokenId}`, { ...payload, web: true });
