import React from 'react';
import { H1, H2, H3, H4, StyledText } from './text.styles';
import { withStyledProps } from '../../utils/colorUtils';

export type TextVariant =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h4'
  | 'body1'
  | 'body2'
  | 'body3'
  | 'body4'
  | 'body5'
  | 'body6'
  | 'body7'
  | 'body8'
  | 'body9';

type TextWeight = 800 | 500 | 400 | 300;

type TextAlign = 'left' | 'center' | 'right';

interface TextProps {
  color?: string;
  weight?: TextWeight;
  variant?: TextVariant;
  block?: boolean;
  children: JSX.Element | string | string[] | number;
  align?: TextAlign;
  onClick?: ((e: React.MouseEvent) => void) | null;
}

export interface TextStyleProps {
  $color: string | undefined;
  $weight?: number;
  $block?: boolean;
  $align?: TextAlign;
  $variant?: TextVariant;
}

export const TextSize: Record<TextVariant, string> = {
  h1: '32px; line-height: 42px;',
  h2: '28px; line-height: 38px;',
  h3: '24px; line-height: 32px;',
  h4: '20px; line-height: 27px;',
  body1: '28px; line-height: 38px;',
  body2: '24px; line-height: 32px;',
  body3: '22px; line-height: 30px;',
  body4: '20px; line-height: 27px;',
  body5: '18px; line-height: 25px;',
  body6: '16px; line-height: 22px;',
  body7: '14px; line-height: 18px;',
  body8: '12px; line-height: 16px;',
  body9: '10px; line-height: 14px;',
};

export const Text = withStyledProps(
  ({ color, align, weight, variant, block, onClick, children, ...props }: TextProps) => {
    if (variant === 'h1') {
      return (
        <H1
          $color={color}
          $weight={weight}
          $block={block}
          $align={align}
          onClick={(e: React.MouseEvent) => onClick?.(e)}
          {...props}
        >
          {children}
        </H1>
      );
    } else if (variant === 'h2') {
      return (
        <H2
          $color={color}
          $weight={weight}
          $block={block}
          $align={align}
          onClick={(e: React.MouseEvent) => onClick?.(e)}
          {...props}
        >
          {children}
        </H2>
      );
    } else if (variant === 'h3') {
      return (
        <H3
          $color={color}
          $weight={weight}
          $block={block}
          $align={align}
          onClick={(e: React.MouseEvent) => onClick?.(e)}
          {...props}
        >
          {children}
        </H3>
      );
    } else if (variant === 'h4') {
      return (
        <H4
          $color={color}
          $weight={weight}
          $block={block}
          $align={align}
          onClick={(e: React.MouseEvent) => onClick?.(e)}
          {...props}
        >
          {children}
        </H4>
      );
    } else {
      return (
        <StyledText
          $color={color}
          $weight={weight}
          $block={block}
          $align={align}
          $variant={variant}
          onClick={(e: React.MouseEvent) => onClick?.(e)}
          {...props}
        >
          {children}
        </StyledText>
      );
    }
  }
);
