import { FieldValues, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexContainer } from '../../../core/pageTitle/pageTitle.styles';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { Notification } from '../../../uiComponents/toast/toast';
import { createCity } from '../../../api/post/city.post';
import { updateCity } from '../../../api/patch/city.patch';
import { letterFormat } from '../../../utils/validations';
import { useState } from 'react';

interface CitySubmitValues extends FieldValues {
  city_name: string;
  city_id: string;
  country_id: string;
}

interface CreateEditCityFormProps {
  isInEdit: boolean;
  values?: CitySubmitValues | null;
  onFormSubmit: () => void;
  onClose: () => void;
}

const cityDetails = {
  city_name: '',
  city_id: '',
  country_id: '',
};

export const CreateEditCityForm = ({ isInEdit, values, onClose, onFormSubmit }: CreateEditCityFormProps) => {
  const [isFormSubmitting, setIsFormSubmitting] = useState<boolean>(false);

  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CitySubmitValues>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: isInEdit && values ? values : cityDetails,
  });

  const onSubmit = (submitValues: CitySubmitValues) => {
    setIsFormSubmitting(true);

    const { city_name, city_id, country_id } = submitValues;
    const payload = {
      city_name,
      city_id,
      country_id,
    };
    const callback = isInEdit ? updateCity : createCity;

    callback(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'City has been successfully created',
          isAlert: true,
        });
        onClose();
        onFormSubmit();
      })
      .catch(() => Notification({ type: 'error', title: 'Error', message: 'Error creating city', isAlert: true }))
      .finally(() => {
        setIsFormSubmitting(false);
      });
  };

  return (
    <form>
      <TextField
        styled={{ flex: 1 }}
        {...register('city_name', {
          required: 'City name is a required field',
          pattern: { value: letterFormat, message: 'Please use only letters' },
        })}
        label="City name"
        error={errors.city_name}
        type="text"
        placeholder="City name"
        required
      />
      <FlexContainer styled={{ marginTop: 20 }} itemsX="end">
        <SecondaryButton styled={{ marginRight: 10 }} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={isFormSubmitting} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexContainer>
    </form>
  );
};
