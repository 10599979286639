import React from 'react';
import { StyledPrimaryButton } from './primaryButton.styles';
import { Spinner } from '../../uiControls/spinner/spinner';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';

interface PrimaryButtonProps {
  disabled?: boolean;
  onClick: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  isGreen?: boolean;
  isProcessing?: boolean;
  children?: JSX.Element | string;
}

export const PrimaryButton = withStyledProps(
  ({ disabled, onClick, isGreen, isProcessing, children, ...props }: PrimaryButtonProps) => {
    return (
      <StyledPrimaryButton
        onClick={(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
          e.preventDefault();
          onClick(e);
        }}
        disabled={disabled}
        $isGreen={isGreen}
        $isProcessing={isProcessing}
        {...props}
      >
        <>{isProcessing ? <Spinner color={!isGreen ? PRIMARY_GREEN : PRIMARY_PURPLE} size={24} /> : children}</>
      </StyledPrimaryButton>
    );
  }
);
