import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { LoginDetails } from '../models/employee';
import { getAuthService } from './cognito/auth.service';

const authService = getAuthService();

export const apiSlice = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_ROOT,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: (await authService.getAuthHeaders()) ?? '',
    },
    prepareHeaders: async (headers) => {
      const token = await authService.getAuthHeaders();
      if (token) {
        headers.set('authorization', `${token}`);
      }

      return headers;
    },
  }),
  endpoints: (builder) => ({
    logIn: builder.query({
      query: (loginDetails: LoginDetails) => ({
        url: 'user/login',
        method: 'POST',
        body: loginDetails,
      }),
    }),
  }),
});
