// import { ChecklistItem, sliderLevels, vehicleInformationItems } from '../../../../../consts/conditionReport';

import { PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../../../../common/styles/Colors';
import { Text } from '../../../../../uiComponents/text/text';
import { SignatureScreen } from '../../../../../uiComponents/customComponents/signature/signature';
import { TextArea } from '../../../../../uiComponents/inputs/textArea/textArea';
import { useFormContext } from 'react-hook-form';
import { SignatureWrapper } from './styles';

export function SignOff() {
  const { control, register } = useFormContext();

  return (
    <div>
      <Text variant="body6" color={PRIMARY_PURPLE} weight={800} block>
        Condition report sign off
      </Text>
      <SignatureWrapper>
        <SignatureScreen
          name="signature"
          styled={{ width: '100%' }}
          control={control}
          required={{
            required: 'Please sign and click "Submit Signature"',
          }}
        />
        <div>
          <TextArea
            label="Notes"
            {...register('notes')}
            styled={{ height: 138, borderColor: PRIMARY_PURPLE, backgroundColor: SECONDARY_PURPLE_5 }}
          />
        </div>
      </SignatureWrapper>
    </div>
  );
}
