import { TextArea } from '../../uiComponents/inputs/textArea/textArea';
import { styled } from 'styled-components';
import { TextFieldLabel } from '../../uiComponents/inputs/textField/textField.styles';
import { PRIMARY_GREEN } from '../../common/styles/Colors';

export const StyledTextArea = styled(TextArea)`
  label {
    text-align: left;
    color: ${PRIMARY_GREEN};
  }
`;

export const TextAreaContainer = styled.div`
  width: 100%;
`;

export const StyledTextFieldLabel = styled(TextFieldLabel)`
  text-align: left;
  width: 100%;
`;
