import { CreateApplication, Declaration, Downpayment } from '../../models/application';
import api from '../../utils/api';

/**
 * Create Order Request
 * @category API/POST
 * @param {object} data - Data for order creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  driver_details: {},
 *  files: [],
 *  ...,
 * }
 * createApplication(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createApplication = (data: CreateApplication): Promise<{ data: { application_id: string } }> => {
  return api.post('application', data);
};

/**
 * Go Live Request
 * @category API/POST
 * @param {object} goLive - Data for go live
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  application_id: '',
 *  agreement_id: '',
 * }
 * goLive(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const goLive = (data: { application_id: string }) => {
  return api.post('application/go-live', data);
};

/**
 * Creates a downpayment for the application
 * @category API/POST
 * @return {Promise} create downpayment api call
 * @example
 * createDownpayment(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const downPayment = (payload: Downpayment) => {
  return api.post('application/create-downpayment', payload);
};

export const submitDeclaration = (payload: Declaration) => {
  return api.post('application/submit-declaration', payload);
};

/**
 * Creates a final downpayment for the application
 * @category API/POST
 * @return {Promise} create final downpayment api call
 * @example
 * createFinalDownpayment(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const finalDownPayment = (payload: Downpayment) => {
  return api.post('application/create-final-downpayment', payload);
};
