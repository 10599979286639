import { Vehicle } from '../../models/vehicle';
import { apiSlice } from '../../api/baseApi';

interface ListAndMergeVehiclesParams {
  query: string;
  refetch: boolean;
}

interface ListAndMergeVehiclesResponse {
  count: number;
  data: Vehicle[];
}

export const vehiclesList = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    listAndMergeVehiclesList: builder.query<ListAndMergeVehiclesResponse, ListAndMergeVehiclesParams>({
      query: ({ query }) => `vehicle${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useListAndMergeVehiclesListQuery } = vehiclesList;
