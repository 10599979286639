import React, { useState, useContext, useEffect, useCallback } from 'react';
import { BsPencil } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';
import { useTableFilters } from '../../../../../hooks/useTableFilters';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { ActionIcon } from '../../../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../../../uiComponents/table/table';
import { vehicleModelsColumns } from '../../../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../../../uiComponents/table/tableFilters/tableFilters';
import {
  DEFAULT_NUM_ROWS_PER_PAGE,
  TableTextCell,
  getQueryString,
} from '../../../../../uiComponents/table/tableUtils/tableUtils';
import { getAllVehicleTypes } from '../../../../../api/get/vehicleType.get';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { APP_CONTEXT } from '../../../../../utils/context';
import { OptionList } from '../../../../../utils/props';
import { VEHICLE_MODELS } from '../../../../../consts/routes';
import {
  VehicleModelPayload,
  useGetColorsListQuery,
  useGetFuelListQuery,
  useGetVehicleModelListQuery,
} from '../../../../../api/vehicleModel/vehicleModelApiSlice';
import { useGetVehicleManufacturerListQuery } from '../../../../../api/contracts/contractsDetailsApiSlice';

export const VehicleModelList = () => {
  const navigate = useNavigate();
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedModel, setSelectedModel] = useState<OptionList[]>([]);
  const [selectedFuelType, setSelectedFuelType] = useState<OptionList[]>([]);
  const [selectedColour, setSelectedColour] = useState<OptionList[]>([]);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=created_at:ASC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const { data: vehicleModelsList, isFetching: isVehicleTypesListFetching } = useGetVehicleModelListQuery({
    query: queryStringState,
    refetch: refetchData,
  });

  const handleGetVehicleTypeResponse = useCallback(
    (count: number, lists: VehicleModelPayload[]) => {
      const vehicleTypeList = lists?.map((list: VehicleModelPayload) => {
        return {
          rowData: { data: list },
          cells: [
            <TableTextCell value={list.manufacturer || '-'} />,
            <TableTextCell value={list.model} />,
            <TableTextCell value={list.spec || '-'} />,
            <TableTextCell value={list.exterior_colors?.join(', ')} />,
            <TableTextCell value={list.fuel_types?.join(', ')} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => navigate(`${VEHICLE_MODELS}/edit/${list.id}`)}
                icon={<BsPencil color={PRIMARY_PURPLE} size={24} data-tip="Edit" />}
                tooltip="Edit"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(vehicleTypeList);
      setTotalRows(count);
    },
    [navigate, setTableData, setTotalRows]
  );

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Select a manufacturer"
          options={(manufacturerList?.data || [])?.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })}
          multiValues={selectedManufacturer}
          title="Manufacturer:"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'fuel_types',
      element: (
        <DropDownFilter
          name="fuel_types"
          placeholder="Select a fuel type"
          multiValues={selectedFuelType}
          options={(fuelList?.data || [])?.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })}
          title="Fuel type:"
          onChange={(items) => setSelectedFuelType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'exterior_colors',
      element: (
        <DropDownFilter
          name="exterior_colors"
          placeholder="Color"
          multiValues={selectedColour}
          options={(colorsList?.data || [])?.map((item: { id: string; name: string }) => {
            return { value: item.id, label: item.name };
          })}
          title="Color"
          onChange={(items) => setSelectedColour(items as OptionList[])}
        />
      ),
    },
  ];

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setActiveSideNav('vehicleModelListPage');
    setPageTitle('Vehicle Model');
    setSortingColumn('created_at');
    setSortAscending(false);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'manufacturer_id', options: selectedManufacturer },
      { columnName: 'model', options: selectedModel },
      { columnName: 'fuel_types', options: selectedFuelType },
      { columnName: 'exterior_colors', options: selectedColour },
    ]);
  }, [setTableFilters, selectedManufacturer, selectedModel, selectedFuelType, selectedColour]);

  useEffect(() => {
    if (vehicleModelsList) {
      handleGetVehicleTypeResponse(vehicleModelsList.count, vehicleModelsList.data);
    }
  }, [vehicleModelsList, queryStringState, handleGetVehicleTypeResponse]);

  const onClearClick = useCallback(() => {
    setSelectedManufacturer([]);
    setSelectedModel([]);
    setSelectedFuelType([]);
    setSelectedColour([]);
  }, []);

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isVehicleTypesListFetching}
        header="Vehicle model list"
        actionButtonText="Create vehicle model"
        onActionButtonClick={() => {
          navigate(`${VEHICLE_MODELS}/create`, { state: VEHICLE_MODELS });
        }}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={vehicleModelsColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        filterQuery={filterQuery}
        filters={filters}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        downloadApi={getAllVehicleTypes}
        downloadName="Vehicle model"
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
      />
    </>
  );
};
