import React, { useState } from 'react';
import { BsExclamationCircle } from 'react-icons/bs';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30 } from '../../../../common/styles/Colors';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../uiComponents/text/text';
import { Notification } from '../../../../uiComponents/toast/toast';
import { completeAftercare } from '../../../../api/post/aftercare.post';

export const CompleteAftercare = ({ aftercareId, onClose }: { aftercareId: string; onClose: () => void }) => {
  const [completing, setCompleting] = useState<boolean>(false);

  const handleSubmit = () => {
    setCompleting(true);
    completeAftercare({
      aftercare_id: aftercareId,
    })
      .then(() => {
        onClose();
        setCompleting(false);
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Aftercare completed successfully',
          isAlert: true,
        });
      })
      .catch((e: Error) => {
        setCompleting(false);
        Notification({
          type: 'error',
          title: 'Error',
          message: e.message,
          isAlert: true,
        });
      });
  };
  return (
    <FlexLayout vertical itemsX="center" gap={16}>
      <BsExclamationCircle size={200} color={SECONDARY_PURPLE_30} />
      <Text styled={{ textAligh: 'center' }} variant="h4" weight={800} color={PRIMARY_PURPLE} block>
        You are about to make this aftercare agreement live
      </Text>
      <Text styled={{ textAligh: 'center' }} color={PRIMARY_PURPLE} variant="body6">
        Taking the aftercare agreement live will complete the current R2B agreement.
      </Text>
      <FlexLayout itemsX="center">
        <PrimaryButton isProcessing={completing} onClick={handleSubmit}>
          Go live and complete
        </PrimaryButton>
      </FlexLayout>
    </FlexLayout>
  );
};
