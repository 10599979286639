import { City } from '../../models/city';
import api from '../../utils/api';

/**
 * Delete city request
 * @category API/DELETE
 * @param {object} id - city id for the user to delete the package
 * @return {Promise} Returns a promise for the api request
 * @example
 * deleteCity(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteCity = (id: string): Promise<{ data: City }> => api.delete(`internal/city/${id}`);
