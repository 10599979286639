import { EditDocumentPayload } from '../../models/document';
import api from '../../utils/api';

/**
 * Update Document
 * @category API/PATCH
 * @param {string} id - Document id to update
 * @param {object} payload - Data for the document to be updated
 * @return {Promise} Returns a promise for the api request
 * Update driver by driver id request
 * @category API/PATCH
 * @param {object} payload - Data api to update the driver with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const payload = {
 * ...fields
 * }
 * updateDocument(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const updateDocument = (id: string, payload: EditDocumentPayload): Promise<{ data: { id: string }[] }> =>
  api.patch(`document/${id}`, payload);
