import styled from 'styled-components';
import { PRIMARY_GREEN, PRIMARY_PURPLE, STATUS_BLUE, STATUS_ORANGE } from '../../../common/styles/Colors';
import { StyledPhvMotExpiryLegendProps } from './calendar';

export const Container = styled.div`
  height: 100%;
  min-height: 73vh;
  width: 100%;
  padding: 20px;

  .fc-daygrid-day-frame {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    height: 100%;
    width: 100%;
  }
  .fc-daygrid-day-frame:hover {
    border: 1px solid ${PRIMARY_GREEN};
    border-radius: 50%;
  }

  .fc-event {
    cursor: pointer;
  }

  .fc-event:hover {
    background: none;
  }

  .fc-button-primary {
    border-radius: 20px;
    background-color: ${PRIMARY_PURPLE};
  }

  .fc-button-primary:hover,
  .fc-button-primary:focus {
    border-radius: 20px;
    background-color: ${PRIMARY_PURPLE};
  }

  .fc-today-button,
  .fc-today-button:disabled {
    border: 1px solid ${PRIMARY_PURPLE};
    background: transparent;
    color: ${PRIMARY_PURPLE};
  }

  .fc td,
  .fc th {
    border-style: none !important;
  }

  .fc-scrollgrid {
    border-style: none !important;
  }
`;

export const LegendContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 15px;
`;

export const Legend = styled.div<StyledPhvMotExpiryLegendProps>`
  display: flex;
  align-items: center;
  color: ${({ $expiryType }) => ($expiryType === 'MOT-expiry' ? `${STATUS_ORANGE}` : `${STATUS_BLUE}`)};
  font-size: 18px;
  font-weight: 300;
  line-height: normal;
`;

export const LegendDot = styled.div<StyledPhvMotExpiryLegendProps>`
  height: 10px;
  width: 10px;
  background-color: ${({ $expiryType }) => ($expiryType === 'MOT-expiry' ? `${STATUS_ORANGE}` : `${STATUS_BLUE}`)};
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px;
`;

export const ClearFilter = styled.div`
  border-radius: 16px;
  border: 1px solid ${PRIMARY_PURPLE};
  padding: 5px 10px;
  cursor: pointer;
`;
