import React, { useCallback, useEffect } from 'react';
import { BsDot } from 'react-icons/bs';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Table } from '../../../../uiComponents/table/table';
import { driverMileageColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import { TableTextCell, getQueryString } from '../../../../uiComponents/table/tableUtils/tableUtils';
import { Text } from '../../../../uiComponents/text/text';
import { getDriverMileage } from '../../../../api/get/driver.get';
import { DriverTableProps, DriveMileageProps } from '../../../../models/driver';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../../common/styles/Colors';

export const DriverMileages = ({ driverId }: DriverTableProps) => {
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const handleGetDriverMileageResponse = useCallback(
    (mileages: DriveMileageProps[]) => {
      const mileageRows = mileages?.map((mileage: DriveMileageProps) => {
        return {
          rowData: { data: mileage },
          cells: [
            <TableTextCell value={`${mileage?.date_range?.start_date} - ${mileage?.date_range?.end_date}`} />,
            <TableTextCell value={mileage?.holiday ? 'Yes' : 'No'} />,
            <TableTextCell value={mileage?.is_courtesy ? 'Yes' : 'No'} />,
            <TableTextCell value={mileage?.main_vehicle_vrm || '-'} />,
            <TableTextCell value={mileage?.courtesy_vehicle_vrm || '-'} />,
            <TableTextCell value={mileage?.main_vehicle_mileage ?? '-'} />,
            <TableTextCell value={mileage?.courtesy_vehicle_mileage} />,
            <TableTextCell value={mileage?.total_mileage} />,
            <TableTextCell value={mileage?.excess_miles} />,
            <TableTextCell value={mileage?.excess_mileage_charge} />,
          ],
        };
      });
      setTableData(mileageRows);
      setTotalRows(mileageRows.length);
    },
    [setTotalRows, setTableData]
  );

  const fetchMileageList = useCallback(
    (queryString: string) => {
      getDriverMileage(queryString).then((response: { data: DriveMileageProps[] }) => {
        handleGetDriverMileageResponse(response.data);
      });
    },
    [handleGetDriverMileageResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchMileageList(queryString);
    },
    [fetchMileageList, setTableData, tableFilters]
  );

  useEffect(() => {
    fetchMileageList(`${driverId}`);
    setTableFilters([{ columnName: 'driver_id', options: { label: driverId, value: driverId } }]);
  }, [fetchMileageList, setTableFilters, driverId]);

  return (
    <>
      <FlexLayout
        styled={{
          color: `${PRIMARY_PURPLE}`,
          marginBottom: 16,
          padding: 10,
        }}
        gap={20}
      >
        <FlexLayout itemsX="center" itemsY="center">
          <BsDot size={24} color={PRIMARY_GREEN} />
          <Text variant="body7" weight={500}>
            Weekly mileage limit - 1000 miles
          </Text>
        </FlexLayout>
        <FlexLayout itemsX="center" itemsY="center">
          <BsDot size={24} color={PRIMARY_GREEN} />
          <Text variant="body7" weight={500}>
            Driver&#39;s weekly holiday limit - 250 miles
          </Text>
        </FlexLayout>
        <FlexLayout itemsX="center" itemsY="center">
          <BsDot size={24} color={PRIMARY_GREEN} />
          <Text variant="body7" weight={500}>
            Excess mileage charges - 20p/mile
          </Text>
        </FlexLayout>
      </FlexLayout>
      <Table
        variant="compact"
        tableTheme="purple"
        embedded
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={driverMileageColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={[]}
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
        }}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, sortingColumn, sortAscending);
        }}
      />
    </>
  );
};
