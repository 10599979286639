import React, { useState, useCallback, useEffect } from 'react';
import { Control, FieldError, FieldValues, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { DriverTagBox } from './editDriverDetailsForm.styles';
import { WarningCard } from '../../../../uiComponents/customComponents/warningCard/warningCard';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Tag } from '../../../../uiComponents/customComponents/tag/tag';
import { TextArea } from '../../../../uiComponents/inputs/textArea/textArea';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { InputType } from '../../../../uiComponents/inputs/textInput/textInput';
import { Notification } from '../../../../uiComponents/toast/toast';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { updateDriver } from '../../../../api/patch/driver.patch';
import {
  getDriverByBP,
  getDriverByShell,
  verifyEmailAddressExists,
  verifyMobileNumberExists,
} from '../../../../api/get/driver.get';
import { Driver, DuplicateBPId, DuplicateShellCardCheck, EditDriverPayload } from '../../../../models/driver';
import { SECONDARY_PURPLE_30 } from '../../../../common/styles/Colors';
import { decideInsuranceTagColor } from '../../../../common/utils';
import { checkPostCodeFormat } from '../../../../utils/utils';
import {
  bpNumberFormat,
  dvlaFormat,
  emailFormat,
  letterFormat,
  mobileNumberFormat,
  nationalInsurance,
  shellNumberFormat,
  ukPostCode,
  validAgeDateFormat,
} from '../../../../utils/validations';
import { titleOptions } from '../../../../consts/user';

interface EditDriverFormProps {
  driverData: Driver | null;
  close: (refresh?: boolean) => void;
}

interface EditFields {
  label: string;
  name: keyof Driver;
  type: InputType | 'select' | 'checkbox';
  placeholder: string;
  pattern?: {
    value: RegExp;
    message: string;
  };
  onBlur?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  validate?: { validation: (v: string) => boolean | string };
  required?: string;
  disabled?: boolean;
}

export const EditDriverDetailsForm = ({ driverData, close }: EditDriverFormProps) => {
  const params = useParams();
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [tagReasonShow, setTagReasonShow] = useState<boolean>(false);
  const [tagReasonShowWarning, setTagReasonShowWarning] = useState<boolean>(false);
  const driverId: string | undefined = params?.driveId ?? driverData?.id;

  const {
    handleSubmit,
    register,
    setValue,
    setError,
    control,
    watch,
    formState: { errors },
  } = useForm<EditDriverPayload>({
    defaultValues: {
      ...driverData,
      date_of_birth: driverData?.date_of_birth && moment(driverData.date_of_birth).format('YYYY-MM-DD'),
      address_post_code: driverData?.address_post_code && checkPostCodeFormat(driverData.address_post_code),
      dvla_test_date: driverData?.dvla_test_date && moment(driverData.dvla_test_date).format('YYYY-MM-DD'),
    },
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  const onSubmit = (submitValues: EditDriverPayload): void => {
    const getDifference = (driverInfo: Driver, submitValues: EditDriverPayload) =>
      Object.fromEntries(
        Object.entries(submitValues).filter(
          ([key, val]) => key in driverInfo && driverInfo[key as keyof Driver] !== val
        )
      );

    const driverObj = { ...driverData };
    delete driverObj?.penalty_codes;
    delete driverObj?.tagData;
    delete driverObj?.dvla_test_date;
    const payload = getDifference(driverObj ?? {}, submitValues);

    if (moment(driverData?.date_of_birth).isSame(payload.date_of_birth, 'day')) {
      delete payload.date_of_birth;
    }

    if (payload?.uber_rating != null) {
      payload.uber_rating = +payload?.uber_rating;
    }

    setLoadingSubmit(true);
    if (tagReasonShow && submitValues) {
      payload.tag_name = submitValues?.tag_name;
      payload.tag_category = 'DRIVER';
      payload.category_id = driverId;
    }

    const update = async (): Promise<void> => {
      if (Object.keys(payload).length === 0) {
        setLoadingSubmit(false);
        close(false);
        return;
      }
      setLoadingSubmit(true);
      if (driverId) {
        await updateDriver(driverId, payload)
          .then(() => {
            close(true);
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Driver has been successfully updated',
            });
          })
          .catch((err) => {
            Notification({
              type: 'error',
              title: 'Error',
              message: `${err}`,
            });
          })
          .finally(() => {
            setLoadingSubmit(false);
            close(true);
          });
      }
    };
    setLoadingSubmit(true);
    update();
  };
  const validateEmailAddressIsAvailable = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const emailVal = e?.target.value;
      if (emailVal === driverData?.email) return;
      if (emailVal.length > 5) {
        verifyEmailAddressExists(emailVal).then(({ data }) => {
          if (data >= 1) {
            setError('email', { message: 'This email address is in use' });
          }
        });
      }
    },
    [setError, driverData]
  );

  const validateMobileNumberIsAvailable = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const mobilePhoneValue = e.target.value;
      if (mobilePhoneValue === driverData?.mobile_phone) return;
      if (mobilePhoneValue.length > 8) {
        if (driverData?.no_area_code) {
          verifyMobileNumberExists(driverData?.no_area_code, mobilePhoneValue).then(({ data }) => {
            if (data >= 1) {
              setError('mobile_phone', {
                message: 'This mobile number is in use',
              });
            }
          });
        }
      }
    },
    [setError, driverData]
  );

  const reasonOther = watch('reason');
  const tagType = watch('tag_name');

  useEffect(() => {
    if (tagType === 'REMOVED-INSURANCE') {
      setTagReasonShowWarning(true);
    } else {
      setTagReasonShowWarning(false);
      setValue('reason', undefined);
    }
  }, [tagType, setValue]);

  const editDetails: EditFields[] = [
    {
      label: 'First Name',
      name: 'first_name',
      type: 'text',
      placeholder: 'Enter first name',
      pattern: {
        value: letterFormat,
        message: 'Please use A-Z, spaces and dashes only',
      },
      required: 'First name is a required field',
    },
    {
      label: 'Middle Name',
      name: 'middle_name',
      type: 'text',
      placeholder: 'Enter middle name',
      pattern: {
        value: letterFormat,
        message: 'Please use A-Z, spaces and dashes only',
      },
    },
    {
      label: 'Last Name',
      name: 'last_name',
      type: 'text',
      placeholder: 'Enter last name',
      pattern: {
        value: letterFormat,
        message: 'Please use A-Z, spaces and dashes only',
      },
      required: 'First name is a required field',
    },
    {
      label: 'Date of Birth',
      name: 'date_of_birth',
      type: 'date',
      placeholder: 'dd/mm/yyyy',
      validate: {
        validation: (v) => !!(new Date(v) < new Date(validAgeDateFormat)) || 'Driver must be older than 25',
      },
    },
    {
      label: 'Email',
      name: 'email',
      type: 'email',
      placeholder: 'Enter your email',
      pattern: {
        value: emailFormat,
        message: 'Please enter a valid email address',
      },
      onBlur: validateEmailAddressIsAvailable,
      required: 'Email is a required field',
    },
    {
      label: 'Mobile',
      name: 'mobile_phone',
      type: 'number',
      placeholder: 'Enter your phone number',
      pattern: {
        value: mobileNumberFormat,
        message: 'Please enter a valid phone number',
      },
      onBlur: validateMobileNumberIsAvailable,
    },
    {
      label: 'Address Line 1',
      name: 'address_line_1',
      type: 'text',
      placeholder: 'Address Line 1',
      required: 'Address Line 1 is a required field',
    },
    {
      label: 'Address Line 2',
      name: 'address_line_2',
      type: 'text',
      placeholder: 'Address Line 2',
    },
    {
      label: 'City',
      name: 'address_city',
      type: 'text',
      placeholder: 'Enter the City',
      required: 'City is a required field',
    },
    {
      label: 'Post Code',
      name: 'address_post_code',
      type: 'text',
      placeholder: 'Enter your post code',
      required: 'Post Code is a required field',
      pattern: {
        value: ukPostCode,
        message: 'Must be post code format e.g. PO1 3AX',
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setValue('address_post_code', e.target.value.toUpperCase()),
    },
    {
      label: 'DVLA Number',
      name: 'dvla_no',
      type: 'text',
      placeholder: 'Enter DVLA number',
      required: 'DVLA is a required field',
      pattern: {
        value: dvlaFormat,
        message: 'Please provide a valid DVLA number',
      },
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => setValue('dvla_no', e.target.value.toUpperCase()),
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => setValue('dvla_no', e.target.value.toUpperCase()),
    },
    {
      label: 'PCO Number',
      name: 'pco_no',
      type: 'text',
      placeholder: 'Enter DVLA number',
    },
    {
      label: 'National Insurance Number',
      name: 'national_insurance_number',
      type: 'text',
      placeholder: 'Enter National Insurance Number',
      pattern: {
        value: nationalInsurance,
        message: 'Please provide a valid National insurance number',
      },
    },
    {
      label: 'Shell Card Number',
      name: 'shell_card_id',
      type: 'text',
      placeholder: 'Enter Shell Card Number',
      pattern: {
        value: shellNumberFormat,
        message: 'Shell card number should be 6 digits',
      },
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.value?.length === 6) {
          getDriverByShell(driverId ?? '', e?.target?.value).then((response: { data: DuplicateShellCardCheck[] }) => {
            const otherDriversWithShellId = response?.data?.filter((item) => item?.id !== driverId) ?? [];
            if (otherDriversWithShellId?.length > 0) {
              setError('shell_card_id', { message: 'The shell card number is already in use' });
            }
          });
        }
      },
    },
    {
      label: 'BP Card Number',
      name: 'bp_card_id',
      type: 'text',
      placeholder: 'Enter BP Card Number',
      pattern: {
        value: bpNumberFormat,
        message: 'BP card number should start with F or 1 followed by digits',
      },
      onBlur: (e: React.ChangeEvent<HTMLInputElement>) => {
        getDriverByBP(driverId ?? '', e?.target?.value).then((response: { data: DuplicateBPId[] }) => {
          const otherDriversWithBpId = response?.data?.filter((item) => item?.id !== driverId) ?? [];
          if (otherDriversWithBpId?.length > 0) {
            setError('bp_card_id', { message: 'The BP card number is already in use' });
          }
        });
      },
    },
    {
      label: 'Uber rating',
      name: 'uber_rating',
      type: 'number',
      placeholder: 'Uber rating',
    },
  ];

  return (
    <>
      <GridLayout template={3} gap={18}>
        <div>
          <TextFieldLabel $isRequired>Title</TextFieldLabel>
          <DropDown
            name="title"
            error={errors.title}
            required={{ required: 'Title is required' }}
            placeholder="Select title"
            options={titleOptions}
            control={control as unknown as Control<FieldValues>}
          />
        </div>
        {editDetails.map((edit) => (
          <TextField
            key={edit?.name}
            {...register(edit?.name, {
              required: edit.required,
              pattern: edit.pattern,
              onBlur: edit.onBlur,
              onChange: edit.onChange,
            })}
            required={!!edit.required}
            label={edit.label}
            placeholder={edit.placeholder}
            type={edit.type !== 'checkbox' && edit.type !== 'select' ? edit.type : 'text'}
            name={edit.name}
            error={errors[edit.name] as FieldError}
            disabled={edit.disabled}
            onBlur={(e) => edit?.onBlur?.(e)}
          />
        ))}
        <div>
          <TextFieldLabel>Insurance tags</TextFieldLabel>
          <DropDown
            name="tag_name"
            error={errors.tag_name}
            placeholder="Select tag"
            options={[
              {
                label: 'Remove Insurance',
                value: 'REMOVED-INSURANCE',
                color: SECONDARY_PURPLE_30,
              },
            ].filter((obj1) => !driverData?.tagData?.some((obj2) => obj2.tag_name === obj1.value))}
            control={control as unknown as Control<FieldValues>}
          />
          {tagReasonShowWarning && (
            <WarningCard
              onContinue={() => {
                setTagReasonShow(!tagReasonShow);
                setTagReasonShowWarning(false);
              }}
              title="Warning Message"
              subTitle="Please note that removing the Ottocar insurance would also remove the document from the vehicle page"
            />
          )}
          {driverData?.tagData &&
            driverData?.tagData?.length > 0 &&
            driverData?.tagData?.some((tag) => tag.tag_category === 'DRIVER') && (
              <DriverTagBox>
                {driverData.tagData
                  .filter((tagKey) => tagKey.tag_category === 'DRIVER')
                  .map((tag) => {
                    return <Tag color={decideInsuranceTagColor(tag.tag_name)}>{tag.tag_name}</Tag>;
                  })}
              </DriverTagBox>
            )}
        </div>
        {tagReasonShow && (
          <div>
            <TextFieldLabel $isRequired>Reason</TextFieldLabel>
            <DropDown
              name="reason"
              required={{
                required: 'If removed insurance tag is selected you have to provide a reason',
              }}
              error={errors.reason}
              placeholder="Select Reason"
              options={[
                {
                  value: 'ACCIDENT',
                  label: 'Accident',
                },
                {
                  value: 'DANGEROUS-DRIVING',
                  label: 'Dangerous driving',
                },
                {
                  value: 'EXCESSIVE-MILEAGE',
                  label: 'Excessive Mileage',
                },
                {
                  value: 'OTHER',
                  label: 'Other',
                },
              ]}
              control={control as unknown as Control<FieldValues>}
            />
          </div>
        )}
        {reasonOther === 'OTHER' && (
          <div>
            <TextArea
              label="Other tag"
              placeholder="Provide tag description"
              required={true}
              name="other_reason"
              onChange={() => {}}
              styled={{ maxHeight: 128 }}
            />
          </div>
        )}
      </GridLayout>
      <FlexLayout styled={{ marginTop: '20px' }} itemsX="end">
        <SecondaryButton styled={{ marginRight: '20px' }} onClick={() => close()} disabled={loadingSubmit}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={loadingSubmit} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
