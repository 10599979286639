import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';
import { VehicleModel } from '../../../../../models/vehicle';
import { TextInput } from '../../../../../components/inputs/text-input/text-input';
import { SelectInput } from '../../../../../components/inputs/select-input/select-input';
import { Option } from '../../../../../components/ui/select/select';
import { Button } from '../../../../../components/ui/button/button';
import { BsArrowLeft } from 'react-icons/bs';
import { VEHICLE_MODELS } from '../../../../../consts/routes';
import { useGetVehicleManufacturerListQuery } from '../../../../../api/contracts/contractsDetailsApiSlice';
import {
  useGetColorsListQuery,
  useGetFuelListQuery,
  useCreateVehicleModelMutation,
  useEditVehicleModelMutation,
  VehicleModelPayload,
} from '../../../../../api/vehicleModel/vehicleModelApiSlice';
import { Notification } from '../../../../../uiComponents/toast/toast';

const optionSchema = z.object({
  value: z.string().min(1, 'Value is required'),
  label: z.string().min(1, 'Label is required'),
  info: z.string().optional(),
  node: z.any().optional(),
});

const vehicleModelFormSchema = z.object({
  id: z.string().optional(),
  manufacturer_id: z.array(optionSchema).nonempty('Manufacturer is required'),
  model: z.string().min(0, 'Model is required'),
  fuel_types: z.array(optionSchema).nonempty('Fuel type is required'),
  spec: z.string().optional().nullish(),
  exterior_colors: z.array(optionSchema).nonempty('Colors is required'),
  service_interval: z.number().min(-1, 'Service interval is required'),
});

export type VehicleModelFormType = z.infer<typeof vehicleModelFormSchema>;

interface VehicleModelFormProps {
  defaultValues?: VehicleModelFormType;
  isEdit?: boolean;
}

export const VehicleModelForm = ({ defaultValues, isEdit }: VehicleModelFormProps) => {
  const { data: manufacturerList } = useGetVehicleManufacturerListQuery({});
  const { data: fuelList } = useGetFuelListQuery({});
  const { data: colorsList } = useGetColorsListQuery({});
  const [createVehicleModel, { isLoading: createVehicleModelLoading }] = useCreateVehicleModelMutation();
  const [editVehicleModel, { isLoading: editVehicleModelLoading }] = useEditVehicleModelMutation();
  const isLoading = createVehicleModelLoading || editVehicleModelLoading;
  const navigate = useNavigate();

  const {
    handleSubmit,
    getValues,
    formState: { errors, isValid, isDirty },
    setValue,
  } = useForm<VehicleModelFormType>({
    mode: 'onChange',
    defaultValues: defaultValues,
    resolver: zodResolver(vehicleModelFormSchema),
  });

  const handleEdit = (data: VehicleModelPayload) => {
    editVehicleModel(data)
      .unwrap()
      .then(() => {
        Notification({
          type: 'success',
          title: 'Vehicle model edited',
          message: 'Your vehicle model was edited successfully',
        });
        navigate(VEHICLE_MODELS);
      })
      .catch((error) => {
        Notification({
          type: 'error',
          title: 'Error editing vehicle model',
          message: `${error.status}: An error occurred while trying to edit vehicle model`,
        });
      });
  };

  const handleCreate = (data: VehicleModelPayload) => {
    createVehicleModel(data)
      .unwrap()
      .then(() => {
        Notification({
          type: 'success',
          title: 'Vehicle model created',
          message: 'Your vehicle model was created successfully',
        });
        navigate(VEHICLE_MODELS);
      })
      .catch((error) => {
        Notification({
          type: 'error',
          title: 'Error creating vehicle model',
          message: `${error.status}: An error occurred while trying to create vehicle model`,
        });
      });
  };

  const onSubmit: SubmitHandler<VehicleModelFormType> = (data) => {
    const payload: VehicleModelPayload = {
      id: data.id,
      manufacturer_id: data.manufacturer_id[0]?.value,
      model: data.model,
      fuel_types: data.fuel_types.map((item) => item.value),
      spec: data.spec as string,
      exterior_colors: data.exterior_colors.map((item) => item.value),
      service_interval: data.service_interval,
    };
    if (isEdit) {
      handleEdit(payload);
    } else {
      handleCreate(payload);
    }
  };

  const handleChange =
    (field: keyof VehicleModel) => (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
      const value = event.target.value;
      if (field === 'service_interval') {
        setValue(field, parseInt(value), { shouldValidate: true, shouldDirty: true });
      } else {
        setValue(field, value, { shouldValidate: true, shouldDirty: true });
      }
    };

  const handleSelectChange = (field: keyof VehicleModel) => (option: Option[]) => {
    setValue(field, option as any, { shouldValidate: true, shouldDirty: true });
  };

  return (
    <div className="bg-white p-8 pb-0">
      <Button type="button" color="secondary" data-testid="back-btn" onClick={() => navigate(VEHICLE_MODELS)}>
        <BsArrowLeft />
        Back
      </Button>
      <div className="pt-8 pb-5 mb-14 border-solid border-b border-utility-gray-200">
        <span className="text-lg font-extrabold text-utility-gray-900" data-testid="title">
          {isEdit ? 'Edit' : 'Create'} vehicle model
        </span>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="grid lg:grid-cols-3 md:grid-cols-3 sm:grid-cols-1 gap-4">
          <SelectInput
            data-testid="manufacturer_id"
            label="Manufacturer"
            placeholder="eg. Toyota"
            options={(manufacturerList?.data || [])?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })}
            value={getValues().manufacturer_id}
            onSelect={handleSelectChange('manufacturer_id')}
            error={errors.manufacturer_id?.message}
            required
          />
          <TextInput
            data-testid="model"
            label="Model"
            placeholder="eg. Prius"
            value={getValues().model}
            onChange={handleChange('model')}
            error={errors.model?.message}
            required
          />
          <SelectInput
            data-testid="fuel_types"
            label="Fuel Type"
            placeholder="Please choose fuel type"
            options={(fuelList?.data || [])?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })}
            value={getValues().fuel_types}
            onSelect={handleSelectChange('fuel_types')}
            error={errors.fuel_types?.message}
            required
            multiple
          />
          <TextInput
            data-testid="spec"
            label="Spec"
            placeholder="eg. 2L 86Kwh"
            value={getValues().spec as string}
            onChange={handleChange('spec')}
            error={errors.spec?.message}
          />
          <SelectInput
            data-testid="exterior_colors"
            label="Colors"
            placeholder="Red"
            options={(colorsList?.data || [])?.map((item: { id: string; name: string }) => {
              return { value: item.id, label: item.name };
            })}
            value={getValues().exterior_colors}
            onSelect={handleSelectChange('exterior_colors')}
            error={errors.exterior_colors?.message}
            multiple
            required
          />
          <TextInput
            data-testid="service_interval"
            label="Service interval"
            placeholder="10000"
            value={getValues().service_interval}
            onChange={handleChange('service_interval')}
            error={errors.service_interval?.message}
            required
            type="number"
            hint="Enter the recommended amount of miles between each service interval"
            fixedStartAdornment={<div className="flex justify-center items-center h-full">Miles</div>}
          />
        </div>
        <div className="flex justify-end mt-6 py-5 border-solid border-t border-utility-gray-200">
          <div>
            <Button
              type="button"
              data-testid="cancel-btn"
              size="md"
              className="w-full"
              color="secondary"
              onClick={() => navigate(VEHICLE_MODELS)}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </div>
          <div className="ml-[12px]">
            <Button
              type="submit"
              data-testid={isEdit ? 'update-btn' : 'create-btn'}
              size="md"
              className="w-full"
              disabled={isLoading || !isValid || !isDirty}
            >
              {isEdit ? 'Update' : 'Create'}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};
