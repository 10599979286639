import {
  GRAY_1,
  GRAY_10,
  PRIMARY_BLACK,
  PRIMARY_GRAY,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  PRIMARY_GREEN,
  STATUS_RED,
} from '../../../common/styles/Colors';
import styled from 'styled-components';

export const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0;

  .react-datepicker {
    border: none;
  }

  .react-datepicker__day--disabled {
    color: ${PRIMARY_GRAY};
  }

  .react-datepicker__month-container {
    background-color: ${PRIMARY_PURPLE};
    border-radius: 16px;
  }

  .react-datepicker__header {
    background-color: ${PRIMARY_PURPLE};
    color: ${PRIMARY_WHITE};
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .react-datepicker__day-name {
    color: ${PRIMARY_WHITE};
  }

  .react-datepicker__current-month {
    color: ${PRIMARY_WHITE};
  }

  .react-datepicker__day--highlighted {
    color: ${PRIMARY_BLACK};
    background-color: ${GRAY_1};
  }

  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${PRIMARY_PURPLE};
  }

  .react-datepicker__input-container {
    input {
      border: 1px solid ${GRAY_10};
      height: 48px;
      border-radius: 50px;
      font-size: 15px;
      font-style: normal;
      width: 100%;
      color: ${PRIMARY_PURPLE};
      box-shadow: none;
      padding-left: 20px;
    }

    input: hover {
      border: 1px solid ${PRIMARY_GREEN};
    }

    input: active {
      border: 1px solid ${PRIMARY_GREEN};
    }
  }
`;

export const Container = styled.div`
  margin: 0 0 0 0;
`;

export const ErrorMessage = styled.p`
  color: ${STATUS_RED};
  margin: 0 15px;
`;
