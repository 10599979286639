import { apiSlice } from '../../api/baseApi';
import { ContractListItem } from '../../models/contract';

interface GetContractsListParams {
  query: string;
  refetch: boolean;
}

interface GetContractsListResponse {
  count: number;
  data: ContractListItem[];
}

export const contracts = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getContractsList: builder.query<GetContractsListResponse, GetContractsListParams>({
      query: ({ query }) => `contracts/list${query && `?${query}`}`,
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }
        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
    getVehicleManufacturerList: builder.query({
      query: () => 'vehicle-manufacturer/list',
    }),
    getVehicleModelList: builder.query({
      query: () => 'vehicle-model/list',
    }),
    getContractTypeList: builder.query({
      query: () => 'contracts-type/list',
    }),
    getLocationList: builder.query({
      query: () => 'internal/city',
    }),
    getReferenceDataList: builder.query({
      query: () => 'contracts-reference/list',
    }),
    getContractById: builder.query({
      query: ({ query }) => `contracts/${query}`,
      keepUnusedDataFor: 0.0001,
    }),
    saveContractDraft: builder.mutation({
      query: (body) => ({
        url: 'contracts/draft',
        method: 'POST',
        body,
      }),
    }),
    createContract: builder.mutation({
      query: (body) => ({
        url: 'contracts',
        method: 'POST',
        body,
      }),
    }),
    editContract: builder.mutation({
      query: (body) => ({
        url: `contracts/${body.id}`,
        method: 'PATCH',
        body,
      }),
    }),
    cancelContract: builder.mutation({
      query: (body) => ({
        url: `contracts/${body.id}`,
        method: 'DELETE',
        body,
      }),
    }),
  }),
});

export const {
  useGetContractsListQuery,
  useGetVehicleManufacturerListQuery,
  useGetVehicleModelListQuery,
  useGetContractTypeListQuery,
  useGetLocationListQuery,
  useGetReferenceDataListQuery,
  useGetContractByIdQuery,
  useSaveContractDraftMutation,
  useCreateContractMutation,
  useEditContractMutation,
  useCancelContractMutation,
} = contracts;
