/* eslint-disable no-console */
import { getUrl, uploadData } from 'aws-amplify/storage';

interface UploadFileToStorageProps {
  path: string;
  data: Blob | File;
}

interface GetFileUrlFromStorage {
  path: string;
}

class FileService {
  async uploadFileToStorage({ data, path }: UploadFileToStorageProps) {
    try {
      const result = await uploadData({
        path,
        data,
      }).result;

      return result;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error uploading file:', error);
        throw new Error(`Failed to upload file: ${error.message}`);
      } else {
        console.error('Unknown error uploading file:', error);
        throw new Error('Failed to upload file: Unknown error');
      }
    }
  }

  async getFileUrlFromStorage({ path }: GetFileUrlFromStorage) {
    try {
      const linkToStorageFile = await getUrl({
        path,
      });

      return linkToStorageFile.url;
    } catch (error) {
      if (error instanceof Error) {
        console.error('Error dowloading the file:', error);
        throw new Error(`Failed to dowload file: ${error.message}`);
      } else {
        console.error('Unknown error uploading file:', error);
        throw new Error('Failed to dowload file: Unknown error');
      }
    }
  }
}

let fileService: FileService | null = null;

export function getFileService() {
  if (!fileService) {
    fileService = new FileService();
  }
  return fileService;
}
