import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FiChevronRight } from 'react-icons/fi';
import { MdOutlineError } from 'react-icons/md';
import { AxiosError } from 'axios';
import { AssignVehicleSection } from './sections/assignVehicleSection/assignVehicleSection';
import { LoadingMask, OrderSections, InfoBanner } from './order/order.styles';
import { ASSIGN_COURTESY, COLLECTION_REPLACEMENT, COMPLETED } from '../../consts';
import { CREATE_TRANSFER, SERVICING_LIST, VEHICLES } from '../../consts/routes';
import { PageContainer } from '../../core/coreStyles';
import { DriverInfoCard } from '../../pages/drivers/driver/driverInfoCard/driverInfoCard';
import { SecondaryButton } from '../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { ConfirmationModal } from '../../uiComponents/modals/confirmationModal/confirmationModal';
import { Spinner } from '../../uiComponents/uiControls/spinner/spinner';
import { Text } from '../../uiComponents/text/text';
import { Notification } from '../../uiComponents/toast/toast';
import { cancelCourtesyAgreement } from '../../api/delete/agreement.delete';
import { cancelCourtesyApplication } from '../../api/delete/application.delete';
import { createCourtesyAgreement } from '../../api/post/agreement.post';
import { progressCourtesyChecker } from '../../api/get/application.get';
import { unassignCourtesy } from '../../api/post/vehicle.post';
import { ApplicationProgress, ApplicationSections } from '../../models/application';
import { Driver } from '../../models/driver';
import { PRIMARY_PURPLE, STATUS_RED } from '../../common/styles/Colors';

export const CourtesyOrder = () => {
  const navigate = useNavigate();
  const { courtesyId } = useParams();
  const [driver, setDriver] = useState<Driver>();
  const [progress, setProgress] = useState<ApplicationProgress>();
  const [sections, setSections] = useState<ApplicationSections>();
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [modal, setModal] = useState<JSX.Element>();
  const { state } = useLocation();

  const checkProgress = useCallback(() => {
    setIsFetching(true);
    if (courtesyId) {
      progressCourtesyChecker(courtesyId).then(
        (response: { data: { progress: ApplicationProgress; sections: ApplicationSections } }) => {
          setProgress(response?.data?.progress);
          setSections(response?.data?.sections);
          setDriver({
            id: response?.data?.progress?.driver_id,
            first_name: response?.data?.progress?.driver_first_name,
            last_name: response?.data?.progress?.driver_last_name,
            title: response?.data?.progress?.driver_title,
            no_area_code: response?.data?.progress?.no_area_code,
            mobile_phone: response?.data?.progress?.mobile_phone,
            ordway_customer_id: response?.data?.progress?.ordway_customer_id,
            pco_no: response?.data?.progress?.pco_document_no,
            dvla_no: response?.data?.progress?.dvla_document_no,
            uber_rating: response?.data?.progress?.uber_rating,
            tag_type: response?.data?.progress?.tag_type,
          });
          setIsFetching(false);
        }
      );
    }
  }, [courtesyId]);

  const handleSendScrivePopup = useCallback(() => {
    const scriveModal: JSX.Element = (
      <ConfirmationModal
        onClose={() => setModal(undefined)}
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        isOpen
        title="Scrive contract will be sent to all parties for signing."
        onConfirm={() => {
          setModal(undefined);
          setIsFetching(true);
          if (courtesyId && progress?.driver_id) {
            createCourtesyAgreement({ courtesy_id: courtesyId, driver_id: progress?.driver_id })
              .then(() => {
                Notification({
                  type: 'success',
                  title: 'Success',
                  message: 'Scrive successfully sent.',
                });
                checkProgress();
              })
              .catch((e: AxiosError<Error>) => {
                Notification({
                  type: 'error',
                  title: 'Error',
                  message: e.message,
                });
              });
          }
        }}
      />
    );
    setModal(scriveModal);
  }, [checkProgress, courtesyId, progress]);

  const sendScrive = useCallback(async () => {
    if (!progress?.vrm) {
      Notification({
        type: 'error',
        title: 'Error',
        message: 'Cant send scrive without a vrm assigned to the vehicle',
      });
    } else {
      handleSendScrivePopup();
    }
  }, [progress, handleSendScrivePopup]);

  const cancelScrive = useCallback(() => {
    const cancelScriveModal: JSX.Element = (
      <ConfirmationModal
        closeButtonCaption="No"
        confirmButtonCaption="Yes"
        onClose={() => setModal(undefined)}
        title="Are you sure you want to cancel the pending scrive agreement?"
        isOpen
        onConfirm={() => {
          setIsFetching(true);
          setModal(undefined);
          cancelCourtesyAgreement(courtesyId ?? '')
            .then(() => {
              Notification({
                type: 'success',
                title: 'Success',
                message: 'Scrive successfully cancelled.',
              });
              checkProgress();
            })
            .catch((e: AxiosError<Error>) => {
              Notification({
                type: 'error',
                title: 'Error',
                message: e.message,
              });
              checkProgress();
            });
        }}
      />
    );
    setModal(cancelScriveModal);
  }, [courtesyId, checkProgress]);

  const makeAgreementLive = useCallback(() => {
    const conditionReportModal: JSX.Element = (
      <ConfirmationModal
        isOpen
        onClose={() => setModal(undefined)}
        title="Please create a condition report for this replacement vehicle."
        confirmButtonCaption="OK"
        closeButtonCaption="Cancel"
        onConfirm={() => {
          setModal(undefined);
          const params = {
            application_id: progress?.application_id,
            reason: COLLECTION_REPLACEMENT,
            dynamic: ASSIGN_COURTESY,
            vrm: progress?.vehicle?.vrm,
            agreement_id: progress?.agreement_id,
            courtesy_id: courtesyId,
            driver_id: progress?.driver_id,
          };
          navigate(CREATE_TRANSFER, { state: params });
        }}
      />
    );
    setModal(conditionReportModal);
  }, [navigate, courtesyId, progress]);

  useEffect(() => {
    checkProgress();
  }, [checkProgress]);

  useEffect(() => {
    if (progress?.scrive_id && progress?.scrive_signed && !progress?.condition_report_out) {
      makeAgreementLive();
    }
  }, [progress, makeAgreementLive]);

  return (
    <PageContainer>
      <FlexLayout itemsX="end" gap={16}>
        {!progress?.scrive_id && !isFetching && (
          <SecondaryButton
            onClick={() => {
              if (courtesyId) {
                setModal(
                  <ConfirmationModal
                    confirmButtonCaption="Yes"
                    closeButtonCaption="No"
                    isOpen
                    title="Are you sure you want to cancel this replacement vehicle order?"
                    preConfirm={async () => {
                      if (progress?.vehicle_id) {
                        await unassignCourtesy({
                          courtesy_id: courtesyId,
                          vehicle_id: progress?.vehicle_id,
                        });
                        await cancelCourtesyApplication(courtesyId);
                        navigate(SERVICING_LIST);
                      } else {
                        return cancelCourtesyApplication(courtesyId).then(() => navigate(SERVICING_LIST));
                      }
                    }}
                    onClose={() => setModal(undefined)}
                  />
                );
              }
            }}
            styled={{ width: 152 }}
          >
            Cancel
          </SecondaryButton>
        )}
        <SecondaryButton onClick={() => navigate(state ?? -1)} styled={{ width: 152 }}>
          Back
        </SecondaryButton>
      </FlexLayout>
      <FlexLayout styled={{ marginTop: 16 }} gap={16} itemsY="start">
        {driver && <DriverInfoCard driver={driver} orderProgress={0} />}
        {progress && sections && (
          <OrderSections>
            <AssignVehicleSection
              isComplete={sections?.assign_vehicle ?? false}
              isLocked={progress?.scrive_id != null || progress?.application_status === COMPLETED}
              progress={progress}
              replacementApplicationId={courtesyId}
              onAssignVehicle={() => checkProgress()}
            />
            {(progress?.scrive_id == null || !sections?.assign_vehicle) && (
              <InfoBanner itemsX="center" itemsY="center" gap={16}>
                <MdOutlineError color={STATUS_RED} size={24} />
                <Text color={STATUS_RED} variant="body6" weight={500}>
                  The scrive contract will be emailed to yourself (staff) to verify the insurance first, then the driver
                  will be required to sign, and then finally your own signature.
                </Text>
              </InfoBanner>
            )}
            {progress?.scrive_id == null ? (
              <FlexLayout itemsX="end">
                <SecondaryButton
                  onClick={() => sendScrive()}
                  disabled={!sections?.assign_vehicle}
                  styled={{ marginTop: 16 }}
                >
                  Send scrive agreement
                </SecondaryButton>
              </FlexLayout>
            ) : (
              <>
                {progress?.condition_report_out != null && progress?.scrive_signed ? (
                  <FlexLayout
                    itemsX="end"
                    itemsY="center"
                    onClick={() => navigate(`${VEHICLES}/${progress?.vehicle_id}`)}
                    styled={{ marginTop: 16, cursor: 'pointer' }}
                  >
                    <Text
                      styled={{ textDecoration: 'underline' }}
                      weight={500}
                      color={PRIMARY_PURPLE}
                      variant="body4"
                      block
                      align="right"
                    >
                      {`Replacement vehicle ${progress?.vrm} is now live!`}
                    </Text>
                    <FiChevronRight color={PRIMARY_PURPLE} size={20} />
                  </FlexLayout>
                ) : (
                  <FlexLayout itemsX="end">
                    <SecondaryButton styled={{ marginTop: 16 }} onClick={() => cancelScrive()}>
                      Cancel scrive agreement
                    </SecondaryButton>
                  </FlexLayout>
                )}
              </>
            )}
            {isFetching && (
              <LoadingMask itemsX="center" itemsY="center">
                <Spinner color={PRIMARY_PURPLE} size={40} />
              </LoadingMask>
            )}
          </OrderSections>
        )}
      </FlexLayout>
      {modal != null && modal}
    </PageContainer>
  );
};
