import React, { useEffect, useState } from 'react';
import { BsCheckCircle, BsCircle } from 'react-icons/bs';
import { ChecklistProps } from '../../conditionReport';
import { ChecklistItem, mandatoryPhotosItems } from '../../../../../consts/conditionReport';
import { CollapsiblePanel } from '../../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../../../uiComponents/layouts/gridLayout/gridLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { UploaderInput } from '../../../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { useAppSelector } from '../../../../../store-hooks';
import { getFileService } from '../../../../../api/cognito/file.service';

export const MandatoryPhotosCheck = ({ isComplete, control, errors, setValue }: ChecklistProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const conditionReportData = useAppSelector((state) => state.conditionReport.draft);
  const fileService = getFileService();

  useEffect(() => {
    const fetchImages = async () => {
      if (conditionReportData?.s3_images_url)
        conditionReportData?.s3_images_url.forEach(async (item) => {
          const urlPath = mandatoryPhotosItems.find((image) => image.s3Folder === item.fileName);

          if (urlPath) {
            const finalUrl = await fileService.getFileUrlFromStorage({ path: item.s3_url });
            setValue(urlPath.name, finalUrl.href);
          }
        });
    };

    fetchImages();
  }, [conditionReportData?.s3_images_url, setValue, fileService]);

  return (
    <CollapsiblePanel
      header={
        <FlexLayout gap={16} itemsY="center">
          {isComplete ? (
            <BsCheckCircle color={PRIMARY_GREEN} size={32} />
          ) : (
            <BsCircle size={32} color={PRIMARY_PURPLE} />
          )}
          <div>
            <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
              Mandatory Photos
            </Text>
            {isComplete && (
              <Text variant="body7" color={PRIMARY_GREEN} weight={300} block>
                Completed
              </Text>
            )}
          </div>
        </FlexLayout>
      }
      expanded={!isCollapsed}
      onCollapse={() => setIsCollapsed(!isCollapsed)}
    >
      <div>
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
          Mandatory Photos
        </Text>
        <GridLayout template={3} gap={24} styled={{ marginTop: 32 }}>
          {mandatoryPhotosItems?.map((item: ChecklistItem) => (
            <UploaderInput
              key={item?.name}
              label={item?.label}
              name={item?.name}
              control={control}
              error={errors[item?.name]}
              aditionalInfo={item?.aditionalInfo}
              required={item?.required}
              isImageOnly
            />
          ))}
        </GridLayout>
      </div>
    </CollapsiblePanel>
  );
};
