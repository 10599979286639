export const ASSESSMENT = 'ASSESSMENT';
export const CLAIMS = 'CLAIMS';
export const ENQUIRIES = 'ENQUIRIES';
export const FINANCE = 'FINANCE';
export const FULFILLMENT = 'FULFILLMENT';
export const MARKETING = 'MARKETING';
export const OPERATIONS = 'OPERATIONS';
export const SALES = 'SALES';
export const SERVICES = 'SERVICES';
export const VANS = 'VANS';
