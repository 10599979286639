import { Branch, BranchPayload } from '../../models/branch';
import api from '../../utils/api';

/**
 * Create Branch
 * @category API/POST
 * @param {object} branchData - Data for the branch creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * createBranch(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createBranch = (params: { body: BranchPayload }): Promise<{ data: Branch }> =>
  api.post('internal/branch/', params.body);
