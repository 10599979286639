import { createContext, Dispatch, SetStateAction } from 'react';

export interface IAppContext {
  activeSideNav: string;
  setActiveSideNav: Dispatch<SetStateAction<string>>;
  pageTitle: string;
  setPageTitle: Dispatch<SetStateAction<string>>;
  showShadow: boolean;
  setShowShadow: Dispatch<SetStateAction<boolean>>;
}

const APP_CONTEXT = createContext<IAppContext>({
  activeSideNav: 'dashboard',
  setActiveSideNav: () => null,
  pageTitle: '',
  setPageTitle: () => null,
  showShadow: false,
  setShowShadow: () => null,
});
const AppContextProvider = APP_CONTEXT.Provider;
const AppContextConsumer = APP_CONTEXT.Consumer;

export { AppContextProvider, AppContextConsumer, APP_CONTEXT };
