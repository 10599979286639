import { UploadDocumentPayload, S3, CreateDocumentResponse } from '../../models/document';
import api from '../../utils/api';

/**
 * Create Document Request
 * @category API/POST
 * @param {object} data - Data for document creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *    document_type: 'PCO',
      document_category: 'DRIVER',
      file_extension:'jpeg',
      category_id,
      file: {
        fileNam,
        type,
        content,
      },
    };
 * }
 * createDocument(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createDocument = (params: UploadDocumentPayload): Promise<{ data: CreateDocumentResponse }> =>
  api.post('document/', params);

export const uploadToS3 = (params: S3): Promise<{ data: { s3_url: string } }> => api.post('document/s3', params);

export default { createDocument, uploadToS3 };
