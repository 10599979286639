import { createSlice } from '@reduxjs/toolkit';

type VehicleConditionReportListType = {
  created_date: string;
  status: string;
  condition_report_id: string;
  count: number;
};

const vehicleConditionReportListSlice = createSlice({
  name: 'vehicleConditionReportListSlice',
  initialState: {
    vehicleConditionReports: {
      data: [] as VehicleConditionReportListType[],
    },
  },
  reducers: {},
});

export const {} = vehicleConditionReportListSlice.actions;
export default vehicleConditionReportListSlice.reducer;
