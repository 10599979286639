import React, { useEffect, useState, useCallback, useRef, SyntheticEvent } from 'react';
import {
  BsFileEarmarkMinus,
  BsFileEarmarkText,
  BsGear,
  BsPerson,
  BsPlusCircle,
  BsReverseLayoutTextSidebarReverse,
  BsUmbrella,
} from 'react-icons/bs';
import { FiCreditCard, FiList, FiShoppingBag } from 'react-icons/fi';
import { IoCloseSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdOutlinePersonPin } from 'react-icons/md';
import { AxiosResponse } from 'axios';
import { updatePulseUserState } from '../../features/pulseUser/pulseUserSlice';
import {
  PageTitleContainer,
  Title,
  TitleText,
  FlexContainer,
  SearchIcon,
  StyledSpinner,
  UserText,
  OttoLogo,
  InputContainer,
  StyledDropdownMenu,
  StyledTextInput,
  SearchResults,
  ResultSelector,
  ResultRow,
  ResultText,
  ClearIcon,
} from './pageTitle.styles';
import { useInteractOutside } from '../../hooks/useInteractOutside';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { NavigationPill } from '../../uiComponents/layouts/navigationPill/navigationPill';
import { getDriverVehicles } from '../../api/get/driverVehicleSearch.get';
import { PRIMARY_PURPLE, PRIMARY_GREEN } from '../../common/styles/Colors';
import { OptionList } from '../../utils/props';
import { DASHBOARD, DRIVERS, VEHICLES } from '../../consts/routes';
import { Text } from '../../uiComponents/text/text';
import { Notification } from '../../uiComponents/toast/toast';
import { getCurrentUser } from '../../utils/utils';
import { getAuthService } from '../../api/cognito/auth.service';
import { useAppDispatch } from '../../store-hooks';

const PageIds: Record<string, { icon: JSX.Element; title: string }> = {
  dashboard: {
    icon: <FiShoppingBag size={32} color={PRIMARY_PURPLE} />,
    title: 'Sales',
  },
  agreements: {
    icon: <BsFileEarmarkMinus size={32} color={PRIMARY_PURPLE} />,
    title: 'Agreements',
  },
  sales: {
    icon: <FiShoppingBag size={32} color={PRIMARY_PURPLE} />,
    title: 'Sales',
  },
  driver: {
    icon: <MdOutlinePersonPin size={32} color={PRIMARY_PURPLE} />,
    title: 'Drivers',
  },
  driverDetails: {
    icon: <MdOutlinePersonPin size={32} color={PRIMARY_PURPLE} />,
    title: 'Driver information',
  },
  agreement: {
    icon: <MdOutlinePersonPin size={32} color={PRIMARY_PURPLE} />,
    title: 'Agreements',
  },
  agreementDetails: {
    icon: <BsFileEarmarkText size={32} color={PRIMARY_PURPLE} />,
    title: 'Agreement information',
  },
  aftercare: {
    icon: <BsGear size={32} color={PRIMARY_PURPLE} />,
    title: 'Aftercare',
  },
  additionalCharges: {
    icon: <FiCreditCard size={32} color={PRIMARY_PURPLE} />,
    title: 'Additional charges',
  },
  vehicles: {
    icon: <FiList size={32} color={PRIMARY_PURPLE} />,
    title: 'Fleet',
  },
  vehicleDetails: {
    icon: <FiList size={32} color={PRIMARY_PURPLE} />,
    title: 'Vehicle details',
  },
  transfers: {
    icon: <FiList size={32} color={PRIMARY_PURPLE} />,
    title: 'Transfers',
  },
  conditionReport: {
    icon: <BsReverseLayoutTextSidebarReverse size={32} color={PRIMARY_PURPLE} />,
    title: 'Condition report',
  },
  vehicleTypes: {
    icon: <FiList size={32} color={PRIMARY_PURPLE} />,
    title: 'Vehicle types',
  },
  vehicleBatches: {
    icon: <FiList size={32} color={PRIMARY_PURPLE} />,
    title: 'Vehicle batches',
  },
  admin: {
    icon: <BsPerson size={32} color={PRIMARY_PURPLE} />,
    title: 'Admin',
  },
  servicing: {
    icon: <BsGear size={32} color={PRIMARY_PURPLE} />,
    title: 'Servicing',
  },
  createDriver: {
    icon: <BsPlusCircle size={32} color={PRIMARY_PURPLE} />,
    title: 'Create driver profile',
  },
  createOrder: {
    icon: <BsPlusCircle size={32} color={PRIMARY_PURPLE} />,
    title: 'Create order',
  },
  insurance: {
    icon: <BsUmbrella size={32} color={PRIMARY_PURPLE} />,
    title: 'Insurance',
  },
};

interface ResultRow {
  onRowClick: () => void;
}

interface VehicleResultProps extends ResultRow {
  id: string;
  vin: string;
  vrm: string;
}

const VehicleResult = ({ id, vin, vrm, onRowClick }: VehicleResultProps) => {
  const navigate = useNavigate();
  return (
    <ResultRow
      itemsY="center"
      onClick={() => {
        navigate(`${VEHICLES}/${id}`);
        onRowClick();
      }}
    >
      <FlexLayout itemsX="space-between" itemsY="center" styled={{ flex: 1 }}>
        <ResultText>{vrm}</ResultText>
        <ResultText>{vin}</ResultText>
      </FlexLayout>
    </ResultRow>
  );
};

interface DriverResultProps extends ResultRow {
  agreement_list: string;
  driver_name: string;
  email: string;
  id: string;
}

interface SearchResultsResponse {
  drivers: DriverResultProps[];
  vehicles: VehicleResultProps[];
}

const DriverResult = ({ id, driver_name, email, onRowClick }: DriverResultProps) => {
  const navigate = useNavigate();
  return (
    <ResultRow
      onClick={() => {
        navigate(`${DRIVERS}/${id}`);
        onRowClick();
      }}
    >
      <FlexLayout itemsX="space-between" itemsY="center" styled={{ flex: 1 }}>
        <ResultText>{`${driver_name}`}</ResultText>
        <ResultText $noWrap>{email}</ResultText>
      </FlexLayout>
    </ResultRow>
  );
};

export const PageTitle = ({ pageId }: { pageId: string }) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const menuRef = useRef<HTMLDivElement>(null);
  const pageTitleRef = useRef<HTMLDivElement>(null);
  const [searchString, setSearchString] = useState<string>('');
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [resultHeaders, setResultHeaders] = useState<OptionList[]>([]);
  const [searchResults, setSearchResults] = useState<SearchResultsResponse | undefined>();
  const [showSearchResults, setShowSearchResults] = useState<boolean>(false);
  const [resultsView, setResultsView] = useState<string>('drivers');
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [inputValue, setInputValue] = useState<string>('');
  const [selectedHeader, setSelectedHeader] = useState<OptionList>();
  const title = PageIds[pageId];
  const browserTabTitle: string = title?.title;
  const [userName, setUserName] = useState<string | null>(null);
  const authService = getAuthService();

  const loadUser = async () => {
    const currentUser = await getCurrentUser();

    if (!currentUser) {
      navigate('/login');
      return;
    }

    dispatch(updatePulseUserState(currentUser));
    setUserName(`${currentUser.first_name} ${currentUser.last_name}`);
  };

  useEffect(() => {
    loadUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSelectedHeader(resultHeaders?.[0]);
  }, [resultHeaders]);

  useEffect(() => {
    document.title = `Pulse | ${browserTabTitle}`;
  }, [browserTabTitle, pageId]);

  const onOptionSelect = useCallback(
    async (option: OptionList) => {
      if (option?.value === 'logout') {
        await authService.logout();
      }
      setShowMenu(false);
    },
    [authService]
  );

  useInteractOutside(
    menuRef,
    (event: SyntheticEvent) => {
      if (!menuRef.current?.contains(event.target as HTMLDivElement)) {
        setShowMenu(false);
        event.stopPropagation();
        event.preventDefault();
      }
    },
    !showMenu
  );

  useInteractOutside(pageTitleRef, (e) => {
    if (!pageTitleRef?.current?.contains(e.target as HTMLDivElement)) {
      setShowSearchResults(false);
      e.stopPropagation();
      e.preventDefault();
    }
  });

  useEffect(() => {
    const clearSearchResults = (searchString: string) => {
      setInputValue(searchString);
      if (searchString === '' || searchString == null) {
        setShowSearchResults(false);
        setSearchResults(undefined);
      }
    };

    const updateSearchResultsBox = (results: SearchResultsResponse) => {
      if (results?.drivers?.length > 0 && results?.vehicles?.length > 0) {
        setResultHeaders([
          { value: 'drivers', label: 'Drivers' },
          { value: 'vehicles', label: 'Vehicles' },
        ]);
        setResultsView('drivers');
      } else if (results?.drivers?.length > 0) {
        setResultHeaders([{ value: 'drivers', label: 'Drivers' }]);
        setResultsView('drivers');
      } else if (results?.vehicles?.length > 0) {
        setResultHeaders([{ value: 'vehicles', label: 'Vehicles' }]);
        setResultsView('vehicles');
      } else {
        setResultHeaders([]);
      }
    };

    const search = async (str: string) => {
      const controller = new AbortController();
      try {
        const driversAndVehicles: AxiosResponse<SearchResultsResponse> = await getDriverVehicles(
          str,
          controller.signal
        );
        setIsLoading(false);
        setSearchResults(driversAndVehicles?.data);
        setShowSearchResults(isValidSearchString);
        updateSearchResultsBox(driversAndVehicles?.data);
      } catch {
        (error: AxiosResponse<Error>) => {
          Notification({ type: 'error', title: 'Search error', message: error?.data?.message });
          setIsLoading(false);
          setSearchResults(undefined);
        };
      }
    };
    const isValidSearchString: boolean = searchString !== '' && searchString != null;

    clearSearchResults(searchString);
    setIsLoading(isValidSearchString);
    if (!isValidSearchString) {
      return;
    }
    search(searchString);
  }, [searchString]);

  const onRowClick = useCallback(() => {
    setShowSearchResults(false);
  }, []);

  return (
    <>
      <div>
        <PageTitleContainer ref={pageTitleRef} onClick={() => setShowSearchResults(false)}>
          <FlexContainer itemsY="center">
            <OttoLogo src={`${process.env.PUBLIC_URL}/logo.svg`} onClick={() => navigate(DASHBOARD)} />
            <Text variant="body7" weight={300} color={PRIMARY_PURPLE} block styled={{ marginLeft: 16 }}>
              {process.env.REACT_APP_VERSION_NO ?? ''}
            </Text>
            {title && (
              <Title>
                {title?.icon}
                <TitleText>{title?.title}</TitleText>
              </Title>
            )}
          </FlexContainer>
          <FlexContainer itemsY="center">
            <InputContainer onClick={(e) => e.stopPropagation()}>
              <StyledTextInput
                placeholder="Lookup Driver or Vehicle"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setSelectedHeader(resultHeaders?.[0]);
                  setSearchString(e.target.value);
                }}
                onFocus={(e: React.FocusEvent<HTMLInputElement>) => setSearchString(e.target.value)}
                value={inputValue}
              />
              {isLoading && <StyledSpinner color={PRIMARY_PURPLE} size={14} />}
              {inputValue === '' ? (
                <SearchIcon size={20} color={PRIMARY_PURPLE} />
              ) : (
                <ClearIcon itemsX="center" itemsY="center" onClick={() => setSearchString('')}>
                  <IoCloseSharp size={20} color={PRIMARY_PURPLE} />
                </ClearIcon>
              )}
              {searchResults && showSearchResults && searchString !== '' && (
                <SearchResults>
                  <ResultSelector itemsX={resultHeaders?.length > 0 ? 'space-between' : 'center'} itemsY="center">
                    {resultHeaders?.length > 0 ? (
                      <NavigationPill
                        onSelect={(option?: OptionList) => {
                          if (option) {
                            setResultsView(option?.value);
                          }
                          setSelectedHeader(option ?? resultHeaders?.[0]);
                        }}
                        selected={selectedHeader}
                        options={resultHeaders}
                      />
                    ) : (
                      <ResultText>There are no results for your search</ResultText>
                    )}
                  </ResultSelector>
                  <div>
                    {resultsView === 'drivers'
                      ? searchResults?.drivers?.map((driver: DriverResultProps) => {
                          return (
                            <DriverResult
                              key={driver?.id}
                              id={driver?.id}
                              agreement_list={driver?.agreement_list}
                              driver_name={driver?.driver_name}
                              email={driver?.email}
                              onRowClick={() => onRowClick()}
                            />
                          );
                        })
                      : searchResults?.vehicles?.map((vehicle: VehicleResultProps) => {
                          return (
                            <VehicleResult
                              key={vehicle?.id}
                              id={vehicle?.id}
                              vin={vehicle?.vin}
                              vrm={vehicle?.vrm}
                              onRowClick={() => onRowClick()}
                            />
                          );
                        })}
                  </div>
                </SearchResults>
              )}
            </InputContainer>
            {userName && (
              <FlexContainer $relative $showPointer>
                <FlexLayout itemsY="center" onClick={() => setShowMenu(!showMenu)}>
                  <UserText>{userName}</UserText>
                  {showMenu ? (
                    <MdKeyboardArrowUp size={24} color={PRIMARY_GREEN} />
                  ) : (
                    <MdKeyboardArrowDown size={24} color={PRIMARY_GREEN} />
                  )}
                </FlexLayout>
                {showMenu && (
                  <StyledDropdownMenu
                    ref={menuRef}
                    options={[{ value: 'logout', label: 'Log out' }]}
                    onOptionSelect={(option) => onOptionSelect(option)}
                  />
                )}
              </FlexContainer>
            )}
          </FlexContainer>
        </PageTitleContainer>
      </div>
    </>
  );
};
