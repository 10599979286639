import { FieldValues, useForm } from 'react-hook-form';
import { DocumentType } from '../../../models/document';
import { TextField } from '../../inputs/textField/textField';
import { Text } from '../../text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { useCallback, useState } from 'react';
import { updateDocument } from '../../../api/patch/document.patch';
import moment from 'moment';
import { POA } from '../../../consts/document';

interface EditDocumentForm extends FieldValues {
  document_expiry_date: string;
}

interface EditDocumentProps {
  documentId: string;
  documentType: DocumentType;
  expiryDate: string;
  onClose: (fetchData?: boolean) => void;
  categoryId: string;
}

export const EditDocument = ({ documentId, documentType, expiryDate, onClose, categoryId }: EditDocumentProps) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<EditDocumentForm>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: { document_expiry_date: moment(expiryDate)?.format('YYYY-MM-DD') },
  });

  const onSubmit = useCallback(
    (values: EditDocumentForm) => {
      setSubmitting(true);
      updateDocument(documentId, {
        expiry_date: values?.document_expiry_date,
        document_type: documentType,
        category_id: categoryId,
      }).then(() => {
        setSubmitting(false);
        onClose(true);
      });
    },
    [documentId, documentType, onClose, categoryId]
  );

  return (
    <>
      <Text styled={{ marginTop: 24 }} variant="body6" weight={500} color={PRIMARY_PURPLE} block>
        {documentType}
      </Text>
      <TextField
        {...register('document_expiry_date', {
          validate:
            documentType === POA
              ? {
                  validateExpiryDate: (date: string | undefined) => {
                    const isFuture: boolean = moment(date).isAfter(moment());
                    const olderThan3Months: boolean = moment().diff(moment(date), 'months', true) > 3;
                    if (isFuture || olderThan3Months) {
                      return 'Proof of address issue date should be within the last 3 months';
                    }
                  },
                }
              : {
                  validateExpiryDate: (date: string) => {
                    if (new Date(date) < new Date()) {
                      return 'Expiry date should be in the future';
                    }
                  },
                },
        })}
        label="Document expiry date"
        type="date"
        name="document_expiry_date"
        error={errors?.document_expiry_date}
        styled={{ marginTop: 24 }}
      />
      <FlexLayout itemsX="end" styled={{ marginTop: 24 }} gap={16}>
        <SecondaryButton onClick={() => onClose()} disabled={submitting}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={submitting} onClick={handleSubmit(onSubmit)}>
          Save
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
