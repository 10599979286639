import { apiSlice } from '../../api/baseApi';

type ConditionReportByIdRequest = {
  id: string;
  filters: string;
};

export const vehicleApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllVehicles2: builder.query({
      query: (queryParams) => `vehicle${queryParams && `?${queryParams}`}`,
    }),
    getConditionReportsByVehicleId: builder.query({
      query: (fetchArgs: ConditionReportByIdRequest) =>
        `condition-report/by/vehicle/${fetchArgs.id}${fetchArgs.filters && `?${fetchArgs.filters}`}`,
    }),
  }),
});

export const { useGetAllVehicles2Query, useGetConditionReportsByVehicleIdQuery } = vehicleApiSlice;
