import api from '../../utils/api';

/**
 * Cancels and archives the specified agreement
 * @category API/DELETE
 * @return {Promise} delete api call
 * @example
 * cancelAgreement(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const cancelAgreement = (agreementId: string) => {
  return api.delete(`/agreement/${agreementId}`);
};

/**
 * Cancels and archives the courtesy agreement
 * @category API/DELETE
 * @return {Promise} delete api call
 * @example
 * cancelCourtesyAgreement(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const cancelCourtesyAgreement = (courtesyId: string) => {
  return api.delete(`/agreement/courtesy-agreement/${courtesyId}`);
};
