import api from '../../utils/api';
import { NewVehiclePackagePayload } from '../../models/vehicle';
/**
 * Create vehicle package request
 * @category API/POST
 * @param {object} payload - Data for the user create a package with pricings
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 * package_name =package_name.toUpperCase(),
 * total_weeks = Number(total_weeks),
 * insurance: false,
 * servicing: false,
 * package_type: 'FRIENDLY-FINANCE',
 * package_status: 'ACTIVE',
 * country_id: '5aa0543d-eaa3-4115-a6f2-17a5fa9b7afe',
 * archived: false,
 * prices: pricings,
 * }
 * createVehiclePackage(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const createVehiclePackage = (id: string, payload: NewVehiclePackagePayload) =>
  api.post(`vehicle-type/${id}/package`, payload);
