import { Table as ReactTable, flexRender, ColumnDef } from '@tanstack/react-table';
import { Table as ShadcnTable, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../../ui/table/table';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '../../ui/dropdown/dropdown-menu';
import { BsThreeDotsVertical } from 'react-icons/bs';
import { Button } from '../../ui/button/button';
import { Badge } from '../../ui/badge/badge';
import { cn } from '../../../lib/utils';
import { useCallback, useEffect, useRef } from 'react';

declare module '@tanstack/react-table' {
  interface TableMeta<TData> {
    deleteData?: (data: TData) => void;
    editData?: (data: TData) => void;
    viewData?: (data: TData) => void;
    archiveData?: (data: TData) => void;
  }
}

interface TableProps<TData, TValue> {
  table: ReactTable<TData>;
  columns: ColumnDef<TData, TValue>[];
  isLoading: boolean;
  hasMore: boolean;
  getMore: () => void;
  scrollToTop?: boolean;
}

function Table<TData, TValue>({ table, columns, isLoading, hasMore, getMore, scrollToTop }: TableProps<TData, TValue>) {
  const scrollableContainerRef = useRef(null);
  const observer = useRef<IntersectionObserver | null>();
  const lastRowElementRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading || !table.getRowModel().rows) {
        return;
      }
      if (observer.current) {
        observer.current.disconnect();
      }

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting && hasMore && table.getRowModel().rows.length > 0) {
          getMore();
        }
      });

      if (node) observer.current.observe(node);
    },
    [isLoading, hasMore, table, getMore]
  );

  useEffect(() => {
    if (scrollableContainerRef.current && scrollToTop) {
      (scrollableContainerRef.current as HTMLTableElement).scrollTo({
        top: 0,
      });
    }
  }, [scrollToTop]);

  return (
    <div
      ref={scrollableContainerRef}
      className="max-h-[calc(100vh-260px)] overflow-auto rounded-b-lg bg-white border-t-0 border-l-[1px] border-r-[1px] border-b-[1px] border-utility-gray-200"
    >
      <ShadcnTable data-testid="table">
        <TableHeader data-testid="table-header">
          {table.getHeaderGroups().map((headerGroup, rowIndex) => (
            <TableRow key={headerGroup.id} data-testid={`table-header-row-${rowIndex}`}>
              {headerGroup.headers.map((header, headIndex) => {
                return (
                  <TableHead key={header.id} data-testid={`table-header-row-${rowIndex}-head-${headIndex}`}>
                    {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                  </TableHead>
                );
              })}
            </TableRow>
          ))}
        </TableHeader>
        <TableBody data-testid="table-body">
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, rowIndex) => (
              <TableRow
                key={row.id}
                data-state={row.getIsSelected() && 'selected'}
                data-testid={`table-body-row-${rowIndex}`}
              >
                {row.getVisibleCells().map((cell, cellIndex) => (
                  <TableCell key={cell.id} data-testid={`table-body-row-${rowIndex}-cell-${cellIndex}`}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </TableCell>
                ))}
              </TableRow>
            ))
          ) : isLoading ? (
            <TableRow>
              <TableCell data-testid="table-loading" colSpan={columns.length} className="h-24 text-center">
                Loading...
              </TableCell>
            </TableRow>
          ) : (
            <TableRow>
              <TableCell data-testid="table-no-results" colSpan={columns.length} className="h-24 text-center">
                No results.
              </TableCell>
            </TableRow>
          )}
          {isLoading && table.getRowModel().rows?.length > 0 && (
            <TableRow>
              <TableCell data-testid="table-loading" colSpan={columns.length} className="h-24 text-center">
                Loading...
              </TableCell>
            </TableRow>
          )}
          {table.getRowModel().rows?.length > 0 && <TableRow ref={lastRowElementRef}></TableRow>}
        </TableBody>
      </ShadcnTable>
    </div>
  );
}

interface ColumnVisibilityDropdownProps<TData> {
  table: ReactTable<TData>;
}

function ColumnVisibilityDropdown<TData>({ table }: ColumnVisibilityDropdownProps<TData>) {
  const formatId = (id: string) => {
    return id.replace('_', ' ').replace('-', ' ');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="mr-2" asChild data-testid="table-column-visibility-trigger">
        <Button variant="ghost" color="secondary" data-testid="table-column-visibility-button">
          <BsThreeDotsVertical className="text-utility-gray-400" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" data-testid="table-column-visibility-content">
        {table
          .getAllColumns()
          .filter((column) => column.getCanHide())
          .map((column, index) => {
            return (
              <DropdownMenuCheckboxItem
                key={column.id}
                className="capitalize"
                checked={column.getIsVisible()}
                onCheckedChange={(value) => column.toggleVisibility(!!value)}
                data-testid={`table-column-visibility-checkbox-item-${index}`}
              >
                {formatId(column.id)}
              </DropdownMenuCheckboxItem>
            );
          })}
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function HeaderContainer({ children, className }: React.InputHTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn('bg-white rounded-t-xl border-[1px] border-utility-gray-200', className)}>
      <div className="flex max-sm:flex-col items-center justify-between">{children}</div>
    </div>
  );
}

function HeaderActionsContainer({ children, className }: React.InputHTMLAttributes<HTMLDivElement>) {
  return <div className={cn('flex items-center gap-3 pt-5 pb-8', className)}>{children}</div>;
}

interface TitleProps extends React.InputHTMLAttributes<HTMLHeadingElement> {
  children: string;
  rowCount: number;
}

function Title({ children, rowCount, className, ...props }: TitleProps) {
  return (
    <h2
      className={cn(
        'font-extrabold text-lg text-utility-gray-900 pl-[24px] inline-flex gap-x-2 items-center justify-center gap-x-2',
        className
      )}
      {...props}
    >
      {children}
      <Badge color="purple">{rowCount}</Badge>
    </h2>
  );
}

export const DashDataTable = {
  ColumnVisibilityDropdown: ColumnVisibilityDropdown,
  HeaderContainer: HeaderContainer,
  HeaderActionsContainer: HeaderActionsContainer,
  Title: Title,
  Table: Table,
};
