import React, { useEffect, useMemo, useState } from 'react';
import { Control, FieldError, FieldValues, useFieldArray, useForm } from 'react-hook-form';
import { FiTrash2 } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';

import {
  FileType,
  ModifiedVehicleType,
  NewVehicleTypePayload,
  VehicleFile,
  VehicleType,
} from '../../../../models/vehicle';
import { fuelTypeOptions, months } from '../../../../consts/vehicleType';
import { InputChips } from '../../../../uiComponents/inputChips/inputChips';
import { fileToBase64 } from '../../../../utils/utils';
import { updateVehicleType } from '../../../../api/patch/vehicleType.patch';
import { createVehicleType, uploadVehicleTypePhoto } from '../../../../api/post/vehicleType.post';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../../common/styles/Colors';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { TextArea } from '../../../../uiComponents/inputs/textArea/textArea';
import { UploaderInput } from '../../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { RadioButton } from '../../../../uiComponents/uiControls/radioButton/radioButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { Text } from '../../../../uiComponents/text/text';
import { CarColour, Image, StyledDiv } from './vehicleType.styles';
import { Notification } from '../../../../uiComponents/toast/toast';

interface VehicleTypeFormProps {
  values?: ModifiedVehicleType | null;
  close?: (props?: string) => void;
}
interface ImageS3 {
  color: string;
  s3_url: string;
  defaultImage: boolean;
}

export const EditVehicleTypeForm = ({ values, close }: VehicleTypeFormProps) => {
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const getColorsList = (): { value: string }[] => {
    if (!values?.colors || !values!.colors[0]) return [];
    return values?.colors.map((item) => {
      return { value: item };
    });
  };
  const {
    handleSubmit,
    register,
    control,
    formState: { errors },
    setValue,
    watch,
  } = useForm<VehicleType>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      ...values,
      colors: getColorsList(),
    },
  });
  const {
    fields: colorFields,
    remove,
    append,
    replace,
    update,
  } = useFieldArray({
    control,
    name: 'files',
  });
  const selectedColors = watch('colors');
  useEffect(() => {
    if (selectedColors && selectedColors.length === 0) {
      setValue('files', []);
    }
  }, [selectedColors, setValue]);
  const showImagesAndUpload = useMemo(() => {
    setLoading(true);
    const colors = (values && values.colors) || [];
    const images = (values && values.image_s3_url) || [];
    const colorsWithImages = images
      .filter((image) => colors.includes(image.color))
      .map((x) => ({
        name: x.color,
        image: x.s3_url,
        defaultImage: x.defaultImage,
      }));
    const colorsWithoutImages = colors
      .filter((x) => !images.find(({ color }) => x === color))
      .map((x) => ({ name: x, image: '', defaultImage: false }));
    const formattedArray: VehicleFile[] = [...colorsWithImages, ...colorsWithoutImages];
    setLoading(false);
    return formattedArray;
  }, [values]);
  useEffect(() => {
    if (showImagesAndUpload) {
      replace(showImagesAndUpload);
    }
  }, [showImagesAndUpload, replace]);
  const handleUpdate = (submitValues: VehicleType, files: FileType[], imagesS3: ImageS3[], id: string) => {
    const {
      manufacturer,
      model,
      spec,
      model_year,
      colors,
      fuel_type,
      agreement_length_weeks,
      agreement_value_weeks,
      gross_value,
      net_value,
      vat_value,
      deposit,
      accident_excess,
      service_interval_months,
      service_interval_mileage,
      sales_points,
      sales_paragraph,
      breakdown_cover_months,
      government_grant_value,
      list_price_ex_vat,
      net_purchase_price_otto,
      country_id,
    } = submitValues;
    const payload: ModifiedVehicleType = {
      manufacturer,
      model,
      spec,
      model_year,
      colors: colors.map((color: { value: string }) => color.value),
      fuel_type,
      agreement_length_weeks: Number(agreement_length_weeks),
      agreement_value_weeks: Number(agreement_value_weeks).toFixed(2),
      gross_value: Number(gross_value).toFixed(2),
      net_value: Number(net_value).toFixed(2),
      vat_value: Number(vat_value).toFixed(2),
      deposit: Number(deposit).toFixed(2),
      accident_excess: Number(accident_excess).toFixed(2),
      service_interval_months: Number(service_interval_months),
      service_interval_mileage: Number(service_interval_mileage),
      breakdown_cover_months: Number(breakdown_cover_months),
      sales_points,
      sales_paragraph,
      government_grant_value: Number(government_grant_value).toFixed(2),
      list_price_ex_vat: Number(list_price_ex_vat).toFixed(2),
      net_purchase_price_otto: Number(net_purchase_price_otto).toFixed(2),
      visible: true,
      country_id,
      image_s3_url: imagesS3,
    };
    updateVehicleType(id, payload)
      .then((data) => {
        if (files && files.length) {
          uploadVehicleTypePhoto(data.data[0].id, { files })
            .then(() => {
              Notification({
                type: 'success',
                title: 'Success',
                message: 'Vehicle type has been successfully updated.',
              });
              if (close) {
                close('close');
              }
            })
            .catch(() => {
              setLoading(false);
              Notification({
                type: 'error',
                title: 'Error',
                message: 'Error uploading photo.',
              });
            });
        } else {
          Notification({
            type: 'success',
            title: 'Success',
            message: 'Vehicle type has been successfully updated.',
          });
          setLoading(false);
          if (close) {
            close('close');
          }
        }
        return setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error updating vehicle type.',
        });
      });
  };
  const handleCreate = (submitValues: VehicleType, files: FileType[]) => {
    const {
      manufacturer,
      model,
      spec,
      model_year,
      colors,
      fuel_type,
      agreement_length_weeks,
      agreement_value_weeks,
      gross_value,
      total_due,
      net_value,
      vat_value,
      deposit,
      accident_excess,
      service_interval_months,
      service_interval_mileage,
      sales_points,
      sales_paragraph,
      breakdown_cover_months,
      government_grant_value,
      list_price_ex_vat,
      net_purchase_price_otto,
      country_id,
    } = submitValues;
    const payload: NewVehicleTypePayload = {
      manufacturer: manufacturer.toUpperCase(),
      model: model.toUpperCase(),
      spec: spec.toUpperCase(),
      model_year,
      colors: colors.map((color: { value: string }) => color.value.toUpperCase()),
      fuel_type,
      agreement_length_weeks: Number(agreement_length_weeks),
      agreement_value_weeks: Number(agreement_value_weeks).toFixed(2),
      total_due: Number(total_due).toFixed(2),
      gross_value: Number(gross_value).toFixed(2),
      net_value: Number(net_value).toFixed(2),
      vat_value: Number(vat_value).toFixed(2),
      deposit: Number(deposit).toFixed(2),
      accident_excess: Number(accident_excess).toFixed(2),
      service_interval_months: Number(service_interval_months),
      service_interval_mileage: Number(service_interval_mileage),
      breakdown_cover_months: Number(breakdown_cover_months),
      government_grant_value: Number(government_grant_value).toFixed(2),
      list_price_ex_vat: Number(list_price_ex_vat).toFixed(2),
      net_purchase_price_otto: Number(net_purchase_price_otto).toFixed(2),
      sales_points,
      sales_paragraph,
      visible: true,
      country_id,
    };
    createVehicleType(payload).then((data: any) => {
      if (files.length) {
        uploadVehicleTypePhoto(data.data[0].id, { files })
          .then(() => {
            Notification({
              type: 'success',
              title: 'Success',
              message: 'Vehicle type has been successfully created.',
            });
            navigate(-1);
          })
          .catch(() => {
            setLoading(false);
            Notification({
              type: 'error',
              title: 'Error',
              message: 'Error uploading photo.',
            });
          });
      } else {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Vehicle type has been successfully created.',
        });
        navigate(-1);
      }
      return setLoading(false);
    });
  };
  const onSubmit = async (submitValues: VehicleType) => {
    setLoading(true);
    const { files: vehicleColorImages } = submitValues;

    const files: FileType[] = [];
    const imagesS3: ImageS3[] = [];

    if (vehicleColorImages) {
      const images: VehicleFile[] =
        (
          vehicleColorImages && vehicleColorImages.filter((i: VehicleFile) => (i.image !== '' ? i.image : undefined))
        ).filter((x: VehicleFile) => x !== undefined) || [];
      await Promise.all(
        images.map(async (image: { name: string; image: string | File; defaultImage?: boolean }) => {
          if (typeof image.image === 'string') {
            imagesS3.push({
              color: image.name,
              s3_url: image.image,
              defaultImage: images.length > 1 ? (image?.defaultImage ? image.defaultImage : false) : true,
            });
          } else {
            const base64Image = await fileToBase64(image.image);
            files.push({
              fileName: image.name,
              type: image.image.type,
              contents: base64Image,
              defaultImage: images.length > 1 ? (image?.defaultImage ? image.defaultImage : false) : true,
            });
          }
        })
      );
    }
    if (values?.id && values) {
      handleUpdate(submitValues, files, imagesS3, values.id);
    } else {
      handleCreate(submitValues, files);
    }
  };

  const totalDue: number = Number(watch('agreement_length_weeks') || 0) * Number(watch('agreement_value_weeks') || 0);
  const netValue: number = Number(watch('net_purchase_price_otto') || 0) + Number(watch('government_grant_value') || 0);
  const grossValue: number = netValue + Number(watch('vat_value') || 0);

  useEffect(() => {
    setValue('total_due', totalDue, { shouldValidate: true });
    setValue('net_value', netValue, { shouldValidate: true });
    setValue('gross_value', grossValue, { shouldValidate: true });
  }, [totalDue, netValue, grossValue, setValue]);

  return (
    <>
      <GridLayout template={3} gap={26}>
        <TextField
          label="Manufacturer"
          {...register('manufacturer', {
            required: 'manufacture is required',
          })}
          required
          name="manufacturer"
          type="text"
          placeholder="Manufacturer"
          error={errors.manufacturer}
        />
        <TextField
          label="Model"
          {...register('model', {
            required: 'model is required',
          })}
          required
          name="model"
          type="text"
          placeholder="Model"
          error={errors.model}
        />
        <div>
          <TextFieldLabel $isRequired>Fuel Type</TextFieldLabel>
          <DropDown
            name="fuel_type"
            error={errors.fuel_type}
            placeholder="Select from the list"
            defaultValue={values ? values.fuel_type?.toString() : ''}
            required={{
              required: 'fuel type is required',
            }}
            control={control as unknown as Control<FieldValues>}
            options={fuelTypeOptions}
          />
        </div>
        <TextField
          label="Spec"
          {...register('spec', {
            required: 'spec is required',
          })}
          required
          name="spec"
          type="text"
          placeholder="Spec"
          error={errors.spec}
        />
        <TextField
          label="Year"
          {...register('model_year', {
            required: 'Year is required',
          })}
          required
          name="model_year"
          type="number"
          placeholder="Year"
          error={errors.model_year}
        />
        <TextField
          label="Agreement Length (weeks)"
          {...register('agreement_length_weeks', {
            required: 'Agreement Length (weeks) is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="agreement_length_weeks"
          type="number"
          placeholder="Agreement Length (weeks)"
          error={errors.agreement_length_weeks}
        />
        <TextField
          label="Agreement Value Weeks"
          {...register('agreement_value_weeks', {
            required: 'Agreement Value Weeks is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="agreement_value_weeks"
          type="number"
          placeholder="Agreement Value Weeks"
          error={errors.agreement_value_weeks}
        />
        <TextField
          label="Net Purchase price (by otto)"
          {...register('net_purchase_price_otto', {
            required: 'Net Purchase price (by otto) is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="net_purchase_price_otto"
          type="number"
          placeholder="Net Purchase price (by otto)"
          error={errors.net_purchase_price_otto}
        />
        <TextField
          label="Government Grant Value"
          {...register('government_grant_value', {
            required: 'Government Grant Value is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="government_grant_value"
          type="number"
          placeholder="Government Grant Value"
          error={errors.government_grant_value}
        />
        <TextField
          label="List price ex VAT"
          {...register('list_price_ex_vat', {
            required: 'List price ex VAT is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="list_price_ex_vat"
          type="number"
          placeholder="List price ex VAT"
          error={errors.list_price_ex_vat}
        />
        <TextField
          label="Deposit"
          {...register('deposit', {
            required: 'Deposit is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="deposit"
          type="text"
          placeholder="Deposit"
          error={errors.deposit}
        />
        {!values && (
          <TextField
            label="Total due"
            {...register('total_due', {
              required: 'Total due is required',
            })}
            required
            name="total_due"
            type="number"
            placeholder="Total due"
            error={errors.total_due}
            disabled
          />
        )}
        <TextField
          label="Gross Value"
          {...register('gross_value', {
            required: 'Gross Value is required',
          })}
          required
          name="gross_value"
          type="number"
          placeholder="Gross Value"
          error={errors.gross_value}
          disabled={!values}
        />
        <TextField
          label="NET Value"
          {...register('net_value', {
            required: 'NET Value is required',
          })}
          required
          name="net_value"
          type="number"
          placeholder="NET Value"
          disabled={!values}
          error={errors.net_value}
        />
        <TextField
          label="VAT Value"
          {...register('vat_value', {
            required: 'VAT Value is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="vat_value"
          type="number"
          placeholder="VAT Value"
          error={errors.vat_value}
        />
        <TextField
          label="Service Interval (Mileage)"
          {...register('service_interval_mileage', {
            required: 'Service Interval (Mileage) is required',
            min: {
              value: 0,
              message: 'The min value is 0',
            },
          })}
          required
          name="service_interval_mileage"
          type="number"
          placeholder="Service Interval (Mileage)"
          error={errors.service_interval_mileage}
        />
        {!values ? (
          <div>
            <TextFieldLabel $isRequired>Service Interval (Months)</TextFieldLabel>
            <DropDown
              name="service_interval_months"
              error={errors.service_interval_months}
              placeholder="Select from the list"
              required={{
                required: 'Service Interval is required',
              }}
              control={control as unknown as Control<FieldValues>}
              options={months}
            />
          </div>
        ) : (
          <TextField
            label="Service Interval (Months)"
            {...register('service_interval_months', {
              required: 'Service Interval (Months) is required',
            })}
            required
            name="service_interval_months"
            type="number"
            placeholder="Service Interval (Months)"
            error={errors.service_interval_months}
          />
        )}
        {!values ? (
          <div>
            <TextFieldLabel $isRequired>Breakdown Cover (months)</TextFieldLabel>
            <DropDown
              name="breakdown_cover_months"
              error={errors.breakdown_cover_months}
              placeholder="Select from the list"
              required={{
                required: 'Breakdown Cover is required',
              }}
              control={control as unknown as Control<FieldValues>}
              options={months}
            />
          </div>
        ) : (
          <TextField
            label="Breakdown Cover (months)"
            {...register('breakdown_cover_months', {
              required: 'Breakdown Cover (months) is required',
            })}
            required
            name="breakdown_cover_months"
            type="number"
            placeholder="Breakdown Cover (months)"
            error={errors.breakdown_cover_months}
          />
        )}
      </GridLayout>
      <GridLayout template={2} gap={33} styled={{ marginTop: '26px' }}>
        <div>
          <TextArea
            label="Sales Points"
            {...register('sales_points', {
              required: 'Sales points are required',
            })}
            required
            placeholder="Sales points"
            error={errors.sales_points}
            styled={{ height: '17vh' }}
          />
        </div>
        <div>
          <TextArea
            label="Sales Paragraph"
            {...register('sales_paragraph', {
              required: 'Sales points are required',
            })}
            required
            placeholder="Sales paragraph"
            error={errors.sales_paragraph}
            styled={{ height: '17vh' }}
          />
        </div>
        <StyledDiv marginTop={15}>
          <TextFieldLabel $isRequired={true}>Country</TextFieldLabel>
          <DropDown
            name="country_id"
            error={errors['country_id']}
            placeholder="Select from list"
            defaultValue={values?.country_id}
            required={{
              required: 'Country id is required',
            }}
            control={control as unknown as Control<FieldValues>}
            options={[
              {
                label: 'United Kingdom',
                value: '5aa0543d-eaa3-4115-a6f2-17a5fa9b7afe',
              },
            ]}
          />
        </StyledDiv>
        <StyledDiv marginTop={5}>
          <InputChips
            name="colors"
            label="Colours"
            header="Colours"
            register={register}
            control={control}
            required={{
              required: 'Colour is a required field',
            }}
            error={errors.colors as FieldError}
            addCallback={(v) => {
              append({
                name: v,
                image: '',
              });
            }}
            removeCallback={(i) => remove(i)}
          />
        </StyledDiv>
      </GridLayout>
      <>
        {colorFields.length > 0 &&
          colorFields.map((color, index) => (
            <CarColour inline vertical wrap key={index}>
              {color.image === '' ? (
                <UploaderInput
                  control={control as unknown as Control<FieldValues>}
                  name={`files[${index}].image]`}
                  loading={loading}
                  disabled={loading}
                  key={color.id}
                  label={color.name}
                />
              ) : (
                <div>
                  <Image
                    src={typeof color.image === 'object' ? URL.createObjectURL(color.image) : color.image}
                    alt={color.name}
                  />
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                    {color?.name}
                  </Text>
                </div>
              )}
              <FlexLayout styled={{ marginTop: 16 }} gap={24}>
                {colorFields.length > 1 && (
                  <FlexLayout gap={8} itemsY="center">
                    <RadioButton
                      value={color?.name}
                      id={color?.id}
                      defaultChecked={!!color?.defaultImage}
                      name={'defaultImage'}
                    />
                    <Text color={PRIMARY_PURPLE} variant="body6" weight={500} block>
                      Default
                    </Text>
                  </FlexLayout>
                )}
                <FlexLayout>
                  <FiTrash2
                    size={20}
                    color={STATUS_RED}
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      update(index, {
                        name: color.name,
                        image: '',
                        defaultImage: false,
                      });
                    }}
                  />
                </FlexLayout>
              </FlexLayout>
            </CarColour>
          ))}
      </>
      <FlexLayout itemsX="end" itemsY="end" styled={{ marginTop: '15px' }}>
        {close && (
          <SecondaryButton
            disabled={loading}
            styled={{ marginRight: '10px', padding: '0px 38px' }}
            onClick={() => close('cancel')}
          >
            Cancel
          </SecondaryButton>
        )}
        <PrimaryButton onClick={handleSubmit(onSubmit)} isProcessing={loading} styled={{ padding: '0px 38px' }}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
