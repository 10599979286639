import React from 'react';
import { FieldError } from 'react-hook-form';
import { StyledTextArea, TextAreaError } from './textArea.styles';
import { TextFieldLabel } from '../textField/textField.styles';
import { withStyledProps } from '../../../utils/colorUtils';

interface TextAreaProps extends React.PropsWithoutRef<JSX.IntrinsicElements['textarea']> {
  label?: string;
  error?: FieldError;
  filled?: boolean;
}

export const TextArea = withStyledProps(
  React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    ({ label, placeholder, disabled, onChange, error, filled, required, name, ...props }: TextAreaProps, ref) => {
      return (
        <>
          {label && <TextFieldLabel $isRequired={required}>{label}</TextFieldLabel>}
          <StyledTextArea
            {...props}
            ref={ref}
            $filled={filled}
            onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) => onChange?.(e)}
            placeholder={placeholder}
            disabled={disabled}
            name={name}
            autoCorrect="off"
            autoComplete="off"
            spellCheck={false}
            {...props}
          />
          {error && <TextAreaError>{error?.message}</TextAreaError>}
        </>
      );
    }
  )
);
