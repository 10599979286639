import {
  AddExcessMileage,
  CustomerProfile,
  DriverTagsPayload,
  IDriverUploadRequest,
  UpdateDriverSubscription,
} from '../../models/driver';
import { NewFreezePayload } from '../../models/freeze';
import { NewHolidayPayload } from '../../models/holiday';
import { UpdateOrdwayPaymentGatewayPayload } from '../../models/ordway';
import api from '../../utils/api';

/**
 * Change Subscription Request
 * @category API/POST
 * @return {Promise} Returns a promise for the api request
 */
export const changeSubscription = (params: UpdateDriverSubscription) =>
  api.post('/driver/updateDriverSubscription', params);

export const addExcessMileage = (params: AddExcessMileage) => api.post('/driver/addExcessMileage', params);

export const updateOrdwayCustomerGateway = (params: UpdateOrdwayPaymentGatewayPayload) =>
  api.post('/driver/ordway/updateOrdwayCustomerGateway', params);

export const createHolidayBatchWithHolidays = (params: NewHolidayPayload) => api.post('/holiday', params);

export const createFreezeBatchWithFreezes = (params: NewFreezePayload) => api.post('/freeze', params);

export const submitInitialDriverDetails = (params: CustomerProfile) =>
  api.post('driver/submit-initial-details', params);

export const submitAdditionalDriverDetails = (params: CustomerProfile) =>
  api.post('driver/submit-additional-details', params);

export const activateOrdwayCustomer = (customerId: string) =>
  api.post('driver/activate-ordway-customer', { customerId });

export const createDriverTags = (driverId: string, payload: DriverTagsPayload) =>
  api.post(`/driver/tag/${driverId}`, payload);

// export const getPresignedUrl = (payload: IDriverUploadRequest) => api.post('/driver/get/presigned/url/upload', payload);

export const createDriverUploadedDocument = (payload: IDriverUploadRequest) =>
  api.post('/driver/create/document', payload); // save
