import React, { useState } from 'react';
import { RadioButton } from '../../../uiComponents/uiControls/radioButton/radioButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { SelectTemplateHeader, SelectTemplateRoot, SelectTemplateRow } from './SelectTemplateForm.styles';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';

interface SelectTemplateFormProps {
  onCancel: () => void;
  onConfirm: (template: number) => void;
  onSendTestEmail: (template: number) => void;
}

export const SelectTemplateForm = ({ onCancel, onSendTestEmail, onConfirm }: SelectTemplateFormProps) => {
  const [template, setTemplate] = useState<string>();

  return (
    <>
      <SelectTemplateRoot>
        <SelectTemplateHeader>
          <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
            Choose template
          </Text>
        </SelectTemplateHeader>
        <SelectTemplateRow>
          <FlexLayout gap={28} itemsX="start" paddingY={10}>
            <RadioButton
              id="notification_of_renewal"
              name="selected_template"
              onCheck={() => setTemplate('notification_of_renewal')}
            />
            <Text variant="body7" color={PRIMARY_PURPLE} weight={300}>
              Notification of Renewal
            </Text>
          </FlexLayout>
        </SelectTemplateRow>
        <SelectTemplateRow>
          <FlexLayout gap={28} itemsX="start" paddingY={10}>
            <RadioButton
              id="renewal_documents"
              name="selected_template"
              onCheck={() => setTemplate('renewal_documents')}
            />
            <Text variant="body7" color={PRIMARY_PURPLE} weight={300}>
              Renewal Documents
            </Text>
          </FlexLayout>
        </SelectTemplateRow>
      </SelectTemplateRoot>
      <FlexLayout itemsX="space-between" itemsY="center">
        <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
        <FlexLayout itemsX="end" itemsY="center" gap={10}>
          <SecondaryButton
            disabled={!template}
            onClick={() => onSendTestEmail(template === 'notification_of_renewal' ? 0 : 1)}
          >
            Send test email
          </SecondaryButton>
          <PrimaryButton disabled={!template} onClick={() => onConfirm(template === 'notification_of_renewal' ? 0 : 1)}>
            Confirm
          </PrimaryButton>
        </FlexLayout>
      </FlexLayout>
    </>
  );
};
