import React, { useState } from 'react';
import { Modal } from '../modal';
import {
  StyledText,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledExclamationIcon,
  StyledSubtitle,
} from './confirmationModal.styles';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_30 } from '../../../common/styles/Colors';

interface ConfirmationModalProps {
  icon?: JSX.Element;
  onClose?: () => void;
  onConfirm?: () => void;
  preConfirm?: () => Promise<any>;
  preClose?: () => Promise<any>;
  closeButtonCaption?: string;
  confirmButtonCaption?: string;
  title: string | JSX.Element | (() => string | JSX.Element);
  isOpen: boolean;
  subtitle?: string;
}

export const ConfirmationModal = ({
  icon,
  onClose,
  onConfirm,
  preConfirm,
  preClose,
  closeButtonCaption,
  confirmButtonCaption,
  title,
  subtitle,
  isOpen,
}: ConfirmationModalProps) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const getTitle = (): string | JSX.Element => {
    if (typeof title === 'function') {
      return title();
    }
    return title;
  };

  return (
    <Modal open={isOpen} showClose={false} onClose={() => onClose?.()} styled={{ maxWidth: 800, minWidth: 375 }}>
      <FlexLayout vertical itemsX="center">
        {icon ? icon : <StyledExclamationIcon size={200} color={SECONDARY_PURPLE_30} />}
        <StyledText>{getTitle()}</StyledText>
        {subtitle && (
          <StyledSubtitle color={PRIMARY_PURPLE} variant="body6">
            {subtitle}
          </StyledSubtitle>
        )}
        <FlexLayout itemsX="center">
          {!!confirmButtonCaption && (
            <StyledPrimaryButton
              isProcessing={isProcessing}
              onClick={() => {
                if (preConfirm != null) {
                  setIsProcessing(true);
                  preConfirm?.()
                    ?.then(() => {
                      onConfirm?.();
                    })
                    .finally(() => {
                      setIsProcessing(false);
                    });
                } else {
                  onConfirm?.();
                }
              }}
            >
              {confirmButtonCaption}
            </StyledPrimaryButton>
          )}
          {closeButtonCaption && (
            <StyledSecondaryButton
              disabled={isProcessing}
              onClick={() => {
                if (preClose != null) {
                  setIsProcessing(true);
                  preClose?.()
                    ?.then(() => {
                      onClose?.();
                    })
                    .finally(() => {
                      setIsProcessing(false);
                    });
                } else {
                  onClose?.();
                }
              }}
            >
              {closeButtonCaption}
            </StyledSecondaryButton>
          )}
        </FlexLayout>
      </FlexLayout>
    </Modal>
  );
};
