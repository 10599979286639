import styled from 'styled-components';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';

export const ImgContainer = styled(FlexLayout)`
  border: 1px solid ${PRIMARY_PURPLE};
  border-radius: 10px;
  color: ${PRIMARY_PURPLE};
  margin-top: 64px;
  padding-bottom: 10px;
  width: 100%;
`;

export const Image = styled.img`
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  margin-bottom: 10px;
  height: 80%;
  width: 100%;
`;

export const ActionContainer = styled(FlexLayout)`
  color: ${PRIMARY_PURPLE};
  width: 100%;
`;
