export const PRACTICAL_PENDING = 'PRACTICAL-PENDING';
export const PRACTICAL_PASSED = 'PRACTICAL-PASSED';
export const PRACTICAL_FAILED = 'PRACTICAL-FAILED';
export const ONLINE_PENDING = 'ONLINE-PENDING';
export const ONLINE_PASSED = 'ONLINE-PASSED';
export const ONLINE_FAILED = 'ONLINE-FAILED';
export const ASSESSMENT_ONLINE = 'ONLINE';
export const ASSESSMENT_PRACTICAL = 'PRACTICAL';
export const ASSESSMENT_NONE = 'NONE';
export const ASSESSMENT_STATUS_NOT_NEEDED = 'NOT-NEEDED';
