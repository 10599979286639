import styled from 'styled-components';
import { PRIMARY_PURPLE, NATURAL_GRAY } from '../../../../../common/styles/Colors';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';

export const ReassignFormRoot = styled.form`
  width: 523px;
`;

export const ReassignLabel = styled.div`
  width: 100%;
  border-radius: 50px;
  color: ${PRIMARY_PURPLE};
  background-color: ${NATURAL_GRAY};
  font-size: 14px;
  font-weight: 300;
  padding: 8px 20px;
`;

export const ReassignText = styled.div`
  width: 100%;
  padding: 5px 28px;
  color: ${PRIMARY_PURPLE};
  font-size: 14px;
  font-weight: 500;
`;

export const ReassignTitle = styled.div`
  width: 100%;
  color: ${PRIMARY_PURPLE};
  font-size: 16px;
  font-weight: 500;
  margin-top: 20px;
  margin-bottom: 5px;
`;

export const DriverSearch = styled.div`
  width: 100%;
  position: relative;
`;

export const SearchButton = styled(FlexLayout)`
  position: absolute;
  right: 8px;
  top: 0;
  width: 44px;
  height: 44px;
  cursor: pointer;
`;
