import styled from 'styled-components';

export const RadialChart = styled.div<{ $width: number; $margin: number }>`
  width: ${(props) => `${props.$width}px`};
  margin: ${(props) => `${props.$margin}`};
  transition: all 0.3s ease-in;
  text-align: center;
  line-height: initial;
`;
export const RadialChartTotal = styled.circle`
  opacity: 0.3;
`;
export const RadialChartProgress = styled.circle`
  transform: rotate(270deg);
  transform-origin: center;
  transition: all 0.6s cubic-bezier(0.58, 0.16, 0.5, 1.14);
  transition-delay: 0.3s;
`;

export const CenterText = styled.span`
  font-size: 15px;
  letter-spacing: 0;
  font-family: 'Poppins', sans-serif;
`;

export const BelowText = styled.div`
  font-family: 'Poppins', sans-serif;
  font-size: 12px;
`;
