import React from 'react';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../../uiComponents/text/text';
import { PRIMARY_PURPLE, SECONDARY_PURPLE_5 } from '../../../../../common/styles/Colors';

interface TelematicsSubcardProps {
  title: string;
  icon: JSX.Element;
  telematicsType: string;
}

export const TelematicsMediumCard = ({ title, icon, telematicsType }: TelematicsSubcardProps) => {
  return (
    <FlexLayout
      styled={{
        backgroundColor: `${SECONDARY_PURPLE_5}`,
        borderRadius: '16px',
        color: `${PRIMARY_PURPLE}`,
        margin: '10px 0',
        padding: 10,
      }}
      itemsX="start"
      gap={1}
      vertical
    >
      <Text variant="body6" weight={500}>
        {title}
      </Text>
      {title === 'Driver location' ? (
        <FlexLayout styled={{ marginTop: '10px' }} itemsX="center">
          <a href={telematicsType} target="_blank" rel="noreferrer">
            {icon}
          </a>
        </FlexLayout>
      ) : (
        <FlexLayout styled={{ marginTop: '10px' }} itemsY="center" gap={10}>
          <>{icon}</>
          <Text variant="body6" color={PRIMARY_PURPLE} weight={800}>
            {`${telematicsType} miles`}
          </Text>
        </FlexLayout>
      )}
    </FlexLayout>
  );
};
