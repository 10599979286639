import api from '../../utils/api';

/**
 * Delete note request
 * @category API/DELETE
 * @param {object} id - note id for the user to delete the package
 * @return {Promise} Returns a promise for the api request
 * @example

 * deleteNote(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteNote = (id: string) => api.delete(`/note/${id}`);
