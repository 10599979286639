import { ChecklistType, Reason } from '../models/transfer';

// Dynamic
export const ASSIGN_COURTESY = 'assignCourtesy';
export const FINISH_SERVICE_AND_UNFREEZE_AGREEMENT = 'finishServiceAndUnfreezeAgreement';
export const FINISH_SERVICING = 'finishServicing';
export const FINISH_OFF_ROAD_VEH = 'finishServicingForOffRoadVehicle';
export const NEW_USED_AGREEMENT = 'newUsedAgreement';
export const RETURN_COURTESY = 'returnCourtesy';
export const TERMINATE = 'terminate';

// Reason
export const ACCIDENT = 'ACCIDENT';
export const AGREEMENT_CANCELLED = 'CANCELLED';
export const ASSIGN_REPLACEMENT = 'ASSIGN-REPLACEMENT';
export const COLLECTION_COURTESY = 'COLLECTION-COURTESY';
export const COLLECTION_NEW = 'COLLECTION-NEW';
export const COLLECTION_REPLACEMENT = 'COLLECTION-REPLACEMENT';
export const COURTESY_RETURN = 'COURTESY-RETURN';
export const DRIVER_ISSUE = 'DRIVER-ISSUE';
export const DROP = 'DROP';
export const MOT = 'MOT';
export const PHV_PROBLEM = 'PHV-PROBLEM';
export const PULL_OFF = 'PULL-OFF';
export const REPAIR_FINISHED = 'REPAIR-FINISHED';
export const REPAIR_OTHER = 'REPAIR-OTHER';
export const REPAIR_WARRANTY = 'REPAIR-WARRANTY';
export const REPO = 'REPO';
export const REPLACEMENT_RETURN = 'REPLACEMENT-RETURN';
export const SWAP = 'SWAP';
export const TERMINATE_AND_SERVICE = 'TERMINATE-AND-SERVICE';
export const TERMINATED = 'TERMINATED';

export const CHECK_IN = 'CHECK-IN';
export const CHECK_OUT = 'CHECK-OUT';

export const conditionReportReasons: Reason[] = [
  {
    type: CHECK_IN,
    label: 'Accident',
    value: 'ACCIDENT',
  },
  {
    type: CHECK_IN,
    label: 'Repair: Warranty',
    value: 'REPAIR-WARRANTY',
  },
  {
    type: CHECK_IN,
    label: 'Repair: Non-warranty',
    value: 'REPAIR-OTHER',
  },
  {
    type: CHECK_IN,
    label: 'MOT',
    value: 'MOT',
  },
  {
    type: CHECK_IN,
    label: 'PHV issue',
    value: 'PHV-PROBLEM',
  },
  {
    type: CHECK_IN,
    label: 'Swap',
    value: 'SWAP',
  },
  {
    type: CHECK_IN,
    label: 'Repo',
    value: 'REPO',
  },
  {
    type: CHECK_IN,
    label: 'Terminate',
    value: 'TERMINATED',
  },
  {
    type: CHECK_IN,
    label: 'Collection replacement',
    value: 'COLLECTION-REPLACEMENT',
  },
];

export const driverChecklistData: ChecklistType[] = [
  {
    name: 'frontBumper',
    s3Folder: 'FRONT-BUMPER',
    label: 'Front Bumper',
  },
  {
    name: 'windscreen',
    s3Folder: 'WINDSCREEN',
    label: 'Windscreen',
  },
  {
    name: 'driverFc',
    s3Folder: 'DRIVER-FC',
    label: 'Driver Side - Front Corner',
  },
  {
    name: 'driverFa',
    s3Folder: 'DRIVER-FA',
    label: 'Driver Side - Front Wheel/Alloy',
  },
  {
    name: 'driverRa',
    s3Folder: 'DRIVER-RA',
    label: 'Driver Side - Back Wheel/Alloy',
  },
  {
    name: 'driverRc',
    s3Folder: 'DRIVER-RC',
    label: 'Driver Side - Back Corner',
  },
  {
    name: 'rearBumper',
    s3Folder: 'REAR-BUMPER',
    label: 'Rear Bumper',
  },
  {
    name: 'boot',
    s3Folder: 'BOOT',
    label: 'Boot',
  },
  {
    name: 'internal1',
    s3Folder: 'INTERNAL-1',
    label: 'Internal 1',
  },
  {
    name: 'internal2',
    s3Folder: 'INTERNAL-2',
    label: 'Tools',
  },
  {
    name: 'odometer',
    s3Folder: 'ODOMETER',
    label: 'Odometer',
  },
  {
    name: 'roof',
    s3Folder: 'ROOF',
    label: 'Roof',
  },
  {
    name: 'seatBelts',
    s3Folder: 'SEATBELTS',
    label: 'Seat belts',
  },
  {
    name: 'telematics',
    s3Folder: 'TELEMATICS',
    label: 'Telematics',
  },
];

export const passengerChecklistData: ChecklistType[] = [
  {
    name: 'passengerRc',
    s3Folder: 'PASSENGER-RC',
    label: 'Passenger Side - Back Corner',
  },
  {
    name: 'passengerRa',
    s3Folder: 'PASSENGER-RA',
    label: 'Passenger Side - Back Wheel/Alloy',
  },
  {
    name: 'interior1',
    s3Folder: 'INTERIOR-1',
    label: 'Interior Back seats',
  },
  {
    name: 'interior2',
    s3Folder: 'INTERIOR-2',
    label: 'Interior Front Seats',
  },
  {
    name: 'passengerFa',
    s3Folder: 'PASSENGER-FA',
    label: 'Passenger Side - Front Wheel/Alloy',
  },
  {
    name: 'passengerFc',
    s3Folder: 'PASSENGER-FC',
    label: 'Passenger Side - Front Corner',
  },
];

export const vehicleInfoData: ChecklistType[] = [
  {
    name: 'allElectrical',
    label: 'All Electrical',
  },
  {
    name: 'allGlass',
    label: 'All Glass',
  },
  {
    name: 'floorMats',
    label: 'Floor Mats',
  },
  {
    name: 'lockingWheelNut',
    label: 'Locking wheel nut',
  },
  {
    name: 'luggageCover',
    label: 'Luggage Cover',
  },
  {
    name: 'oilLevel',
    label: 'Oil Level',
  },
  {
    name: 'safetyBelts',
    label: 'Safety Belts',
  },
  {
    name: 'scWashers',
    label: 'SC Washers',
  },
  {
    name: 'spareWheel',
    label: 'Spare Wheel',
  },
  {
    name: 'telematicDevice',
    label: 'Telematics',
  },
  {
    name: 'tools',
    label: 'Tools',
  },
  {
    name: 'tyreConditions',
    label: 'Tyre Tread Depth',
  },
  {
    name: 'tyrePressure',
    label: 'Tyre Tread Depth',
  },
  {
    name: 'waterLevel',
    label: 'Coolant',
  },
];
