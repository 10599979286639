import styled from 'styled-components';
import { SECONDARY_PURPLE_30, SECONDARY_PURPLE_5, PRIMARY_PURPLE } from '../../../../../../common/styles/Colors';

export const TextBox = styled.div<{ $textArea?: boolean }>`
  width: 100%;
  background-color: ${SECONDARY_PURPLE_5};
  border: 1px solid ${SECONDARY_PURPLE_30};
  border-radius: ${(props) => (props.$textArea ? '16px' : '50px')};
  padding: 13px;
  color: ${PRIMARY_PURPLE};
  font-weight: 300;
  font-size: 16px;
  min-height: ${(props) => (props.$textArea ? '108px' : 'fit-content')};
`;

export const ImageContainerSection = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 63px;
  position: relative;
  cursor: pointer;
`;

export const ImageContainer = styled.div<{ $disabled?: boolean; $active?: boolean; $error?: boolean }>`
  justify-content: center;
  flex-direction: column;
  border: 1px dashed ${PRIMARY_PURPLE};
  border-radius: 10px;
  display: flex;
  height: 63px;
  width: 63px;
  padding: 2px;
  pointer-events: ${(props) => (props.$disabled ? 'none' : '')};
`;

export const BackgroundImage = styled.img<{ $view?: boolean }>`
  height: 100%;
  width: 100%;
  border-radius: ${(props) => (props.$view ? '0px' : '8px')};
`;

export const TextLabelPurple = styled.span`
  display: block;
  color: ${PRIMARY_PURPLE};
  font-weight: 300;
  width: 50px;
  font-size: 10px;
  line-height: 12px;
  margin-top: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const FakeButtonSection = styled.div`
  height: 63px;
  width: 63px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;
