import { styled } from 'styled-components';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../uiComponents/text/text';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';

export const Tray = styled.div<{ $open: boolean; $xOffset: number }>`
  position: fixed;
  left: 0;
  bottom: 24px;
  height: 64px;
  padding: 0 40px;
  display: flex;
  align-items: center;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  background-color: ${PRIMARY_PURPLE};
  transition: transform 0.2s ease-in;
  transform: ${(props) => `translateX(${props.$xOffset}px)`};
  visibility: ${(props) => (props.$open ? 'visible' : 'hidden')};
  z-index: 120;
`;

export const TrayItemLink = styled(FlexLayout)`
  margin: 0 32px;
  height: 100%;
  cursor: pointer;
`;

export const Burger = styled(FlexLayout)<{ $show: boolean }>`
  position: fixed;
  left: 0;
  bottom: 24px;
  height: 64px;
  padding-left: 24px;
  padding-right: 40px;
  cursor: pointer;
  background-color: ${PRIMARY_PURPLE};
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  visibility: ${(props) => (props.$show ? 'visible' : 'hidden')};
  z-index: 120;
`;

export const RouteName = styled(Text)`
  margin-left: 8px;
`;

export const TrayCloseButton = styled(FlexLayout)`
  position: absolute;
  right: -112px;
  background-color: ${PRIMARY_PURPLE};
  width: 64px;
  height: 64px;
  border-radius: 50%;
  cursor: pointer;
`;
