import { Fragment, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../order/order';
import { SectionHeader } from './sectionHeader';
import { OptionList } from '../../../utils/props';
import { NavigationPill } from '../../../uiComponents/layouts/navigationPill/navigationPill';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { Text } from '../../../uiComponents/text/text';
import {
  RENTAL,
  isR2B,
  OPT_FOR_CAREER_BOOST,
  PART_EXCHANGE,
  PlanOption,
  londonPlans,
  nationalPlans,
  planOptions,
} from '../../../consts';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { AgreementType } from '../../../models/agreement';
import { Plan, ThisOne } from '../order/order.styles';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { setPlan } from '../../../api/patch/application.patch';
import { unassignVehicle } from '../../../api/post/vehicle.post';

const planLookup: Record<string, PlanOption[]> = {
  london: londonPlans,
  national: nationalPlans,
};

interface PlanProps extends SectionProps {
  onPlanSelect: () => void;
}

const isPlanAvailable = (city: string, plan: PlanOption, tags: string[]) => {
  if (city === 'London') {
    if (isR2B(plan?.id) && tags?.includes(OPT_FOR_CAREER_BOOST)) {
      return false;
    } else if (plan?.id === RENTAL && tags?.includes(PART_EXCHANGE)) {
      return false;
    }
    return plan.regions.includes(city);
  }
  return plan.regions.includes('national');
};

export const PlanSection = ({ isComplete, isLocked, progress, onPlanSelect }: PlanProps) => {
  const isNational: boolean = progress?.city_name !== 'London';
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [selectedPlanFilter, setSelectedPlanFilter] = useState<OptionList>(planOptions[isNational ? 1 : 0]);
  const visiblePlans: PlanOption[] = planLookup[selectedPlanFilter?.value];
  const selectedPlan: AgreementType = progress?.application_type;
  const [confirmationModal, setConfirmationModal] = useState<JSX.Element>();

  return (
    <>
      <CollapsiblePanel
        styled={{ marginTop: 16 }}
        expanded={!isCollapsed}
        locked={isLocked}
        onCollapse={() => {
          setIsCollapsed(!isCollapsed);
        }}
        header={
          <SectionHeader
            title="Plan"
            isComplete={isComplete}
            isLocked={isLocked}
            expanded={false}
            completedText={progress?.application_type ?? ''}
          />
        }
      >
        <div>
          <SectionHeader
            title={progress?.application_type ? `Plan: ${progress?.application_type}` : 'Plan'}
            isComplete={isComplete}
            isLocked={isLocked}
            expanded
          />
          <NavigationPill
            styled={{ marginTop: 24 }}
            options={planOptions}
            onSelect={(option: OptionList) => setSelectedPlanFilter(option)}
            selected={selectedPlanFilter}
          />
          <GridLayout styled={{ marginTop: 24 }} gap={16} template={3}>
            {visiblePlans?.map((plan: PlanOption) => (
              <Fragment key={plan?.id}>
                <Plan
                  $disabled={!isPlanAvailable(progress?.city_name, plan, progress?.tag_type ?? [])}
                  $selected={plan?.id === selectedPlan}
                  itemsX="center"
                  vertical
                  itemsY="space-between"
                >
                  <FlexLayout itemsX="center" vertical>
                    <FlexLayout itemsY="center" styled={{ height: 135 }}>
                      <img src={plan?.logo}></img>
                    </FlexLayout>
                    <Text styled={{ marginTop: 16 }} variant="body4" color={PRIMARY_PURPLE} weight={800} block>
                      {plan?.title}
                    </Text>
                    <Text variant="body7" color={PRIMARY_PURPLE} weight={300} block align="center">
                      {plan?.description}
                    </Text>
                  </FlexLayout>
                  {plan?.id === selectedPlan ? (
                    <ThisOne itemsX="center" itemsY="center">
                      <Text variant="body7" color={PRIMARY_PURPLE} weight={300}>
                        This one!
                      </Text>
                    </ThisOne>
                  ) : (
                    <PrimaryButton
                      styled={{ height: 48, width: 162, marginTop: 32 }}
                      onClick={() => {
                        setConfirmationModal(
                          <ConfirmationModal
                            title="If there is an assigned vehicle on the application it will be removed. Would you like to select this plan?"
                            confirmButtonCaption="Yes"
                            closeButtonCaption="No"
                            onClose={() => setConfirmationModal(undefined)}
                            preConfirm={() =>
                              setPlan(progress?.application_id, { application_type: plan?.id }).then(() => {
                                onPlanSelect();
                                if (progress?.vehicle_id != null) {
                                  unassignVehicle({
                                    application_id: progress?.application_id,
                                    vehicle_id: progress?.vehicle_id,
                                  }).then(() => onPlanSelect());
                                }
                                setIsCollapsed(true);
                              })
                            }
                            onConfirm={() => setConfirmationModal(undefined)}
                            isOpen
                          />
                        );
                      }}
                      isGreen
                    >
                      This one!
                    </PrimaryButton>
                  )}
                </Plan>
              </Fragment>
            ))}
          </GridLayout>
        </div>
      </CollapsiblePanel>
      {confirmationModal != null && <>{confirmationModal}</>}
    </>
  );
};
