import { apiSlice } from '../../api/baseApi';
import { UpcomingAftercare } from '../../models/aftercare';

interface GetAndMergeUpcomingAgreementsParams {
  query: string;
  refetch: boolean;
}

interface GetAndMergeUpcomingAgreementsResponse {
  count: number;
  data: UpcomingAftercare[];
}

export const upcomingAgreements = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAndMergeUpcomingAgreements: builder.query<
      GetAndMergeUpcomingAgreementsResponse,
      GetAndMergeUpcomingAgreementsParams
    >({
      query: ({ query }) => `/aftercare/upcoming${query && `?${query}`}`,
      // Only have one cache entry because the arg always maps to one string
      serializeQueryArgs: ({ endpointName }) => {
        return endpointName;
      },
      // Always merge incoming data to the cache entry
      merge: (currentCache, newItems, { arg: { refetch } }) => {
        if (refetch) {
          return {
            data: newItems.data,
            count: newItems.count,
          };
        }

        return {
          data: [...currentCache.data, ...newItems.data],
          count: newItems.count,
        };
      },
      // Refetch when the page arg changes
      forceRefetch({ currentArg, previousArg }) {
        return currentArg !== previousArg;
      },
    }),
  }),
});

export const { useGetAndMergeUpcomingAgreementsQuery } = upcomingAgreements;
