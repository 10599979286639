import React from 'react';
import { FieldError } from 'react-hook-form';
import { StyledInput, InputError } from './textInput.styles';
import { withStyledProps } from '../../../utils/colorUtils';

/**
 * Text input component
 * @placeholder string placeholder
 * @value string value to fill input
 * @defaultValue default string value
 * @type data type such as text/number/email etc.
 * @align aligns content left or right. Default left.
 * @mode can be either 'light' or 'dark'. Default is light with a white background.
 * @onBlur callback function when input blurs
 * @onFocus callback function when input is focused
 *
 */

export type InputType = 'tel' | 'email' | 'search' | 'text' | 'password' | 'number' | 'url' | 'date';

export interface TextInputProps extends React.PropsWithoutRef<JSX.IntrinsicElements['input']> {
  align?: 'left' | 'center' | 'right';
  error?: FieldError;
  onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
  onChange?: (e: React.FocusEvent<HTMLInputElement, Element>) => void;
}

export const TextInput = withStyledProps(
  React.forwardRef<HTMLInputElement, TextInputProps>(
    (
      {
        placeholder,
        type = 'text',
        align = 'left',
        disabled,
        error,
        name,
        defaultValue,
        value,
        onBlur,
        ...props
      }: TextInputProps,
      ref
    ) => {
      return (
        <>
          <StyledInput
            {...props}
            ref={ref}
            placeholder={placeholder}
            type={type ?? 'text'}
            $align={align}
            autoComplete="off"
            autoCorrect="off"
            spellCheck="false"
            defaultValue={defaultValue}
            name={name}
            disabled={disabled}
            value={value}
            $hasError={error != null}
            onBlur={(e: React.FocusEvent<HTMLInputElement, Element>) => onBlur?.(e)}
          />
          {error && <InputError>{error?.message}</InputError>}
        </>
      );
    }
  )
);
