import { PRIMARY_PURPLE, PRIMARY_WHITE, STATUS_RED } from '../../../common/styles/Colors';
import { Text } from '../../text/text';
import { styled } from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const ErrorText = styled(Text)`
  position: absolute;
  bottom: 40px;
`;

export const SignatureBox = styled.div<{ $hasError: boolean }>`
  border: 1px solid ${(props) => (props?.$hasError ? STATUS_RED : PRIMARY_PURPLE)};
  border-radius: 16px;
  width: 100%;
  height: 138px;
  margin-top: 8px;
  background-color: ${PRIMARY_WHITE};

  canvas {
    width: 100%;
    height: 100%;
  }
`;
