import { EmployeePayload, UpdateEmployeeResponse } from '../../models/employee';
import api from '../../utils/api';
/**
 * Log In Request
 * @category API/POST
 * @param {object} loginData - Data for the user to log in with
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  password: '',
 * }
 * LogIn(values).then((res) => {
 *  // Promise resolved
 * });
 */

export const updateEmployee = (id: string, payload: EmployeePayload): Promise<UpdateEmployeeResponse> => {
  return api.patch(`employee/${id}`, payload);
};

export const changePassword = (body: { password: string }) => {
  return api.patch('employee/change-password', body);
};

export default { updateEmployee, changePassword };
