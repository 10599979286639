import React, { useEffect } from 'react';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { FlexLayout } from '../flexLayout/flexLayout';
import { Panel, ExpandedHeader, StyledArrowUp, Content } from './collapsiblePanel.styles';
import { PRIMARY_GREEN, PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { withStyledProps } from '../../../utils/colorUtils';

interface CollapsiblePanelProps {
  expanded: boolean;
  header: JSX.Element;
  onCollapse: (collapsed: boolean) => void;
  completed?: boolean;
  disabled?: boolean;
  locked?: boolean;
  children: JSX.Element;
}

export const CollapsiblePanel = withStyledProps(
  ({ expanded, header, onCollapse, completed, disabled, locked, children, ...props }: CollapsiblePanelProps) => {
    useEffect(() => {
      if (expanded && (locked || disabled)) {
        onCollapse(true);
      }
    }, [onCollapse, expanded, locked, disabled]);
    return (
      <Panel
        $locked={locked}
        $completed={completed ?? false}
        $expanded={expanded}
        $disabled={disabled ?? false}
        onClick={() => {
          if (!expanded && !locked) {
            onCollapse(!expanded);
          }
        }}
        {...props}
      >
        <ExpandedHeader $expanded={expanded} itemsX="end" itemsY="center" onClick={() => onCollapse(!expanded)}>
          {expanded && !locked && <StyledArrowUp size={20} color={PRIMARY_GREEN} />}
        </ExpandedHeader>
        <FlexLayout itemsX="space-between" itemsY="center" styled={{ display: expanded ? 'none' : 'flex', flex: 1 }}>
          {header}
          {!locked && <MdKeyboardArrowDown size={20} color={completed && !expanded ? PRIMARY_PURPLE : PRIMARY_GREEN} />}
        </FlexLayout>
        <Content $expanded={expanded}>{children}</Content>
      </Panel>
    );
  }
);
