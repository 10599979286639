import { PolicyPayload, PolicyVehicles, RenewPolicyPayload } from '../../models/insurancePolicy';
import api from '../../utils/api';

/**
 * Update Insurance policy Request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} id - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {

 * }
 * updateInsurancePolicy(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateInsurancePolicy = (payload: {
  id: string;
  body: PolicyPayload | RenewPolicyPayload;
}): Promise<{ data: PolicyVehicles }> => api.patch(`insurance/policy/${payload.id}`, payload.body);
