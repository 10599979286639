import { GenericAbortSignal } from 'axios';
import { City } from '../../models/city';
import api from '../../utils/api';

/**
 * Get single city request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getCity(cityId).then((res) => {
 *  // Promise resolved
 * });
 */
export const getCity = (id: string): Promise<{ data: City }> => api.get(`internal/city${id}`);

/**
 * Get all cities request
 * @category API/GET
 * @return {Promise} Returns a promise for the api request
 * @example
 * getAllCities(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const getAllCities = (
  query: string = '',
  signal?: GenericAbortSignal
): Promise<{ count: number; data: City[] }> => api.get(`internal/city${query && `?${query}`}`, { signal });
