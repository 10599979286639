import api from '../../utils/api';

/**
 * Delete employee request
 * @category API/DELETE
 * @return {Promise} delete api call
 * @example
 * deleteEmployee(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteEmployee = (employeeId: string) => {
  return api.delete(`/employee/${employeeId}`);
};

export default { deleteEmployee };
