import { Transfer } from '../../models/transfer';
import { apiSlice } from '../../api/baseApi';

interface GetRecentConditionReportParams {
  vehicleId?: string;
}

interface GetRecentConditionReportResponse {
  data: Transfer;
}

export const conditionReportApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getRecentConditionReport: builder.query<GetRecentConditionReportResponse, GetRecentConditionReportParams>({
      query: ({ vehicleId }) => `condition-report/${vehicleId}/vehicle`,
    }),
  }),
});

export const { useGetRecentConditionReportQuery } = conditionReportApiSlice;
