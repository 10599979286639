import React, { useEffect, useState } from 'react';
import { PRIMARY_PURPLE } from '../../common/styles/Colors';
import { getHistoricalAssessment } from '../../api/get/assessment.get';
import { Assessment, HistoricalAssessmentResponse, HistoricalDataArray } from '../../models/assessment';
import { FlexLayout } from '../../uiComponents/layouts/flexLayout/flexLayout';
import { Spinner } from '../../uiComponents/uiControls/spinner/spinner';
import { Text } from '../../uiComponents/text/text';
import { HistoricalAssessment } from './historicalAssessment';
import { OptionList } from '../../utils/props';
import moment from 'moment';
import { NavigationPill } from '../../uiComponents/layouts/navigationPill/navigationPill';

interface ViewAssessmentProps {
  selectedRow?: Assessment | null;
}

export const ViewAssessment = ({ selectedRow }: ViewAssessmentProps) => {
  const [historicalAssessmentData, setHistoricalAssessmentData] = useState<HistoricalAssessmentResponse[]>();
  const dates: OptionList[] | undefined = historicalAssessmentData?.map((item: HistoricalAssessmentResponse) => {
    return {
      value: item?.assessment_type_id,
      label: moment(item?.assessment_date)?.format('DD MMM YYYY'),
    };
  });
  const [selectedDate, setSelectedDate] = useState<OptionList | undefined>(dates?.[0]);
  const selectedAssessment: HistoricalDataArray | undefined = historicalAssessmentData?.find(
    (item: HistoricalAssessmentResponse) => item?.assessment_type_id === selectedDate?.value
  )?.data_array?.[0];

  useEffect(() => {
    getHistoricalAssessment(selectedRow?.id).then((response: { data: HistoricalAssessmentResponse[] }) => {
      setHistoricalAssessmentData(response?.data);
    });
  }, [selectedRow?.id]);

  useEffect(() => {
    if (!selectedDate) {
      setSelectedDate(dates?.[0]);
    }
  }, [historicalAssessmentData, selectedDate, dates]);

  return (
    <>
      {historicalAssessmentData ? (
        <>
          {historicalAssessmentData?.length > 0 ? (
            <>
              <NavigationPill
                options={dates ?? []}
                onSelect={(option: OptionList) => setSelectedDate(option)}
                selected={selectedDate}
              />
              <HistoricalAssessment historicalAssessmentDetails={selectedAssessment} selectedAssessment={selectedRow} />
            </>
          ) : (
            <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
              There are no assessments available for this driver.
            </Text>
          )}
        </>
      ) : (
        <FlexLayout itemsX="center">
          <Spinner color={PRIMARY_PURPLE} size={24} />
        </FlexLayout>
      )}
    </>
  );
};
