import styled, { css } from 'styled-components';
import { StyledFlexLayoutProps, FlexItemsAlignment } from './flexLayout';
import { withPx } from '../../../utils/props';

function flexLayoutToCssAlign(alignment?: FlexItemsAlignment) {
  switch (alignment) {
    case 'center':
      return 'center';
    case 'end':
      return 'flex-end';
    case 'start':
      return 'flex-start';
    case 'space-between':
      return 'space-between';
    case 'space-around':
      return 'space-around';
    case 'stretch':
    default:
      return 'initial'; /* stretch in the alignment axis, start in the other */
  }
}

export const Flex = styled.div<StyledFlexLayoutProps>`
  display: ${({ $inline }) => ($inline ? 'inline-flex' : 'flex')};
  flex-direction: ${({ $vertical }) => ($vertical ? 'column' : 'row')};
  flex-wrap: ${({ $flexWrap }) => {
    if ($flexWrap === 'reverse') {
      return 'wrap-reverse';
    }
    if ($flexWrap) {
      return 'wrap';
    }
    return 'nowrap';
  }};
  justify-content: ${({ $vertical, $itemsX, $itemsY }) => flexLayoutToCssAlign($vertical ? $itemsY : $itemsX)};
  align-items: ${({ $vertical, $itemsX, $itemsY }) => flexLayoutToCssAlign($vertical ? $itemsX : $itemsY)};
  vertical-align: top;
  ${({ $gap }) =>
    $gap != null &&
    css`
      gap: ${withPx($gap)};
    `};
`;
