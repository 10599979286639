import { UPCOMING_STATUS, CANCELLED_STATUS, COMPLETED_STATUS } from '../../../../../consts/status';
import { DriverPaymentFreeze, BookedFreezeDetails, FreezeStatus } from '../../../../../models/freeze';
import { DriverPaymentHoliday, BookedHolidayDetails } from '../../../../../models/holiday';

export const isHoliday = (data: DriverPaymentHoliday | DriverPaymentFreeze): data is DriverPaymentHoliday => {
  return 'holidays' in data;
};

export const getSummaryStatus = (data: DriverPaymentHoliday | DriverPaymentFreeze): string => {
  if (isHoliday(data)) {
    if (data.holidays?.some((holiday) => holiday.holiday_status === UPCOMING_STATUS)) {
      return UPCOMING_STATUS;
    }
    if (data.holidays?.some((holiday) => holiday.holiday_status === COMPLETED_STATUS)) {
      return COMPLETED_STATUS;
    }
  } else {
    if (data.freezes?.some((freeze) => freeze.status_code === UPCOMING_STATUS)) {
      return UPCOMING_STATUS;
    }
    if (data.freezes?.some((freeze) => freeze.status_code === COMPLETED_STATUS)) {
      return COMPLETED_STATUS;
    }
  }
  return CANCELLED_STATUS;
};

export const getBookedDates = (
  actionData: (DriverPaymentHoliday | DriverPaymentFreeze)[]
): (BookedHolidayDetails | BookedFreezeDetails)[] => {
  const bookedDates: (BookedHolidayDetails | BookedFreezeDetails)[] = [];

  actionData.forEach((batch) => {
    if (isHoliday(batch)) {
      // Handle DriverPaymentHoliday
      batch?.holidays?.forEach((holiday) => {
        if (holiday.holiday_status !== CANCELLED_STATUS) {
          bookedDates.push({
            holiday_batch_id: batch?.id,
            commencement_date: holiday?.commencement_date,
          });
        }
      });
    } else {
      // Handle DriverPaymentFreeze
      batch?.freezes?.forEach((freeze) => {
        if (freeze.status_code !== CANCELLED_STATUS) {
          bookedDates.push({
            holiday_batch_id: batch?.id,
            commencement_date: freeze?.commencement_date,
          });
        }
      });
    }
  });

  return bookedDates;
};

export const statusToString = (status: FreezeStatus | string): string => {
  switch (status) {
    case FreezeStatus.COMPLETED:
      return COMPLETED_STATUS;
    case FreezeStatus.CANCELLED:
      return CANCELLED_STATUS;
    case FreezeStatus.UPCOMING:
      return UPCOMING_STATUS;
    default:
      return 'Unknown';
  }
};
