import api from '../../utils/api';

/**
 * Cancel application request
 * @category API/DELETE
 * @param {object} id - Application id for the user to delete the application
 * @return {Promise} Returns a promise for the api request
 * @example
 * cancelApplication(id).then((res) => {
 *  // Promise resolved
 * });
 */

export const cancelApplication = (id: string): Promise<{ data: { id: string } }> => {
  return api.delete(`/application/${id}`);
};

export const cancelCourtesyApplication = (id: string): Promise<{ data: { id: string } }> => {
  return api.delete(`/courtesy/${id}`);
};
