import { DEFAULT_NUM_ROWS_PER_PAGE, TableFilter, getQueryString } from '../../uiComponents/table/tableUtils/tableUtils';
import { SortingState, Updater } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

interface PaginationHelperProps extends React.InputHTMLAttributes<HTMLHeadingElement> {
  currentPage: number;
  search: string;
  sorting: SortingState;
  filters: TableFilter[];
  setCurrentPage: (page: number) => void;
  setSearch: (search: string) => void;
  setSorting: (sorting: SortingState) => void;
  setFilters: (filter: TableFilter[]) => void;
}

function usePaginationHelper({
  currentPage,
  search,
  sorting,
  filters,
  setCurrentPage,
  setSearch,
  setSorting,
  setFilters,
}: PaginationHelperProps) {
  const [query, setQuery] = useState<string>('');
  const [sort, setSort] = useState<SortingState>([]);

  useEffect(() => {
    const queryString = getQueryString(filters, DEFAULT_NUM_ROWS_PER_PAGE, 0, search, sort[0]?.id, !sort[0]?.desc);
    setCurrentPage(0);
    setSorting(sort);
    setQuery(queryString);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sort]);

  const handleNext = () => {
    const nextPage = currentPage + 1;
    const queryString = getQueryString(
      filters,
      DEFAULT_NUM_ROWS_PER_PAGE,
      nextPage,
      search,
      sorting[0]?.id,
      !sorting[0]?.desc
    );
    setQuery(queryString);
    setCurrentPage(nextPage);
  };

  const handleSearch = (value: string) => {
    const queryString = getQueryString(filters, DEFAULT_NUM_ROWS_PER_PAGE, 0, value, sorting[0]?.id, !sorting[0]?.desc);
    setCurrentPage(0);
    setSearch(value);
    setQuery(queryString);
  };

  const handleSorting = (value: Updater<SortingState>) => {
    setSort(value);
  };

  const handleFilters = (value: TableFilter[]) => {
    const queryString = getQueryString(value, DEFAULT_NUM_ROWS_PER_PAGE, 0, search, sorting[0]?.id, !sorting[0]?.desc);
    setCurrentPage(0);
    setFilters(value);
    setQuery(queryString);
  };

  return { query, handleNext, handleSearch, handleSorting, handleFilters };
}

export default usePaginationHelper;
