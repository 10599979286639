import styled, { css } from 'styled-components';
import { GridLayout } from '../../layouts/gridLayout/gridLayout';
import { PRIMARY_WHITE, SECONDARY_PURPLE_70, SECONDARY_PURPLE_90 } from '../../../common/styles/Colors';
import { PrimaryButton } from '../../buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../buttons/secondaryButton/secondaryButton';
import { darken, lighten } from '../../../utils/colorUtils';

export const Filters = styled.div<{ $hidden: boolean }>`
  position: absolute;
  left: 0px;
  top: 45px;
  min-width: 980px;
  max-width: 90vw;
  overflow: visible;
  padding: 16px 24px;
  background-color: ${SECONDARY_PURPLE_90};
  border-radius: 16px;
  z-index: 22;

  ${(props) => {
    if (props.$hidden) {
      return css`
        display: none;
      `;
    }
  }}
`;

export const CloseFiltersButton = styled.button`
  position: absolute;
  cursor: pointer;
  border: none;
  background-color: transparent;
  box-shadow: none;
  right: 0;
  top: 8px;
  width: 40px;
  height: 40px;
`;

export const FilterTitle = styled.div`
  margin-bottom: 12px;
`;

export const FilterGrid = styled(GridLayout)``;

export const ApplyFilterButton = styled(PrimaryButton)`
  background-color: ${PRIMARY_WHITE};
  border: 'none';
  &:hover:not(:disabled) {
    background-color: ${darken(PRIMARY_WHITE, 10)};
  }
`;

export const ClearFilterButton = styled(SecondaryButton)`
  background-color: ${SECONDARY_PURPLE_90};
  border: ${`1px solid ${PRIMARY_WHITE}`};
  color: ${PRIMARY_WHITE};
  &:hover:not(:disabled) {
    background-color: ${lighten(SECONDARY_PURPLE_90, 5)};
  }
`;

export const FilterInput = styled.input`
  height: 24px;
  border: 1px solid ${SECONDARY_PURPLE_70};
  border-radius: 50px;
  color: ${PRIMARY_WHITE};
  width: 100%;
  box-shadow: none;
  outline: none;
  background-color: ${SECONDARY_PURPLE_90};
  padding: 0 16px;

  &::placeholder {
    font-size: 12px;
    color: ${SECONDARY_PURPLE_70};
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
