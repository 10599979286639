import React from 'react';
import { Tag } from '../../../../../uiComponents/customComponents/tag/tag';
import { PRIMARY_PURPLE } from '../../../../../common/styles/Colors';
import { statusColors } from '../../../../../common/utils';

const getColor = (status: string, isArrears: boolean, arrearsColor: string) => {
  if (isArrears) {
    return arrearsColor;
  } else {
    return statusColors[status] || PRIMARY_PURPLE;
  }
};

interface ColoredStatusProps {
  status: string;
  isArrears?: boolean;
  arrearsColor?: string;
}

export const ColoredStatus = ({ status, isArrears, arrearsColor = PRIMARY_PURPLE }: ColoredStatusProps) => {
  return (
    <Tag styled={{ fontSize: 12 }} key={status} color={getColor(status, isArrears || false, arrearsColor)}>
      {status}
    </Tag>
  );
};
