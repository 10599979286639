import React, { useEffect, useMemo, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { Notification } from '../../../uiComponents/toast/toast';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { Spinner } from '../../../uiComponents/uiControls/spinner/spinner';
import { getAllVehicleTypes } from '../../../api/get/vehicleType.get';
import { createBatch } from '../../../api/post/batches.post';
import { CreateVehicleBatch, ModifiedVehicleType, VehicleBatch } from '../../../models/vehicle';
import { PRIMARY_GREEN } from '../../../common/styles/Colors';
import { numberRegex } from '../../../utils/validations';

interface BatchesProps {
  onClose: (action: 'cancel' | 'submit') => void;
}

export const CreateBatchesForm = ({ onClose }: BatchesProps) => {
  const [vehicleTypes, setVehicleTypes] = useState<ModifiedVehicleType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [loadingVehicles, setLoadingVehicles] = useState<boolean>(true);
  const {
    handleSubmit,
    register,
    control,
    watch,
    reset,
    formState: { errors },
  } = useForm<VehicleBatch>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      manufacturer: '',
      model: '',
      spec: '',
      model_year: '',
      color: '',
      fuel_type: '',
    },
  });
  useEffect(() => {
    setLoadingVehicles(true);
    getAllVehicleTypes('').then((res: { count: number; data: ModifiedVehicleType[] }) => {
      setVehicleTypes(res.data);
      setLoadingVehicles(false);
    });
  }, []);

  const manufacturerWatch = watch('manufacturer');
  const modelWatch = watch('model');
  const modelYearWatch = watch('model_year');
  const fuelTypeWatch = watch('fuel_type');
  const specWatch = watch('spec');

  const manufacturerOptions = useMemo(() => {
    return [...new Set(vehicleTypes.map((item) => item.manufacturer))].map((item) => ({
      label: item,
      value: item,
    }));
  }, [vehicleTypes]);

  const modelOptions = useMemo(() => {
    if (manufacturerWatch) {
      return (
        [
          ...new Set(vehicleTypes.filter((item) => item.manufacturer === manufacturerWatch).map((item) => item.model)),
        ].map((item) => ({
          label: item,
          value: item,
        })) || undefined
      );
    }
    return [];
  }, [vehicleTypes, manufacturerWatch]);

  const modelYearOptions = useMemo(() => {
    if (manufacturerWatch && modelWatch) {
      return [
        ...new Set(
          vehicleTypes
            .filter((item) => item.manufacturer === manufacturerWatch)
            .filter((item) => item.model === modelWatch)
            .map((item) => item.model_year)
        ),
      ].map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  }, [vehicleTypes, manufacturerWatch, modelWatch]);

  const fuelTypeOptions = useMemo(() => {
    if (manufacturerWatch && modelWatch && modelYearWatch) {
      return [
        ...new Set(
          vehicleTypes
            .filter(
              (item) =>
                item.manufacturer === manufacturerWatch &&
                item.model === modelWatch &&
                item.model_year === modelYearWatch
            )
            .map((item) => item.fuel_type)
        ),
      ].map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  }, [vehicleTypes, manufacturerWatch, modelWatch, modelYearWatch]);

  const colorOptions = useMemo(() => {
    if (manufacturerWatch && modelWatch && modelYearWatch && fuelTypeWatch && specWatch) {
      const VT = vehicleTypes.find(
        (item) =>
          item.manufacturer === manufacturerWatch &&
          item.model === modelWatch &&
          item.model_year === modelYearWatch &&
          item.fuel_type === fuelTypeWatch &&
          item.spec === specWatch
      );
      if (VT) {
        return VT.colors.map((item) => ({
          label: item,
          value: item,
        }));
      }
    }
    return [];
  }, [vehicleTypes, manufacturerWatch, modelWatch, modelYearWatch, fuelTypeWatch, specWatch]);

  const specOptions = useMemo(() => {
    if (manufacturerWatch && modelWatch && modelYearWatch && fuelTypeWatch) {
      return [
        ...new Set(
          vehicleTypes
            .filter(
              (item) =>
                item.manufacturer === manufacturerWatch &&
                item.model === modelWatch &&
                item.model_year === modelYearWatch &&
                item.fuel_type === fuelTypeWatch
            )
            .map((item) => item.spec)
        ),
      ].map((item) => ({
        label: item,
        value: item,
      }));
    }
    return [];
  }, [vehicleTypes, manufacturerWatch, modelWatch, modelYearWatch, fuelTypeWatch]);

  const onSubmit = (submitValues: VehicleBatch) => {
    setLoading(true);
    const {
      manufacturer,
      model,
      spec,
      model_year: modelYear,
      color,
      fuel_type: fuelType,
      qty,
      available_on: availableOn,
      vehicle_source: vehicleSource,
    } = submitValues;

    const VT = vehicleTypes.find(
      (item) =>
        item.manufacturer === manufacturer &&
        item.model === model &&
        item.model_year === modelYear &&
        item.fuel_type === fuelType &&
        item.spec === spec
    );

    const payload: CreateVehicleBatch = {
      color: color,
      amount: Number(qty),
      available_on: availableOn,
      vehicle_type_id: VT?.id ? VT?.id : '',
      vehicle_source: vehicleSource,
    };

    createBatch(payload)
      .then(() => {
        Notification({
          type: 'success',
          title: 'Success',
          message: 'Batch has been successfully created',
        });
        onClose('submit');
      })
      .catch(() => {
        Notification({
          type: 'error',
          title: 'Error',
          message: 'Error creating batch',
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };
  if (loadingVehicles) {
    return (
      <FlexLayout itemsX="center" itemsY="center">
        <Spinner color={PRIMARY_GREEN} size={70} />
      </FlexLayout>
    );
  }
  return (
    <>
      <GridLayout template={2} gap={18}>
        <div>
          <TextFieldLabel $isRequired>Manufacturer</TextFieldLabel>
          <DropDown
            name="manufacturer"
            error={errors.manufacturer}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a manufacturer'}
            required={{
              required: 'Manufacturer is a required field',
              onChange: (e) => {
                reset({
                  manufacturer: e.target.value,
                  model: '',
                  model_year: '',
                  fuel_type: '',
                  spec: '',
                });
              },
            }}
            options={manufacturerOptions}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Model</TextFieldLabel>
          <DropDown
            name="model"
            error={errors.model}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a model type'}
            disabled={!manufacturerWatch}
            required={{
              required: 'Model is a required field',
              onChange: (e) => {
                reset({
                  manufacturer: manufacturerWatch,
                  model: e.target.value,
                  model_year: '',
                  fuel_type: '',
                  spec: '',
                });
              },
            }}
            options={modelOptions}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Model year</TextFieldLabel>
          <DropDown
            name="model_year"
            error={errors.model_year}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a model year'}
            disabled={!manufacturerWatch || !modelWatch}
            required={{
              required: 'Model year is a required field',
              onChange: (e) => {
                reset({
                  manufacturer: manufacturerWatch,
                  model: modelWatch,
                  model_year: e.target.value,
                  fuel_type: '',
                  spec: '',
                });
              },
            }}
            options={modelYearOptions}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Fuel type</TextFieldLabel>
          <DropDown
            name="fuel_type"
            error={errors.fuel_type}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a fuel type'}
            disabled={!manufacturerWatch || !modelWatch || !modelYearWatch}
            required={{
              required: 'Fuel Type is a required field',
              onChange: (e) => {
                reset({
                  manufacturer: manufacturerWatch,
                  model: modelWatch,
                  model_year: modelYearWatch,
                  fuel_type: e.target.value,
                  spec: '',
                });
              },
            }}
            options={fuelTypeOptions}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Spec</TextFieldLabel>
          <DropDown
            name="spec"
            error={errors.spec}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a Spec'}
            disabled={!manufacturerWatch || !modelWatch || !modelYearWatch || !fuelTypeWatch}
            required={{
              required: 'Spec is a required field',
            }}
            options={specOptions}
          />
        </div>
        <TextField
          {...register('available_on', {
            required: 'Available on is a required field',
          })}
          required
          label="Available on"
          type="date"
          error={errors.available_on}
        />
        <div>
          <TextFieldLabel $isRequired>Colour</TextFieldLabel>
          <DropDown
            name="color"
            error={errors.color}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a color'}
            disabled={!manufacturerWatch || !modelWatch || !modelYearWatch || !fuelTypeWatch || !specWatch}
            required={{
              required: 'Colour is a required field',
            }}
            options={colorOptions}
          />
        </div>
        <div>
          <TextFieldLabel $isRequired>Vehicle Source</TextFieldLabel>
          <DropDown
            name="vehicle_source"
            error={errors.vehicle_source}
            control={control as unknown as Control<FieldValues>}
            placeholder={'Select a vehicle source'}
            required={{
              required: 'vehicle source is a required field',
            }}
            options={[
              {
                value: 'OTTOCAR',
                label: 'Ottocar',
              },
              {
                value: 'LEUMI',
                label: 'Leumi Bank',
              },
            ]}
          />
        </div>
        <TextField
          {...register('qty', {
            required: 'Quantity on is a required field',
            pattern: {
              value: numberRegex,
              message: 'Please use numbers only',
            },
          })}
          required
          placeholder="Quantity"
          label="Quantity"
          type="number"
          error={errors.qty}
        />
      </GridLayout>
      <FlexLayout itemsX="end">
        <SecondaryButton disabled={loading} onClick={() => onClose('cancel')}>
          Cancel
        </SecondaryButton>
        <PrimaryButton isProcessing={loading} styled={{ marginLeft: '10px' }} onClick={handleSubmit(onSubmit)}>
          Submit
        </PrimaryButton>
      </FlexLayout>
    </>
  );
};
