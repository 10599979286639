import api from '../../utils/api';

/**
 * Cancels aftercare
 * @category API/DELETE
 * @return {Promise} delete api call
 * @example
 * cancelAftercare(values).then((res) => {
 *  // Promise resolved
 * });
 */
export const cancelAftercareSetup = ({ id }: { id: string }): Promise<{ data: { id: string }[] }> =>
  api.delete(`/aftercare/${id}`);
