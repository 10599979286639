import styled from 'styled-components';
import { PRIMARY_WHITE } from '../../common/styles/Colors';
import { FlexLayout } from '../layouts/flexLayout/flexLayout';

export const ModalOverlay = styled(FlexLayout)`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(57, 39, 105, 0.6);
  z-index: 9999;
`;

export const ModalComponent = styled.div`
  position: relative;
  background-color: ${PRIMARY_WHITE};
  border-radius: 16px;
  z-index: 9999;
  max-height: 90vh;
  min-height: 350px;
  min-width: 600px;
  overflow: auto;
`;

export const CloseButton = styled(FlexLayout)`
  width: 40px;
  height: 40px;
  cursor: pointer;
`;

export const Header = styled(FlexLayout)`
  position: sticky;
  top: 0;
  left: 0;
  padding-top: 16px;
  padding-left: 32px;
  padding-right: 16px;
  background-color: ${PRIMARY_WHITE};
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  z-index: 10;
`;

export const ModalContent = styled.div`
  padding: 32px;
`;
