import { AftercareAgreement } from '../../models/aftercare';
import { Vehicle } from '../../models/vehicle';
import api from '../../utils/api';
import { TerminateAgreementPayload } from '../../models/agreement';

/**
 * Create Agreement Request
 * @category API/POST
 * @param {object} data - Data for agreement
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  application_id: '',
 * }
 * createAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createAgreement = (data: { application_id: string; payment_start_date?: string }) =>
  api.post('agreement', data);

/**
 * Create Courtesy Agreement Request
 * @category API/POST
 * @param {object} data - Data for courtesy agreement
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  courtesy_id: '',
 * }
 * createAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */

export const createCourtesyAgreement = (data: { courtesy_id: string; driver_id: string }) =>
  api.post('agreement/courtesy-agreement', data);

/**
 * Complete Agreement Request
 * @category API/POST
 * @param {object} data - agreement data
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  agreement_id: '',
 * }
 * completeAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const completeAgreement = (data: { agreement_id: string; status: string }) =>
  api.post('agreement/complete', data);

/**
 * Create Aftercare Request
 * @category API/POST
 * @param {object} data - Data for aftercare
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  agreement_id: '',
 * }
 * createAftercareAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createAfterCareAgreement = (data: AftercareAgreement) => api.post('agreement/create-aftercare', data);

/**
 * Terminate Agreement Request
 * @category API/POST
 * @param {object} data - Data for termination
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  agreement_id: '',
 * }
 * terminateAgreement(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const terminateAgreement = (data: TerminateAgreementPayload) => {
  return api.post('agreement/terminate', data);
};

/**
 * Inform accounts of termination request when driver in arrears
 * @category API/POST
 * @param {object} data - Data for email to accounts
 * @return {Promise} Returns a promise for the api request
 */
export const terminationRequestWithArrears = (data: Vehicle) => api.post('agreement/termination-with-arrears', data);
