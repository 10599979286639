import React, { useEffect, useState } from 'react';
import { Control, FieldValues, useForm } from 'react-hook-form';
import {
  ButtonContainer,
  CreateOnlyField,
  StyledCheckbox,
  StyledDropdown,
  StyledFieldContainer,
  StyledLabel,
  StyledPrimaryButton,
  StyledSecondaryButton,
  StyledTextField,
  StyledUploaderInput,
} from './createEditUser.styles';
import { titleOptions, departmentOptions } from '../../../../consts/user';
import { TextFieldLabel } from '../../../../uiComponents/inputs/textField/textField.styles';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Spinner } from '../../../../uiComponents/uiControls/spinner/spinner';
import { Text } from '../../../../uiComponents/text/text';
import { Notification } from '../../../../uiComponents/toast/toast';
import { createEmployee } from '../../../../api/post/employee.post';
import { updateEmployee } from '../../../../api/patch/employee.patch';
import { EmployeeDepartment, EmployeePayload } from '../../../../models/employee';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { fileNameTimeStampFormat, renderNotification } from '../../../../utils/utils';
import { getFileService } from '../../../../api/cognito/file.service';
import { useAppSelector } from '../../../../store-hooks';

const userDetails = {
  title: '',
  first_name: '',
  middle_name: '',
  last_name: '',
  email: '',
  department: undefined,
  password: '',
  super_admin: false,
  profilePicture: undefined,
};
export interface CreateEditUserFormValues {
  id: string;
  title: string;
  first_name: string;
  middle_name: string;
  last_name: string;
  email: string;
  department?: EmployeeDepartment;
  password?: string;
  super_admin: boolean;
  profilePicture?: Blob | File;
  profile_s3_url: string;
}

interface CreateUserProps {
  values?: CreateEditUserFormValues | null;
  isInEdit?: boolean;
  onFormSubmit: () => void;
  onClose: () => void;
}

export const CreateEditUser = ({ values, isInEdit, onClose, onFormSubmit }: CreateUserProps) => {
  const pulseUser = useAppSelector((state) => state.pulseUser);
  const [isLoading, setIsLoading] = useState<boolean>();
  const fileService = getFileService();
  const [profilePictureUrl, setProfilePictureUrl] = useState<URL | null>(null);

  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
  } = useForm<CreateEditUserFormValues, string>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: isInEdit && values ? values : userDetails,
  });

  useEffect(() => {
    const fetchProfilePictureUrl = async () => {
      if (values?.profile_s3_url) {
        try {
          const linkToStorageFile = await fileService.getFileUrlFromStorage({ path: values?.profile_s3_url });
          setProfilePictureUrl(linkToStorageFile);
        } catch (error: any) {
          renderNotification('error', 'Error', `${error.message}`, true);
        }
      }
    };

    fetchProfilePictureUrl();
  }, [values?.profile_s3_url, fileService]);

  const onSubmit = async (submitValues: CreateEditUserFormValues) => {
    const handleResponse = () => {
      setIsLoading(false);
      Notification({
        type: 'success',
        title: 'Success',
        message: `Employee has been successfully ${isInEdit ? 'updated' : 'created'}`,
        isAlert: true,
      });
      onFormSubmit();
      onClose();
    };

    const handleError = (error: { message: string }) => {
      setIsLoading(false);
      Notification({ type: 'error', title: 'Error', message: error.message, isAlert: true });
    };

    setIsLoading(true);
    const fileName = 'PROFILE-PHOTO';
    const fileNameWithTimeStamp = fileNameTimeStampFormat(fileName);

    const path = `DOCUMENTS/EMPLOYEE/${submitValues?.id}/${fileName}/${fileNameWithTimeStamp}`;

    let s3_url;
    if (submitValues.profilePicture) {
      try {
        await fileService.uploadFileToStorage({
          path,
          data: submitValues.profilePicture,
        });
        s3_url = path;
      } catch (error: any) {
        renderNotification('error', 'Error', `${error.message}.`, true);
        setIsLoading(false);
        return;
      }
    }

    const payload: EmployeePayload = {
      ...submitValues,
      s3_url,
      document_type: fileName,
    };

    if (isInEdit) {
      updateEmployee(submitValues?.id, payload)
        .then(() => {
          handleResponse();
        })
        .catch((error: { message: string }) => {
          handleError(error);
        });
    } else {
      createEmployee(payload)
        .then(() => {
          handleResponse();
        })
        .catch((error: { message: string }) => {
          handleError(error);
        });
    }
  };

  return (
    <form>
      {isLoading ? (
        <FlexLayout itemsX="center" itemsY="center">
          <Spinner color={PRIMARY_PURPLE} size={50} />
        </FlexLayout>
      ) : (
        <>
          <FlexLayout vertical itemsX="center">
            <Text variant="body5" color={PRIMARY_PURPLE}>
              Set profile picture
            </Text>
            <StyledUploaderInput
              label=""
              control={control as unknown as Control<FieldValues>}
              isProfilePicture
              name="profilePicture"
              defaultValue={profilePictureUrl?.href}
              error={errors?.profilePicture}
            />
          </FlexLayout>
          <FlexLayout itemsX="space-between">
            <StyledFieldContainer vertical>
              <TextFieldLabel $isRequired>Title</TextFieldLabel>
              <StyledDropdown
                control={control as unknown as Control<FieldValues>}
                options={titleOptions}
                placeholder="Title"
                name="title"
                error={errors.title}
                required={{
                  required: 'Title is a required field',
                }}
              />
            </StyledFieldContainer>
            <StyledTextField
              label="First name"
              {...register('first_name', {
                required: 'First name is a required field',
              })}
              required
              type="text"
              placeholder="First name"
              name="first_name"
              error={errors?.first_name}
            />
          </FlexLayout>
          <FlexLayout itemsX="space-between">
            <StyledTextField
              label="Middle name"
              {...register('middle_name')}
              type="text"
              placeholder="Middle name"
              name="middle_name"
              error={errors?.middle_name}
            />
            <StyledTextField
              label="Last name"
              {...register('last_name', {
                required: 'Last name is a required field',
              })}
              required
              type="text"
              placeholder="Last name"
              name="last_name"
              error={errors?.last_name}
            />
          </FlexLayout>
          <FlexLayout itemsX="space-between">
            <StyledTextField
              label="Email"
              {...register('email', {
                required: 'Email is a required field',
              })}
              required
              type="email"
              placeholder="Email"
              name="email"
              error={errors?.email}
            />
            {pulseUser && pulseUser.super_admin && (
              <StyledFieldContainer itemsY="center">
                <StyledCheckbox {...register('super_admin')} />
                <StyledLabel htmlFor="super_admin">Super admin</StyledLabel>
              </StyledFieldContainer>
            )}
          </FlexLayout>
          <FlexLayout itemsX="space-between">
            <StyledFieldContainer vertical>
              <TextFieldLabel $isRequired>Department</TextFieldLabel>
              <StyledDropdown
                control={control as unknown as Control<FieldValues>}
                options={departmentOptions}
                placeholder="Select department"
                name="department"
                error={errors.department}
                required={{
                  required: 'Department is a required field',
                }}
              />
            </StyledFieldContainer>
            <CreateOnlyField>
              {!isInEdit && (
                <StyledTextField
                  label="Temporary password"
                  {...register('password', {
                    required: 'Temporary password is a required field',
                  })}
                  required={!isInEdit}
                  type="text"
                  placeholder="Password"
                  name="password"
                  error={errors?.password}
                />
              )}
            </CreateOnlyField>
          </FlexLayout>
          <ButtonContainer itemsX="end">
            <StyledSecondaryButton onClick={onClose}>Cancel</StyledSecondaryButton>
            <StyledPrimaryButton onClick={handleSubmit(onSubmit)}>Submit</StyledPrimaryButton>
          </ButtonContainer>
        </>
      )}
    </form>
  );
};
