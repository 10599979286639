import { BsInfoCircle } from 'react-icons/bs';
import { Input } from '../../ui/input/input';

export interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  hint?: string;
  info?: string;
  error?: string;
  dimension?: 'sm' | 'md';
  startAdornment?: React.ReactNode;
  endAdornment?: React.ReactNode;
  fixedStartAdornment?: React.ReactNode;
  fixedEndAdornment?: React.ReactNode;
  required?: boolean;
  'data-testid'?: string;
}

export function TextInput({
  label,
  hint,
  info,
  error,
  startAdornment,
  endAdornment,
  fixedStartAdornment,
  fixedEndAdornment,
  dimension,
  required,
  ...props
}: TextInputProps) {
  return (
    <div data-testid={props['data-testid']}>
      {label && (
        <label data-testid="label" className="text-sm font-medium text-utility-gray-700 p-0 m-0 mb-1.5">
          {label}
          {required && '*'}
        </label>
      )}
      <Input
        variant={error ? 'error' : 'default'}
        dimension={dimension}
        fixedStartAdornment={fixedStartAdornment}
        fixedEndAdornment={fixedEndAdornment}
        startAdornment={startAdornment}
        endAdornment={
          info ? (
            <BsInfoCircle title={info} className={error ? 'text-utility-error-500' : 'text-utility-gray-400'} />
          ) : (
            endAdornment
          )
        }
        {...props}
        data-testid="input-field"
      />
      {hint && !error && (
        <p className="text-sm font-light text-utility-gray-600 p-0 m-0 mt-1.5" data-testid="hint-text">
          {hint}
        </p>
      )}
      {error && (
        <p className="text-sm font-light text-utility-error-600 p-0 m-0 mt-1.5" data-testid="error-text">
          {error}
        </p>
      )}
    </div>
  );
}
