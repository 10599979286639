import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';
import { DropDown } from '../../../../uiComponents/uiControls/dropDown/dropDown';
import { Checkbox } from '../../../../uiComponents/uiControls/checkbox/checkbox';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { UploaderInput } from '../../../../uiComponents/inputs/uploaderInput/uploaderInput';
import { styled } from 'styled-components';
import { FlexContainer } from '../../../../core/pageTitle/pageTitle.styles';

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 6px;
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  color: ${PRIMARY_PURPLE};
  margin-left: 8px;
  cursor: pointer;
`;

export const StyledCheckbox = styled(Checkbox)`
  margin-left: 10px;
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
  padding: 10px;
`;

export const StyledDropdown = styled(DropDown)`
  width: 100%;
`;

export const StyledFieldContainer = styled(FlexContainer)`
  width: 100%;
  padding: 10px;
`;

export const StyledPrimaryButton = styled(PrimaryButton)`
  display: inherit;
  min-width: 120px;
  margin-left: 8px;
`;

export const StyledSecondaryButton = styled(SecondaryButton)`
  display: inherit;
  min-width: 120px;
`;

export const ButtonContainer = styled(FlexContainer)`
  margin-top: 20px;
`;

export const StyledUploaderInput = styled(UploaderInput)`
  margin: 16px 0;
`;

export const CreateOnlyField = styled.div`
  width: 100%;
`;
