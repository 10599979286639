import api from '../../utils/api';

/**
 * Update Batch Request
 * @category API/PATCH
 * @param {string} id - id to update
 * @param {object} data - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  available_on: '',
 * }
 * updateBatch(id, values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateBatch = (id: string, payload: { available_on: string }) => api.patch(`batch/${id}`, payload);
