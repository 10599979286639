import { styled } from 'styled-components';
import {
  PRIMARY_GREEN,
  PRIMARY_PURPLE,
  PRIMARY_WHITE,
  SECONDARY_PURPLE_30,
  SECONDARY_PURPLE_70,
  STATUS_RED,
} from '../../../common/styles/Colors';

export const TextAreaContainer = styled.div`
  min-height: 140px;
  min-width: 420px;
`;

export const StyledTextArea = styled.textarea<{ $filled?: boolean }>`
  width: 100%;
  padding: 16px;
  border-radius: 16px;
  color: ${PRIMARY_PURPLE};
  border: 1px solid ${SECONDARY_PURPLE_30};
  background-color: ${(props) => (props?.$filled ? SECONDARY_PURPLE_70 : PRIMARY_WHITE)};
  font-family: 'Avenir', sans-serif;
  resize: none;

  &:focus {
    border: 1px solid ${PRIMARY_GREEN};
    outline: none;
  }

  &::placeholder {
    font-size: 16px;
    color: ${SECONDARY_PURPLE_30};
    font-family: 'Avenir', sans-serif;
  }

  &:disabled {
    opacity: 0.3;
  }
`;

export const TextAreaError = styled.span`
  position: absolute;
  display: block;
  font-size: 12px;
  line-height: 14px;
  color: ${STATUS_RED};
`;
