import {
  BackgroundImage,
  DamageForm,
  DamageImages,
  FakeButtonSection,
  ImageContainer,
  ImageContainerSection,
  ImagesWrapper,
  TextLabelPurple,
} from './styles';
import { TextField } from '../../../../../uiComponents/inputs/textField/textField';
import { TextArea } from '../../../../../uiComponents/inputs/textArea/textArea';
import { DamageReportItem } from '../../../../../consts/conditionReport';
import { getFileService } from '../../../../../api/cognito/file.service';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../../common/styles/Colors';
import { FiEye } from 'react-icons/fi';
import { useEffect, useState } from 'react';
import { ImageArrayType } from '../../../../../models/transfer';
import { Text } from '../../../../../uiComponents/text/text';
import { Modal } from '../../../../../uiComponents/modals/modal';

interface DamageReportFormInfo {
  damageLog: DamageReportItem;
}

export const ViewDamageLogItem = ({ damageLog }: DamageReportFormInfo) => {
  const fileService = getFileService();
  const [updatedFilesWithUrl, setUpdatedFilesWithUrl] = useState<ImageArrayType[]>([]);
  const [viewImage, setViewImage] = useState<string>();
  const [openDamageImageModal, setOpenDamageImageModal] = useState<boolean>(false);

  useEffect(() => {
    const fetchFileUrls = async () => {
      if (!damageLog.files) return;

      const filesWithUrl = await Promise.all(
        damageLog.files.map(async (file) => {
          if ('s3_url' in file) {
            const url = await fileService.getFileUrlFromStorage({ path: file.s3_url });
            return {
              ...file,
              s3_url: url.href,
            } as ImageArrayType;
          }
          return null;
        })
      );
      const filteredFiles = filesWithUrl.filter((file): file is ImageArrayType => file !== null);
      setUpdatedFilesWithUrl(filteredFiles);
    };

    fetchFileUrls();
  }, [damageLog, fileService]);

  return (
    <DamageForm>
      <div>
        <TextField
          type="text"
          label="Damaged type"
          name="damaged_type"
          defaultValue={damageLog.damaged_type}
          disabled={true}
        />
      </div>
      <div />
      <TextField
        label="Damaged area name"
        type="text"
        name="damaged_area"
        defaultValue={damageLog.damaged_area}
        disabled={true}
      />
      <TextField
        label="Damage description"
        type="text"
        name="damage_description"
        defaultValue={damageLog.damage_description}
        disabled={true}
      />
      <div>
        <TextArea
          styled={{ height: 108 }}
          label="Damage detail"
          defaultValue={damageLog.damage_detail}
          disabled={true}
        />
      </div>
      <DamageImages>
        <Text color={PRIMARY_PURPLE} variant={'body6'} weight={500}>
          Photos
        </Text>
        <ImagesWrapper>
          {updatedFilesWithUrl.map((file) => {
            if ('s3_url' in file) {
              return (
                <ImageContainerSection key={file.fileName}>
                  <ImageContainer>
                    <BackgroundImage draggable="false" alt="image" src={file.s3_url} />
                  </ImageContainer>
                  <TextLabelPurple>{file.fileName}</TextLabelPurple>
                  <FakeButtonSection
                    onClick={() => {
                      setViewImage(file.s3_url);
                      setOpenDamageImageModal(true);
                    }}
                  >
                    <FiEye size={26} color={PRIMARY_WHITE} />
                  </FakeButtonSection>
                </ImageContainerSection>
              );
            }
          })}
        </ImagesWrapper>
      </DamageImages>
      <Modal
        open={openDamageImageModal}
        onClose={() => {
          setOpenDamageImageModal(false);
        }}
        showClose={true}
        styled={{ padding: 0, maxWidth: '70%' }}
      >
        <BackgroundImage $view draggable="false" alt="image" src={viewImage} />
      </Modal>
    </DamageForm>
  );
};
