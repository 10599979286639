import { useCallback, useEffect, useState } from 'react';
import { CollapsiblePanel } from '../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { SectionProps } from '../order/order';
import { SectionHeader } from './sectionHeader';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { Text } from '../../../uiComponents/text/text';
import { PRIMARY_PURPLE, STATUS_RED } from '../../../common/styles/Colors';
import { ApplicationProgress, ApplicationSections, DownpaymentStatus } from '../../../models/application';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { updateOrdwayCustomerGateway } from '../../../api/post/driver.post';
import { progressChecker } from '../../../api/get/application.get';
import { downPayment, finalDownPayment } from '../../../api/post/application.post';
import { AxiosError } from 'axios';
import { DownpaymentPanel, Status } from '../order/order.styles';
import { PAYMENT_GATEWAY_LEUMI, PAYMENT_GATEWAY_OTTO_CAR } from '../../../consts';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { PaymentGateway } from '../../../models/ordway';

interface DownpaymentProps extends SectionProps {
  isFinalDownpayment: boolean;
  checkProgress: () => void;
  updateProgress: (progress: ApplicationProgress, sections: ApplicationSections) => void;
}

const getFinalDownpayment = (deposit: number, downpaymentValue: number) => {
  return deposit - downpaymentValue;
};

export const DownpaymentSection = ({
  isComplete,
  isLocked,
  isFinalDownpayment,
  progress,
  checkProgress,
  updateProgress,
}: DownpaymentProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const [makingPayment, setMakingPayment] = useState<boolean>(false);
  const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
  const [downpaymentValue, setDownpaymentValue] = useState<number>(
    isFinalDownpayment
      ? getFinalDownpayment(+(progress?.deposit ?? '0'), +(progress?.downpayment_amount ?? '0'))
      : +(progress?.deposit ?? '0')
  );
  const [paymentError, setPaymentError] = useState<string>();
  const finalDownpaymentAmount: number = +(progress?.deposit ?? '0') - +(progress?.downpayment_amount ?? '0');
  const ordwayCustomerId: string | undefined = progress?.ordway_customer_id;
  const paymentGateway: PaymentGateway = progress?.ordway_customer_payment_gateway;
  const scriveSigned: boolean = progress?.scrive_signed;
  const applicationId: string = progress?.application_id;
  const customerPaymentCardNumber: string | undefined = progress?.ordwayData?.account_number;
  const submitPayment = isFinalDownpayment ? finalDownPayment : downPayment;
  const deposit: string | null = progress?.deposit;
  const downPaymentStatus: DownpaymentStatus = progress?.downpayment_status;

  const checkCustomerGateway = useCallback(async () => {
    if (paymentGateway === PAYMENT_GATEWAY_LEUMI && !scriveSigned) {
      await updateOrdwayCustomerGateway({
        ordway_customer_id: ordwayCustomerId,
        gateway_account_name: PAYMENT_GATEWAY_OTTO_CAR,
      });

      const progressData = await progressChecker(applicationId);
      updateProgress(progressData?.data?.progress, progressData?.data?.sections);
      return progressData?.data?.progress?.ordwayData?.account_number;
    }
    return customerPaymentCardNumber;
  }, [ordwayCustomerId, paymentGateway, scriveSigned, applicationId, customerPaymentCardNumber, updateProgress]);

  const makeDownpayment = useCallback(() => {
    submitPayment({ application_id: applicationId, deposit: downpaymentValue })
      .then(() => {
        return true;
      })
      .catch((err: AxiosError<{ message: string }>) => {
        setPaymentError(err?.response?.data?.message ?? 'The payment could not be processed.');
      })
      .finally(() => {
        setMakingPayment(false);
        checkProgress();
      });
  }, [applicationId, downpaymentValue, submitPayment, checkProgress]);

  useEffect(() => {
    if (!isFinalDownpayment) {
      if (downPaymentStatus !== 'SUCCEEDED') {
        setDownpaymentValue(+(deposit ?? '0'));
      }
    }
  }, [isFinalDownpayment, deposit, downPaymentStatus]);

  return (
    <>
      <DownpaymentPanel>
        <CollapsiblePanel
          styled={{ marginTop: 16 }}
          locked={isLocked}
          header={
            <SectionHeader
              title={isFinalDownpayment ? 'Final downpayment' : 'Downpayment'}
              isComplete={isComplete}
              completedText={`£${
                isFinalDownpayment ? progress?.final_downpayment_amount : progress?.downpayment_amount ?? '0.00'
              } - Succeeded`}
              isLocked={isLocked}
              expanded={false}
            />
          }
          expanded={!isCollapsed}
          onCollapse={() => {
            setIsCollapsed(!isCollapsed);
          }}
        >
          <div>
            <SectionHeader
              title={isFinalDownpayment ? 'Final downpayment' : 'Downpayment'}
              isComplete={isComplete}
              isLocked={isLocked}
              expanded
            />
            {paymentError != null && (
              <Text styled={{ marginTop: 16 }} variant="body7" color={STATUS_RED} weight={300} block>
                {paymentError}
              </Text>
            )}
            <FlexLayout itemsY="center" styled={{ marginTop: 32 }} gap={24}>
              {isFinalDownpayment ? (
                <div>
                  <Text variant="body6" weight={500} color={PRIMARY_PURPLE} block>
                    Amount
                  </Text>
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                    {`£${finalDownpaymentAmount.toFixed(2)}`}
                  </Text>
                </div>
              ) : (
                <>
                  {!isCollapsed && (
                    <TextField
                      label="Amount"
                      placeholder={isFinalDownpayment ? 'Final downpayment amount' : 'Downpayment amount'}
                      name="downpayment"
                      type="number"
                      defaultValue={
                        progress?.downpayment_status === 'SUCCEEDED'
                          ? progress?.downpayment_amount ?? '0'
                          : progress?.deposit ?? '0'
                      }
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => setDownpaymentValue(+e?.target?.value)}
                    />
                  )}
                </>
              )}
              <div>
                <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block>
                  {isFinalDownpayment ? 'Final downpayment status' : 'Downpayment status'}
                </Text>
                <Status
                  $status={isFinalDownpayment ? progress?.final_downpayment_status : progress?.downpayment_status}
                  itemsY="center"
                >
                  <Text variant="body6" color={PRIMARY_PURPLE} weight={300} styled={{ opacity: 0.5 }}>
                    {isFinalDownpayment ? progress?.final_downpayment_status : progress?.downpayment_status}
                  </Text>
                </Status>
              </div>
            </FlexLayout>
            {!isComplete && (
              <PrimaryButton
                styled={{ marginTop: 24 }}
                disabled={downpaymentValue < 0}
                isProcessing={makingPayment}
                onClick={() => setShowConfirmation(true)}
              >
                Make payment
              </PrimaryButton>
            )}
          </div>
        </CollapsiblePanel>
      </DownpaymentPanel>
      <ConfirmationModal
        isOpen={showConfirmation}
        onClose={() => setShowConfirmation(false)}
        title={`Are you sure you want to make a downpayment of £${downpaymentValue.toFixed(2)}?`}
        confirmButtonCaption="Yes"
        closeButtonCaption="No"
        preConfirm={() =>
          checkCustomerGateway().then((paymentCardNumber: string) => {
            setMakingPayment(true);
            if (!paymentCardNumber) {
              setMakingPayment(false);
            } else {
              makeDownpayment();
            }
          })
        }
        onConfirm={() => {
          setShowConfirmation(false);
        }}
      />
    </>
  );
};
