// DEPENDENCIES
import React, { useCallback, useEffect, useState } from 'react';
import { BsPencil } from 'react-icons/bs';
import moment from 'moment';
// COMPONENTS & STYLES
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { vehicleBatchListColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DateRangeFilter, DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { TableRowGrid } from '../../../uiComponents/table/table.styles';
import { NoResults } from '../../../uiComponents/table/noResults/noResults';
import { Text } from '../../../uiComponents/text/text';
// API CALLS & MODELS
import { getAllBatches } from '../../../api/get/batches.get';
import { getAllVehiclesByBatchId } from '../../../api/get/vehicle.get';
import { getAllVehicleTypes } from '../../../api/get/vehicleType.get';
import { BatchVehicle, ModifiedVehicleType, VehicleBatch } from '../../../models/vehicle';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { OptionList } from '../../../utils/props';
import { PRIMARY_GRAY, PRIMARY_PURPLE, SECONDARY_GRAY_20, SECONDARY_PURPLE_70 } from '../../../common/styles/Colors';
import { Modal } from '../../../uiComponents/modals/modal';
import { CreateBatchesForm } from './createBatchesForm';
import { EditBatchForm } from './editBatchForm';
import { EditVehicleBatchForm } from './editVehicleBatchForm';
import { useDateRangeFilter } from '../../../hooks/useDateRangeFilter';
import { useListAndMergeVehicleBatchesListQuery } from '../../../api/listAndMerge/listAndMergeVehicleBatchesApiSlice';
interface RenderBatchVehicleListProps {
  vehicles: BatchVehicle[];
  retchBatchesList: () => void;
}
const RenderBatchVehicleList = ({ vehicles, retchBatchesList }: RenderBatchVehicleListProps) => {
  const [vehicleValue, setVehicleValue] = useState<BatchVehicle>();
  const [openUpdateVehicle, setOpenUpdateVehicle] = useState<boolean>(false);
  const editVehicle = (row: BatchVehicle) => {
    setOpenUpdateVehicle(true);
    setVehicleValue(row);
  };
  const closeModal = (action?: 'cancel' | 'submit') => {
    setOpenUpdateVehicle(false);
    if (action === 'submit') {
      retchBatchesList();
    }
  };

  return (
    <>
      <TableRowGrid $rowClickable={false} $borderColur={SECONDARY_GRAY_20} gap={8} template={4}>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          VIN
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          VRM
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Available Date
        </Text>
        <Text variant="body7" color={PRIMARY_PURPLE} weight={500}>
          Actions
        </Text>
      </TableRowGrid>
      {vehicles?.length > 0 ? (
        <>
          {vehicles?.map((vehicle: BatchVehicle) => (
            <TableRowGrid $borderColur={SECONDARY_GRAY_20} $rowClickable={false} key={vehicle?.id} gap={8} template={4}>
              <TableTextCell value={vehicle?.vin} />
              <TableTextCell value={vehicle?.vrm} />
              <TableTextCell value={moment(vehicle?.available_on).format('DD MMM YYYY')} />
              <ActionIcon
                onClick={() => editVehicle(vehicle)}
                icon={<BsPencil size={24} color={SECONDARY_PURPLE_70} />}
                tooltip="Edit"
              />
            </TableRowGrid>
          ))}
        </>
      ) : (
        <NoResults />
      )}
      <Modal
        styled={{ width: '60vw', minWidth: 600 }}
        title="Edit vehicle"
        open={openUpdateVehicle}
        onClose={closeModal}
        showClose
      >
        <EditVehicleBatchForm values={vehicleValue} close={closeModal} />
      </Modal>
    </>
  );
};

export const VehicleBatchesList = () => {
  const [vehicleTypes, setVehicleTypes] = useState<ModifiedVehicleType[]>();
  const [selectedManufacturer, setSelectedManufacturer] = useState<OptionList[]>([]);
  const [selectedModel, setSelectedModel] = useState<OptionList[]>([]);
  const [selectedModelYear, setSelectedModelYear] = useState<OptionList[]>([]);
  const [selectedFuelType, setSelectedFuelType] = useState<OptionList[]>([]);
  const [openCreateBatch, setOpenCreateBatch] = useState<boolean>(false);
  const [openUpdateBatch, setOpenUpdateBatch] = useState<boolean>(false);
  const [batchValue, setBatchValue] = useState<VehicleBatch>();
  const { updateDateRangeFilter, dateRangeFilter, setDateRangeFilter, invalidDates } = useDateRangeFilter();

  const manufacturers: OptionList[] = [
    ...new Map(vehicleTypes?.map((item) => [item['manufacturer'], item])).values(),
  ]?.map((vehicleType: ModifiedVehicleType) => {
    return { value: vehicleType.manufacturer, label: vehicleType.manufacturer };
  });

  const models: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model, label: vehicleType.model };
    }
  );

  const modelYears: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['model_year'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.model_year, label: vehicleType.model_year };
    }
  );

  const allColours: string[] = [];
  for (const i of vehicleTypes ?? []) {
    for (const j of i.colors) {
      allColours?.push(j);
    }
  }

  const fuelTypes: OptionList[] = [...new Map(vehicleTypes?.map((item) => [item['fuel_type'], item])).values()]?.map(
    (vehicleType: ModifiedVehicleType) => {
      return { value: vehicleType.fuel_type, label: vehicleType.fuel_type };
    }
  );

  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    setSortAscending,
    getSortDirection,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const defaultString = `limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=updated_date:DESC`;
  const [queryStringState, setQueryStringState] = useState<string>(defaultString);
  const [refetchData, setRefetchData] = useState<boolean>(true);
  const {
    data: vehicleBatchesList,
    isFetching: isVehicleBatchesListFetching,
    refetch,
  } = useListAndMergeVehicleBatchesListQuery({ query: queryStringState, refetch: refetchData });

  const editBatch = (row: VehicleBatch) => {
    setOpenUpdateBatch(true);
    setBatchValue(row);
  };

  const filters: FilterItem[] = [
    {
      name: 'manufacturer',
      element: (
        <DropDownFilter
          name="manufacturer"
          placeholder="Manufacturer"
          options={manufacturers}
          multiValues={selectedManufacturer}
          title="Manufacturer"
          onChange={(items) => setSelectedManufacturer(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model',
      element: (
        <DropDownFilter
          name="model"
          placeholder="Model"
          multiValues={selectedModel}
          options={models}
          title="Model"
          onChange={(items) => setSelectedModel(items as OptionList[])}
        />
      ),
    },
    {
      name: 'model_year',
      element: (
        <DropDownFilter
          name="model_year"
          placeholder="Model year"
          multiValues={selectedModelYear}
          options={modelYears}
          title="Model year"
          onChange={(items) => setSelectedModelYear(items as OptionList[])}
        />
      ),
    },
    {
      name: 'fuel_type',
      element: (
        <DropDownFilter
          name="fuel_type"
          placeholder="Fuel type"
          multiValues={selectedFuelType}
          options={fuelTypes}
          title="Fuel type"
          onChange={(items) => setSelectedFuelType(items as OptionList[])}
        />
      ),
    },
    {
      name: 'available-date',
      element: (
        <DateRangeFilter
          title="Available date"
          onFromDateChange={(value: string) => updateDateRangeFilter(value, 0)}
          onToDateChange={(value: string) => updateDateRangeFilter(value, 1)}
          dateRanges={dateRangeFilter?.flatMap((d) => d?.label)}
        />
      ),
    },
  ];

  const handleGetBatchesResponse = useCallback(
    (count: number, batches: VehicleBatch[]) => {
      const batchRows = batches?.map((batch: VehicleBatch) => {
        return {
          rowData: { data: batch },
          cells: [
            <TableTextCell value={batch?.manufacturer} />,
            <TableTextCell value={batch?.model} />,
            <TableTextCell value={batch?.spec} />,
            <TableTextCell value={batch?.model_year} />,
            <TableTextCell value={batch?.color} />,
            <TableTextCell value={batch?.fuel_type} />,
            <TableTextCell value={batch?.amount} />,
            <TableTextCell value={moment(batch?.available_on).format('DD MMM YYYY')} />,
            <ActionIcon
              icon={<BsPencil onClick={() => editBatch(batch)} size={24} color={PRIMARY_GRAY} />}
              tooltip="Edit"
            />,
          ],
        };
      });
      setTableData(batchRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean,
      pagination?: boolean
    ) => {
      if (!pagination) {
        setRefetchData(true);
      } else {
        setRefetchData(false);
      }

      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );

      setQueryStringState(queryString);
    },
    [goToPageNumber, tableFilters, setQueryStringState]
  );

  useEffect(() => {
    setSortingColumn('updated_date');
    setSortAscending(false);
    getAllVehicleTypes('').then((response: { count: number; data: ModifiedVehicleType[] }) => {
      setVehicleTypes(response.data);
    });
  }, [setSortingColumn, setSortAscending]);

  useEffect(() => {
    setTableFilters([
      { columnName: 'manufacturer', options: selectedManufacturer },
      { columnName: 'model', options: selectedModel },
      { columnName: 'model_year', options: selectedModelYear },
      { columnName: 'fuel_type', options: selectedFuelType },
      {
        columnName: 'available_on',
        options: dateRangeFilter,
        clause: '$btw',
      },
    ]);
  }, [setTableFilters, dateRangeFilter, selectedManufacturer, selectedModel, selectedModelYear, selectedFuelType]);

  useEffect(() => {
    if (vehicleBatchesList) {
      handleGetBatchesResponse(vehicleBatchesList.count, vehicleBatchesList.data);
    }
  }, [vehicleBatchesList, queryStringState, handleGetBatchesResponse]);

  const onClearClick = useCallback(() => {
    setSelectedManufacturer([]);
    setSelectedModel([]);
    setSelectedModelYear([]);
    setSelectedFuelType([]);
    setDateRangeFilter([]);
  }, [setDateRangeFilter]);

  const refetchToFirstPage = useCallback(() => {
    window.scroll({
      top: 0,
      behavior: 'smooth',
    });
    setRefetchData(true);

    if (queryStringState === defaultString) {
      refetch();
    } else {
      setQueryStringState(defaultString);
    }

    onClearClick();
    goToPageNumber(0);
  }, [goToPageNumber, onClearClick, queryStringState, refetch, defaultString]);

  const closeModal = (action?: 'cancel' | 'submit') => {
    setOpenCreateBatch(false);
    setOpenUpdateBatch(false);
    if (action === 'submit') {
      refetchToFirstPage();
    }
  };

  return (
    <>
      <Table
        isInfitineScroll={true}
        isLoading={isVehicleBatchesListFetching}
        header="Vehicle batch list"
        actionButtonText="Create batch"
        onActionButtonClick={() => setOpenCreateBatch(true)}
        disableApply={invalidDates}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(0, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        dataDownloadMethod="download"
        sortAscending={sortAscending}
        columns={vehicleBatchListColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(0, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllBatches}
        downloadName="Vehicle batches"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending, true);
        }}
        onApplyClick={() => applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        getTableRowData={(id: string) =>
          getAllVehiclesByBatchId(id).then((res: { count: number; data: BatchVehicle[] }) => (
            <RenderBatchVehicleList vehicles={res?.data} retchBatchesList={refetchToFirstPage} />
          ))
        }
      />
      <Modal
        styled={{ width: openCreateBatch ? '80vw' : '50vw', minWidth: 600 }}
        title={openCreateBatch ? 'Create vehicle batch' : 'Edit vehicle batch'}
        open={openCreateBatch || openUpdateBatch}
        onClose={closeModal}
        showClose
      >
        <>
          {openCreateBatch && <CreateBatchesForm onClose={closeModal} />}
          {openUpdateBatch && batchValue && <EditBatchForm values={batchValue} onClose={closeModal} />}
        </>
      </Modal>
    </>
  );
};
