export const ACTIVE_STATUS = 'ACTIVE';
export const AFTERCARE_STATUS = 'AFTERCARE';
export const ASSIGNED_STATUS = 'ASSIGNED';
export const CANCELLED_STATUS = 'CANCELLED';
export const COMPLETED_STATUS = 'COMPLETED';
export const COMPLETE_STATUS = 'COMPLETE';
export const INCOMPLETE_STATUS = 'INCOMPLETE';
export const COURTESY_ASSIGNED_STATUS = 'COURTESY-ASSIGNED';
export const DECLARATION_COMPLETED_STATUS = 'DECLARATION-COMPLETED';
export const DIGITAL_ONBOARDED = 'DIGITAL-ONBOARDED';
export const LIVE_STATUS = 'LIVE';
export const PENDING_STATUS = 'PENDING';
export const SCRIVE_SENT = 'SCRIVE-SENT';
export const WITH_3RD_PARTY = 'WITH-3RD-PARTY';
export const IN_SERVICING = 'IN-SERVICING';
export const UPCOMING_STATUS = 'UPCOMING';
