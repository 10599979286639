import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { Text } from '../../text/text';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';

interface TableHeaderProps {
  tableHeaderTitle: string;
}

export const TableHeader = ({ tableHeaderTitle }: TableHeaderProps) => {
  return (
    <FlexLayout itemsX="space-between" itemsY="center">
      <Text variant="h4" weight={800} color={PRIMARY_PURPLE} block>
        {tableHeaderTitle}
      </Text>
    </FlexLayout>
  );
};
