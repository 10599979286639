import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Control, FieldError, FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { CREATE_TRANSFER } from '../../../consts/routes';
import {
  editFields,
  dropdownFields,
  SERVICING_ACTIVE,
  BRANCH_ID,
  CITY_ID,
  SERVICING_TYPE,
  VRM,
} from '../../../consts/servicing';
import { CHECK_IN } from '../../../consts/transfer';
import { LIVE, IN_SERVICING, AFTERCARE } from '../../../consts/vehicle';
import { PrimaryButton } from '../../../uiComponents/buttons/primaryButton/primaryButton';
import { SecondaryButton } from '../../../uiComponents/buttons/secondaryButton/secondaryButton';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { TextArea } from '../../../uiComponents/inputs/textArea/textArea';
import { TextField } from '../../../uiComponents/inputs/textField/textField';
import { TextFieldLabel } from '../../../uiComponents/inputs/textField/textField.styles';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { servicingTypes } from '../../../uiComponents/table/tableFilters/tableFilterOptions';
import { Notification } from '../../../uiComponents/toast/toast';
import { DropDown } from '../../../uiComponents/uiControls/dropDown/dropDown';
import { getAllBranches } from '../../../api/get/branch.get';
import { getAllCities } from '../../../api/get/city.get';
import { getAllServicingsForVehicle } from '../../../api/get/servicing.get';
import { getVehicleByVrm } from '../../../api/get/vehicle.get';
import { createServicing, createServicingWithParentId } from '../../../api/post/servicing.post';
import { Branch } from '../../../models/branch';
import { City } from '../../../models/city';
import { Vehicle } from '../../../models/vehicle';
import { CreateServicingPayload, Servicing } from '../../../models/servicing';
import { OptionList } from '../../../utils/props';

interface CreateSingleServicingProps {
  vehicleId?: string | null;
  onFormSubmit: () => void;
  onClose: () => void;
  isMainServicing?: boolean;
}

/**
 * Component for creating a single servicing
 * @param isMainServicing - Optional flag to differentiate between main and subservicing.
 */
export const CreateSingleServicing = ({
  vehicleId,
  onFormSubmit,
  onClose,
  isMainServicing,
}: CreateSingleServicingProps) => {
  const navigate = useNavigate();
  const [branches, setBranches] = useState<Branch[]>([]);
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [inputVRM, setInputVRM] = useState<string>('');
  const [isInServicing, setIsInServicing] = useState<boolean>(false);
  const [creatingService, setCreatingService] = useState<boolean>(false);
  const [isLive, setIsLive] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedCity, setSelectedCity] = useState<OptionList>();
  const [selectedBranch, setSelectedBranch] = useState<OptionList | null>();
  const [vehicleByVrm, setVehicleByVrm] = useState<Vehicle>();
  const cityBranches: Branch[] = branches?.filter((branch: Branch) => branch.city_id === selectedCity?.value);
  const cityBranchOptions: OptionList[] = cityBranches?.map((cityBranch: Branch) => {
    return { value: cityBranch?.branch_id, label: cityBranch?.branch_name };
  });
  const [vehicleData, setVehicleData] = useState<Vehicle>();
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm({
    mode: 'all',
    reValidateMode: 'onSubmit',
  });

  useEffect(() => {
    getAllCities().then((response: { count: number; data: City[] }) => {
      const cityOptions: OptionList[] = response?.data?.map((city: City) => {
        return {
          value: city.city_id,
          label: city.city_name,
        };
      });
      setCityOptions(cityOptions);
    });

    getAllBranches().then((response: { count: number; data: Branch[] }) => {
      setBranches(response?.data);
    });
  }, []);

  const validateVehicleStatus = useCallback(
    async (vrm: string) => {
      try {
        if (vrm) {
          setIsLoading(true);
          setInputVRM(vrm);
          const { data } = await getVehicleByVrm(vrm);
          if (data) {
            setIsLoading(false);
            setVehicleByVrm(data);
          }
          const { vehicle_status: status, vehicle_id: vehicleId } = data || {};
          if (status === LIVE || status === AFTERCARE) {
            setIsLive(true);
            setIsModalOpen(true);
          } else {
            const { data: servicings } = await getAllServicingsForVehicle(vehicleId);
            const activeServicings: Servicing[] = servicings.filter(
              (servicing: Servicing) =>
                [SERVICING_ACTIVE, null].includes(servicing.servicing_status) &&
                servicing.vehicle_status === IN_SERVICING
            );
            setIsInServicing(activeServicings.length > 0);
            if (activeServicings.length > 0) {
              setError('vrm', {
                type: 'custom',
                message: 'Vehicle is currently in service. Finish it in the servicing page!',
              });
            } else {
              setVehicleData(data);
            }
          }
        }
      } catch (err) {
        const error: Error = err as Error;
        setError('vrm', { type: 'custom', message: `Vehicle not found. ${error}` });
      } finally {
        setIsLoading(false);
      }
      setIsLoading(false);
    },
    [setError]
  );

  const renderNotification = (value: string) => {
    return Notification({
      type: `${value ? 'success' : 'error'}`,
      title: `${value ? 'Success' : 'Error'}`,
      message: `${value ? 'Servicing has been successfully created' : 'Failed to create servicing'}`,
      isAlert: true,
    });
  };

  const onSubmit = useCallback<SubmitHandler<FieldValues>>(
    async (values: FieldValues) => {
      const { start_date, projected_ttr, servicing_type, notes, branch_id } = values;
      setCreatingService(true);
      try {
        if (isMainServicing && vehicleData) {
          const { vrm } = values;
          const payload: CreateServicingPayload = {
            vrm,
            vehicle_id: vehicleData?.vehicle_id,
            agreement_id: vehicleData?.agreement_id,
            branch_id,
            servicing_type,
            start_date,
            notes,
            projected_ttr,
          };
          const { data } = await createServicing(payload);
          renderNotification(data.id);
        } else if (vehicleId) {
          const payload: CreateServicingPayload = {
            vrm: vehicleId,
            vehicle_id: vehicleId,
            parent_id: vehicleId,
            branch_id,
            servicing_type,
            start_date,
            notes,
            projected_ttr,
            end_date: undefined,
          };
          const { data } = await createServicingWithParentId(vehicleId, payload); // Use createServicingWithParentId API
          renderNotification(data.id);
        }
        setCreatingService(false);
      } catch (err) {
        const error: Error = err as Error;
        Notification({
          type: 'error',
          title: 'Error',
          message: `${error}`,
          isAlert: true,
        });
        setCreatingService(false);
      }
      onFormSubmit();
    },
    [onFormSubmit, vehicleId, isMainServicing, vehicleData]
  );

  const handleDropDownSelection = (option: OptionList, fieldName: string) => {
    if (fieldName === CITY_ID) {
      const selectedOption: OptionList = option as OptionList;
      if (selectedOption?.value !== selectedCity?.value) {
        setSelectedBranch(null);
      }
      setSelectedCity(selectedOption);
    } else if (fieldName === BRANCH_ID) {
      setSelectedBranch(option as OptionList);
    }
  };

  const getDropDpwnOptionsAndValue = (fieldName: string): [OptionList[], OptionList | null | undefined] => {
    if (fieldName === CITY_ID) {
      return [cityOptions, selectedCity];
    } else if (fieldName === BRANCH_ID) {
      return [cityBranchOptions || [], selectedBranch];
    } else {
      return [servicingTypes, null];
    }
  };

  // VRM is not needed for creating a subservicing, but needed for creating a servicing
  // Difference b/t a regular servicing and subservicing is that the latter has parent_id which is vehicle_id and the former doesn't
  const servicingEditFields = useMemo(() => (isMainServicing ? editFields : editFields.slice(-2)), [isMainServicing]);
  return (
    <>
      <GridLayout styled={{ margin: 20 }} template={2} gap={18}>
        {servicingEditFields?.map(({ ...field }, index) => (
          <TextField
            key={index}
            {...register(field?.name, {
              onBlur: isMainServicing && field.name === VRM ? (e) => validateVehicleStatus(e.target.value) : undefined,
              pattern: field.pattern,
              required: field.requiredText,
              validate: field.validate,
            })}
            error={errors[field.name] as FieldError}
            label={field.label}
            loading={isMainServicing && field.name === VRM ? isLoading : false}
            name={field.name}
            placeholder={field.placeholder ? field.placeholder : ''}
            required={!!field.requiredText}
            type={field.type}
            styled={{ marginBottom: 15 }}
          />
        ))}
        {dropdownFields.map((field, index) => (
          <div key={index}>
            <TextFieldLabel $isRequired>{field.label}</TextFieldLabel>
            <DropDown
              control={control as unknown as Control<FieldValues>}
              error={errors[field.name] as FieldError}
              name={field.name}
              multiSelect={field.name === SERVICING_TYPE}
              onSelect={(option) => handleDropDownSelection(option as OptionList, field.name)}
              options={getDropDpwnOptionsAndValue(field.name)[0]}
              placeholder={`Select ${field.label.toLowerCase()}`}
              required={{
                required: field.requiredText,
              }}
              value={getDropDpwnOptionsAndValue(field.label)[1]}
            />
          </div>
        ))}
      </GridLayout>
      <div style={{ margin: 20 }}>
        <TextFieldLabel>Notes</TextFieldLabel>
        <TextArea {...register('notes')} name="notes" placeholder="Notes"></TextArea>
      </div>
      <FlexLayout itemsX="end" itemsY="end">
        <SecondaryButton styled={{ marginRight: 10 }} onClick={() => onClose()}>
          Cancel
        </SecondaryButton>
        <PrimaryButton
          disabled={isInServicing || isLive}
          isProcessing={creatingService}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </PrimaryButton>
      </FlexLayout>
      <ConfirmationModal
        title={`This is ${
          vehicleByVrm?.vehicle_status === AFTERCARE ? 'an aftercare' : 'a live'
        } vehicle. Create a check-in instead?`}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={() => {
          const params = {
            agreement_id: null,
            dynamic: null,
            reason: null,
            type: CHECK_IN,
            vrm: inputVRM,
          };
          navigate(CREATE_TRANSFER, { state: params });
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
