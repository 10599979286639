import api from '../../utils/api';

/**
 * Update City Request
 * @category API/PATCH
 * @param {object} id - id to update
 * @param {object} id - data to update
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  city_name: '',
 * }
 * updateCity(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const updateCity = (payload: { city_name: string; city_id: string }) =>
  api.patch(`internal/city/${payload.city_id}`, payload);
