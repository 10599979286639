import { useState, useCallback, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { VEHICLES } from '../../../../consts/routes';
import { useTableFilters } from '../../../../hooks/useTableFilters';
import { CollapsiblePanel } from '../../../../uiComponents/layouts/collapsiblePanel/collapsiblePanel';
import { Text } from '../../../../uiComponents/text/text';
import { Table } from '../../../../uiComponents/table/table';
import { driverVehicleColumns } from '../../../../uiComponents/table/tableColumns/tableColumns';
import {
  TableTextCell,
  TableTagCell,
  getQueryString,
  DEFAULT_NUM_ROWS_PER_PAGE,
} from '../../../../uiComponents/table/tableUtils/tableUtils';
import { getAllVehicles } from '../../../../api/get/vehicle.get';
import { DriverTableProps } from '../../../../models/driver';
import { Vehicle } from '../../../../models/vehicle';
import { PRIMARY_PURPLE } from '../../../../common/styles/Colors';

interface DriverVehiclesProps extends DriverTableProps {
  tableHeader: string;
}

export const DriverVehicles = ({ driverId, tableHeader }: DriverVehiclesProps) => {
  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const {
    setTableData,
    goToPageNumber,
    setTotalRows,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setTableFilters,
    filterQuery,
    sortAscending,
    sortingColumn,
    tableData,
    totalRows,
    pageNumber,
    numRowsPerPage,
    tableFilters,
  } = useTableFilters();

  const handleGetVehiclesResponse = useCallback(
    (count: number, vehicles: Vehicle[]) => {
      const vehicleRows = vehicles?.map((vehicle: Vehicle) => {
        return {
          rowData: { data: vehicle },
          cells: [
            <TableTextCell value={vehicle?.vrm ?? '-'} />,
            <TableTextCell value={vehicle?.vehicle_make_model ?? '-'} />,
            <TableTextCell value={vehicle?.spec ?? '-'} />,
            <TableTextCell value={`£${vehicle?.accident_excess}` ?? '-'} />,
            <TableTagCell tags={[vehicle?.vehicle_status] ?? []} />,
          ],
        };
      });
      setTableData(vehicleRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchVehicleList = useCallback(
    (queryString: string) => {
      getAllVehicles(queryString).then((response: { count: number; data: Vehicle[] }) => {
        handleGetVehiclesResponse(response.count, response.data);
      });
    },
    [handleGetVehiclesResponse]
  );

  const applyFilters = useCallback(
    (pageNumber: number, rowsPerPage: number, sortingColumn: string, sortAscending: boolean) => {
      setTableData(undefined);
      const queryString = getQueryString(tableFilters, rowsPerPage, pageNumber, '', sortingColumn, sortAscending);
      fetchVehicleList(queryString);
    },
    [fetchVehicleList, setTableData, tableFilters]
  );

  useEffect(() => {
    setSortingColumn('vrm');
    fetchVehicleList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=vrm:ASC&filter=driver_id$eq=${driverId}`);
    setTableFilters([{ columnName: 'driver_id', options: { label: driverId, value: driverId } }]);
  }, [fetchVehicleList, setSortingColumn, setTableFilters, driverId]);

  return (
    <CollapsiblePanel
      header={
        <Text variant="body6" color={PRIMARY_PURPLE} weight={500}>
          {tableHeader}
        </Text>
      }
      expanded={!isCollapsed}
      onCollapse={(collapsed: boolean) => setIsCollapsed(!collapsed)}
      styled={{ marginTop: 16 }}
    >
      <div>
        <Text variant="h4" weight={800} color={PRIMARY_PURPLE} styled={{ marginBottom: 32 }} block>
          {tableHeader}
        </Text>
        <Table
          variant="compact"
          tableTheme="purple"
          embedded
          onColumnHeaderClick={(columnId: string) =>
            applyFilters(pageNumber, numRowsPerPage, columnId, getSortDirection(columnId))
          }
          sortAscending={sortAscending}
          columns={driverVehicleColumns}
          rows={tableData}
          totalRows={totalRows}
          rowsPerPage={numRowsPerPage}
          currentPageNumber={pageNumber}
          sortingColumn={sortingColumn}
          filters={[]}
          filterQuery={filterQuery}
          goToPage={(pageNumber: number) => {
            goToPageNumber(pageNumber);
            applyFilters(pageNumber, numRowsPerPage, sortingColumn, sortAscending);
          }}
          onRowClick={(vehicle: { data: Vehicle }) => navigate(`${VEHICLES}/${vehicle?.data?.vehicle_id}`)}
          onNumRowsPerPageChange={(value: number) => {
            setNumRowsPerPage(value);
            goToPageNumber(0);
            applyFilters(0, value, sortingColumn, sortAscending);
          }}
        />
      </div>
    </CollapsiblePanel>
  );
};
