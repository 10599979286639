// DEPENDENCIES
import React, { useContext, useState, useCallback, useEffect } from 'react';
import { BsPencil } from 'react-icons/bs';
import { FiTrash2 } from 'react-icons/fi';
// COMPONENTS & STYLES
import { CreateEditBranchForm } from './createEditBranchForm/createEditBranchForm';
import { ConfirmationModal } from '../../../uiComponents/modals/confirmationModal/confirmationModal';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Modal } from '../../../uiComponents/modals/modal';
import { ActionIcon } from '../../../uiComponents/table/actionIcon/actionIcon';
import { Table } from '../../../uiComponents/table/table';
import { branchColumns } from '../../../uiComponents/table/tableColumns/tableColumns';
import { DropDownFilter, FilterItem } from '../../../uiComponents/table/tableFilters/tableFilters';
import {
  TableTextCell,
  DEFAULT_NUM_ROWS_PER_PAGE,
  getQueryString,
} from '../../../uiComponents/table/tableUtils/tableUtils';
import { Notification } from '../../../uiComponents/toast/toast';
// API CALLS & MODELS
import { getAllBranches } from '../../../api/get/branch.get';
import { getAllCities } from '../../../api/get/city.get';
import { deleteBranch } from '../../../api/delete/branch.delete';
import { Branch } from '../../../models/branch';
// HOOKS & UTILS & COMMONS
import { useTableFilters } from '../../../hooks/useTableFilters';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { City } from '../../../models/city';
import { APP_CONTEXT } from '../../../utils/context';
import { OptionList } from '../../../utils/props';

export const BranchList = () => {
  const { setActiveSideNav, setPageTitle } = useContext(APP_CONTEXT);
  const [cityOptions, setCityOptions] = useState<OptionList[]>([]);
  const [selectedCity, setSelectedCity] = useState<OptionList[]>();
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [branchToEdit, setBranchToEdit] = useState<Branch>();
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false);
  const {
    setTableData,
    setTableFilters,
    goToPageNumber,
    setTotalRows,
    setSearchString,
    setSortingColumn,
    getSortDirection,
    setNumRowsPerPage,
    setSortAscending,
    filterQuery,
    tableFilters,
    sortAscending,
    sortingColumn,
    tableData,
    searchString,
    totalRows,
    pageNumber,
    numRowsPerPage,
  } = useTableFilters();

  const filters: FilterItem[] = [
    {
      name: 'city',
      element: (
        <DropDownFilter
          name="city"
          placeholder="Select a city"
          multiValues={selectedCity}
          options={cityOptions}
          title="City:"
          onChange={(items) => setSelectedCity(items as OptionList[])}
        />
      ),
    },
  ];

  const handleGetBranchesResponse = useCallback(
    (count: number, branches: Branch[]) => {
      const branchRows = branches?.map((branch: Branch) => {
        return {
          rowData: { data: branch },
          cells: [
            <TableTextCell value={branch?.branch_name} />,
            <TableTextCell value={branch?.city_name} />,
            <TableTextCell value={branch?.country_name} />,
            <FlexLayout gap={4} itemsY="center">
              <ActionIcon
                onClick={() => {
                  setBranchToEdit(branch);
                  setIsEditModalOpen(true);
                }}
                icon={<BsPencil size={24} color={PRIMARY_PURPLE} />}
                tooltip="Edit branch"
              />
              <ActionIcon
                onClick={() => {
                  setBranchToEdit(branch);
                  setIsDeleteConfirmationModalOpen(true);
                }}
                icon={<FiTrash2 size={24} color={PRIMARY_PURPLE} />}
                tooltip="Delete branch"
              />
            </FlexLayout>,
          ],
        };
      });
      setTableData(branchRows);
      setTotalRows(count);
    },
    [setTotalRows, setTableData]
  );

  const fetchBranchList = useCallback(
    (queryString: string) => {
      const controller = new AbortController();
      getAllBranches(queryString, controller.signal).then((response: { count: number; data: Branch[] }) => {
        handleGetBranchesResponse(response.count, response.data);
      });
    },
    [handleGetBranchesResponse]
  );

  const applyFilters = useCallback(
    (
      pageNumber: number,
      rowsPerPage: number,
      searchString: string,
      sortingColumn: string | undefined,
      sortAscending: boolean
    ) => {
      setTableData(undefined);
      goToPageNumber(pageNumber);
      const queryString = getQueryString(
        tableFilters,
        rowsPerPage,
        pageNumber,
        searchString,
        sortingColumn,
        sortAscending
      );
      fetchBranchList(queryString);
    },
    [fetchBranchList, setTableData, goToPageNumber, tableFilters]
  );

  useEffect(() => {
    setActiveSideNav('branchListPage');
    setPageTitle('Branches');
    getAllCities().then((results) => {
      const items: OptionList[] = results.data.map((city: City) => {
        return { value: city.city_name, label: city.city_name };
      });
      setCityOptions(items);
    });
    setSortingColumn('branch_name');
    setSortAscending(true);
    fetchBranchList(`limit=${DEFAULT_NUM_ROWS_PER_PAGE}&sort=branch_name:ASC`);
  }, [setActiveSideNav, setPageTitle, setSortingColumn, fetchBranchList, setSortAscending]);

  useEffect(() => {
    setTableFilters([{ columnName: 'city_name', options: selectedCity || [] }]);
  }, [setTableFilters, selectedCity]);

  const onClearClick = useCallback(() => {
    setSelectedCity([]);
    fetchBranchList(`limit=${numRowsPerPage}&sort=branch_name:ASC`);
  }, [fetchBranchList, numRowsPerPage]);

  const deleteActiveBranch = (branchId: string) => {
    deleteBranch(branchId).then(() => {
      applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Branch has been successfully deleted',
        isAlert: true,
      });
    });
  };

  return (
    <>
      <Table
        header="Branch list"
        actionButtonText="Add branch"
        onActionButtonClick={() => setIsCreateModalOpen(true)}
        onColumnHeaderClick={(columnId: string) =>
          applyFilters(pageNumber, numRowsPerPage, searchString, columnId, getSortDirection(columnId))
        }
        sortAscending={sortAscending}
        columns={branchColumns}
        rows={tableData}
        totalRows={totalRows}
        rowsPerPage={numRowsPerPage}
        currentPageNumber={pageNumber}
        sortingColumn={sortingColumn}
        filters={filters}
        onSearchChange={(value: string) => {
          setSearchString(value);
          applyFilters(pageNumber, numRowsPerPage, value, sortingColumn, sortAscending);
        }}
        downloadApi={getAllBranches}
        downloadName="Branches"
        dataDownloadMethod="download"
        filterQuery={filterQuery}
        goToPage={(pageNumber: number) => {
          goToPageNumber(pageNumber);
          applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
        }}
        onApplyClick={() => applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending)}
        onClearClick={() => onClearClick()}
        onNumRowsPerPageChange={(value: number) => {
          setNumRowsPerPage(value);
          goToPageNumber(0);
          applyFilters(0, value, searchString, sortingColumn, sortAscending);
        }}
      />
      <Modal
        title="Edit branch"
        open={isEditModalOpen}
        showClose
        onClose={() => setIsEditModalOpen(false)}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <CreateEditBranchForm
          isInEdit
          values={branchToEdit}
          onClose={() => setIsEditModalOpen(false)}
          onFormSubmit={() => {
            setIsEditModalOpen(false);
            applyFilters(pageNumber, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
        />
      </Modal>
      <Modal
        title="Add new branch"
        open={isCreateModalOpen}
        showClose
        onClose={() => setIsCreateModalOpen(false)}
        styled={{ width: '60vw', minWidth: '600px' }}
      >
        <CreateEditBranchForm
          isInEdit={false}
          onClose={() => setIsCreateModalOpen(false)}
          onFormSubmit={() => {
            setIsCreateModalOpen(false);
            applyFilters(0, numRowsPerPage, searchString, sortingColumn, sortAscending);
          }}
        />
      </Modal>
      <ConfirmationModal
        title={'Are you sure you want to delete this branch?'}
        isOpen={isDeleteConfirmationModalOpen}
        onClose={() => setIsDeleteConfirmationModalOpen(false)}
        onConfirm={() => {
          if (branchToEdit?.branch_id) {
            deleteActiveBranch(branchToEdit?.branch_id);
            setIsDeleteConfirmationModalOpen(false);
          }
        }}
        confirmButtonCaption={'Yes'}
        closeButtonCaption={'No'}
      />
    </>
  );
};
