import { DriverTag } from '../../models/driver';
import { apiSlice } from '../../api/baseApi';

export const driverDetailsApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getDriverByDVLA: builder.query({
      query: (dvlaNo) => `driver/dvla/${dvlaNo}`,
    }),
    getDriverDocumentUploadPresignedUrl: builder.query({
      query: (params) => ({ url: '/driver/get/presigned/url/upload', method: 'POST', body: params }),
    }),
    getDriverTags: builder.query<DriverTag[], string>({
      query: (driverId) => `/driver/tag/${driverId}`,
      transformResponse: (response: { data: DriverTag[] }) => {
        return response.data;
      },
    }),
  }),
});

export const { useGetDriverByDVLAQuery, useGetDriverDocumentUploadPresignedUrlQuery, useGetDriverTagsQuery } =
  driverDetailsApiSlice;
