import { styled } from 'styled-components';
import { GRAY_5, PRIMARY_GREEN } from '../../../common/styles/Colors';

export const Icon = styled.div`
  position: relative;
`;

export const IconWrapper = styled.div<{ $disabled: boolean }>`
  cursor: ${(props) => (props.$disabled ? 'not-allowed' : 'pointer')};
  opacity: ${(props) => (props.$disabled ? 0.3 : 1)};
`;

export const Tooltip = styled.div<{ $show: boolean }>`
  position: absolute;
  border-radius: 16px;
  padding: 4px 16px;
  background-color: ${GRAY_5};
  top: calc(-100% - 14px);
  transform: translateX(calc(-50% + 18px));
  opacity: ${(props) => (props.$show ? 1 : 0)};
  transition: opacity 0.3s ease-in;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  align-items: center;

  &:before {
    content: '';
    position: absolute;
    top: 45%;
    left: 0;
    right: 0;
    border: 0.5px solid ${PRIMARY_GREEN};
  }

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 6px);
    bottom: -6px;
    width: 12px;
    height: 12px;
    transform: rotate(45deg);
    background-color: ${GRAY_5};
  }
`;
