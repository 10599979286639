import styled from 'styled-components';

export const StyledTag = styled.div<{ $color: string }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  margin-right: 8px;
  padding: 0 16px;
  min-height: 24px;
  border-radius: 50px;
  font-size: 10px;
  line-height: 16px;
  min-width: 70px;
  color: ${(props) => props.$color};
  border: 1px solid ${(props) => props.$color};
`;
