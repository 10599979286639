import React, { useState } from 'react';
import { Control, FieldError, FieldErrors, FieldValues } from 'react-hook-form';
import { Documents, Uploader } from '../../../order/order.styles';
import { FlexLayout } from '../../../../../uiComponents/layouts/flexLayout/flexLayout';
import { DocumentConfirmation } from '../../../../../uiComponents/customComponents/documentConfirmation/documentConfirmation';
import { CustomerProfile, DriverDocumentType } from '../../../../../models/driver';
import { useAppDispatch, useAppSelector } from '../../../../../store-hooks';
import { updateCustomerDetailsState, updateUploadState } from '../../../../../features/driver/customerProfileSlice';
import { fileNameTimeStampFormat } from '../../../../../utils/utils';
import { getFileService } from '../../../../../api/cognito/file.service';

interface DVLADocumentationProps {
  errors: FieldErrors<CustomerProfile>;
  control: Control<CustomerProfile>;
  isDigitalOnb?: boolean;
}

export const DriverDocs = React.forwardRef<HTMLDivElement, DVLADocumentationProps>(
  ({ control, errors, isDigitalOnb }: DVLADocumentationProps, ref) => {
    const customerProfileFields = useAppSelector((state) => state.customerProfile);
    const dispatch = useAppDispatch();
    const [uplaodDvlaFront, setUplaodDvlaFront] = useState<boolean>(false);
    const [uplaodDvlaBack, setUplaodDvlaBack] = useState<boolean>(false);
    const [uplaodPCO, setUplaodPCO] = useState<boolean>(false);
    const [uplaodPCOCert, setUplaodPCOCert] = useState<boolean>(false);
    const [uploadPOA, setUploadPOA] = useState<boolean>(false);

    const handleDrop = async (e: File[], ...fields: string[]) => {
      const { type, name: fileName } = e[0];
      const fileNameWithTimeStamp = fileNameTimeStampFormat(fileName);
      const fileSerive = getFileService();

      const [s3UrlField, expiryDateField, driverDocumentTypeEnum, field] = fields;
      const documentTypeCorrespondentEnum = driverDocumentTypeEnum.toUpperCase() as keyof typeof DriverDocumentType;
      const payloadObject = {
        category_id: customerProfileFields.id ?? '',
        document_category: 'DRIVER',
        expiry_date: customerProfileFields[expiryDateField],
        document_type: DriverDocumentType[documentTypeCorrespondentEnum],
        type,
        fileName: fileNameWithTimeStamp,
      };
      const path = `DOCUMENTS/${payloadObject.document_category}/${payloadObject.category_id}/${payloadObject.document_type}/${payloadObject.fileName}`;

      dispatch(
        updateUploadState({
          loading: {
            field: `${field}_is_loading`,
            value: true,
          },
        })
      );

      try {
        const result = await fileSerive.uploadFileToStorage({
          path,
          data: e[0],
        });
        dispatch(
          updateUploadState({
            loading: {
              field: `${field}_is_loading`,
              value: false,
            },
            url: {
              field: s3UrlField,
              value: result.path,
            },
            type: {
              field: `${field}_file_type`,
              value: type,
            },
          })
        );
      } catch (error: any) {
        errors[field] = error as any;
        dispatch(
          updateCustomerDetailsState({
            ...customerProfileFields,
            [`${field}_is_loading`]: false,
          })
        );
      }
    };

    return (
      <Documents ref={ref}>
        <FlexLayout itemsX="space-between" gap={40}>
          {customerProfileFields?.dvla_front_s3_url && isDigitalOnb && !uplaodDvlaFront ? (
            <DocumentConfirmation
              source={customerProfileFields?.dvla_front_s3_url}
              name="DVLA front"
              switchToUpload={() => setUplaodDvlaFront(true)}
            />
          ) : (
            <Uploader
              styled={{ width: '100%' }}
              required={!customerProfileFields.dvla_front_s3_url}
              loading={customerProfileFields.dvla_front_is_loading}
              label="Upload DVLA front image"
              control={control as unknown as Control<FieldValues>}
              name="dvla_front"
              error={errors?.dvla_front as FieldError}
              onDrop={(e: File[]) => handleDrop(e, 'dvla_front_s3_url', 'dvla_expiry_date', 'DVLA_FRONT', 'dvla_front')}
              fileUrlToBlob={customerProfileFields['dvla_front_s3_url']}
            />
          )}
          {customerProfileFields?.dvla_back_s3_url && isDigitalOnb && !uplaodDvlaBack ? (
            <DocumentConfirmation
              source={customerProfileFields?.dvla_back_s3_url}
              name="DVLA back"
              switchToUpload={() => setUplaodDvlaBack(true)}
            />
          ) : (
            <Uploader
              required={!customerProfileFields.dvla_back_s3_url}
              loading={customerProfileFields.dvla_back_is_loading}
              label="Upload DVLA back image"
              control={control as unknown as Control<FieldValues>}
              name="dvla_back"
              error={errors?.dvla_back as FieldError}
              onDrop={(e: File[]) =>
                handleDrop(e, 'dvla_back_s3_url', 'dvla_back_expiry_date', 'DVLA_BACK', 'dvla_back')
              }
              fileUrlToBlob={customerProfileFields['dvla_back_s3_url']}
            />
          )}
        </FlexLayout>
        <FlexLayout itemsX="space-between" gap={40}>
          {customerProfileFields?.pco_s3_url && isDigitalOnb && !uplaodPCO ? (
            <DocumentConfirmation
              source={customerProfileFields?.pco_s3_url}
              name="PCO badge"
              switchToUpload={() => setUplaodPCO(true)}
            />
          ) : (
            <Uploader
              required={!customerProfileFields.pco_s3_url}
              loading={customerProfileFields.pco_is_loading}
              label="PCO badge"
              control={control as unknown as Control<FieldValues>}
              name="pco"
              error={errors?.pco as FieldError}
              onDrop={(e: File[]) => handleDrop(e, 'pco_s3_url', 'pco_expiry_date', 'PCO', 'pco')}
              fileUrlToBlob={customerProfileFields['pco_s3_url']}
            />
          )}
          {customerProfileFields?.pco_cert_s3_url && isDigitalOnb && !uplaodPCOCert ? (
            <DocumentConfirmation
              source={customerProfileFields?.pco_cert_s3_url}
              name="PCO certificate"
              switchToUpload={() => setUplaodPCOCert(true)}
            />
          ) : (
            <Uploader
              required={!customerProfileFields.pco_cert_s3_url}
              loading={customerProfileFields.pco_cert_is_loading}
              label="PCO certificate"
              control={control as unknown as Control<FieldValues>}
              name="pco_cert"
              error={errors?.pco_cert as FieldError}
              onDrop={(e: File[]) => handleDrop(e, 'pco_cert_s3_url', 'pco_cert_expiry_date', 'PCO_CERT', 'pco_cert')}
              fileUrlToBlob={customerProfileFields['pco_cert_s3_url']}
            />
          )}
        </FlexLayout>
        <FlexLayout styled={{ width: '48%' }} itemsX="start">
          {customerProfileFields?.poa_s3_url && isDigitalOnb && !uploadPOA ? (
            <DocumentConfirmation
              source={customerProfileFields?.poa_s3_url}
              name="Proof of address"
              switchToUpload={() => setUploadPOA(true)}
            />
          ) : (
            <Uploader
              required={!customerProfileFields.poa_s3_url}
              loading={customerProfileFields.poa_is_loading}
              label="Upload proof of address"
              control={control as unknown as Control<FieldValues>}
              name="poa"
              error={errors?.poa as FieldError}
              onDrop={(e: File[]) => handleDrop(e, 'poa_s3_url', 'poa_expiry_date', 'POA', 'poa')}
              fileUrlToBlob={customerProfileFields['poa_s3_url']}
            />
          )}
        </FlexLayout>
      </Documents>
    );
  }
);
