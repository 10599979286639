import React, { useState } from 'react';
import { PrimaryButton } from '../../../../uiComponents/buttons/primaryButton/primaryButton';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { useForm } from 'react-hook-form';
import { AftercareContact, CallbackRequest } from '../../../../models/aftercare';
import { Notification } from '../../../../uiComponents/toast/toast';
import { registerCallbackRequest } from '../../../../api/post/aftercare.post';
import { TextField } from '../../../../uiComponents/inputs/textField/textField';
import { StyledTextFieldLabel, TextAreaContainer } from '../../aftercare.styles';
import { GridLayout } from '../../../../uiComponents/layouts/gridLayout/gridLayout';

interface CallbackFormValues {
  category_id: string;
  request_hours?: number;
  request_minutes?: number;
  request_date: string;
  note: string;
}
export const CallbackForm = ({
  aftercare,
  onClose,
  onSubmit,
}: {
  aftercare: AftercareContact;
  onClose: () => void;
  onSubmit: () => void;
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<CallbackFormValues, string>({
    mode: 'all',
    reValidateMode: 'onSubmit',
    defaultValues: {
      category_id: aftercare.agreement_id,
      note: '',
    },
  });
  const [submitting, setSubmitting] = useState<boolean>(false);

  const onFormSubmit = async (submitValues: CallbackFormValues) => {
    setSubmitting(true);
    const handleResponse: () => void = () => {
      setSubmitting(false);
      Notification({
        type: 'success',
        title: 'Success',
        message: 'Callback request submitted successfully',
        isAlert: true,
      });
      onSubmit();
      onClose();
    };

    const handleError: (error: { message: string }) => void = (error: { message: string }) => {
      Notification({ type: 'error', title: 'Error', message: error.message, isAlert: true });
    };

    const payload: CallbackRequest = {
      request_date: submitValues.request_date,
      request_time: `${submitValues.request_hours}:${submitValues.request_minutes}`,
      notes: submitValues.note,
      category_id: submitValues.category_id,
    };
    registerCallbackRequest(payload)
      .then(() => {
        handleResponse();
      })
      .catch((error: { message: string }) => {
        handleError(error);
      });
  };
  return (
    <form>
      <FlexLayout gap={20} vertical>
        <GridLayout template={3} gap={16}>
          <TextField
            label="Date"
            {...register('request_date', {
              required: 'Date is a required field',
            })}
            required
            type="date"
            error={errors?.request_date}
          />
          <TextField
            label="Hours"
            {...register('request_hours', {
              required: 'Hours is a required field',
            })}
            required
            type="number"
            placeholder="Hours"
            error={errors?.request_hours}
          />
          <TextField
            label="Minutes"
            {...register('request_minutes', {
              required: 'Minutes is a required field',
            })}
            required
            type="number"
            placeholder="Minutes"
            error={errors?.request_minutes}
          />
        </GridLayout>
        <TextAreaContainer>
          <StyledTextFieldLabel $isRequired={false}>Notes</StyledTextFieldLabel>
          <TextField placeholder="Notes" type="text" {...register('note')} error={errors?.note} />
        </TextAreaContainer>
        <FlexLayout itemsX="end">
          <PrimaryButton isProcessing={submitting} onClick={handleSubmit(onFormSubmit)}>
            Submit
          </PrimaryButton>
        </FlexLayout>
      </FlexLayout>
    </form>
  );
};
