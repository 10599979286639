import { styled } from 'styled-components';

export const RouteContainer = styled.div``;

export const ChildRouteContainer = styled.div`
  margin: 0 32px;
  min-width: 1024px;
`;

export const Ref = styled.div`
  position: absolute;
  margin-top: -150px;
`;
