import styled from 'styled-components';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../common/styles/Colors';
import { darken } from '../../utils/colorUtils';

export const StyledMenu = styled.div`
  border-radius: 16px;
  overflow: hidden;
  background-color: ${PRIMARY_WHITE};
  box-shadow: 0 2px 16px rgba(7, 0, 47, 0.1);
`;

export const MenuOption = styled.div`
  display: flex;
  align-items: center;
  min-height: 48px;
  padding: 0 27px;
  font-size: 16px;
  line-height: 22px;
  color: ${PRIMARY_PURPLE};
  white-space: nowrap;
  transition: background-color 0.3s ease-out;

  &:hover {
    background-color: ${darken(PRIMARY_WHITE, 5)};
  }
`;
