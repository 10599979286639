import api from '../../utils/api';
import { CreateEmployeeResponse, EmployeePayload } from '../../models/employee';
/**
 * Create Employee Request
 * @category API/POST
 * @param {object} body - Data for the employee creation
 * @return {Promise} Returns a promise for the api request
 * @example
 * const values = {
 *  email: '',
 *  first_name: '',
 *  last_name: '',
 * ...
 * }
 * createEmployee(values).then((res) => {
 *  // Promise resolved
 * }).error((err) => {
 * // Error
 * });
 */
export const createEmployee = (body: EmployeePayload): Promise<{ data: CreateEmployeeResponse }> =>
  api.post('employee/', body);
