/* eslint-disable no-console */
import { Amplify } from 'aws-amplify';
import {
  signIn,
  signInWithRedirect,
  fetchAuthSession,
  getCurrentUser,
  signOut,
  updatePassword,
  resetPassword,
  confirmResetPassword,
} from '@aws-amplify/auth';

Amplify.configure({
  Auth: {
    Cognito: {
      identityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID || '',
      userPoolClientId: process.env.REACT_APP_COGNITO_USER_POOL_CLIENT_ID || '',
      userPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID || '',
      loginWith: {
        username: true,
        email: true,
        phone: false,
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN || '',
          scopes: ['email', 'openid', 'profile', 'aws.cognito.signin.user.admin'],
          redirectSignIn: [`${window.location.origin}/dashboard`],
          redirectSignOut: [`${window.location.origin}/`],
          responseType: 'code' as const,
        },
      },
    },
  },
  Storage: {
    S3: {
      bucket: process.env.REACT_APP_ASSETS_BUCKET_NAME || '',
      region: process.env.REACT_APP_COGNITO_REGION || '',
    },
  },
});

class AuthService {
  async loginWithUsername(username: string, password: string) {
    try {
      const res = await signIn({ username, password });
      if (!res.isSignedIn) throw new Error('Failed to sign in');
    } catch (error) {
      console.error(error);
    }
  }

  async loginInWithGoogle() {
    try {
      await signInWithRedirect({ provider: 'Google' });
    } catch (error) {
      console.error(error);
    }
  }

  async updateUserPassword(oldPassword: string, newPassword: string) {
    try {
      await updatePassword({ oldPassword, newPassword });
    } catch (error) {
      console.error(error);
    }
  }

  async resetUserPassword(username: string) {
    try {
      await resetPassword({
        username,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async confirmResetUserPassword(username: string, newPassword: string, confirmationCode: string) {
    try {
      await confirmResetPassword({
        username,
        newPassword,
        confirmationCode,
      });
    } catch (error) {
      console.error(error);
    }
  }

  async getCurrentAuthUser() {
    try {
      const user = await getCurrentUser();
      // console.log({ user });
      return user;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async fetchCurrentAuthSession() {
    try {
      const session = await fetchAuthSession();
      // console.log({ session });
      if (session && session.tokens && session.tokens.accessToken) {
        // console.log(session.tokens.accessToken.toString());
      }
      return session;
    } catch (error) {
      console.error(error);
      return null;
    }
  }

  async logout() {
    try {
      await signOut();
    } catch (error) {
      console.error(error);
    }
  }

  async getAuthHeaders() {
    try {
      const session = await this.fetchCurrentAuthSession();
      if (session) {
        return `Bearer ${session.tokens?.accessToken.toString()}`;
      }
      return null;
    } catch (error) {
      console.error(error);
      return null;
    }
  }
}

let authService: AuthService | null = null;

export function getAuthService() {
  if (!authService) {
    authService = new AuthService();
  }
  return authService;
}

/*
{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Sid": "",
            "Effect": "Allow",
            "Principal": {
                "Federated": "cognito-identity.amazonaws.com"
            },
            "Action": "sts:AssumeRoleWithWebIdentity",
            "Condition": {
                "StringEquals": {
                    "cognito-identity.amazonaws.com:aud": "<cognitio-identity-pool-id>"
                },
                "ForAnyValue:StringLike": {
                    "cognito-identity.amazonaws.com:amr": "authenticated"
                }
            }
        }
    ]
}

*/
