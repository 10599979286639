import { useEffect, useState } from 'react';
import { BsCheckLg } from 'react-icons/bs';
import { Declaration, LiabilityAnswer } from '../../order/order.styles';
import { LIABILITY_QUESTIONS } from '../../../../consts';
import { FlexLayout } from '../../../../uiComponents/layouts/flexLayout/flexLayout';
import { Text } from '../../../../uiComponents/text/text';
import { getDriverByDVLA } from '../../../../api/get/driver.get';
import { PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../../common/styles/Colors';
import { CustomerProfile } from '../../../../models/driver';

export const LiabilityReview = ({ dvlaNumber }: { dvlaNumber: string }) => {
  const [liabilityAnswers, setLiabilityAnswers] = useState<boolean[]>();

  useEffect(() => {
    getDriverByDVLA(dvlaNumber).then(
      (response: { data: CustomerProfile; message: undefined } | { message: 'OK'; data: undefined }) => {
        setLiabilityAnswers(response?.data?.liability_questionnaire_responses ?? []);
      }
    );
  }, [dvlaNumber]);

  return (
    <Declaration>
      {LIABILITY_QUESTIONS?.map((question: string, index: number) => (
        <FlexLayout key={question} styled={{ marginTop: 16 }} itemsX="space-between" itemsY="center">
          <Text variant="body6" weight={500} color={PRIMARY_PURPLE}>
            {question}
          </Text>
          <FlexLayout gap={32}>
            <FlexLayout gap={8}>
              <LiabilityAnswer itemsX="center" itemsY="center" $fill={liabilityAnswers?.[index] ?? false}>
                <BsCheckLg size={16} color={PRIMARY_WHITE} />
              </LiabilityAnswer>
              Yes
            </FlexLayout>
            <FlexLayout gap={8}>
              <LiabilityAnswer itemsX="center" itemsY="center" $fill={!liabilityAnswers?.[index]}>
                <BsCheckLg size={16} color={PRIMARY_WHITE} />
              </LiabilityAnswer>
              No
            </FlexLayout>
          </FlexLayout>
        </FlexLayout>
      ))}
    </Declaration>
  );
};
