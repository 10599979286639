import api from '../../utils/api';

export interface ReassignExcessMileagePayload {
  weeklyMileageIds: string[];
  fromDriverId: string;
  toDriverId: string;
}

export const rejectPendingExcessMileagesCharges = (payload: { chargesIds: string[] }) =>
  api.patch('additional-charge/excess-mileage/reject', payload);

export const reassignExcessMileagesCharges = (payload: ReassignExcessMileagePayload) =>
  api.patch('additional-charge/excess-mileage/reassign', payload);
