import { Driver } from '../../models/driver';
import api from '../../utils/api';

/**
 * Delete document request
 * @category API/DELETE
 * @param {object} id - document id for the user to delete the document
 * @return {Promise} Returns a promise for the api request
 * @example
 * deleteDocument(id).then((res) => {
 *  // Promise resolved
 * });
 */
export const deleteDocument = (id: string): Promise<{ data: Driver }> => api.delete(`/document/${id}`);
