import Compressor from 'compressorjs';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FiCamera, FiX } from 'react-icons/fi';
import { Control, Controller, FieldError, FieldValues, Path } from 'react-hook-form';
import { STATUS_RED, SECONDARY_PURPLE_30, PRIMARY_PURPLE, PRIMARY_WHITE } from '../../../common/styles/Colors';
import { FlexLayout } from '../../layouts/flexLayout/flexLayout';
import { withStyledProps } from '../../../utils/colorUtils';
import {
  UploadImageText,
  UploadLabel,
  UploaderContainer,
  UploaderText,
  UploaderTitle,
  UploaderContainerSmall,
  UploaderContainerSection,
  UploaderTextPurple,
  UploadBackgroundImage,
  UploaderFakeButton,
} from './multiUploaderInput.styles';
import { Text } from '../../text/text';
import { Notification } from '../../toast/toast';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';
import { ImageArrayType } from '../../../models/transfer';

const excelFileType = ['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'];

const compressImage = (img: File, callback: (result: File | Blob) => void) => {
  const maxFileSize = 2 * 1024 * 1024; // 3mb

  if (img.size > maxFileSize && img.type !== 'application/pdf' && !excelFileType.includes(img.type)) {
    new Compressor(img, {
      quality: 0.6,
      convertTypes: ['image/jpg', 'image/jpeg', 'image/png', 'image/webp'],
      convertSize: 5242880, // 5MB
      height: 2038,
      width: 1024,
      resize: 'none',
      success(result) {
        callback(result);
      },
    });
  } else {
    callback(img);
  }
};

interface UploaderProps {
  disabled?: boolean;
  name: string;
  error?: FieldError;
  onChange: (value: Array<Blob | File>) => void;
  value?: Array<Blob | File>;
  label: string;
  invalid?: boolean;
  required?: boolean;
  hideRequiredIndicator?: boolean;
  loading?: boolean;
  maxFiles: number;
  isSmall?: boolean;
}

const UploaderInput = React.forwardRef<HTMLInputElement, UploaderProps>(
  (
    {
      disabled,
      loading,
      label,
      hideRequiredIndicator,
      error,
      onChange,
      invalid,
      value,
      required,
      maxFiles,
      isSmall,
    }: UploaderProps,
    ref
  ) => {
    const [newFile, setNewFile] = useState<boolean>(true);
    const { acceptedFiles, getRootProps, getInputProps, fileRejections } = useDropzone({
      accept: {
        'image/png': ['.png'],
        'image/jpg': ['.jpg'],
        'image/jpeg': ['.jpeg'],
        'application/pdf': ['.pdf'],
      },
      multiple: true,
      maxSize: 26214400, // 25MB
      onDrop: () => setNewFile(true),
      onFileDialogOpen: () => setNewFile(true),
    });

    useEffect(() => {
      if (fileRejections && fileRejections.length > 0) {
        Notification({
          type: 'error',
          title: 'Upload failed',
          message: 'File too big (25MB limit) or incorrect format',
          isAlert: true,
        });
      }
    }, [fileRejections]);

    const updateImage = useCallback(
      async (imagesToCompress: File[]) => {
        if (newFile) {
          setNewFile(false);
          const files: Array<Blob | File> = [];

          for (const imageToCompress of imagesToCompress) {
            const compressedImage = await new Promise<File | Blob>((resolve) => {
              compressImage(imageToCompress, (image: File | Blob) => {
                resolve(image);
              });
            });
            files.push(compressedImage);
          }

          onChange?.(files);
        }
      },
      [onChange, newFile]
    );

    useEffect(() => {
      if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles.length <= maxFiles) {
        updateImage(acceptedFiles);
      }
    }, [maxFiles, acceptedFiles, updateImage]);

    return (
      <>
        {!isSmall && (
          <div>
            <UploadLabel>
              {label}
              {required && !hideRequiredIndicator && <span style={{ color: STATUS_RED, marginLeft: '5px' }}>*</span>}
            </UploadLabel>
            <UploaderContainer $error={invalid} $active={!!value} $disabled={disabled || loading} {...getRootProps()}>
              <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="start" itemsY="center">
                <FiCamera size={52} color={SECONDARY_PURPLE_30} />
                <UploadImageText>
                  <input type="file" ref={ref} {...getInputProps()} />
                  <UploaderTitle>Upload your image</UploaderTitle>
                  <UploaderText>Drag and drop or take a snapshot</UploaderText>
                </UploadImageText>
              </FlexLayout>
            </UploaderContainer>
            {error && (
              <Text variant="body7" color={STATUS_RED} weight={300}>
                {error?.message ?? ''}
              </Text>
            )}
          </div>
        )}
        {isSmall && (
          <div>
            <UploaderContainerSection>
              <UploaderContainerSmall
                $error={invalid}
                $active={!!value}
                $disabled={disabled || loading}
                {...getRootProps()}
              >
                <FlexLayout vertical={false} styled={{ height: '100%' }} itemsX="center" itemsY="center">
                  <input type="file" ref={ref} {...getInputProps()} />
                  <FiCamera size={26} color={PRIMARY_PURPLE} />
                </FlexLayout>
              </UploaderContainerSmall>
              <UploaderTextPurple>Add photo</UploaderTextPurple>
            </UploaderContainerSection>
            {error && (
              <Text variant="body7" color={STATUS_RED} weight={300}>
                {error?.message ?? ''}
              </Text>
            )}
          </div>
        )}
      </>
    );
  }
);

interface MultiUploaderForm<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  onSubmit: (value: Array<Blob | File | ImageArrayType>) => void;
  value?: Array<Blob | File>;
  disabled?: boolean;
  loading?: boolean;
  label: string;
  name: TName;
  required?: boolean;
  error?: FieldError;
  hideRequiredIndicator?: boolean;
  maxFiles: number;
  invalid: boolean;
  defaultValue?: Array<Blob | File | ImageArrayType>;
}

export const MultiUploaderForm = ({
  onSubmit,
  name,
  value,
  disabled,
  loading,
  label,
  required,
  error,
  maxFiles,
  invalid,
  defaultValue,
}: MultiUploaderForm) => {
  const [files, setFiles] = useState<Array<Blob | File | ImageArrayType>>(defaultValue || []);

  function onChange(val: Array<Blob | File | ImageArrayType>) {
    setFiles([...files, ...val].slice(0, maxFiles));
    onSubmit([...files, ...val].slice(0, maxFiles));
  }

  function remove(val: number) {
    const result: Array<Blob | File | ImageArrayType> = [];
    files.forEach((file, index) => {
      if (index !== val) {
        result.push(file);
      }
    });
    setFiles(result);
    onSubmit(result);
  }

  return (
    <div>
      {files.length === 0 && (
        <UploaderInput
          label={label}
          required={required}
          disabled={disabled}
          loading={loading}
          error={error}
          value={value}
          maxFiles={maxFiles}
          invalid={invalid}
          {...{ onChange, name }}
        />
      )}
      {files.length > 0 && (
        <>
          <UploadLabel>Add photos</UploadLabel>
          <GridLayout template={6} gap={8}>
            {files.map((file, index) => {
              return (
                <UploaderContainerSection key={index}>
                  <UploaderContainerSmall>
                    <UploadBackgroundImage
                      draggable="false"
                      alt="image"
                      src={'s3_url' in file ? file.s3_url : URL.createObjectURL(file as File)}
                    />
                  </UploaderContainerSmall>
                  <UploaderTextPurple>{(file as File)?.name}</UploaderTextPurple>
                  <UploaderFakeButton onClick={() => remove(index)}>
                    <FiX size={26} color={PRIMARY_WHITE} />
                  </UploaderFakeButton>
                </UploaderContainerSection>
              );
            })}
            <UploaderInput
              label={label}
              required={required}
              disabled={disabled}
              loading={loading}
              error={error}
              value={value}
              maxFiles={maxFiles}
              invalid={invalid}
              isSmall
              {...{ onChange, name }}
            />
          </GridLayout>
        </>
      )}
    </div>
  );
};

interface MultiUploaderInputProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends Path<TFieldValues> = Path<TFieldValues>,
> {
  disabled?: boolean;
  loading?: boolean;
  label: string;
  control: Control<TFieldValues, string>;
  name: TName;
  required?: boolean;
  error?: FieldError;
  hideRequiredIndicator?: boolean;
  maxFiles: number;
  defaultValue?: Array<Blob | File | ImageArrayType>;
}

export const MultiUploaderInput = withStyledProps(
  <TFieldValues extends FieldValues = FieldValues, TName extends Path<TFieldValues> = Path<TFieldValues>>({
    disabled,
    label,
    name,
    required,
    loading,
    control,
    error,
    maxFiles,
    defaultValue,
    ...props
  }: MultiUploaderInputProps<TFieldValues, TName>) => {
    return (
      <Controller
        control={control}
        rules={{
          required: required ? 'Please upload a file' : undefined,
        }}
        name={name}
        render={({ field: { value, onChange, name }, fieldState: { invalid } }) => {
          return (
            <div {...props}>
              <MultiUploaderForm
                label={label}
                required={required}
                disabled={disabled}
                loading={loading}
                error={error}
                invalid={invalid}
                value={value}
                maxFiles={maxFiles}
                onSubmit={onChange}
                defaultValue={defaultValue}
                {...{ name }}
              />
            </div>
          );
        }}
      />
    );
  }
);
