import React from 'react';
import { Text } from '../../../uiComponents/text/text';
import { Controller, useFormContext } from 'react-hook-form';
import { PRIMARY_PURPLE } from '../../../common/styles/Colors';
import { FlexLayout } from '../../../uiComponents/layouts/flexLayout/flexLayout';
import { Slider } from './styles';
import { GridLayout } from '../../../uiComponents/layouts/gridLayout/gridLayout';

interface ControlledSliderProps {
  label: string;
  name: string;
  sliderLevels: string[];
}

export function ControlledSlider({ label, sliderLevels, name }: ControlledSliderProps) {
  const { control } = useFormContext();

  return (
    <div>
      <Text variant="body6" color={PRIMARY_PURPLE} weight={500} block styled={{ marginBottom: 16 }}>
        {label}
      </Text>
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value } }) => {
          return (
            <FlexLayout gap={12} itemsY="center">
              <Slider $value={value} value={value} min={0} max={100} type="range" onChange={onChange} />
              <Text variant="body6" color={PRIMARY_PURPLE} weight={300}>{`${value}%`}</Text>
            </FlexLayout>
          );
        }}
      />
      <GridLayout template={sliderLevels?.length} style={{ marginTop: 8 }}>
        {sliderLevels?.map((level: string) => (
          <Text key={level} variant="body8" color={PRIMARY_PURPLE} weight={300}>
            {level}
          </Text>
        ))}
      </GridLayout>
    </div>
  );
}
