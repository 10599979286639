import styled from 'styled-components';
import { GRAY_10 } from '../../../../common/styles/Colors';

export const DriverTagBox = styled.div`
  width: 100%;
  border: 0.5px solid ${GRAY_10};
  margin-top: 10px;
  padding: 5px 10px 5px 10px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;
